import { useEventCallback } from '@mui/material/utils';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { TableInstance } from 'react-table';
import { uuid } from 'short-uuid';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { RELedgerAccountSplit } from '@work4all/models/lib/Classes/RELedgerAccountSplit.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  settings,
  useSetting,
} from '../../../../../../../../../../../../settings';
import { useMaskContext } from '../../../../../../../../../hooks/mask-context';
import { parseTemplate } from '../../../../../../../../../utils/use-assignable-template-entity';
import { EditTable } from '../../../../../../../../erp/components/tab-panels/positions/components/edit-table/EditTable';
import { useEditableState } from '../../../../../../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import {
  EditTableEntry,
  OnEditPosition,
} from '../../../../../../../../erp/components/tab-panels/positions/components/edit-table/types';

import { useErpBookingsColumns } from './use-erp-bookings-columns';

declare module 'react-table' {
  interface ColumnInterface {
    align?: 'left' | 'right';
  }
}

type BookingPosition = RELedgerAccountSplit & EditTableEntry;

export type IErpBookingsTableProps = {
  tableInstanceRef: React.RefObject<TableInstance | null>;
  disabled?: boolean;
  defaultLedgerAccount: LedgerAccount;
  bookings: RELedgerAccountSplit[];
  onSelectedPositionIdsChange: (selectedPositionIds: number[]) => void;
  onAddBooking: (booking: RELedgerAccountSplit) => void;
  onEditBooking: (booking: RELedgerAccountSplit) => void;
};

export const ErpBookingTable = (props: IErpBookingsTableProps) => {
  const {
    disabled = false,
    defaultLedgerAccount,
    bookings,
    onAddBooking,
    onEditBooking,
    onSelectedPositionIdsChange,
    tableInstanceRef,
  } = props;

  const mask = useMaskContext();
  const template = parseTemplate(mask);
  const isProjectBased =
    template?.entity === Entities.project && mask.isCreateMode;
  const projectId = isProjectBased ? template.id : undefined;
  const { getValues } = useFormContext();

  const cacheIdLookUp = useRef<{ localId: string; id: number }[]>([]);
  const items = useMemo(() => {
    const returnList: BookingPosition[] = [];

    bookings.forEach((pos: BookingPosition) => {
      let lookUp = cacheIdLookUp.current.find((l) => l.id === pos.id);
      if (!lookUp) {
        const lookUpIndex = cacheIdLookUp.current.findIndex((l) => l.id === 0);
        if (lookUpIndex !== -1) {
          cacheIdLookUp.current[lookUpIndex].id = pos.id;
          lookUp = cacheIdLookUp.current[lookUpIndex];
        } else {
          // only initial phase
          lookUp = {
            localId: uuid(),
            id: pos.id,
          };
          cacheIdLookUp.current.push(lookUp);
        }
      }
      pos.localId = lookUp.localId;
      returnList.push(pos);
    });

    let placeholder = cacheIdLookUp.current.find((l) => l.id === 0);
    if (!placeholder) {
      cacheIdLookUp.current.push({
        localId: uuid(),
        id: 0,
      });
    }
    placeholder = cacheIdLookUp.current.find((l) => l.id === 0);

    const position: BookingPosition = {
      id: placeholder.id,
      localId: placeholder.localId,
      cacheOnly: true,
      konto: defaultLedgerAccount,
      costCenter: null,
      costGroup: null,
      note: '',
      taxKeyValue: defaultLedgerAccount?.taxKeyValue ?? 0,
      vat: 0,
      vatAmount: 0,
      valueNet: 0,
      proportionDM: 0,
      projectId,
      project: projectId ? getValues('project') : null,
    };
    returnList.push(position);

    return returnList;
  }, [defaultLedgerAccount, bookings, projectId, getValues]);

  const callbacks = useMemo(() => {
    const onAddPosition = () => {
      onAddBooking({});
    };

    const onEditPosition = (result: OnEditPosition<RELedgerAccountSplit>) => {
      if (!Object.keys(result.position).filter((pos) => pos !== 'id').length)
        return;
      onEditBooking(result.position);
    };

    return {
      onAddPosition,
      onEditPosition,
    };
  }, [onAddBooking, onEditBooking]);

  const { positions, ...localCallbacks } = useEditableState<BookingPosition>({
    positions: items,
    ...callbacks,
  });

  const handleEdit = useEventCallback(
    (original: BookingPosition, changes: RELedgerAccountSplit) => {
      if (!original.id) {
        onAddBooking({
          konto: defaultLedgerAccount,
          taxKeyValue:
            changes?.konto?.taxKeyValue ??
            defaultLedgerAccount?.taxKeyValue ??
            0,
          projectId,
          ...changes,
        });
      } else {
        localCallbacks.onEditPosition({
          position: { ...changes, id: original.id, localId: original.localId },
        });
      }
    }
  );

  const onEditPosition = useEventCallback(
    (input: OnEditPosition<BookingPosition>) => {
      if (!input.position.id) {
        const shouldAdd = Object.entries(input.position)
          .filter((pos) => pos[0] !== 'localId' && pos[0] !== 'id')
          .some((pos) => pos[1]);
        if (!shouldAdd) return;

        onAddBooking({
          konto: defaultLedgerAccount,
          taxKeyValue:
            input.position?.konto?.taxKeyValue ??
            defaultLedgerAccount?.taxKeyValue ??
            0,
          projectId,
          ...input.position,
        });
      } else {
        localCallbacks.onEditPosition(input);
      }
    }
  );

  const columns = useErpBookingsColumns({ bookings, disabled, handleEdit });

  const noIdEditableCells = useMemo(
    () =>
      columns
        .map((x) => x.accessor as string)
        .filter((def) => def !== 'vatAmount'),
    [columns]
  );

  const columnSettings = useSetting(settings.incomingInvoicePositionsColumn());

  const lastIds = useRef<string[]>([]);
  const onSelectedItemIdsChange = useEventCallback((ids: string[]) => {
    lastIds.current = ids;
    onSelectedPositionIdsChange(
      ids.map((id) => cacheIdLookUp.current.find((y) => y.localId === id)?.id)
    );
  });

  useEffect(() => {
    if (lastIds.current.length) {
      onSelectedPositionIdsChange(
        lastIds.current.map(
          (localId) =>
            cacheIdLookUp.current.find((y) => y.localId === localId)?.id
        )
      );
    }
  }, [bookings, onSelectedPositionIdsChange]);

  return (
    <EditTable<RELedgerAccountSplit>
      {...localCallbacks}
      onEditPosition={onEditPosition}
      tableInstanceRef={tableInstanceRef}
      items={positions}
      columns={columns}
      disabled={disabled}
      columnSettings={columnSettings}
      noIdEditableCells={noIdEditableCells}
      onSelectedItemIdsChange={onSelectedItemIdsChange}
      bordersKey="INCOMING_INVOICE"
      fitSpace
      updateMode="cell"
    />
  );
};
