import { Typography } from '@mui/material';
import { MouseEventHandler, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ArticleImage } from '@work4all/models/lib/Classes/ArticleImage.entity';
import { File } from '@work4all/models/lib/Classes/File.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canEditArticle } from '@work4all/utils/lib/permissions';

import { MIME_TYPES, Preview } from '../../../preview/Preview';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import {
  InfoCard,
  InfoCards,
  PreviewTitle,
  PreviewWrapper,
} from '../components';
import { PreviewInputsProvider } from '../components/preview-input';
import { ArticleGroupPickerInput } from '../components/preview-input/components/pickers/ArticleGroupPickerInput';
import { useProcessHtml } from '../EntityHistory/components/comment-event-view/hooks';
import { usePreviewNavigation } from '../erp-preview/hooks/use-preview-navigation';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { articlePreviewFields } from './ArticlePreviewContainer';

export type IArticlePreviewProps = {
  articles: Article[];
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  onVisibilityToggle?: (visible: boolean) => void;
};

export function ArticlePreview(props: IArticlePreviewProps) {
  const {
    articles,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;

  const user = useUser();
  const { t } = useTranslation();
  const { isInaccessible } = useInaccessibleFields();

  const numberIsAccessible = !isInaccessible(Entities.article, 'number');
  const unitIsAccessible = !isInaccessible(Entities.article, 'unit');
  const entryPriceIsAccessible = !isInaccessible(
    Entities.article,
    'entryPrice'
  );

  const [mutate] = useDataMutation<Article, EMode.upsert>({
    entity: Entities.article,
    mutationType: EMode.upsert,
    responseData: articlePreviewFields as unknown as Article,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.task,
    entries: articles,
    mutate,
  });

  const longtextIsAccessible = !isInaccessible(Entities.article, 'longtext');

  const titleRef = useRef<PreviewTitle>(null);

  const article = articles[0];
  const canEdit = canEditArticle(user, article);

  const articleDocumentList = useMemo(() => {
    const result = [...(article?.articleDocumentList || [])];
    return result.sort((a, b) => {
      return a.id === article?.standardImageId
        ? -1
        : b.id === article?.standardImageId
        ? 1
        : 0;
    });
  }, [article?.articleDocumentList, article?.standardImageId]);
  const { file, component } = usePreviewNavigation<File | ArticleImage>(
    articleDocumentList
  );

  const fileInfos = file?.fileInfos;
  const html = useProcessHtml(article?.longtext?.replace(/\n/g, '<br/>'));

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.article}
      entries={articles}
    >
      <PreviewWrapper>
        <PreviewTitle
          disabled={true}
          showEdit={canEdit}
          ref={titleRef}
          label={article?.name}
          onCloseClick={onCloseClick}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          convertProps={convertProps}
          rightActions={component}
          onVisibilityToggle={onVisibilityToggle}
        >
          {article?.name}
        </PreviewTitle>

        {fileInfos && (
          <Preview
            url={fileInfos?.previewUrl}
            mimeType={fileInfos?.previewMimeType as MIME_TYPES}
            noPreview={
              fileInfos?.fileServiceProviderInfos?.exists
                ? ''
                : t('MASK.NO_PREVIEW_FOR', {
                    type: fileInfos?.previewMimeType,
                  })
            }
            openInFullscreen={false}
          />
        )}

        <InfoCards columns={2}>
          {numberIsAccessible && (
            <InfoCard staticField label={t('COMMON.NUMBER')}>
              {article?.number}
            </InfoCard>
          )}

          <ArticleGroupPickerInput
            labelTranslationKey="COMMON.GROUP"
            onClose={entityPreview.onPopoverClose}
            value={article?.articleGroup}
            onChange={(value) =>
              entityPreview.onEdit({
                groupId: value ? value.id : 0,
              })
            }
            accessor="articleGroup"
          />

          {unitIsAccessible && (
            <InfoCard staticField label={t('FIELDS.unit')}>
              {article?.unit?.name}
            </InfoCard>
          )}

          {entryPriceIsAccessible && (
            <InfoCard staticField label={t('FIELDS.ENTRY_PRICE')}>
              {article?.entryPrice}
            </InfoCard>
          )}
        </InfoCards>
        {longtextIsAccessible && (
          <InfoCards columns={1}>
            <InfoCard staticField label={t('FIELDS.longtext')}>
              <Typography
                component="div"
                variant="body2"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </InfoCard>
          </InfoCards>
        )}
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
