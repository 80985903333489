import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Article } from '@work4all/models/lib/Classes/Article.entity';

type ArticleHandler = (value: { article: Article }) => void;

type ArticlePickerContextType = {
  handler: ArticleHandler;
  registerHandler: (handler: ArticleHandler) => void;
};

const ArticlePickerContext = createContext<
  ArticlePickerContextType | undefined
>(undefined);

export const useArticlePickerContext = () => {
  const context = useContext(ArticlePickerContext);
  if (!context) {
    throw new Error(
      'useArticlePickerContext must be used within ArticlePickerProvider'
    );
  }
  return context;
};

type ArticlePickerProviderProps = {
  children: ReactNode;
};

export const ArticlePickerProvider: React.FC<ArticlePickerProviderProps> = ({
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [handler, registerHandler] = useState<ArticleHandler>(() => () => {});

  return (
    <ArticlePickerContext.Provider value={{ handler, registerHandler }}>
      {children}
    </ArticlePickerContext.Provider>
  );
};
