import styles from './BomVariantExample.module.scss';

import { Box, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { type JSX, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';

import { BomVariant } from './BomVariantPicker';

interface BomVariantExampleProps {
  variant: BomVariant;
  selected: boolean;
  index: number;
  onClick: (variant: BomVariant) => void;
  sampleHeader: BomRowProps;
  sampleData: BomRowProps[];
}

export interface BomRowProps {
  number: string | JSX.Element;
  name: string | JSX.Element;
  currency: string;
  index?: number;
  expandable?: boolean;
}

export const BomVariantExample = (props: BomVariantExampleProps) => {
  const { variant, sampleHeader, sampleData } = props;
  const { t } = useTranslation();

  const Header = ({ expandable, number, name }: BomRowProps) => (
    <React.Fragment>
      <Typography
        variant="body2"
        className={clsx(styles.row, styles.first, {
          [styles.header]: !expandable,
        })}
      >
        {number}
        {expandable && <div className={styles.longLine} />}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.row, {
          [styles.header]: !expandable,
          [styles.double]: !expandable,
        })}
      >
        {name}
      </Typography>
      {expandable && (
        <Typography
          variant="body2"
          className={clsx(styles.row, styles.last, styles.gray)}
        />
      )}
    </React.Fragment>
  );

  const Row = ({ number, name, index, expandable }: BomRowProps) => (
    <React.Fragment>
      {expandable ? (
        <div className={clsx(styles.first)}>
          <div
            className={clsx(styles.line, {
              [styles.firstLine]: index === 0,
            })}
          />
        </div>
      ) : (
        <Typography variant="body2" className={clsx(styles.row, styles.first)}>
          {number || <pre style={{ margin: 0 }}> </pre>}
        </Typography>
      )}

      <Typography variant="body2" className={styles.row}>
        {name || <pre style={{ margin: 0 }}> </pre>}
      </Typography>
      <Typography variant="body2" className={clsx(styles.row, styles.last)} />
    </React.Fragment>
  );

  const Container = ({
    children,
    index,
    selected,
    onClick,
    variant,
  }: PropsWithChildren<BomVariantExampleProps>) => {
    return (
      <Tooltip title={t(`MASK.BILL_OF_MATERIALS_BOM_STYLE_VAL_${index}`)}>
        <div
          className={clsx(styles.container, {
            [styles.selected]: selected,
          })}
          onClick={() => onClick(variant)}
        >
          <Typography
            className={clsx(styles.marker, {
              [styles.selected]: selected,
            })}
          >
            {`${t('COMMON.VARIANT')} ${index}`}
          </Typography>
          <div className={styles.containerInner}>
            <div className={styles.containerGrid}>{children}</div>
          </div>
        </div>
      </Tooltip>
    );
  };

  switch (variant.id) {
    case InsertBomStyle.BOM_STYLE_1:
      return (
        <Container {...props}>
          <Header {...sampleHeader} />
          {sampleData.map((x) => (
            <Row {...x} />
          ))}
        </Container>
      );
    case InsertBomStyle.BOM_STYLE_2:
      return (
        <Container {...props}>
          {sampleData.map((x) => (
            <Row {...x} number={x.number.toString().split('.')[1]} />
          ))}
        </Container>
      );
    case InsertBomStyle.BOM_STYLE_3:
      return (
        <Container {...props}>
          <Row {...sampleHeader} />
        </Container>
      );
    case InsertBomStyle.BOM_STYLE_4:
      return (
        <Container {...props}>
          <Row
            {...sampleHeader}
            name={
              <React.Fragment>
                {sampleHeader.name}
                <div className={styles.list}>
                  {sampleData.map((x) => (
                    <Typography variant="body2">{`-  ${x.name}`}</Typography>
                  ))}
                </div>
              </React.Fragment>
            }
          />
        </Container>
      );
    case InsertBomStyle.BOM_STYLE_6:
      return (
        <Container {...props}>
          <Header
            expandable
            {...sampleHeader}
            number={<Box position="relative">{sampleHeader.number}</Box>}
          />
          {sampleData.map((x, idx) => (
            <Row {...x} index={idx} expandable />
          ))}
        </Container>
      );
    case InsertBomStyle.BOM_STYLE_5:
      return (
        <Container {...props}>
          <Header
            expandable
            {...sampleHeader}
            name={
              <React.Fragment>
                {sampleHeader.name}
                <div className={styles.list}>
                  {sampleData.map((x) => (
                    <Typography variant="body2">{`-  ${x.name}`}</Typography>
                  ))}
                </div>
              </React.Fragment>
            }
            number={<Box position="relative">{sampleHeader.number}</Box>}
          />
          {sampleData.map((x, idx) => (
            <Row {...x} index={idx} expandable />
          ))}
        </Container>
      );
    default:
      return (
        <Container {...props}>
          <Header {...sampleHeader} />
          {sampleData.map((x) => (
            <Row {...x} />
          ))}
        </Container>
      );
  }
};
