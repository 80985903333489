import styles from './FileContactsCard.module.scss';

import clsx from 'clsx';
import React, { type JSX } from 'react';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { InlineData, InlineDataProps } from './InlineData';

export interface InlineDataListItem extends InlineDataProps {
  id?: number | string;
  className?: string;
  acitonsComponent?: JSX.Element;
  hasDivider?: boolean;
}

export interface InlineDataListProps<T extends InlineDataListItem> {
  items: T[];
  onClick?: (item: T) => void;
  onLabelClick?: (item: T) => void;
}

export function InlineDataList<T extends InlineDataListItem>(
  props: InlineDataListProps<T>
) {
  const { onClick, items, onLabelClick } = props;

  return (
    <div className={clsx('custom-scrollbar', styles.list)}>
      {items.map((item) => {
        const { id, className, acitonsComponent, ...inlineDataProps } = item;
        const onLocalClick = inlineDataProps.onClick
          ? undefined
          : onClick
          ? () => onClick(item)
          : undefined;

        return (
          <React.Fragment key={id}>
            <div
              className={clsx(className, {
                [styles.row]: true,
                [styles.actionRow]: !!onLocalClick,
              })}
            >
              <div
                style={{ width: '100%' }}
                className={clsx({
                  [styles.button]: !!onLocalClick,
                })}
                onClick={onLocalClick}
              >
                <InlineData
                  onLabelClick={
                    onLabelClick ? () => onLabelClick(item) : undefined
                  }
                  {...inlineDataProps}
                />
              </div>
              {acitonsComponent ? (
                <div className={styles.actions}>{acitonsComponent}</div>
              ) : null}
            </div>
            {item.hasDivider && <Divider size="body" style={{ padding: 0 }} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
