import { useCallback, useEffect, useRef } from 'react';

import { useDialogs } from '@work4all/components';

import { CloneConvertDialog } from '../components/CloneConvertDialog';
import { CloneConvertDialogProps } from '../types';

export type CloneConvertDialogCallbacks = Omit<
  CloneConvertDialogProps,
  'onClose'
> &
  Partial<Pick<CloneConvertDialogProps, 'onClose'>>;
export const useCloneConvertDialogCallbacks = (
  props: Omit<CloneConvertDialogCallbacks, 'open'>
) => {
  const dialogs = useDialogs();
  const idRef = useRef<string>(null);

  const close = useCallback(() => {
    dialogs.close(idRef.current);
    idRef.current = '';
  }, [dialogs]);

  const open = useCallback(() => {
    if (idRef.current) return;
    const { id } = dialogs.open(CloneConvertDialog, {
      ...props,
      onClose: () => {
        props.onClose?.();
        close();
      },
      onConfirm: async (params) => {
        await props.onConfirm(params);
        close();
      },
    });
    idRef.current = id;
    return () => {
      dialogs.close(id);
    };
  }, [dialogs, props, close]);

  return { open, close };
};

export const useCloneConvertDialog = (
  props: Omit<CloneConvertDialogProps, 'open'>
) => {
  const { open, close } = useCloneConvertDialogCallbacks(props);
  useEffect(() => {
    if (props.targetEntity) open();
    else if (!props.targetEntity) close();
  }, [open, close, props.targetEntity]);
};
