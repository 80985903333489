import styles from './Search.module.scss';

import React, { CSSProperties, useLayoutEffect, useState } from 'react';

import { SearchBarWithDropDown } from '../../../search-bar-with-dropdown/SearchBarWithDropdown';

interface ISearchProps
  extends Omit<
    React.ComponentProps<typeof SearchBarWithDropDown>,
    'mobile' | 'autofocus'
  > {
  activeInputRef: React.RefObject<HTMLDivElement>;
  active: boolean;
}

export const Search: React.FC<ISearchProps> = (props) => {
  const { active, activeInputRef, disabled, ...searchProps } = props;

  const [positionStyles, setPositionStyles] = useState<CSSProperties | null>(
    null
  );
  useLayoutEffect(() => {
    if (active && activeInputRef.current) {
      setPositionStyles({
        top: activeInputRef.current?.offsetTop,
        height: activeInputRef.current?.offsetHeight,
      });
    } else {
      setPositionStyles(null);
    }
  }, [active, activeInputRef]);

  useLayoutEffect(() => {
    const height = positionStyles?.height;
    const newHeight = activeInputRef.current?.offsetHeight;
    /**
     * input height depends from "inputValue".
     * Try to update height when inputValue changes.
     */
    if (height && height !== newHeight) {
      setPositionStyles((old) => {
        return {
          ...old,
          height: newHeight,
        };
      });
    }
  }, [activeInputRef, positionStyles?.height, searchProps.inputValue]);

  if (!active) {
    return null;
  }

  return (
    <div className={styles.wrapper} style={positionStyles}>
      <SearchBarWithDropDown
        mobile={false}
        autofocus={true}
        active={!disabled && active}
        classes={{
          root: styles.searchRoot,
          searchBar: styles.searchBar,
          searchIconWrapper: styles.searchIconWrapper,
        }}
        disabled={disabled}
        {...searchProps}
      />
    </div>
  );
};
