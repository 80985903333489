import styles from './DocumentPreview.module.scss';

import clsx from 'clsx';
import React from 'react';

import { DocumentPreviewContent } from './components/DocumentPreviewContent';
import { DocumentPreviewTitle } from './components/DocumentPreviewTitle';
import { useDocumentPreview } from './hooks/use-document-preview';
import { IDocumentPreviewProps } from './types/IDocumentPreviewProps';

export function DocumentPreview(props: IDocumentPreviewProps) {
  const { filePath, subtitleComp, classes, onTitleChanged } = props;

  const documentPreviewProps = useDocumentPreview(filePath);

  return (
    <div className={clsx(styles.wrapper, classes?.wrapper)}>
      <DocumentPreviewTitle
        {...props}
        {...documentPreviewProps}
        onTitleChanged={onTitleChanged}
      />
      {subtitleComp}
      <DocumentPreviewContent {...props} {...documentPreviewProps} />
    </div>
  );
}
