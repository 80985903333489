import { ArticleGroup } from '@work4all/models/lib/Classes/ArticleGroup.entity';

import { ArticleGroupPicker } from '../../../../../entity-picker/entity-group-picker/EntityGroupPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const ArticleGroupPickerInput = withExtras<ArticleGroup>(
  ({ value, onChange, ...rest }) => {
    return (
      <PreviewPickerInput<ArticleGroup>
        {...rest}
        picker={
          <ArticleGroupPicker
            value={value ? [value] : null}
            onChange={(groups) => {
              if (groups && groups.length > 0) {
                if (groups[0].id === value?.id) {
                  onChange(null);
                } else {
                  onChange(groups[0]);
                }
              } else {
                onChange(null);
              }
            }}
            multiple={false}
          />
        }
        value={value}
        formattedValue={value?.name}
      />
    );
  }
);
