import { useForceUpdate } from 'observable-hooks';
import { useCallback } from 'react';
import { TableInstance } from 'react-table';

import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';

import { UseSettingReturn } from '@work4all/data/lib/settings';

import { ERP_EXPAND_COLUMN_ID } from '../../../../../../../../../../components/data-tables/generic-column/GenericColumns';
import { ErpPositionConfigType } from '../../../../../../../../../../settings/settings';

export interface UseResetColumnsProps {
  columnSettings: UseSettingReturn<{
    visibility: string[];
    order: string[];
    width: Record<string, number>;
  }>;
  tableInstanceRef: React.RefObject<TableInstance<object>>;
  defaultColumns: string[];
}

export const useResetColumns = (props: UseResetColumnsProps) => {
  const { columnSettings, tableInstanceRef, defaultColumns } = props;
  // resetColumns
  const forceUpdate = useForceUpdate();
  const resetColumns = useCallback(() => {
    columnSettings.delete();
    const newValue: ErpPositionConfigType = {
      visibility: defaultColumns,
      order: [] as string[],
      width: {} as Record<string, number>,
    };
    tableInstanceRef.current.allColumns.forEach((col) => {
      if (col.id === SELECTION_COLUMN_ID) {
        tableInstanceRef.current.toggleHideColumn(SELECTION_COLUMN_ID, false);
        return;
      }
      if (col.id === ERP_EXPAND_COLUMN_ID) {
        tableInstanceRef.current.toggleHideColumn(ERP_EXPAND_COLUMN_ID, false);
        return;
      }
      tableInstanceRef.current.toggleHideColumn(
        col.id,
        !newValue.visibility.includes(col.id)
      );
    });
    forceUpdate();
  }, [columnSettings, forceUpdate, tableInstanceRef, defaultColumns]);

  return resetColumns;
};
