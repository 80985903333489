import styles from './GeneralTabPanel.module.scss';

import { Stack, Theme, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generateFileUploadUrl } from '@work4all/components';
import {
  AttachmentsDropZone,
  AttachmentsDropZoneType,
} from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import {
  EntityPickerTab,
  EntityPickerTabBar,
} from '@work4all/components/lib/components/entity-picker/components';
import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';
import { EntityHistory } from '@work4all/components/lib/components/entity-preview/EntityHistory/EntityHistory';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { useLock } from '@work4all/components/lib/hooks';
import { LookupCheckListField } from '@work4all/components/lib/input/check-list-field/LookupCheckListField';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { isHTMLEmpty } from '@work4all/utils';

import { BusinessPartnerPickerField } from '../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../components/entity-picker/ContactPickerField';
import { ContractServiceContractPickerField } from '../../../../../../../../components/entity-picker/ContractServiceContractPickerField';
import { PositionPickerField } from '../../../../../../../../components/entity-picker/PositionPickerField';
import { PriorityPickerField } from '../../../../../../../../components/entity-picker/PriorityPicketField';
import { ProjectPickerField } from '../../../../../../../../components/entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../../../../../../../components/entity-picker/ProjectProcessPickerField';
import { TicketCategory1PickerField } from '../../../../../../../../components/entity-picker/TicketCategory1PickerField';
import { TicketCategory2PickerField } from '../../../../../../../../components/entity-picker/TicketCategory2PickerField';
import { TicketKindPickerField } from '../../../../../../../../components/entity-picker/TicketKindPickerField';
import { TicketStatusPickerField } from '../../../../../../../../components/entity-picker/TicketStatusPickerField';
import { UserPickerField } from '../../../../../../../../components/entity-picker/UserPickerField';
import { settings, useSetting } from '../../../../../../../../settings';
import { ControllerPlus } from '../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
  LabeledInputWithUnit,
  TextEditor,
} from '../../../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../../../components';
import { Panel } from '../../../../../components/panel/Panel';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { TicketMaskFormValue } from '../../../types';

const AVAILABLE_TICKET_TEXT_EDITORS = [
  'problemDescription',
  'solution',
] as const;
type TicketTextEditor = (typeof AVAILABLE_TICKET_TEXT_EDITORS)[number];
const LABEL_TO_FORM_VALUE: Record<TicketTextEditor, keyof Ticket> = {
  problemDescription: 'problemDescription',
  solution: 'solutionText',
};

export const GeneralTabPanel = () => {
  const { t } = useTranslation();

  const mask = useMaskContext<Ticket>();

  const { locked } = useLock();

  const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
  const isDownLg = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('lg')
  );
  const isDownSm = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const form = useFormContextPlus<TicketMaskFormValue>();

  const { register, control, watch, setValue, formState } = form;

  const user = useUser();
  const { activeTenant } = useTenant();

  const businessPartnerType = watch('businessPartnerType');
  const businessPartnerId = watch('businessPartnerId');
  const bp = watch('businessPartner');
  const project = watch('project');
  const projectProcess = watch('projectProcess');
  const contract = watch('contract');
  const serviceContract = watch('serviceContract');

  const contractServiceContractPickerPrefilter = useMemo(() => {
    return [
      {
        'businessPartnerContactCombined.businessPartner.id': {
          $eq: businessPartnerId,
        },
      },
    ];
  }, [businessPartnerId]);

  const [shownTextEditor, setShownTextEditor] =
    useState<TicketTextEditor>('problemDescription');

  const { value: isTicketSolutionRequiered } = useSetting(
    settings.isTicketSolutionRequiered()
  );

  const solutionText = watch('solutionText') ?? '';

  const { enqueueSnackbar } = useSnackbar();

  const dropZoneRef = useRef<AttachmentsDropZoneType>(null);

  return (
    <div className={styles.root}>
      <AttachmentsDropZone
        ref={dropZoneRef}
        fullHeight={false}
        className={styles.dropZoneContainer}
      >
        <Panel className={styles.panel}>
          <Collapse defaultOpen title={t('MASK.DESCRIPTION')}>
            <Card style={{ overflow: 'visible' }}>
              <div className={styles.wrapper}>
                <LabeledInput
                  {...register('title')}
                  className={styles.title}
                  label={t('COMMON.TITLE')}
                />

                <div className={styles.date}>
                  <div className={styles.flexItem}>
                    <DateTimeInputPicker
                      {...register('followUpDate')}
                      withTime={false}
                      clearable={false}
                      dateLabel={t('COMMON.FOLLOWUP_DATE')}
                    />
                  </div>
                  <div className={styles.flexItem}>
                    <ControllerPlus
                      control={control}
                      name="priority"
                      render={({ field }) => {
                        return <PriorityPickerField {...field} />;
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.textEditors}>
                <EntityPickerTabBar
                  value={shownTextEditor}
                  onChange={(value) => setShownTextEditor(value)}
                >
                  {AVAILABLE_TICKET_TEXT_EDITORS.map((value, index) => {
                    const errors = formState.errors[LABEL_TO_FORM_VALUE[value]]
                      ? 1
                      : 0;

                    return (
                      <EntityPickerTab
                        value={value}
                        key={index}
                        errors={errors}
                        disabled={locked}
                      >
                        {t(`COMMON.${value.toUpperCase()}`)}
                      </EntityPickerTab>
                    );
                  })}
                </EntityPickerTabBar>

                {shownTextEditor === 'problemDescription' && (
                  <ControllerPlus
                    control={control}
                    name="problemDescription"
                    render={({ field }) => (
                      <TextEditor
                        enableTextBuildingBlocks
                        value={field.value}
                        onChange={(args) => {
                          dropZoneRef.current.reset();
                          field.onChange(args);
                        }}
                        config={{
                          requestHeaders: {
                            'x-work4all-mandant': activeTenant,
                          },
                          imageUploadURL: generateFileUploadUrl(
                            user.baseUrl,
                            user.token
                          ),
                          heightMax: 300,
                          heightMin: 150,
                          pastePlain: true,
                        }}
                      />
                    )}
                  />
                )}

                {shownTextEditor === 'solution' && (
                  <ControllerPlus
                    control={control}
                    name="solutionText"
                    render={({ field }) => (
                      <div className={styles.solutionTextWrapper}>
                        <TextEditor
                          enableTextBuildingBlocks
                          value={field.value}
                          onChange={(val) => {
                            dropZoneRef.current.reset();
                            field.onChange(val);
                          }}
                          config={{
                            requestHeaders: {
                              'x-work4all-mandant': activeTenant,
                            },
                            imageUploadURL: generateFileUploadUrl(
                              user.baseUrl,
                              user.token
                            ),
                            heightMax: isDownSm ? undefined : 300,
                            heightMin: 150,
                            pastePlain: true,
                          }}
                        />
                        <span className={styles.errorText}>{field?.error}</span>
                      </div>
                    )}
                  />
                )}
              </div>
            </Card>
          </Collapse>
          <Collapse defaultOpen title={t('MASK.ASSIGNMENT')}>
            <Card>
              <ControlWrapper columns={1}>
                <ControllerPlus
                  control={control}
                  name="businessPartner.data"
                  render={({ field: businessPartner }) => {
                    return (
                      <ControllerPlus
                        control={control}
                        name="contact"
                        render={({ field: contact }) => {
                          return (
                            <MultiStepControls>
                              <Step index={0} active>
                                <BusinessPartnerPickerField
                                  {...businessPartner}
                                  value={businessPartner?.value ?? null}
                                  onChange={(e) => {
                                    businessPartner.onChange(e?.data || null);
                                  }}
                                />
                              </Step>
                              <Step
                                index={1}
                                active={
                                  businessPartner.value != null &&
                                  !businessPartner.value.isPrivateCustomer
                                }
                              >
                                {businessPartner.value != null && (
                                  <ContactPickerField
                                    businessPartnerType={businessPartnerType}
                                    businessPartnerId={businessPartnerId}
                                    businessPartner={bp}
                                    project={project}
                                    projectProcess={projectProcess}
                                    {...contact}
                                  />
                                )}
                              </Step>
                            </MultiStepControls>
                          );
                        }}
                      />
                    );
                  }}
                />

                <ControllerPlus
                  control={control}
                  name="project"
                  render={({ field: project }) => {
                    // TODO Add ProjectProcess when it is added to the API.
                    return (
                      <ControllerPlus
                        control={control}
                        name="projectProcess"
                        render={({ field: projectProcess }) => {
                          return (
                            <MultiStepControls>
                              <Step index={0} active>
                                <ProjectPickerField
                                  {...project}
                                  data={PROJECT_DATA}
                                  clearable
                                />
                              </Step>
                              <Step index={1} active={project.value != null}>
                                {project.value != null && (
                                  <ProjectProcessPickerField
                                    projectId={project.value.id}
                                    {...projectProcess}
                                  />
                                )}
                              </Step>
                            </MultiStepControls>
                          );
                        }}
                      />
                    );
                  }}
                />
              </ControlWrapper>

              <ControlWrapper columns={isLg ? 2 : 1}>
                <ControllerPlus
                  control={control}
                  name="ticketKind"
                  render={({ field }) => {
                    return <TicketKindPickerField {...field} />;
                  }}
                />

                <MultiStepControls>
                  <Step index={0} active>
                    <ControllerPlus
                      control={control}
                      name="ticketCategory1"
                      render={({ field }) => {
                        return <TicketCategory1PickerField {...field} />;
                      }}
                    />
                  </Step>

                  <Step index={1} active>
                    <ControllerPlus
                      control={control}
                      name="ticketCategory2"
                      render={({ field }) => {
                        return <TicketCategory2PickerField {...field} />;
                      }}
                    />
                  </Step>
                </MultiStepControls>
              </ControlWrapper>
              <ControlWrapper paddingBottom={false}>
                <MultiStepControls>
                  <Step index={0} active={!!businessPartnerId}>
                    <ContractServiceContractPickerField
                      onChange={(value, entity) => {
                        setValue(
                          Entities.contract === entity
                            ? 'contract'
                            : 'serviceContract',
                          value
                        );
                      }}
                      value={contract ?? serviceContract}
                      prefilter={contractServiceContractPickerPrefilter}
                    />
                  </Step>

                  <Step index={1} active={!!contract}>
                    <ControllerPlus
                      control={control}
                      name="position"
                      render={({ field }) => {
                        return (
                          <PositionPickerField
                            positions={contract?.positionList ?? []}
                            {...field}
                          />
                        );
                      }}
                    />
                  </Step>
                </MultiStepControls>
              </ControlWrapper>
            </Card>
          </Collapse>
          <Collapse defaultOpen title={t('MASK.EDITING')}>
            <Card>
              <ControlWrapper columns={isLg ? 2 : 1}>
                <MultiStepControls>
                  <Step index={0} active>
                    <ControllerPlus
                      control={control}
                      name="status1"
                      render={({ field }) => {
                        return (
                          <TicketStatusPickerField
                            multiple={false}
                            {...field}
                            value={{ id: field.value, name: '' }}
                            availableStatus={[
                              ...new Set([
                                TicketStatus.OFFEN,
                                TicketStatus.ERLEDIGT,
                                TicketStatus.IN_BEARBEITUNG,
                                TicketStatus.ZURUECKGESTELLT,
                                field.value,
                              ]),
                            ]}
                            onChange={(value) => {
                              if (
                                isTicketSolutionRequiered &&
                                value === TicketStatus.ERLEDIGT &&
                                isHTMLEmpty(solutionText)
                              ) {
                                enqueueSnackbar(
                                  t('TICKET_STATUS_PICKER.PREVENT_MESSAGE'),
                                  {
                                    variant: 'error',
                                    autoHideDuration: 3000,
                                  }
                                );

                                return;
                              }

                              field.onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  </Step>

                  <Step index={1} active>
                    <ControllerPlus
                      control={control}
                      name="editor1"
                      render={({ field }) => {
                        return (
                          <UserPickerField
                            label={t('COMMON.EDITOR')}
                            {...field}
                          />
                        );
                      }}
                    />
                  </Step>
                </MultiStepControls>
                <MultiStepControls>
                  <Step index={0} active>
                    <ControllerPlus
                      control={control}
                      name="status2"
                      render={({ field }) => {
                        return (
                          <TicketStatusPickerField
                            label={t('COMMON.STATUS2')}
                            multiple={false}
                            {...field}
                            value={{ id: field.value, name: '' }}
                            availableStatus={[
                              ...new Set([
                                TicketStatus.OFFEN,
                                TicketStatus.ERLEDIGT,
                                TicketStatus.IN_BEARBEITUNG,
                                TicketStatus.ZURUECKGESTELLT,
                                field.value,
                              ]),
                            ]}
                          />
                        );
                      }}
                    />
                  </Step>

                  <Step index={1} active>
                    <ControllerPlus
                      control={control}
                      name="editor2"
                      render={({ field }) => {
                        return (
                          <UserPickerField
                            label={t('COMMON.EDITOR2')}
                            {...field}
                          />
                        );
                      }}
                    />
                  </Step>
                </MultiStepControls>
              </ControlWrapper>
            </Card>
          </Collapse>

          <Stack direction={isDownSm ? 'column' : 'row'} flexWrap="wrap">
            <Collapse
              defaultOpen
              title={t('MASK.PLANNING')}
              className={styles.flexItem}
            >
              <Card>
                <ControlWrapper columns={2}>
                  <ControllerPlus
                    control={control}
                    name="timeRequired"
                    render={({ field: duration }) => {
                      return (
                        <LabeledInputWithUnit
                          inputProps={{ step: '0.25', min: '0' }}
                          type="number"
                          placeholder={'0,00'}
                          unit={'h'}
                          label={t('INPUTS.TIME_ESTIMATION')}
                          value={duration.value}
                          onChange={(event) => {
                            duration.onChange(parseFloat(event.target.value));
                          }}
                        />
                      );
                    }}
                  />
                  <ControllerPlus
                    control={control}
                    name="profit"
                    render={({ field }) => {
                      return (
                        <LabeledInput
                          {...field}
                          type="number"
                          onChange={(event) => {
                            field.onChange(parseFloat(event.target.value));
                          }}
                          label={t('INPUTS.PROFIT')}
                        />
                      );
                    }}
                  />
                </ControlWrapper>
              </Card>
            </Collapse>
            <Collapse
              defaultOpen
              title={t('MASK.CHECKLIST')}
              className={styles.flexItem}
              headerClassName={styles.rightHeader}
              classes={{
                root: styles.checkListRoot,
              }}
            >
              <Card>
                <ControllerPlus
                  control={control}
                  name="checklist"
                  render={({ field }) => {
                    const { value, onChange } = field;
                    return (
                      <LookupCheckListField
                        value={value}
                        disabled={locked}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </Card>
            </Collapse>
          </Stack>
        </Panel>
      </AttachmentsDropZone>

      {mask.isEditMode && (
        <EntityHistory
          fullWidth={isDownLg}
          className={styles.history}
          entity={mask.entity}
          id={mask.id}
          breadcrumbTitle={`Ticket ${mask.data.number} | ${mask.data.title}`}
        />
      )}
    </div>
  );
};
