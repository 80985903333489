import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { IAttachmentEntity } from '@work4all/models';
import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ArticleImage } from '@work4all/models/lib/Classes/ArticleImage.entity';
import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ARTICLE_DOCUMENT_LIST } from '../../../../article/use-article-request';

interface UseArticleAttachmentsProps {
  data?: ERPTypes;
  entityType: Entities;
}

export const useArticleAttachments = (props: UseArticleAttachmentsProps) => {
  const request: DataRequest = useMemo(() => {
    const ids = props.data.positionList
      .map((x) => x.article)
      .filter(Boolean)
      .map((x) => x.id);
    const uniqueIds: number[] = [...new Set(ids)];

    const data = {
      id: null,
      articleDocumentList: ARTICLE_DOCUMENT_LIST,
    };

    return {
      entity: Entities.article,
      data,
      filter: [{ id: { $in: uniqueIds } }],
    };
  }, [props.data]);

  // Query size is increased, but this is single request doing report so I think is acceptable
  const { data } = useDataProvider<Article>(request, false, 1000);

  const artileImages = useMemo<IAttachmentEntity[]>(() => {
    const filteredImages = data
      .flatMap((x) => x.articleDocumentList as ArticleImage)
      .filter((image) => {
        switch (props.entityType) {
          case Entities.offer:
            return image.asAttachmentOffer;
          case Entities.contract:
            return image.asAttachmentOrderConfirmation;
          case Entities.demand:
            return image.asAttachmentDemand;
          case Entities.order:
            return image.asAttachmentOrder;
          case Entities.calculation:
            return image.asAttachmentCalculation;
          case Entities.deliveryNote:
            return image.asAttachmentDeliveryNote;
          case Entities.invoice:
            return image.asAttachmentInvoice;
          default:
            return false; // If entityType doesn't match any case, exclude the item
        }
      });

    return filteredImages.map((x) => ({
      id: x.id,
      fileName: x.name,
      fileInfos: x.fileInfos,
      date: new Date().toISOString(),
    }));
  }, [props.entityType, data]);

  return artileImages;
};
