import styles from './AppointmentQuickCreateDialog.module.scss';

import { Close, Save } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { partition } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppointmentStatePicker } from '@work4all/components/lib/components/entity-picker/appointmentStatePicker/AppointmentStatePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { TimeInputPicker } from '@work4all/components/lib/input/time-input-picker';

import { useDataMutation, useFormPlus, useUser } from '@work4all/data';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { InputTerminRelation } from '@work4all/models/lib/Classes/InputTerminRelation.entity';
import { InputTerminTeilnehmerAdd } from '@work4all/models/lib/Classes/InputTerminTeilnehmerAdd.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { ProjectPickerField } from '../../../components/entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../../components/entity-picker/ProjectProcessPickerField';
import { TeamsMeetingCheckbox } from '../../../components/TeamsMeetingCheckbox';
import { useCanCreateTeamsMeeting } from '../../../hooks/use-can-create-teams-meeting';
import { ControllerPlus } from '../../mask-overlays/form-plus/controller-plus';
import { useControllerPlus } from '../../mask-overlays/form-plus/use-controller-plus';
import { useFormContextPlus } from '../../mask-overlays/form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  Chip,
  DateTimeInputPicker,
  LabeledInput,
  PickerTargetButton,
} from '../../mask-overlays/locked-inputs';
import {
  Collapse,
  ControlWrapper,
  Form,
} from '../../mask-overlays/mask-overlay/components';
import {
  MaskContextProvider,
  useMaskContextValue,
} from '../../mask-overlays/mask-overlay/hooks/mask-context';
import { useFormUpdate } from '../../mask-overlays/mask-overlay/utils/use-form-update';
import { sortAttendees } from '../../mask-overlays/mask-overlay/views/appointment/AppointmentOverlayController';
import {
  ContactRessourcePicker,
  renderChipContent,
} from '../../mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/contactpicker/ContactRessourcePicker';
import { AttendeesPicker } from '../../mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/participant/attendee-picker/AttendeePicker';
import {
  isAttendee,
  isResource,
  isTheSameAttendee,
  mapAttendee,
  mapAttendeeContact,
  mapResourceContact,
} from '../../mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/participant/utils';

const HOURS_PER_DAY = 8;
const MINUTES_PER_HOUR = 60;

export type AppointmentTemplate = Pick<
  Appointment,
  | 'appointmentAttendeeList'
  | 'startDate'
  | 'endDate'
  | 'isWholeDay'
  | 'title'
  | 'note'
  | 'project'
  | 'projectId'
  | 'projectProcess'
  | 'projectProcessId'
  | 'businessPartner'
  | 'businessPartnerId'
  | 'businessPartnerType'
  | 'isRealAppointment'
  | 'projectProcessDuration'
>;

enum SerialAppointmentTimeRange {
  custom = 'custom',
  wholeDay = 'wholeDay',
  morning = 'morning',
  afternoon = 'afternoon',
}

export interface AppointmentQuickCreateDialogProps
  extends AppointmentQuickCreateFormProps {
  open: boolean;
  showEditDetailsBtn?: boolean;
}

type ExtendedAppointment = Appointment & {
  createMultipleAppointments: boolean;
  serialAppointmentExcludeWeekends: boolean;
  serialAppointmentTimeRange: SerialAppointmentTimeRange;
  dayStartTime: string;
  dayEndTime: string;
};

type AppointmentFormValue = PathsOf<ExtendedAppointment>;

const formId = 'appointmentForm';

export function AppointmentQuickCreateDialog(
  props: AppointmentQuickCreateDialogProps
) {
  const {
    open,
    onClose,
    template,
    onOpenMask,
    showEditDetailsBtn,
    attendeeSuggestions,
  } = props;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <AppointmentQuickCreateForm
        template={template}
        onClose={onClose}
        onOpenMask={onOpenMask}
        showEditDetailsBtn={showEditDetailsBtn}
        attendeeSuggestions={attendeeSuggestions}
      />
    </Dialog>
  );
}

interface AppointmentQuickCreateFormProps {
  template: AppointmentTemplate;
  onOpenMask: (appointment: Appointment) => void;
  onClose: () => void;
  showEditDetailsBtn?: boolean;
  attendeeSuggestions?: AppointmentAttendee[];
}

function AppointmentQuickCreateForm(props: AppointmentQuickCreateFormProps) {
  const {
    template,
    onOpenMask,
    onClose,
    showEditDetailsBtn = false,
    attendeeSuggestions,
  } = props;

  const { t } = useTranslation();

  const user = useUser();

  const [mutate] = useDataMutation<
    Appointment,
    EMode.upsert,
    InputTerminRelation
  >({
    entity: Entities.appointment,
    mutationType: EMode.upsert,
    responseData: { id: null },
    onCompleted: onClose,
  });

  const customRules = useCallback(
    (data) => {
      const attendees: AppointmentAttendee[] =
        data.appointmentAttendeeList || [];
      const hasAtLeastOneUser = attendees.find((el) => el.user || el.ressource);
      if (!hasAtLeastOneUser) {
        return {
          appointmentAttendeeList: {
            message: t('ERROR.MIN_ONE_USER_PER_APPOINTMENT'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const schema = useEntityJsonSchema(Entities.appointment);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const canCreateTeamsMeeting = useCanCreateTeamsMeeting();

  const form = useFormPlus<AppointmentFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: makeDefaultValues(template, canCreateTeamsMeeting),
    context: {
      schema,
    },
  });

  const { isRealAppointment } = form.watch();
  const dayStartTime = form.watch('dayStartTime');
  const dayEndTime = form.watch('dayEndTime');

  useEffect(() => {
    const { hours } = DateTime.fromISO(dayEndTime).diff(
      DateTime.fromISO(dayStartTime),
      'hours'
    );
    const planDays = hours / HOURS_PER_DAY;
    form.setValue('projectProcessDuration', planDays);
  }, [dayEndTime, dayStartTime, form]);

  const getReminderDate = useCallback(() => {
    const { isWholeDay, startDate } = form.watch();

    const reminder = DateTime.fromISO(startDate);
    let resultingRemindDate = null;
    if (isWholeDay) {
      resultingRemindDate = reminder.startOf('day').minus({ day: 1 }).toISO();
    } else {
      resultingRemindDate = reminder.minus({ minutes: 15 }).toISO();
    }
    return resultingRemindDate;
  }, [form]);

  useFormUpdate(
    {
      projectProcessDuration: (value: number) => {
        if (value < 1) {
          return {
            isWholeDay: false,
            serialAppointmentTimeRange: SerialAppointmentTimeRange.custom,
          };
        } else {
          return {
            isWholeDay: true,
            serialAppointmentTimeRange: SerialAppointmentTimeRange.wholeDay,
          };
        }
      },
      appointmentAttendeeList: (
        appointmentAttendeeList: AppointmentAttendee[]
      ) => {
        return {
          exchangeMeeting:
            canCreateTeamsMeeting && appointmentAttendeeList.length > 1,
        };
      },
      appointmentState: (state: AppointmentState) => {
        return { colorId: state?.id || 0 };
      },
      startDate: () => {
        return { remindDate: getReminderDate() };
      },
      isWholeDay: () => {
        return { remindDate: getReminderDate() };
      },
      project: (project: Project) => {
        return {
          projectId: project?.id || 0,
          projectProcess: null,
        };
      },
      projectProcess: (process: ProjectProcess) => {
        return {
          projectProcessId: process?.id,
        };
      },
      serialAppointmentTimeRange: (value: SerialAppointmentTimeRange) => {
        let { dayStartTime, dayEndTime } = form.getValues();

        switch (value) {
          case SerialAppointmentTimeRange.wholeDay: {
            dayStartTime = DateTime.now()
              .set({ hour: 9 })
              .startOf('hour')
              .toISO();
            dayEndTime = DateTime.now()
              .set({ hour: 17 })
              .startOf('hour')
              .toISO();
            break;
          }
          case SerialAppointmentTimeRange.afternoon: {
            dayStartTime = DateTime.now()
              .set({ hour: 13 })
              .startOf('hour')
              .toISO();
            dayEndTime = DateTime.now()
              .set({ hour: 17 })
              .startOf('hour')
              .toISO();
            break;
          }
          case SerialAppointmentTimeRange.morning: {
            dayStartTime = DateTime.now()
              .set({ hour: 9 })
              .startOf('hour')
              .toISO();
            dayEndTime = DateTime.now()
              .set({ hour: 13 })
              .startOf('hour')
              .toISO();
            break;
          }
        }

        return {
          dayStartTime,
          dayEndTime,
        };
      },
    },
    form
  );

  const onSubmit = (data: ExtendedAppointment) => {
    const appointment = { ...data };

    const appointmentAttendeeList =
      appointment.appointmentAttendeeList.length > 0
        ? {
            add: appointment.appointmentAttendeeList.map((x) => ({
              benutzerCode: x.userId,
              ansprechpartnerCode: x.contactId,
              sdObjMemberCode: x.businessPartnerId,
              sdObjType: x.businessPartnerType,
            })) as InputTerminTeilnehmerAdd[],
          }
        : null;

    const userIsAttendee = appointment.appointmentAttendeeList.find(
      (attendee) => attendee.userId === user.benutzerCode
    );

    if (userIsAttendee) {
      appointment.userId = userIsAttendee.userId;
    }

    const relations: InputTerminRelation = appointmentAttendeeList
      ? { teilnehmer: appointmentAttendeeList }
      : null;

    let promises = [];

    if (data.isRealAppointment) {
      promises = [mutate(appointment, { relations })];
    } else {
      const { dayStartTime, dayEndTime, startDate, endDate } = data;
      let start = new Date(startDate);
      const end = new Date(endDate);

      const dateArray = [];
      while (start <= end) {
        if (
          !data.serialAppointmentExcludeWeekends ||
          !DateTime.fromJSDate(start).isWeekend
        ) {
          dateArray.push(new Date(start));
        }
        start = DateTime.fromJSDate(start).plus({ day: 1 }).toJSDate();
      }

      promises = dateArray.map((date) => {
        return mutate(
          {
            ...appointment,
            startDate: DateTime.fromJSDate(date).set({
              hour: new Date(dayStartTime).getHours(),
              minute: new Date(dayStartTime).getMinutes(),
            }),
            endDate: DateTime.fromJSDate(date).set({
              hour: new Date(dayEndTime).getHours(),
              minute: new Date(dayEndTime).getMinutes(),
            }),
            isWholeDay:
              data.serialAppointmentTimeRange ===
              SerialAppointmentTimeRange.wholeDay,
          },
          { relations }
        );
      });
    }

    return Promise.all(promises);
  };

  const maskContext = useMaskContextValue({
    entity: Entities.appointment,
    isCreateMode: true,
    isEditMode: false,
    mode: 'create',
    wip: false,
    params: {},
  });

  return (
    <MaskContextProvider value={maskContext}>
      <Box>
        <Stack direction="row" className={styles.topBar}>
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
          <Typography variant="h4" p="1rem 0">
            {t(
              isRealAppointment
                ? 'COMMON.APPOINTMENT'
                : 'COMMON.PLANNINGAPPOINTMENT'
            )}
          </Typography>
          <Box flexGrow={1}></Box>

          <Stack direction="row" gap="1rem">
            {showEditDetailsBtn && (
              <Button
                size="large"
                startIcon={<EditIcon />}
                onClick={() => {
                  onOpenMask(form.getValues());
                }}
              >
                {t('CALENDAR.EDIT_DETAILS')}
              </Button>
            )}
            <Button
              size="large"
              startIcon={<Save />}
              type="submit"
              form={formId}
            >
              {t('INPUTS.SAVE')}
            </Button>
          </Stack>
        </Stack>

        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)} id={formId}>
            <FormContent attendeeSuggestions={attendeeSuggestions} />
          </Form>
        </FormProvider>
      </Box>
    </MaskContextProvider>
  );
}

function makeDefaultValues(
  template: AppointmentTemplate,
  canCreateTeamsMeeting: boolean
): ExtendedAppointment {
  return {
    title: '',
    userId: 0,
    appointmentState: null,
    startDate: null,
    endDate: null,
    appointmentAttendeeList: [],
    isRealAppointment: true,
    exchangeMeeting:
      canCreateTeamsMeeting && template.appointmentAttendeeList.length > 1,
    ...template,
    remind: true,
    remindDate: DateTime.fromISO(template.startDate)
      .minus({ minutes: 30 })
      .toISO(),
    serialAppointmentExcludeWeekends: true,
    serialAppointmentTimeRange: SerialAppointmentTimeRange.wholeDay,
    createMultipleAppointments: false,
    dayStartTime: DateTime.now().set({ hour: 9 }).startOf('hour').toISO(),
    dayEndTime: DateTime.now().set({ hour: 17 }).startOf('hour').toISO(),
  };
}

export function FormContent({
  attendeeSuggestions,
}: {
  attendeeSuggestions: AppointmentAttendee[];
}) {
  const { t } = useTranslation();

  const { control, register } = useFormContextPlus<AppointmentFormValue>();

  const isWholeDay = useWatch({ control, name: 'isWholeDay' });
  const isRealAppointment = useWatch({ control, name: 'isRealAppointment' });
  const serialAppointmentTimeRange = useWatch({
    control,
    name: 'serialAppointmentTimeRange',
  });
  const serialAppointmentExcludeWeekends = useWatch({
    control,
    name: 'serialAppointmentExcludeWeekends',
  });

  const stateWrapperRef = useRef<HTMLElement>(null);

  return (
    <>
      <Collapse title={t('COMMON.PARTICIPANT')} defaultOpen={true}>
        <Participants attendeeSuggestions={attendeeSuggestions} />
      </Collapse>

      <Collapse
        title={t('COMMON.TIMESPAN')}
        defaultOpen={true}
        headerBar={
          !isRealAppointment ? (
            <Grid item xs={12} display="flex" justifyContent="end">
              <ControllerPlus
                control={control}
                name="serialAppointmentTimeRange"
                render={({ field: isWholeDay }) => {
                  return (
                    <ToggleButtonGroup
                      {...isWholeDay}
                      onChange={(_, value) => {
                        isWholeDay.onChange(value);
                      }}
                      exclusive
                      style={{ alignSelf: 'right' }}
                      size="small"
                    >
                      <ToggleButton
                        value={SerialAppointmentTimeRange.morning}
                        className={styles.minimalToggle}
                      >
                        {t('COMMON.IN_THE_MORNING')}
                      </ToggleButton>
                      <ToggleButton
                        value={SerialAppointmentTimeRange.afternoon}
                        className={styles.minimalToggle}
                      >
                        {t('COMMON.IN_THE_AFTERNOON')}
                      </ToggleButton>
                      <ToggleButton
                        value={SerialAppointmentTimeRange.wholeDay}
                        className={styles.minimalToggle}
                      >
                        {t('COMMON.WHOLEDAY')}
                      </ToggleButton>
                      <ToggleButton
                        value={SerialAppointmentTimeRange.custom}
                        className={styles.minimalToggle}
                      >
                        {t('COMMON.TIME')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  );
                }}
              />
            </Grid>
          ) : null
        }
      >
        <Stack gap="1rem">
          <Grid container spacing={2}>
            <Grid item xs={isRealAppointment ? 6 : 3}>
              <DateTimeInputPicker
                dateLabel={t('COMMON.STARTDATE')}
                clearable={false}
                withTime={!isWholeDay && isRealAppointment}
                {...register('startDate')}
              />
            </Grid>
            <Grid item xs={isRealAppointment ? 6 : 3}>
              <DateTimeInputPicker
                dateLabel={t('COMMON.ENDDATE')}
                clearable={false}
                withTime={!isWholeDay && isRealAppointment}
                {...register('endDate')}
              />
            </Grid>
            {!isRealAppointment && (
              <>
                <Grid item xs={3}>
                  <ControllerPlus
                    control={control}
                    name="dayStartTime"
                    render={({ field: dayStartTime }) => {
                      return (
                        <TimeInputPicker
                          clearable={false}
                          label={t('COMMON.FROM')}
                          value={DateTime.fromISO(dayStartTime.value)}
                          onChange={(e) => {
                            //eslint-disable-next-line
                            //@ts-ignore
                            dayStartTime.onChange(e.target.value as string);
                          }}
                          disabled={
                            serialAppointmentTimeRange !==
                            SerialAppointmentTimeRange.custom
                          }
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ControllerPlus
                    control={control}
                    name="dayEndTime"
                    render={({ field: dayEndTime }) => {
                      return (
                        <TimeInputPicker
                          clearable={false}
                          label={t('COMMON.TO')}
                          value={DateTime.fromISO(dayEndTime.value)}
                          onChange={(e) => {
                            //eslint-disable-next-line
                            //@ts-ignore
                            dayEndTime.onChange(e.target.value as string);
                          }}
                          disabled={
                            serialAppointmentTimeRange !==
                            SerialAppointmentTimeRange.custom
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              </>
            )}

            {!isRealAppointment ? (
              <>
                <Grid item xs={6}>
                  <Divider
                    title={t('MASK.OPTIONS')}
                    style={{ paddingBottom: '1rem' }}
                  />
                  <CheckboxRadioItem
                    label={t('COMMON.EXCLUDE_WEEKENDS')}
                    checked={serialAppointmentExcludeWeekends}
                    fitContent
                    {...register('serialAppointmentExcludeWeekends')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Divider
                    title={t('COMMON.PLANNING_VALUE') + '/' + t('COMMON.DAY')}
                    style={{ paddingBottom: '1rem' }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ControllerPlus
                        control={control}
                        name="projectProcessDuration"
                        render={({ field: projectProcessDuration }) => {
                          return (
                            <LabeledInput
                              {...projectProcessDuration}
                              value={projectProcessDuration.value}
                              onChange={(e) => {
                                projectProcessDuration.onChange(
                                  parseFloat(e.target.value)
                                );
                              }}
                              type="number"
                              endAdornment={
                                <Typography pl="0.25rem" color="var(--text03)">
                                  {t('COMMON.DAY_SHORT')}
                                </Typography>
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <ControllerPlus
                        control={control}
                        name="projectProcessDuration"
                        render={({ field: projectProcessDuration }) => {
                          return (
                            <LabeledInput
                              {...projectProcessDuration}
                              value={
                                projectProcessDuration.value * HOURS_PER_DAY
                              }
                              onChange={(e) => {
                                projectProcessDuration.onChange(
                                  parseFloat(e.target.value) / HOURS_PER_DAY
                                );
                              }}
                              type="number"
                              endAdornment={
                                <Typography pl="0.25rem" color="var(--text03)">
                                  {t('COMMON.HOUR_SHORT')}
                                </Typography>
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <ControllerPlus
                        control={control}
                        name="projectProcessDuration"
                        render={({ field: projectProcessDuration }) => {
                          return (
                            <LabeledInput
                              {...projectProcessDuration}
                              value={
                                projectProcessDuration.value *
                                HOURS_PER_DAY *
                                MINUTES_PER_HOUR
                              }
                              onChange={(e) => {
                                projectProcessDuration.onChange(
                                  parseFloat(e.target.value) /
                                    HOURS_PER_DAY /
                                    MINUTES_PER_HOUR
                                );
                              }}
                              type="number"
                              endAdornment={
                                <Typography pl="0.25rem" color="var(--text03)">
                                  {t('COMMON.MINUTE_SHORT')}
                                </Typography>
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={6}>
                <CheckboxRadioItem
                  label={t('COMMON.WHOLEDAYEVENT')}
                  checked={isWholeDay}
                  fitContent
                  {...register('isWholeDay')}
                />
              </Grid>
            )}

            {isRealAppointment && (
              <Grid item xs={6}>
                <ControllerPlus
                  control={control}
                  name="exchangeMeeting"
                  render={({ field }) => (
                    <TeamsMeetingCheckbox
                      checked={field.value}
                      fitContent
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Stack>
      </Collapse>
      <Collapse title={t('COMMON.GENERAL')} defaultOpen={true}>
        <Stack gap="1rem" pb="1rem">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <LabeledInput
                {...register('title')}
                label={t('COMMON.SUBJECT')}
              />
            </Grid>
            <Grid item xs={4}>
              <Box ref={stateWrapperRef}>
                <ControllerPlus
                  control={control}
                  name="appointmentState"
                  render={({ field: appointmentState }) => {
                    return (
                      <EntityPickerPopover
                        anchorEl={stateWrapperRef}
                        picker={
                          <AppointmentStatePicker
                            multiple={false}
                            value={appointmentState?.value}
                            onChange={appointmentState.onChange}
                          />
                        }
                      >
                        <PickerTargetButton
                          onClear={null}
                          value={appointmentState.value?.name || ''}
                          label={t('COMMON.STATUS')}
                        />
                      </EntityPickerPopover>
                    );
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <LabeledInput
            {...register('note')}
            label={t('COMMON.NOTE')}
            multiline
          />

          <Grid container spacing={2}>
            <ControllerPlus
              control={control}
              name="project"
              render={({ field: projectField }) => {
                return (
                  <>
                    <Grid item xs={6}>
                      <ProjectPickerField {...projectField} clearable />
                    </Grid>
                    {projectField.value && (
                      <Grid item xs={6}>
                        <ControllerPlus
                          control={control}
                          name="projectProcess"
                          render={({ field: processField }) => (
                            <ProjectProcessPickerField
                              projectId={projectField?.value?.id}
                              {...processField}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </>
                );
              }}
            />
          </Grid>
        </Stack>
      </Collapse>
    </>
  );
}

const RESOURCE_CONTACT_TYPES = [ContactKind.RESSOURCE];

// For the most part copied from apps/work4all/src/containers/mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/participant/Participant.tsx
export function Participants(props: {
  attendeeSuggestions: AppointmentAttendee[];
}) {
  const { t } = useTranslation();

  const { attendeeSuggestions } = props;

  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const pickerAttendeeWrapperRef = useRef<HTMLDivElement>(null);
  const pickerAttendeePopoverRef = useRef<EntityPickerPopover>(null);
  const pickerResourceWrapperRef = useRef<HTMLDivElement>(null);
  const pickerResourcePopoverRef = useRef<EntityPickerPopover>(null);

  const { control, setValue } = useFormContextPlus<AppointmentFormValue>();

  function renderChipInput({
    label,
    contacts,
    onDelete,
    forceLineBreak = false,
    error,
  }: {
    label: string;
    contacts: ContactUnionWrapper[];
    onDelete: (contact: ContactUnionWrapper) => void;
    forceLineBreak: boolean;
    error: string;
  }) {
    const [userGroup, contactGroup] = partition(contacts, (ctc) =>
      [ContactKind.BENUTZER, ContactKind.MITARBEITER].includes(ctc.contactKind)
    );

    const userGroupEls =
      userGroup.length > 0
        ? userGroup.map((item) => {
            return (
              <Chip
                key={item.id}
                withSeperator={true}
                maxWidth={false}
                label={renderChipContent(item)}
                handleDelete={() => {
                  onDelete(item);
                }}
              />
            );
          })
        : undefined;
    const contactGroupEls =
      contactGroup.length > 0
        ? contactGroup.map((item) => {
            return (
              <Chip
                key={item.id}
                withSeperator={true}
                maxWidth={false}
                label={renderChipContent(item)}
                handleDelete={() => {
                  onDelete(item);
                }}
              />
            );
          })
        : undefined;

    return (
      <LabeledInput
        multiline={true}
        error={error}
        value={
          (userGroup.length || contactGroup.length) && forceLineBreak ? (
            <div>
              {userGroupEls?.length && (
                <div className={styles.chipsContainer}>{userGroupEls}</div>
              )}
              {contactGroupEls?.length && (
                <div className={styles.chipsContainer}>{contactGroupEls}</div>
              )}
            </div>
          ) : (
            <div className={styles.chipsContainer}>
              {userGroupEls}
              {contactGroupEls}
            </div>
          )
        }
        label={label}
      />
    );
  }

  const { field } = useControllerPlus({
    control,
    name: 'appointmentAttendeeList',
  });

  const { value, onChange } = field;

  const {
    attendees,
    resources,
    attendeeContacts,
    resourceContacts,
    handleChange,
    handleDelete,
  } = useMemo(() => {
    const all = Array.isArray(value) ? value : [];

    const attendees = all.filter(isAttendee);
    const resources = all.filter(isResource);

    if (attendees.length + resources.length !== all.length) {
      throw new Error(
        "Couldn't map attendee list to resource and attendee contacts."
      );
    }

    const attendeeContacts = attendees.map(mapAttendeeContact);
    const resourceContacts = resources.map(mapResourceContact);

    const handleChange = (
      originalAttendees: AppointmentAttendee[],
      fixedItems: AppointmentAttendee[]
    ) => {
      return (selected: ContactUnionWrapper[]) => {
        const mapped = selected.map((contact) => {
          const found = originalAttendees.find((resource) => {
            return isTheSameAttendee(resource, contact);
          });

          if (found) {
            return found;
          }

          return mapAttendee(contact);
        });

        onChange(sortAttendees([...fixedItems, ...mapped]));
      };
    };

    const handleDelete = (contact: ContactUnionWrapper) => {
      onChange(
        all.filter((original) => {
          return !isTheSameAttendee(original, contact);
        })
      );
    };

    return {
      attendees,
      resources,
      attendeeContacts,
      resourceContacts,
      handleDelete,
      handleChange,
    };
  }, [value, onChange]);

  const unUsedSuggestions = useMemo(() => {
    return attendeeSuggestions?.filter(
      (att) => !attendees.find((currAtt) => att.userId === currAtt.userId)
    );
  }, [attendeeSuggestions, attendees]);

  return (
    <>
      <ControlWrapper
        ref={pickerAttendeeWrapperRef}
        className={styles.container}
        paddingBottom={attendeeContacts.length > 0}
      >
        <EntityPickerPopover
          ref={pickerAttendeePopoverRef}
          anchorEl={pickerAttendeeWrapperRef}
          autoclose={false}
          picker={
            <AttendeesPicker
              value={attendeeContacts}
              onChange={handleChange(attendees, resources)}
            />
          }
        >
          {attendeeContacts.length > 0 ? (
            renderChipInput({
              label: t('COMMON.PARTICIPANT'),
              contacts: attendeeContacts,
              onDelete: handleDelete,
              forceLineBreak: upMd,
              error: field.error,
            })
          ) : (
            <span></span>
          )}
        </EntityPickerPopover>
      </ControlWrapper>
      <ControlWrapper
        ref={pickerResourceWrapperRef}
        className={styles.container}
        paddingBottom={resourceContacts.length > 0}
      >
        <EntityPickerPopover
          ref={pickerResourcePopoverRef}
          anchorEl={pickerResourceWrapperRef}
          picker={
            <ContactRessourcePicker
              multiple={true}
              value={resourceContacts}
              contactKindList={RESOURCE_CONTACT_TYPES}
              onChange={handleChange(resources, attendees)}
              renderChipContent={renderChipContent}
            />
          }
        >
          {resourceContacts.length > 0 ? (
            renderChipInput({
              label: t('COMMON.RESOURCES'),
              contacts: resourceContacts,
              onDelete: handleDelete,
              forceLineBreak: upMd,
              error: field.error,
            })
          ) : (
            <span></span>
          )}
        </EntityPickerPopover>
      </ControlWrapper>
      {unUsedSuggestions?.length > 0 && (
        <Stack
          direction="row"
          gap="0.5rem"
          paddingBottom="0.75rem"
          alignItems="center"
          mt="-0.5rem"
        >
          <Typography variant="caption">{t('COMMON.SUGGESTIONS')}:</Typography>

          <Stack
            direction="row"
            alignItems="center"
            alignContent="center"
            flexWrap="wrap"
            height="2.5rem"
            overflow="hidden"
            gap="0.5rem"
          >
            {unUsedSuggestions?.map((att) => {
              return (
                <Tooltip title={att.displayName}>
                  <Button
                    onClick={() => {
                      setValue('appointmentAttendeeList', [...attendees, att]);
                    }}
                    style={{ padding: 0, minWidth: 0 }}
                  >
                    {att?.userId !== undefined && (
                      <HookedUserIcon userId={att.userId} size="m" />
                    )}
                  </Button>
                </Tooltip>
              );
            })}
          </Stack>
          <Typography variant="caption">|</Typography>
          <Button
            onClick={() => {
              setValue('appointmentAttendeeList', [
                ...attendees,
                ...unUsedSuggestions,
              ]);
            }}
          >
            {t('COMMON.SELECT_ALL')}
          </Button>
        </Stack>
      )}
      <Grid container spacing={2} paddingBottom="1rem">
        {attendeeContacts.length === 0 && (
          <Grid item xs={'auto'}>
            <Button
              onClick={() => pickerAttendeePopoverRef.current?.open()}
              startIcon={<AddIcon />}
              size="large"
              sx={{ justifyContent: 'flex-start' }}
            >
              {t('COMMON.ADD_PARTICIPANT')}
            </Button>
          </Grid>
        )}
        <Grid item xs={'auto'}>
          {resourceContacts.length === 0 && (
            <Button
              onClick={() => pickerResourcePopoverRef.current?.open()}
              startIcon={<AddIcon />}
              size="large"
              sx={{ justifyContent: 'flex-start' }}
            >
              {t('COMMON.ADD_RESOURCE')}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
