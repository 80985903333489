import { useMemo, useState } from 'react';

import { StackProvider } from '@work4all/components/lib/navigation/history-stack';

import { StackContent } from '../../../../more/favorite-links/overflow/StackContent';

import { TableContextMenuContextProvider } from './table-context-menu-context';
import { TableContextMenuRootView } from './TableContextMenuRootView';
import { TableContextMenuActions } from './types';

export interface TableContextMenuContentProps {
  actions: TableContextMenuActions;
}

export function TableContextMenuContent(props: TableContextMenuContentProps) {
  const { actions } = props;

  const [initialView] = useState<{
    title: string;
    view: React.ReactElement<any>;
  }>(() => {
    return { title: 'Menu', view: <TableContextMenuRootView /> };
  });

  const context = useMemo(() => ({ actions }), [actions]);

  return (
    <TableContextMenuContextProvider value={context}>
      <StackProvider initialView={initialView}>
        <StackContent />
      </StackProvider>
    </TableContextMenuContextProvider>
  );
}
