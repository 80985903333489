import styles from './Alert.module.scss';

import { ButtonBase, ModalProps, Paper } from '@mui/material';
import React, { MouseEventHandler } from 'react';

import { ReactComponent as CloseIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { Modal } from '../../components/modal/Modal';
import { Heading4 } from '../../typography/heading4/Heading4';

interface IProps extends Omit<ModalProps, 'children'> {
  header: string;
  body: React.ReactElement<any> | string;
  footer: React.ReactElement<any>;
}

export const Alert: React.FC<IProps> = (props) => {
  const { footer, body, header, ...modalProps } = props;

  return (
    <Modal keepMounted={false} {...modalProps}>
      <Paper className={styles.root}>
        <div className={styles.header}>
          <Heading4 className={styles.heading}>{header}</Heading4>
          <ButtonBase
            className={styles.close}
            onClick={props.onClose as MouseEventHandler}
          >
            <CloseIcon />
          </ButtonBase>
        </div>
        <div className={styles.body}>{body}</div>
        <div className={styles.footer}>{footer}</div>
      </Paper>
    </Modal>
  );
};
