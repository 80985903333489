import styles from './Attachments.module.scss';

import clsx from 'clsx';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { useLock } from '../../hooks';

import { AttachmentProps } from './Attachments';

export interface AttachmentsDropZoneType {
  reset: () => void;
}

export const AttachmentsDropZone = forwardRef(function AttachmentsDropZone(
  props: Omit<AttachmentProps, 'entity'> & {
    children;
    className?: string;
    single?: boolean;
    accept?: Accept;
    onDropRejected?: DropzoneOptions['onDropRejected'];
  },
  ref: ForwardedRef<AttachmentsDropZoneType>
) {
  const {
    children,
    disableAddAction = false,
    fullHeight = true,
    className = 'string',
    single = false,
    accept,
    onDropRejected,
  } = props;

  const lock = useLock();

  const [bringToFront, setBringToFront] = useState(true);

  // Manually handle active state as the dropzone is sometimes used,
  // by desing, with Froala editor inside. Froala handles 'drop'
  // on its own, so the dropzone remains active, after drop in Editor
  // - drop state is not caught by drop handlers from react-dropzone
  const [fileDragActive, setFileDragActive] = useState(false);

  const attachmentsContext = useContext(TempFileManagerContext);
  const { uploadFiles: uploadAttachments } = attachmentsContext;
  const isUploadActionAllowed =
    !disableAddAction &&
    !lock?.locked &&
    !(single && attachmentsContext.fileList.length);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (isUploadActionAllowed) {
        uploadAttachments(acceptedFiles);
      }
      setFileDragActive(false);
    },
    [isUploadActionAllowed, uploadAttachments]
  );

  const onDragEnter = useCallback(
    (e) => {
      if (isUploadActionAllowed) {
        setFileDragActive(true);
        if (e.dataTransfer.items[0]?.type.startsWith('image/')) {
          setBringToFront(false);
        }
      }
    },
    [isUploadActionAllowed]
  );

  const onDragLeave = useCallback(() => {
    setBringToFront(false);
    setFileDragActive(false);
  }, []);

  const { getRootProps } = useDropzone({
    onDrop,
    onDropRejected,
    onDragEnter,
    onDragLeave,
    accept,
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        reset: onDragLeave,
      };
    },
    [onDragLeave]
  );
  const rootProps = getRootProps();
  return (
    <div
      {...rootProps}
      onMouseLeave={() => {
        onDragLeave();
      }}
      className={clsx(className, styles.dropArea, {
        [styles.dropzoneActive]: fileDragActive,
        [styles.fullHeight]: fullHeight,
        [styles.toFront]: bringToFront,
      })}
    >
      {children}
    </div>
  );
});
