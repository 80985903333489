import { Star } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Button,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDialogs } from '@work4all/components';
import { useReportOptions } from '@work4all/components/lib/components/entity-picker/report-picker/ReportPicker';
import { ReportType } from '@work4all/components/lib/components/entity-picker/report-type-picker/ReportTypePicker';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { Report } from '@work4all/models/lib/Classes/Report.entity';
import { EInvoiceFormat } from '@work4all/models/lib/Enums/EInvoiceFormat.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { InvoiceForm } from '@work4all/models/lib/Enums/InvoiceForm.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { convertEntityToBzObjType } from '@work4all/models/lib/utils/convertEntityToBzObjType';

import { settings, useSetting } from '../../../../../../../../settings';
import { useFormContextPlus } from '../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../hooks/mask-context';
import { ErpData } from '../../../ErpData';
import { convertEntityToReportBzObjType } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api';
import { useShadowBzObjectApiContext } from '../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

import { ReportPreviewMask } from './ReportPreviewMask';

interface ReportButtonProps {
  disabled?: boolean;
}

export function ReportButton(props: ReportButtonProps) {
  const { disabled } = props;
  const { t } = useTranslation();
  const mask = useMaskContext();
  const form = useFormContextPlus<ErpData>();

  const { persist, isDirty, positions } = useShadowBzObjectApiContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const invoiceAddress = form.watch('additionalAddress2');

  const isXInvoice = useMemo(() => {
    const customer: Customer = (
      invoiceAddress?.businessPartner as BusinessPartner
    )?.data;

    return (
      mask.entity === Entities.invoice &&
      customer?.invoiceForm === InvoiceForm.ELEKTRONISCH &&
      [EInvoiceFormat.X_RECHNUNG_CII, EInvoiceFormat.X_RECHNUNG_UBL].includes(
        customer?.eInvoiceFormat
      )
    );
  }, [invoiceAddress, mask.entity]);

  const reportBzObjectType = useMemo(() => {
    try {
      return convertEntityToReportBzObjType(mask.entity);
    } catch {
      return undefined;
    }
  }, [mask.entity]);

  const bzObjectType = useMemo(() => {
    try {
      return convertEntityToBzObjType(mask.entity);
    } catch {
      return undefined;
    }
  }, [mask.entity]);

  const { set: setLastUsedReportType } = useSetting(
    settings.lastUsedReportType()
  );

  const { data: reportList } = useReportOptions(reportBzObjectType);

  const sortedReportList = useMemo(() => {
    return [...reportList].sort((a) => {
      return a.standardReport ? -1 : 1;
    });
  }, [reportList]);

  const dialogs = useDialogs();

  const isMdDown = useMediaQuery<Theme>((t) => t.breakpoints.down('md'));

  const buttonRef = useRef(null);
  const popoverState = usePopoverState('bottom');

  const handleClick = useCallback(
    async (props: { reportType: ReportType; report?: Report }) => {
      // Persist the shadow object first if there are changes.
      if (isDirty || mask.isCreateMode) {
        await form.handleSubmit(async () => {
          const response = await persist();
          const data = response.data.persistShadowBzObject.data;

          form.reset(data);

          setTimeout(() => {
            if (pathname.includes('/new')) {
              const path = pathname.replace('new', `${data.id}`);
              navigate(path, { replace: true });
            }

            openReportMaskWithData({
              data: {
                ...data,
                positionList: data.positionList.filter(
                  (x) => 'posId' in x && !x.posId
                ),
              },
              ...props,
            });
          }, 100);
        })();
      } else {
        const data: ERPTypes = {
          ...form.getValues(),
          positionList: positions.filter((x) => !x.posId),
        };

        openReportMaskWithData({ data, ...props });
      }

      function openReportMaskWithData(props: {
        data: ERPTypes;
        reportType: ReportType;
        report?: Report;
      }) {
        const { data, reportType, report } = props;

        const contact = data.businessPartnerContactCombined.contact;
        const businessPartner =
          data.businessPartnerContactCombined.businessPartner;
        const businessPartnerType = data.businessPartnerType;

        const id = data.id ?? mask.id;

        setLastUsedReportType(reportType);

        const initialView: IStackItem = {
          view: (
            <ReportPreviewMask
              id={id}
              reportBzObjectType={reportBzObjectType}
              report={report}
              reportType={reportType}
              data={data}
              contactId={contact?.id}
              businessPartnerId={businessPartner?.id}
              businessPartnerType={
                businessPartnerType === SdObjType.KUNDE
                  ? Entities.customer
                  : Entities.supplier
              }
              bzObjectType={bzObjectType}
              entity={mask.entity}
            />
          ),
        };

        const dialog = dialogs.open(NavigationOverlay, {
          initialView: initialView,
          close() {
            dialogs.close(dialog.id);
          },
        });
      }
    },
    [
      bzObjectType,
      dialogs,
      form,
      isDirty,
      mask.entity,
      mask.id,
      mask.isCreateMode,
      navigate,
      pathname,
      persist,
      positions,
      reportBzObjectType,
      setLastUsedReportType,
    ]
  );

  const handleButtonClick = useCallback(
    (e) => {
      if (isXInvoice) {
        handleClick({ reportType: null });
      } else {
        popoverState.handleClick(e);
      }
    },
    [handleClick, isXInvoice, popoverState]
  );

  return (
    <>
      <Popover anchorEl={buttonRef.current} {...popoverState}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              style={{ padding: '0 1rem' }}
              onClick={() => {
                handleClick({ reportType: ReportType.Simple });
                popoverState.onClose();
              }}
            >
              <ListItemText>
                {t('COMMON.REPORT_TYPE.' + ReportType.Simple)}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider
            title={t('COMMON.REPORT_TYPE.' + ReportType.CrystalReports)}
            horizontalPadding
          />

          {sortedReportList.map((report) => {
            return (
              <ListItem
                key={report.id}
                disablePadding
                secondaryAction={
                  report.standardReport ? (
                    <IconButton edge="end" aria-label="delete">
                      <Star style={{ color: 'var(--brand02)' }} />
                    </IconButton>
                  ) : null
                }
              >
                <ListItemButton
                  style={{ padding: '0 1rem' }}
                  onClick={() => {
                    handleClick({
                      reportType: ReportType.CrystalReports,
                      report,
                    });
                    popoverState.onClose();
                  }}
                >
                  <ListItemText>{report.note}</ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Popover>
      {isMdDown ? (
        <Fab
          color="primary"
          size="medium"
          data-test-id={'preview-icon-button'}
          onClick={handleButtonClick}
          ref={buttonRef}
        >
          <DescriptionIcon />
        </Fab>
      ) : (
        <Button
          disabled={disabled}
          onClick={handleButtonClick}
          startIcon={<DescriptionIcon />}
          ref={buttonRef}
        >
          {t('COMMON.PREVIEW')}
        </Button>
      )}
    </>
  );
}
