import { MbscCalendarEvent, MbscResource } from '@mobiscroll/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { RESOURCE_PROJECT_ID_PRFX } from './utils';

const createProjectDateRessource = (props: {
  project: Project;
  name: string;
  eventList: MbscCalendarEvent[];
}) => {
  const { eventList, name, project } = props;
  const id = name + '-' + project.id;
  const event = eventList.find((x) => x.id === id);
  return {
    id,
    name,
    children: [],
    eventDragBetweenResources: false,
    event,
  } as MbscResource;
};

function sortByIndex(a: ProjectProcess, b: ProjectProcess) {
  return a.index > b.index ? 1 : -1;
}

export const useProjectProcessResources = (props: {
  projectList: Project[];
  compact?: boolean;
  resourceClassIdList?: number[];
  eventList: MbscCalendarEvent[];
  resourceOptions?: Omit<MbscResource, 'id'>;
}) => {
  const {
    projectList,
    compact = true,
    resourceClassIdList,
    eventList,
    resourceOptions,
  } = props;

  const { t } = useTranslation();

  return useMemo<MbscResource[]>(() => {
    const result: MbscResource[] = [];

    for (const project of projectList) {
      const projectDateRessources: MbscResource = {
        id: 'dates-' + project.id,
        name: t('MASK.SCHEDULE'),
        children: [],
        eventDragBetweenResources: false,
      };

      if (!compact) {
        if (project.startDateOuter || project.endDateOuter) {
          projectDateRessources.children.push(
            createProjectDateRessource({
              project,
              name: t(`PROJECTPLANNING.DATERANGE.START_END`),
              eventList,
            })
          );
        }
        if (project.startDateInner || project.endDateInner) {
          projectDateRessources.children.push(
            createProjectDateRessource({
              project,
              name: t(`PROJECTPLANNING.DATERANGE.EVENT_START_END`),
              eventList,
            })
          );
        }
      }

      const filteredProcesses =
        !resourceClassIdList || !resourceClassIdList.length
          ? project?.projectProcessList
          : project?.projectProcessList.filter((x) =>
              resourceClassIdList.includes(x.ressourceClassId)
            );

      const projectResource: MbscResource = {
        id: RESOURCE_PROJECT_ID_PRFX + project.id,
        name: `${project.number} - ${project.name}`,
        children: compact ? [] : [projectDateRessources],
        eventDragBetweenResources: false,
        ...resourceOptions,
      };

      if (!compact) {
        const handleProcess = (process: ProjectProcess) => {
          const event = eventList.find((x) => x.id === process.id);
          const resource = {
            color:
              process.ressourceClass?.hexColorPair?.split(',')[0] ||
              'var(--ui04)',
            name: process.process,
            id: process.id,
            eventDragBetweenResources: false,
            process,
            event,
          };

          if (process.kindId === ProjectProcessKind.GLIEDERUNGSPUNKT) {
            projectResource.children.push({
              id: process.id,
              name: process.process,
              children: [],
              eventDragBetweenResources: false,
            });
          } else {
            const group = projectResource.children.find(
              (g) => g.id === process.parentId
            );

            if (group) {
              group.children.push(resource);
            } else {
              projectResource.children.push(resource);
            }
          }
        };

        [...filteredProcesses]
          .filter((x) => !x.parentId)
          .sort(sortByIndex)
          .forEach(handleProcess);
        [...filteredProcesses]
          .filter((x) => x.parentId)
          .sort(sortByIndex)
          .forEach(handleProcess);
      }

      result.push(projectResource);
    }

    return result;
  }, [
    compact,
    eventList,
    projectList,
    resourceClassIdList,
    resourceOptions,
    t,
  ]);
};
