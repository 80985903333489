import styles from './Receipt.module.scss';

import { Fullscreen } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ChangeEvent, type JSX, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { usePreviewNavigation } from '@work4all/components/lib/components/entity-preview/erp-preview/hooks/use-preview-navigation';
import { LockContext } from '@work4all/components/lib/hooks';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { Remove } from '@work4all/components/lib/input/actions/remove/Remove';
import { Upload } from '@work4all/components/lib/input/actions/upload/Upload';
import {
  FilePreview,
  FilePreviewProvider,
  useFilePreview,
} from '@work4all/components/lib/preview/FilePreviewProvider';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { Collapse } from '../../../../../../../components';

export function Receipt() {
  const receiptConext = useContext(TempFileManagerContext);
  const { file, component } = usePreviewNavigation(receiptConext.fileList);

  return (
    <FilePreviewProvider file={file}>
      <ReceiptInternal navigation={component} />
    </FilePreviewProvider>
  );
}

function ReceiptInternal({ navigation }: { navigation: JSX.Element }) {
  const { t } = useTranslation();

  const { openFullscreen, file } = useFilePreview();
  const receiptConext = useContext(TempFileManagerContext);
  const { locked } = useContext(LockContext);

  const onUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      receiptConext.uploadFiles(Array.from(e.target.files));
    },
    [receiptConext]
  );
  const dialogs = useDialogs();

  const onRemove = useCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('ALERTS.BTN_DELETE'),
      description: t('ALERTS.DELETE.RECEIPT'),
    });
    if (!confirmed) return;
    const id = file?.id;
    receiptConext.markFilesToDelete([id?.toString()]);
  }, [dialogs, t, file?.id, receiptConext]);

  const headerBar = (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <Upload onChange={onUpload} noTitle disabled={locked} />
        {file && <Remove onClick={onRemove} disabled={locked} />}
        {file && (
          <IconButtonWithTooltip
            tooltip={t('COMMON.FULLSCREEN')}
            icon={<Fullscreen />}
            onClick={openFullscreen}
          />
        )}
      </Stack>
      {navigation}
    </Stack>
  );

  return (
    <Collapse
      className={styles.receipt}
      title={t('MASK.RECEIPT')}
      defaultOpen
      headerBar={headerBar}
    >
      <Box
        bgcolor={'#eeeeee'}
        height={'100%'}
        minHeight={600}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {file ? (
          <FilePreview file={file} />
        ) : (
          <Typography>{t('COMMON.EMPTY')}</Typography>
        )}
      </Box>
    </Collapse>
  );
}
