import styles from './LabeledInput.module.scss';

import {
  ButtonBase,
  FormControl,
  Input,
  InputBaseComponentProps,
  InputLabel,
  Link,
} from '@mui/material';
import clsx from 'clsx';
import React, {
  ChangeEventHandler,
  CSSProperties,
  FocusEventHandler,
  isValidElement,
  type JSX,
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { TestDataAttributes } from '@work4all/utils';
import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';
import { withStopPropogation } from '@work4all/utils/lib/withStopPropogation';

import { Caption } from '../../typography/caption/Caption';
import { Action } from '../multi-step-search/types';

import { Required } from './components/required/Required';

interface IClasses {
  input?: string;
  actions?: string;
}

export interface ILabeledInput extends TestDataAttributes {
  children?: React.ReactNode;
  label?: string | JSX.Element;
  value?: string | number | JSX.Element;
  required?: boolean;
  name?: string;
  inputProps?: Omit<InputBaseComponentProps, 'name' | 'size'>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
  >;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onClear?: (inputRef: RefObject<HTMLInputElement>) => void;
  inputOnClick?: MouseEventHandler<HTMLInputElement>;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  iconRight?: JSX.Element;
  placeholder?: string;
  classes?: IClasses;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  wrapperClassName?: string;
  style?: CSSProperties;
  defaultValue?: string | number;
  actions?: Action[];
  multiline?: boolean;
  multilineWithDynamicHeight?: boolean;
  textColor?: 'text02' | 'text03';
  fieldStyle?: 'fill' | 'underline';
  error?: string;
  pattern?: string;
  disabled?: boolean;
  type?: string;
  minRows?: number;
  maxRows?: number;
  autoComplete?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  multientry?: 'none' | 'left' | 'right' | 'both';
}

export const LabeledInput = React.forwardRef<HTMLInputElement, ILabeledInput>(
  function LabeledInput(props, ref) {
    const {
      value,
      disabled,
      type = 'text',
      minRows,
      maxRows,
      selectOnFocus,
      multientry = 'none',
    } = props;

    const { textColor = 'text01', error, fieldStyle = 'fill' } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [forceFocused, setForcedFocused] = useState<boolean | undefined>(
      undefined
    );
    const [isFocused, setIsFocused] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      const isFocusedStart =
        value !== undefined && value !== ''
          ? true
          : inputRef.current?.value !== '' &&
            props.defaultValue !== undefined &&
            props.defaultValue !== ''
          ? true
          : undefined;
      setForcedFocused(isFocusedStart);
    });

    const commonClassNames = clsx(styles.fontStyles, styles[textColor], {
      [styles.inputMargin]:
        !props.multiline && !props.multilineWithDynamicHeight,
      [styles.noMargin]: !props.label,
    });

    useEffect(() => {
      if (props.autoFocus && inputRef.current) {
        inputRef.current.focus();
      }
    }, [props.autoFocus]);

    const refSetter = useMemo(() => {
      return reactRefSetter(inputRef, ref);
    }, [ref, inputRef]);

    const renderInput = () => {
      return (
        <Input
          inputRef={refSetter}
          className={clsx(styles.inputWrapper, commonClassNames)}
          classes={{
            input: clsx(
              styles.inputField,
              {
                [styles.multiline]: props.multiline && !minRows,
                [styles.input]:
                  !props.multiline && !props.multilineWithDynamicHeight,
              },
              props.classes?.input
            ),
          }}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={props.placeholder}
          startAdornment={props.startAdornment}
          endAdornment={props.endAdornment}
          inputProps={{
            size: 1,
            name: props.name,
            ...props.inputProps,
            defaultValue: props.defaultValue,
            pattern: props.pattern,
          }}
          onChange={props.onChange}
          onKeyDown={(e) => {
            if (props?.onKeyDown) {
              props?.onKeyDown(e);
            }
            if (e.code === 'Enter' || e.code === 'Space') {
              inputRef.current.click();
            }
          }}
          onBlur={(e) => {
            props.onBlur?.(e);
            const value = e.currentTarget.value;
            if (value === undefined || value === '') {
              setForcedFocused(undefined);
            }
            setIsFocused(false);
          }}
          onFocus={(e) => {
            if (props.onFocus) {
              props.onFocus(e as React.FocusEvent<HTMLInputElement>);
            }
            if (selectOnFocus) {
              e.target.select();
            }
            setIsFocused(true);
          }}
          onClick={props.inputOnClick}
          multiline={props.multiline || props.multilineWithDynamicHeight}
          minRows={minRows}
          maxRows={maxRows}
          autoComplete={props?.autoComplete}
          autoFocus={props?.autoFocus}
        />
      );
    };
    return (
      <FormControl
        data-test-id={props['data-test-id']}
        focused={forceFocused}
        className={clsx(
          styles.wrapper,
          props.className,
          props.wrapperClassName,
          {
            [styles[`multiple-entry--${multientry}`]]: multientry !== 'none',
            [styles.error]: error !== undefined,
          },
          { [styles.underline]: fieldStyle === 'underline' },
          {
            [styles.focused]: isFocused,
          }
        )}
        style={props.style}
        disabled={disabled}
      >
        {error !== undefined && (
          <div className={styles.errorMessage}>
            <Caption color="error">{error}</Caption>
          </div>
        )}
        <div
          className={styles.left}
          onClick={!disabled ? props.onClick : undefined}
        >
          <InputLabel
            className={clsx(styles.label, {
              [styles.labelInvis]: !props.label,
            })}
            classes={{
              shrink: styles['label--shrink'],
            }}
            // We are using inoutRef.current.value for the uncontrolled
            // use of the component while the value is used for the
            // controlled use.
            shrink={inputRef.current?.value || value ? true : undefined}
          >
            {props.label} {props.required && <Required />}
          </InputLabel>
          {isValidElement(value) ? value : renderInput()}
        </div>

        {(props?.actions || props.iconRight || props.onClear) && (
          <div className={clsx(styles.actionsWrapper, props.classes?.actions)}>
            {props?.actions || (props?.onClear && props.value) ? (
              <>
                {!props.disabled &&
                  props?.actions?.map(({ href, icon, onClick }, idx) => (
                    <Link
                      key={idx}
                      onClick={onClick || withStopPropogation()}
                      color="inherit"
                      href={href}
                      className={styles.actionBtn}
                      underline="hover"
                    >
                      {icon}
                    </Link>
                  ))}

                {props.onClear ? (
                  <ButtonBase
                    disabled={props.disabled}
                    className={styles.close}
                    onClick={withStopPropogation(() => props.onClear(inputRef))}
                  >
                    <ClearIcon />
                  </ButtonBase>
                ) : null}
              </>
            ) : (
              props.iconRight
            )}
          </div>
        )}
        {props.children && (
          <div onClick={withStopPropogation()}>{props.children}</div>
        )}
      </FormControl>
    );
  }
);
