import styles from './ActionButton.module.scss';

import { ButtonBase } from '@mui/material';
import clsx from 'clsx';
import React, { type JSX } from 'react';

import { Link } from '@work4all/data';

import { ButtonText } from '../../typography/button-text/ButtonText';

interface IClasses {
  title?: string;
}

export interface IActionButtonProps {
  icon?: JSX.Element;
  title?: string;
  className?: string;
  classes?: IClasses;
  href?: string;
  render?: (className?: string) => JSX.Element;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

/**
 * @deperecated Use BaseActionButton instead
 * @param props
 * @returns
 */
export const ActionButton: React.FC<IActionButtonProps> = (props) => {
  return (
    <ButtonBase
      component={props.href !== undefined ? Link : undefined}
      to={props.href}
      className={clsx(styles['action-button'], props.className)}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon}
      {props.title && (
        <ButtonText
          className={clsx(styles['action-button__title'], props.classes?.title)}
        >
          {props.title}
        </ButtonText>
      )}
      {props.render && props.render(styles['action-button__title'])}
    </ButtonBase>
  );
};
