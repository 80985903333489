import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { useLock } from '@work4all/components/lib/hooks';

import { useUser } from '@work4all/data/lib/hooks/useUser';

import { useProjectAccessRights } from '../../../../../../../../../../components/data-tables/project-table/hooks/use-project-access-rights';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { CheckboxRadioItem } from '../../../../../../../../locked-inputs';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ProjectMaskFormValue } from '../../../../../types';

export const ProjectAccessGroup: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<ProjectMaskFormValue>();
  const { isCreateMode } = useMaskContext();

  const { locked } = useLock();

  const user = useUser();
  const { data } = useProjectAccessRights();
  const parent = watch('parentProject');
  const projectAccessGroups = watch('projectAccessGroups');
  const childProjects = watch('childProjects');

  useEffect(() => {
    if (isCreateMode && !projectAccessGroups) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue('projectAccessGroups', data);
    }
  }, [data, isCreateMode, setValue, projectAccessGroups]);

  return (
    <Collapse
      bottomIndent="lg"
      defaultOpen
      title={t('COMMON.RIGHTS_GROUP.TITLE_other')}
    >
      {parent && (
        <Typography variant="body2" noWrap>
          {t('COMMON.RIGHTS_GROUP.PARENT_EXIST', { project: parent.name })}
        </Typography>
      )}

      {childProjects?.length > 0 && !parent && (
        <Typography variant="body2" noWrap>
          {t('COMMON.RIGHTS_GROUP.CHILD_EXIST')}
        </Typography>
      )}

      <ControllerPlus
        name="projectAccessGroups"
        control={control}
        render={({ field }) => {
          const checked = field.value ? field.value : [];

          const components = data.map((x) => (
            <CheckboxRadioItem
              disabled={locked || Boolean(parent) || !user.isMaster}
              key={x.id}
              label={x.name}
              checked={checked.some((y) => y.id === x.id)}
              onChange={() => {
                const exist = checked.findIndex((y) => y.id === x.id) !== -1;
                if (exist) {
                  field.onChange(checked.filter((y) => y.id !== x.id));
                } else {
                  field.onChange([...checked, x]);
                }
              }}
            />
          ));

          return <React.Fragment>{components}</React.Fragment>;
        }}
      />
    </Collapse>
  );
};
