import styles from './use-components-mobile-view.module.scss';

import { useCallback, useMemo } from 'react';

import { BOMComponent } from '@work4all/models/lib/Classes/BOMComponent.entity';

export const useComponentsMobileViews = () => {
  const renderMobilePosition = useCallback(
    (
      position: BOMComponent,
      setEditedPosition: (position: BOMComponent) => void
    ) => {
      return (
        <tr key={position.id} className={styles.mobilePositionRow}>
          <td
            style={{ width: '100%' }}
            onClick={() => {
              setEditedPosition(position);
            }}
          >
            <div style={{ whiteSpace: 'pre-line' }}>{position.name}</div>

            <div className={styles.subline}>
              <div className={styles.left}>
                <span>
                  {position.amount} {position.article?.unit?.name}
                </span>
                <span>{position.article?.articleKind}</span>
                <span> {position.article.number}</span>
              </div>
            </div>
          </td>
        </tr>
      );
    },
    []
  );

  const classes = useMemo(
    () => ({
      mobile: styles.mobilePositionsTable,
    }),
    []
  );
  return { renderMobilePosition, classes };
};
