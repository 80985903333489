import { DateTime } from 'luxon';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation } from '@work4all/data';

import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { InputDailyTimeTargets } from '@work4all/models/lib/Classes/InputDailyTimeTargets.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { GeneralTabPanel } from './components/general/GeneralTabPanel';
import { PasswordChangeButton } from './components/password-change/PasswordChangeButton';
import { PersonalTabPanel } from './components/personal/PersonalTabPanel';
import { makeEmptyNowDate } from './components/work-time/getTimeValue';
import { WorkTimeTabPanel } from './components/work-time/WorkTimeTabPanel';
import { useUserFormUpdate } from './hooks/use-user-form-update';
import { useUserRequest } from './hooks/use-user-request';
import { makeOnMutationError } from './MakeOnMutationError';
import { UserFormValue } from './types';

type UserFormRelation = {
  dailyTimeTargets?: InputDailyTimeTargets;
  userImageFromTempFile?: string;
};

export const UserOverlayController: React.FC<MaskControllerProps> = (props) => {
  const mask = useMaskConfig(props);
  const request = useUserRequest({ id: mask.id });

  const normalizeData = useCallback(
    (
      input: UserFormValue,
      newEntityData: UserFormValue,
      isCreateMode: boolean
    ) => {
      const output = { ...input, ...(isCreateMode ? newEntityData : {}) };
      if (output.birthDate === '0001-01-01T00:00:00') output.birthDate = null;
      if (output.enterDate === '0001-01-01T00:00:00') output.enterDate = null;
      if (output.exitDate === '0001-01-01T00:00:00') output.exitDate = null;

      const getDay = (targets, weekday) => {
        const target = (targets || []).find(
          (entry) => entry.weekday === weekday
        );
        return target ? target.dailytarget : 0; // Default to 0 if not found
      };
      const userWorkTimeDaylieTargets = output.userWorkTimeDaylieTargets;
      output.dailyTargetWorkHoursMonday = getDay(userWorkTimeDaylieTargets, 0);
      output.dailyTargetWorkHoursTuesday = getDay(userWorkTimeDaylieTargets, 1);
      output.dailyTargetWorkHoursWednesday = getDay(
        userWorkTimeDaylieTargets,
        2
      );
      output.dailyTargetWorkHoursThursday = getDay(
        userWorkTimeDaylieTargets,
        3
      );
      output.dailyTargetWorkHoursFriday = getDay(userWorkTimeDaylieTargets, 4);
      output.dailyTargetWorkHoursSaturday = getDay(
        userWorkTimeDaylieTargets,
        5
      );
      output.dailyTargetWorkHoursSunday = getDay(userWorkTimeDaylieTargets, 6);
      output.earliestCommingTime = DateTime.now()
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .plus({ minutes: output.comesGoesMinimumStartTime || 0 })
        .toISO();

      output.birthDate = DateTime.fromISO(output.birthDate).toISO();

      output.enterDate = DateTime.fromISO(output.enterDate).toISO();

      output.exitDate = DateTime.fromISO(output.exitDate).toISO();

      if (output.roundingForHour) {
        output.roundingForHour = DateTime.fromISO(
          output.roundingForHour
        ).toISO();
      } else {
        output.roundingForHour = makeEmptyNowDate().toISO();
      }

      return output;
    },
    []
  );

  const { t } = useTranslation();

  const customRules = useCallback(
    (data: UserFormValue) => {
      if (data.userKind === UserClass.RESSOURCE) return true;
      const requiredFields = [
        'name',
        'eMail',
        'shortName',
        'firstName',
        'lastName',
      ];
      const errors = [];
      for (const field of requiredFields) {
        if (!data[field]) {
          errors.push({
            [field]: {
              message: t('ERROR.FIELD_REQUIRED'),
              type: 'customValidation',
            },
          });
        }
      }

      if (errors.length) {
        return errors.reduce((prev, cur) => {
          return { ...prev, ...cur };
        }, {});
      }

      return true;
    },
    [t]
  );

  const newEntityData = useMemo((): FullUser => {
    return {
      userKind: props.params?.userKind as UserClass,
      number: 0,
      vacationValue: 0,
      timing: 0,
      timestampIncludedMinutesPerMonth: 0,
      timestampIncludedMinutesPerDay: 0,
      comesGoesMinimalPause: 0,
    };
  }, [props.params?.userKind]);

  const overlay = useMaskOverlay<UserFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    getSubTitle: (x) => x.displayName,
    normalizeData,
    customRules,
  });

  useUserFormUpdate(overlay.form);

  const [mutate] = useDataMutation<FullUser, EMode.upsert, UserFormRelation>({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data,
    onCompleted: (data) => {
      props.onAfterSave(data);
    },
    onError: makeOnMutationError<UserFormValue>(
      overlay.form,
      Entities.fullUser
    ),
  });

  const onSubmit = async (formData: UserFormValue) => {
    const toModify = pickUpdateFields(
      formData,
      overlay.form.formState.dirtyFields
    );
    const relations = mapUserRelations(toModify);

    if (toModify.earliestCommingTime) {
      toModify.comesGoesMinimumStartTime =
        DateTime.fromISO(toModify.earliestCommingTime).hour * 60 +
        DateTime.fromISO(toModify.earliestCommingTime).minute;
      delete toModify.earliestCommingTime;
    }

    if (formData.userKind === UserClass.RESSOURCE) {
      toModify.userKind = formData.userKind;
      toModify.name = formData.displayName
        ? formData.displayName
        : formData.shortName;
    }

    mutate(toModify, { relations });
  };

  const userKind =
    (props.params?.userKind as UserClass) ?? overlay.data.userKind;
  const loading = props.params?.userKind ? false : overlay.loading;

  return (
    <OverlayController<UserFormValue>
      {...overlay}
      title={userKind ? t(`ENUMS.${userKind.toUpperCase()}`) : overlay.title}
      onSubmit={onSubmit}
      actions={<PasswordChangeButton />}
      tabs={<Tabs userClass={userKind} loading={loading} />}
      disableDelete
    >
      {loading ? <></> : <Content userClass={userKind} />}
    </OverlayController>
  );
};

interface TabsContentProps {
  userClass?: UserClass;
  loading?: boolean;
}

const Tabs = memo(function (props: TabsContentProps) {
  const { t } = useTranslation();
  const { userClass, loading } = props;
  const hidden = userClass === UserClass.RESSOURCE;
  const hiddenStyle =
    loading || hidden
      ? {
          display: 'none',
        }
      : undefined;
  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')} />
      <MaskTab
        style={hiddenStyle}
        value="misc"
        label={t('USER.PERSONAL_DATA_TAB')}
      />
      <MaskTab
        style={hiddenStyle}
        value="prices"
        label={t('USER.WORK_TIME_TAB')}
      />
    </MaskTabs>
  );
});

const Content = memo(function (props: TabsContentProps) {
  const { userClass } = props;

  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>
      {userClass !== UserClass.RESSOURCE && (
        <>
          <MaskTabPanel value="misc">
            <PersonalTabPanel />
          </MaskTabPanel>

          <MaskTabPanel value="prices">
            <WorkTimeTabPanel />
          </MaskTabPanel>
        </>
      )}
    </MaskContent>
  );
});

const mapUserRelations = (input: UserFormValue): UserFormRelation => {
  const userImageFromTempFile = input.userImageFromTempFile;

  const dailyTimeTargets = {
    monday: input.dailyTargetWorkHoursMonday,
    tuesday: input.dailyTargetWorkHoursTuesday,
    wednesday: input.dailyTargetWorkHoursWednesday,
    thursday: input.dailyTargetWorkHoursThursday,
    friday: input.dailyTargetWorkHoursFriday,
    saturday: input.dailyTargetWorkHoursSaturday,
    sunday: input.dailyTargetWorkHoursSunday,
  };
  const hasDailyTimeTargets = Object.values(dailyTimeTargets).some(
    (value) => value !== undefined
  );

  return {
    ...(hasDailyTimeTargets && { dailyTimeTargets }),
    userImageFromTempFile,
  };
};
