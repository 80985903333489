import type { JSX } from 'react';

import { canFlagEntityRecordsAsFavorite } from '@work4all/data/lib/utils/can-flag-entity-records-as-favorite';

import { FavoriteItem } from '@work4all/models/lib/Classes/FavoriteItem.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OverlayControllerFavoriteItemAction } from '../components';

type Props<TData> = {
  data: TData;
  entity: Entities;
  defaultActions: JSX.Element | undefined;
};

export const useOverlayControllerMoreActions = <
  TData extends {
    id?: string | number;
    favoriteItem?: FavoriteItem;
  }
>({
  data,
  entity,
  defaultActions,
}: Props<TData>) => {
  const canFlagAsFavorite = canFlagEntityRecordsAsFavorite(entity);

  if (!defaultActions && !canFlagAsFavorite) {
    return null;
  }

  return (
    <>
      {defaultActions}

      {canFlagAsFavorite && (
        <OverlayControllerFavoriteItemAction
          id={data.id}
          entity={entity}
          isFavorite={Boolean(data.favoriteItem)}
        />
      )}
    </>
  );
};
