import React from 'react';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntitySuggestionPickerField } from './EntitySuggestionPickerField';
import { SuggestionPickerFieldProps } from './SuggestionPickerField';

type CityPickerFieldProps = Omit<SuggestionPickerFieldProps, 'data'>;

export const CityPickerField = React.forwardRef<
  HTMLDivElement,
  CityPickerFieldProps
>(function CityPickerField(props, _ref) {
  const { t } = useTranslation();

  return (
    <EntitySuggestionPickerField
      label={t('INPUTS.LOCATION')}
      {...props}
      entity={Entities.customer}
      field="city"
    />
  );
});
