import { gql, useMutation } from '@apollo/client';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { captureMessage, createTransaction, useUser } from '@work4all/data';

import { ObjectLockResult } from '@work4all/models/lib/Classes/ObjectLockResult.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import {
  ObjectTypeByEntity,
  ObjectTypesUnion,
} from '@work4all/models/lib/GraphQLEntities/Entities';

import { isDev } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { useLockSubscription } from '../object-lock-subscription/LockObjectProvider';

import { LockContext } from './LockContext';
import { application } from './SessionId';

// Duplicated from mask-metadata.tsx to not import app to library
const NEW_ENTITY_ID = 'new';

const SET_LOCK = gql`
  mutation SetObjectLock(
    $objectType: ObjectType!
    $objectPrimaryKey: [PrimaryKey]!
    $application: String
  ) {
    setObjectLock(
      objectType: $objectType
      objectPrimaryKey: $objectPrimaryKey
      application: $application
    ) {
      lockResult
      application
      ownerCode
      user: benutzer {
        id: code
        firstName: vorname
        lastName: nachname
        displayName: anzeigename
      }
    }
  }
`;

interface SetLockResponse {
  setObjectLock: ObjectLockResult;
}

interface SetLockVars {
  objectType: ObjectTypesUnion;
  objectPrimaryKey: string[];
  application: string;
}

const REMOVE_LOCK = gql`
  mutation RemoveObjectLock(
    $objectType: ObjectType!
    $objectPrimaryKey: [PrimaryKey]!
    $application: String!
  ) {
    removeObjectLock(
      objectType: $objectType
      objectPrimaryKey: $objectPrimaryKey
      application: $application
    )
  }
`;

interface RemoveLockResponse {
  removeObjectLock: ObjectLockResult;
}

interface RemoveLockVars {
  objectType: ObjectTypesUnion;
  objectPrimaryKey: string[];
  application: string;
}

interface LockProviderProps {
  entity: Entities;
  entityIds: string | number | (string | number)[];
  autoLock?: boolean;
  forcedObjectType?: ObjectTypesUnion;
}

export const LockProvider = ({
  children,
  entity,
  entityIds,
  autoLock = false,
  forcedObjectType,
}: PropsWithChildren<LockProviderProps>) => {
  const [setLock] = useMutation<SetLockResponse, SetLockVars>(SET_LOCK);

  const [removeLock] = useMutation<RemoveLockResponse, RemoveLockVars>(
    REMOVE_LOCK
  );

  const setLockSubscriptionRef = useRef<Subscription | undefined>(null);

  const { getLock, getLockStable, replaceItem } = useLockSubscription(
    entity,
    forcedObjectType
  );

  const lock = useCallback(
    (args: {
      subEntityType?: Entities;
      subEntityIds?: (string | number)[];
      forcedObjectType?: ObjectTypesUnion;
    }) => {
      captureMessage('[LockProvider.lock] Lock started.');
      if (!args) {
        captureMessage('[LockProvider.lock] There is no args.');
        return;
      }
      const { subEntityType, subEntityIds, forcedObjectType } = args;

      if (subEntityIds[0] === NEW_ENTITY_ID) {
        captureMessage('[LockProvider.lock] Empty id.', 'error');
        return;
      }

      const objectType = forcedObjectType || ObjectTypeByEntity[subEntityType];

      // There are two possible outcomes when trying to acquire a lock:
      //
      // 1. Acquired the lock successfully. In this case we need to refresh the
      //    lock until the mask is closed;
      // 2. Couldn't acquire the lock because the entity is already locked by
      //    someone else. In this keep trying again until we get the lock so that
      //    we can switch the mask to "write" mode.

      setLockSubscriptionRef.current &&
        setLockSubscriptionRef.current.unsubscribe();

      if (!objectType || !subEntityIds || subEntityIds.length === 0) {
        console.warn('requesting object lock for undefined object');
        captureMessage(
          '[LockProvider.lock] Requesting object lock for undefined object.'
        );
        return;
      }

      setLockSubscriptionRef.current = timer(0, 10 * 1000)
        .pipe(
          switchMap(async () => {
            Promise.all(
              subEntityIds.map(async (id) => {
                const transaction = createTransaction({
                  name: '[LockProvider.lock] SetLock',
                });
                const response = await setLock({
                  variables: {
                    objectType,
                    objectPrimaryKey: [`${id}`],
                    application,
                  },
                });
                transaction.setData('customContext', {
                  variables: {
                    objectType,
                    objectPrimaryKey: [id],
                    application,
                  },
                  response,
                });
                transaction?.finish();

                const { user, application: sessionId } =
                  response.data.setObjectLock;
                const newEntry = {
                  user,
                  sessionId: sessionId,
                  entityId: `${id}`,
                };
                replaceItem(entity, newEntry);
              })
            );
          })
        )
        .subscribe();
      captureMessage('[LockProvider.lock] Lock finished.');
    },
    [setLock, replaceItem, entity]
  );

  const unlock = useCallback(
    (args: {
      subEntityType?: Entities;
      subEntityIds?: (string | number)[];
      forcedObjectType?: ObjectTypesUnion;
    }) => {
      if (!args) return;
      const { subEntityType, subEntityIds, forcedObjectType } = args;

      const objectType = forcedObjectType || ObjectTypeByEntity[subEntityType];

      if (
        !objectType ||
        !subEntityIds ||
        subEntityIds.length === 0 ||
        subEntityIds[0] === undefined
      ) {
        if (isDev()) {
          // ToDo: https://work4all.atlassian.net/browse/WW-4090
          console.warn('requesting object unlock for undefined object');
        }
        return;
      }

      setLockSubscriptionRef.current &&
        setLockSubscriptionRef.current.unsubscribe();

      Promise.all(
        subEntityIds
          .filter((x) => x !== 'undefined')
          .map(async (id) => {
            const locker = getLockStable(id, entity);
            if (locker.locked && locker.sessionId === application)
              await removeLock({
                variables: {
                  objectType,
                  objectPrimaryKey: [`${id}`],
                  application,
                },
              });
          })
      );
    },
    [removeLock, getLockStable]
  );

  /// AUTO-LOCK
  const ids = useDeepMemo(() => {
    const _ids = Array.isArray(entityIds) ? entityIds : [entityIds];
    return _ids.filter((x) => x);
  }, [entityIds]);
  const args = useMemo(() => {
    return {
      ids,
      entity,
    };
  }, [ids, entity]);

  const lockWithArgs = useCallback(async () => {
    lock({
      subEntityIds: args.ids,
      subEntityType: args.entity,
      forcedObjectType,
    });
  }, [lock, args, forcedObjectType]);

  const unlockWithArgs = useCallback(() => {
    unlock({
      subEntityIds: args.ids,
      subEntityType: args.entity,
      forcedObjectType,
    });
  }, [unlock, args, forcedObjectType]);

  useEffect(() => {
    if (!autoLock) return;
    lockWithArgs();
    return () => {
      unlockWithArgs();
    };
  }, [autoLock, lockWithArgs, unlockWithArgs]);

  const user = useUser();

  const value = useMemo(() => {
    // Check sessionId if this we are locking ourselves
    // We need to do it for each entity we are trying to lock
    const lockers = args.ids
      .map((id) => getLock(id, args.entity))
      .filter((x) => x.locked);

    const sessionOwnAllLocks = lockers.every(
      (x) => x.sessionId === application
    );

    // Check sessionId if this we are locking ourselves
    const fetchLocked = sessionOwnAllLocks
      ? false
      : Boolean(lockers.length) ?? false;
    return {
      lock: lockWithArgs,
      unlock: unlockWithArgs,
      locked: fetchLocked,
      // There is no need / no feature to know who from those users locking entity and which entity if we want to lock more then one.
      user: lockers[0]?.lockedBy,
    };
  }, [args, lockWithArgs, lockWithArgs, getLock, user]);

  return <LockContext.Provider value={value}>{children}</LockContext.Provider>;
};

export function templateToObjecType(
  entity: Entities,
  template: {
    entity: Entities | string;
    id: string | number;
  }
) {
  return entity === Entities.contact
    ? template?.entity === Entities.customer
      ? 'KUNDENANSPRECHPARTNER'
      : 'LIEFERANTENANSPRECHPARTNER'
    : undefined;
}
