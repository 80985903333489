import { t } from 'i18next';
import { RefObject } from 'react';
import {
  AutoSizer,
  InfiniteLoader,
  InfiniteLoaderProps,
} from 'react-virtualized';
import { VariableSizeList, VariableSizeListProps } from 'react-window';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { reactRefSetter } from '@work4all/utils';

import { EntityLike } from '../../../types';

import {
  EntityPickerListItem,
  NoRowsItem,
  RenderItemData,
} from './PickerListItem';

export interface EntityPickerListProps<T extends EntityLike>
  extends Partial<Pick<InfiniteLoaderProps, 'isRowLoaded' | 'loadMoreRows'>> {
  noResultsText?: string;
  total: number;
  maxItems?: number;
  listItemHeight?: number;
  loading?: boolean;
  listRef?: RefObject<VariableSizeList>;
  itemRenderData: RenderItemData<T>;
}

export function PickerList<T>(props: EntityPickerListProps<T>) {
  const {
    noResultsText = t('PICKER.NO_RESULTS_FOUND'),
    total = 0,
    maxItems = 5,
    loading = false,
    listItemHeight = remToPx(2.25),
    isRowLoaded,
    loadMoreRows,
    listRef,
    itemRenderData,
  } = props;

  // The last row must be 1px shorter to avoid overflow.
  function getRowHeight(index: number) {
    return index === total - 1 ? listItemHeight - 1 : listItemHeight;
  }

  return (
    <div
      style={{
        height: listItemHeight * maxItems - 1,
        flex: 'auto',
      }}
    >
      {!loading && (
        <AutoSizer>
          {({ width, height }) => {
            const listProps: Omit<VariableSizeListProps, 'children'> = {
              className: 'custom-scrollbar',
              width: width,
              // TODO Use some other alternative to <AutoSizer>?
              // AutoSizer rounds values to the nearest integer.
              // This sometimes causes the vertical scrollbar to appear when
              // there is not enough space inside the container to display
              // `maxItems` rows. With this there is no additional scrollbar,
              // but the list is 1px shorter, so if there is exactly
              // `maxItems` rows in the list, the scrollbar will appear.
              // Reduce the height of the last row to avoid this.
              height: height - 1,
              itemSize: getRowHeight,
              itemCount: total,
              overscanCount: 3,
              itemData: itemRenderData,
              innerElementType:
                total === 0
                  ? () => <NoRowsItem text={noResultsText} />
                  : undefined,
            };

            if (!isRowLoaded) {
              //if we are not providing checks for infinite loading we can spare the measurements
              return (
                <VariableSizeList ref={listRef} {...listProps}>
                  {EntityPickerListItem}
                </VariableSizeList>
              );
            }

            return (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={listProps.itemCount}
              >
                {({ onRowsRendered, registerChild }) => {
                  return (
                    <VariableSizeList
                      ref={reactRefSetter(registerChild, listRef)}
                      onItemsRendered={({
                        visibleStartIndex: startIndex,
                        visibleStopIndex: stopIndex,
                      }) => onRowsRendered({ startIndex, stopIndex })}
                      {...listProps}
                    >
                      {EntityPickerListItem}
                    </VariableSizeList>
                  );
                }}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      )}
    </div>
  );
}
