import React from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { PickerTargetButton } from '../../../../../containers/mask-overlays/mask-overlay/views/components/table-cells/PickerTargetButton';

import {
  SupplierPricePicker,
  SupplierPricePickerProps,
} from './SupplierPricePicker';

interface Props extends Omit<SupplierPricePickerProps, 'multiple'> {
  label?: string;
  error?: string;
  disabled?: boolean;
}

export const SupplierPricePickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, _ref) => {
    const { ...pickerProps } = props;
    const { value } = pickerProps;

    return (
      <EntityPickerPopover
        picker={<SupplierPricePicker {...pickerProps} multiple={false} />}
      >
        <PickerTargetButton
          disabled
          hover
          style={{
            margin: '-0.25rem 0 0 0',
          }}
          value={value?.name || ''}
        >
          {value?.name}
        </PickerTargetButton>
      </EntityPickerPopover>
    );
  }
);
