import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs, useTableStateBag } from '@work4all/components';
import {
  GroupedItem,
  GroupedItems,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/types';
import { useDateSections } from '@work4all/components/lib/dataDisplay/basic-table/hooks/use-date-sections';

import { useDataProvider, useUser } from '@work4all/data';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { InputProjectAccessGroupRelation } from '@work4all/models/lib/Classes/InputProjectAccessGroupRelation.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { GridCellValue, GridColumn } from '../../grid/Grid';
import { useGridState } from '../../grid/GridStateProvider';
import { DataTableColumnConfig } from '../../table/DataTableColumnConfig';

import { useProjectAccessGroupMutate } from './use-project-acces-group-mutate';
import { useProjectAccessRights } from './use-project-access-rights';

export type ProjectRightsGridValue = GridCellValue<boolean | null>;

export function unGroup(
  item: Project | GroupedItem,
  done: string[] = [],
  expandedRows: { id: string }[]
): (Project | GroupedItem)[] {
  if (isGrouped(item)) {
    const id = item.meta.path.join('.');
    if (!done.some((x) => x === id)) {
      done.push(id);
      return [item, ...item.subRows.filter((x) => !x.skeleton)]
        .filter((x) => {
          return expandedRows.some((y) => {
            if (x.isGrouped) {
              return y.id === (x.meta?.path ?? []).join('.');
            }
            const expandedId = y.id.split('.');
            return expandedId[expandedId.length - 1] === x.id?.toString();
          });
        })
        .flatMap((x) => unGroup(x, done, expandedRows));
    }
  }

  return [item];
}

export function isGrouped(
  input: GroupedItems | GroupedItem | Project
): input is GroupedItem {
  if (input && 'isGrouped' in input) return true;
  return false;
}

export const useProjectGrid = (props: {
  projects: Project[] | GroupedItems;
  refetch: () => void;
  columnConfigs: DataTableColumnConfig<Work4AllEntity>[];
}) => {
  const { t } = useTranslation();
  const user = useUser();
  const tableStateBag = useTableStateBag();

  const expandedRows = useMemo(
    () => tableStateBag?.tableInstance?.expandedRows ?? [],
    [tableStateBag?.tableInstance?.expandedRows]
  );
  const { selectedColumns } = useGridState();
  const { projects: data, refetch, columnConfigs } = props;

  const projects = useDateSections({
    data: data,
    columns: columnConfigs,
  });

  const {
    data: accessGroups,
    loading,
    refetch: refetchAccessGroups,
  } = useProjectAccessRights(!user.isMaster);

  const columns = useMemo<GridColumn[]>(() => {
    return (
      accessGroups?.map((group) => ({
        id: group.id,
        headerName: group.name,
      })) ?? []
    );
  }, [accessGroups]);

  const unGroupedProjects = useMemo(() => {
    return projects.flatMap((x) => unGroup(x, [], expandedRows));
  }, [projects, expandedRows]);

  const { data: headProjects, pending } = useHeadProjects(unGroupedProjects);

  const lastGridValue = useRef<ProjectRightsGridValue[][]>(null);
  const initialGrid = useMemo(() => {
    if (pending) return lastGridValue.current;
    const internalGrid: ProjectRightsGridValue[][] = [];
    accessGroups.forEach((accessGroup, idx) => {
      const tempCol: ProjectRightsGridValue[] = [];
      unGroupedProjects.forEach(
        (
          proj: (Project | GroupedItem) & { meta: { isSectionStart: boolean } },
          idy
        ) => {
          let notCalculatedValue = false;
          let rowId = proj.id;
          let isHeadProject = false;
          let isHeadProjectSelected = false;
          if (isGrouped(proj)) {
            notCalculatedValue = proj.isGrouped;
            rowId = proj.meta.path.join('.');
            const headProject = headProjects.find(
              (x) => `${x.id}` === proj.meta.groupByVal
            );
            if (headProject && !headProject.parentProjectId) {
              notCalculatedValue = false;
              isHeadProject = true;
              isHeadProjectSelected = headProject
                ? headProject.projectAccessGroups
                    ?.filter(Boolean)
                    .some((x) => x.id === accessGroup.id)
                : false;
            }
          }

          tempCol[idy] = {
            cellValue: notCalculatedValue
              ? null
              : proj.projectAccessGroups
                  ?.filter(Boolean)
                  .some((x) => x.id === accessGroup.id) ||
                isHeadProjectSelected,
            disabled: proj.parentProject !== null ? !isHeadProject : false,
            rowId: rowId,
            colId: accessGroup.id,
            isSectionStart: proj.meta?.isSectionStart,
          };
        }
      );
      internalGrid[idx] = tempCol;
    });
    lastGridValue.current = internalGrid;
    return internalGrid;
  }, [accessGroups, unGroupedProjects, headProjects, pending]);

  const [grid, setGrid] = useState(initialGrid);
  useEffect(() => {
    if (initialGrid) setGrid(initialGrid);
  }, [initialGrid]);

  const mutate = useProjectAccessGroupMutate(refetch);

  const onItemClicked = useCallback(
    (c: number, r: number) => {
      const current = grid[c][r];

      const currentValue = !current.cellValue;
      const group = accessGroups.find((x) => x.id === current.colId);

      const projectId =
        typeof current.rowId === 'number'
          ? current.rowId
          : parseInt(current.rowId);

      const relations: InputProjectAccessGroupRelation = {
        projects: {
          add: currentValue ? [projectId] : [],
          remove: currentValue ? [] : [projectId],
        },
      };
      mutate({ id: group.id, name: group.name }, { relations });
    },
    [grid, mutate, accessGroups]
  );

  const [groupRightId, setGroupRightId] = useState<number | null>(null);
  const closeMask = useCallback(() => {
    setGroupRightId(null);
  }, []);

  const addGroupRight = useCallback(() => {
    setGroupRightId(0);
  }, []);

  const editGroupRight = useCallback(() => {
    const id = selectedColumns[0].id as number;
    if (typeof id === 'string')
      throw new Error('This should be number already.');
    setGroupRightId(id);
  }, [selectedColumns]);

  const dialogs = useDialogs();

  const allowAll = useCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('COMMON.RIGHTS_GROUP.ALLOW_ALL_TITLE'),
      description: t('COMMON.RIGHTS_GROUP.ALLOW_ALL'),
      confirmLabel: t('ALERTS.BTN_OK'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });
    if (!confirmed) return;
    const id = selectedColumns[0].id as number;
    const relations: InputProjectAccessGroupRelation = {
      projects: {
        setAll: true,
      },
    };
    mutate({ id }, { relations });
  }, [dialogs, t, selectedColumns, mutate]);

  const disallowAll = useCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('COMMON.RIGHTS_GROUP.DISALLOW_ALL_TITLE'),
      description: t('COMMON.RIGHTS_GROUP.DISALLOW_ALL'),
      confirmLabel: t('ALERTS.BTN_OK'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });
    if (!confirmed) return;
    const id = selectedColumns[0].id as number;
    const relations: InputProjectAccessGroupRelation = {
      projects: {
        unsetAll: true,
      },
    };
    mutate({ id }, { relations });
  }, [dialogs, t, selectedColumns, mutate]);
  return {
    columns,
    grid,
    onItemClicked,
    addGroupRight,
    editGroupRight,
    closeMask,
    groupRightId,
    groupRights: accessGroups,
    groupRightsLoading: loading,
    refetchGroupRights: refetchAccessGroups,
    setGroupRightId,
    allowAll,
    disallowAll,
  };
};

const useHeadProjects = (unGroupedProjects: (Project | GroupedItem)[]) => {
  const groupedByHeadProjectsIds = useMemo(() => {
    return unGroupedProjects
      .filter((x) => 'isGrouped' in x && x.isGrouped && 'parentProject' in x)
      .map((x) => x.parentProject.id);
  }, [unGroupedProjects]);

  const requestData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $in: groupedByHeadProjectsIds } }];
    return {
      entity: Entities.project,
      data: {
        id: null,
        projectAccessGroups: [
          {
            id: null,
          },
        ],
        parentProjectId: null,
      },
      filter,
    };
  }, [groupedByHeadProjectsIds]);

  const result = useDataProvider<Project>(
    requestData,
    !groupedByHeadProjectsIds.length,
    100,
    true
  );

  return result;
};
