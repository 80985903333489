import React, { useMemo } from 'react';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

import { dateToString } from '../../utils/filters/filterConversion';
import {
  DateRangePicker,
  ShownFilterOptions,
} from '../date-range-picker/DateRangePicker';
import { FilterCard } from '../filter-card/FilterCard';

interface IDateFilterCardProps {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date | RelativeDateFilter, endDate: Date) => void;
  onDelete: () => void;
  onExpandedChange: (val: boolean) => void;
  title: string;
  expanded: boolean;
  shownFilterOptions?: ShownFilterOptions;
}

export const DateFilterCard: React.FC<IDateFilterCardProps> = (props) => {
  const {
    title,
    onChange,
    startDate,
    endDate,
    expanded,
    onExpandedChange,
    onDelete,
    shownFilterOptions,
  } = props;
  const filterValueText: string = useMemo(() => {
    return dateToString(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <FilterCard
      title={title}
      filterValue={filterValueText}
      filterValueSet={!(startDate === null && endDate === null)}
      expanded={expanded}
      onExpandedChange={onExpandedChange}
      onValueDelete={() => {
        onDelete();
      }}
    >
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onChange={(start, end) => {
          !start && !end ? onDelete() : onChange(start, end);
        }}
        shownFilterOptions={shownFilterOptions}
      ></DateRangePicker>
    </FilterCard>
  );
};
