import React, { type JSX, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  InboundDeliveryNoteKindPicker,
  InboundDeliveryNoteKindPickerProps,
  useInboundDeliveryNoteKind,
} from '@work4all/components/lib/components/entity-picker/inbound-delivery-note-kind-picker/InboundDeliveryNoteKindPicker';

import { InboundDeliveryNoteKind } from '@work4all/models/lib/Enums/InboundDeliveryNoteKind.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface InboundDeliveryNoteKindPickerFieldProps<TMultiple extends boolean>
  extends Omit<
    InboundDeliveryNoteKindPickerProps<TMultiple>,
    'multiple' | 'value' | 'onChange'
  > {
  label?: string;
  error?: string;
  disabled?: boolean;
  field?: JSX.Element;
  multiple?: boolean;
  value: InboundDeliveryNoteKind;
  onChange: (value: InboundDeliveryNoteKind) => void;
}

const createInboundDeliveryNoteKindPickerField = <
  TMultiple extends boolean
>() => {
  return React.forwardRef<
    HTMLDivElement,
    InboundDeliveryNoteKindPickerFieldProps<TMultiple>
  >(function DeliveryNotesPickerField(props, ref) {
    const { value, onChange } = props;
    const { t } = useTranslation();

    const popoverRef = useRef<EntityPickerPopover>(null);

    const dataSet = useInboundDeliveryNoteKind();

    const currentValue = dataSet.find((x) => value === x.id);
    return (
      <EntityPickerPopover
        ref={popoverRef}
        picker={
          <InboundDeliveryNoteKindPicker
            multiple={false}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={currentValue as any}
            onChange={(value) => {
              onChange(value.id);
            }}
          />
        }
      >
        <PickerTargetButton
          ref={ref}
          label={t('COMMON.STATUS')}
          value={currentValue?.name}
        />
      </EntityPickerPopover>
    );
  });
};

export const InboundDeliveryNoteKindPickerField =
  createInboundDeliveryNoteKindPickerField();
