import styles from './FileInfoPanelItem.module.scss';

import { Box, Link as MuiLink, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PreviewInputsProvider,
  PreviewTextEditorInput,
} from '@work4all/components/lib/components/entity-preview/components/preview-input';
import { FileGroupPickerInput } from '@work4all/components/lib/components/entity-preview/components/preview-input/components/pickers/FileGroupPickerInput';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { useLock } from '@work4all/components/lib/hooks';
import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';
import { useOpenEmailMaskControllerContext } from '@work4all/components/lib/hooks/contact-email-mask/useOpenEmailMaskControllerContext';

import {
  Link,
  useDataMutation,
  useDataProvider,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { ContactTelephonenumbers } from '@work4all/models/lib/Classes/ContactTelephonenumbers.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { ServiceContract } from '@work4all/models/lib/Classes/ServiceContract.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectProtectionState } from '@work4all/models/lib/Enums/ObjectProtectionState.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  canEditCustomer,
  canEditSupplier,
} from '@work4all/utils/lib/permissions';

import { stringifyTableSettings } from '../../../../../components/data-tables/make-table-settings';
import { settings, useSetting } from '../../../../../settings';
import { AddressCard } from '../file-info-cards/AddressCard';
import { CategoryCard } from '../file-info-cards/CategoryCard';
import { ContactCard } from '../file-info-cards/ContactCard';
import { FileContactsCard } from '../file-info-cards/FileContactsCard';
import { FirstContactCard } from '../file-info-cards/FirstContactCard';
import { InlineData } from '../file-info-cards/InlineData';
import { OpenDueCard } from '../file-info-cards/OpenDueCard';
import { PaymentCard } from '../file-info-cards/PaymentCard';
import { RelationsCard } from '../file-info-cards/RelationsCard';
import { SalesCard } from '../file-info-cards/SalesCard';

import { IFileInfoPanelItems } from './types';

export interface FileInfoPanelProps {
  selectedContactId?: number | null;
  onSelectedContactIdChange?: (contactId: number | null) => void;
  onAddContact?: () => void;
  onEditContact?: (contactId: number) => void;
}

export interface RenderFileItemProps extends FileInfoPanelProps {
  partner: Customer | Supplier;
  entity: Entities;
  item: IFileInfoPanelItems;
  serviceContractList: ServiceContract[];
  salesOpportunityList: SalesOpportunities[];
  loading?: boolean;
}

export function FileInfoPanelItem(props: RenderFileItemProps) {
  const {
    partner,
    entity,
    selectedContactId,
    onSelectedContactIdChange,
    onAddContact,
    onEditContact,
    item,
    serviceContractList,
    salesOpportunityList,
    loading,
  } = props;

  const { isEveryInaccessible, isInaccessible } = useInaccessibleFields();
  const { t } = useTranslation();
  const { setCallModal } = useCallModalContext();
  const openEmailMaskController = useOpenEmailMaskControllerContext();

  const completeTelephonenumbersNumbersRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactTelephonenumbers,
      completeDataResponse: true,
      vars: {
        sdObjType:
          partner?.__typename === 'Kunde'
            ? SdObjType.KUNDE
            : SdObjType.LIEFERANT,
        sdObjMemberCode: partner?.id,
      },
      data: {
        central: null,
        directDial: null,
        directFax: null,
        fax: null,
        mobile: null,
        others: null,
        privateMobile: null,
        privateTelephone: null,
      } as ContactTelephonenumbers,
    };
  }, [partner]);

  const completeTelephoneNumbersResponse = useDataProvider(
    completeTelephonenumbersNumbersRequest,
    !partner
  );

  const completeTelephoneNumbers =
    completeTelephoneNumbersResponse?.data as unknown as ContactTelephonenumbers;

  const addressIsAccessible = !isEveryInaccessible(entity, [
    'street',
    'postalCode',
    'city',
    'postOfficeBox',
    'postOfficeBoxLocation',
    'postOfficeBoxPostalCode',
  ]);

  const contactOptionIsAccessible = !isEveryInaccessible(entity, [
    'eMail',
    'phoneNumber',
    'website',
  ]);
  const contactListPanelIsAccessible = !isEveryInaccessible(entity, [
    'contactList',
    'mainContactId',
  ]);

  const firstContactIsAccessible = !isEveryInaccessible(entity, [
    'firstContactPerson',
    'firstContactDate',
  ]);

  const paymentIsAccessible = !isEveryInaccessible(entity, [
    'paymentKind',
    'paymentDeadline',
    'skonto',
    'skonto2',
    'skontoDurationDays',
    'skonto2DurationDays',
  ]);

  const websiteIsAccessible = !isInaccessible(entity, 'website');
  const numberIsAccessible = !isInaccessible(entity, 'number');

  const streetIsAccessible = !isInaccessible(entity, 'street');
  const postalCodeIsAccessible = !isInaccessible(entity, 'postalCode');
  const cityIsAccessible = !isInaccessible(entity, 'city');
  const eMailIsAccessible = !isInaccessible(entity, 'eMail');
  const phoneNumberIsAccessible = !isInaccessible(entity, 'phoneNumber');
  const contactListIsAccessible = !isInaccessible(entity, 'contactList');
  const mainContactIdIsAccessible = !isInaccessible(entity, 'mainContactId');
  const firstContactPersonIsAccessible = !isInaccessible(
    entity,
    'firstContactPerson'
  );
  const firstContactDateIsAccessible = !isInaccessible(
    entity,
    'firstContactDate'
  );
  const categoryAssignmentListIsAccessible = !isInaccessible(
    entity,
    'categoryAssignmentList'
  );

  const groupIsAccessible = !isInaccessible(entity, 'group');
  const paymentDeadlineIsAccessible = !isInaccessible(
    entity,
    'paymentDeadline'
  );
  const skontoIsAccessible = !isInaccessible(entity, 'skonto');
  const skonto2IsAccessible = !isInaccessible(entity, 'skonto2');
  const skontoDurationDaysIsAccessible = !isInaccessible(
    entity,
    'skontoDurationDays'
  );
  const skonto2DurationDaysIsAccessible = !isInaccessible(
    entity,
    'skonto2DurationDays'
  );
  const noteIsAccessible = !isInaccessible(entity, 'note');

  const fileHideLeftContacts = useSetting(settings.fileHideLeftContacts());

  const [activePicker, setActivePicker] = useState<string | null>(null);

  const { locked, lock, unlock, user: lockedByUser } = useLock();

  const [mutate] = useDataMutation<Customer, EMode.upsert>({
    entity: entity,
    mutationType: EMode.upsert,
    responseData: { id: null, note: null } as unknown as Customer,
  });

  const user = useUser();

  const isCustomer = entity === Entities.customer;

  const canEdit = isCustomer
    ? canEditCustomer(user, partner)
    : canEditSupplier(user, partner);

  const handleLock = useCallback(
    (picker: string) => {
      lock();
      setActivePicker(picker);
    },
    [lock]
  );

  const onPropEdit = useCallback(
    async (field) => {
      const update = {
        id: partner?.id,
        ...field,
      };

      await (mutate(update, undefined) as Promise<Customer>)
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          unlock();
        });
    },
    [mutate, partner?.id, unlock]
  );

  const onPopoverClose = useCallback(() => {
    unlock();
    setActivePicker(null);
  }, [unlock, setActivePicker]);

  const salesOpportunitiesLinkBase = `entity/salesOpportunities?settings=`;

  const salesOpportunitiesDeeplink = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'businessPartner.data.id',
          value: [
            {
              id: partner?.id,
              displayName: partner?.name,
            },
          ],
        },
      ],
    };
    return salesOpportunitiesLinkBase + stringifyTableSettings(filter);
  }, [partner, salesOpportunitiesLinkBase]);

  const openSalesOpportunitiesDeeplink = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'status',
          value: [
            {
              id: SalesOpportunityStatus.ACTIVE,
              name: t(
                'COMMON.SALESOPPORTUNITIES.' + SalesOpportunityStatus.ACTIVE
              ),
            },
          ],
        },
        {
          id: 'businessPartner.data.id',
          value: [
            {
              id: partner?.id,
              displayName: partner?.name,
            },
          ],
        },
      ],
    };
    return salesOpportunitiesLinkBase + stringifyTableSettings(filter);
  }, [t, partner, salesOpportunitiesLinkBase]);

  const contacts = useMemo(() => {
    return contactListIsAccessible
      ? partner?.contactList.filter(
          (c) => !fileHideLeftContacts.value || !c.layedOff
        ) ?? []
      : [];
  }, [
    contactListIsAccessible,
    fileHideLeftContacts.value,
    partner?.contactList,
  ]);

  switch (item) {
    case IFileInfoPanelItems.Address:
      return (
        addressIsAccessible && (
          <AddressCard
            key={item}
            street={streetIsAccessible ? partner?.street : ''}
            postalCode={postalCodeIsAccessible ? partner?.postalCode : ''}
            city={cityIsAccessible ? partner?.city : ''}
          />
        )
      );

    case IFileInfoPanelItems.Number:
      return (
        numberIsAccessible && (
          <InlineData
            key={item}
            label={
              isCustomer
                ? t('FILE_CONTACTS.CUSTOMERNUM')
                : t('FILE_CONTACTS.SUPPLIERNUM')
            }
            content={partner?.number ? partner.number.toString() : '-'}
          />
        )
      );

    case IFileInfoPanelItems.ContactOptions:
      return (
        contactOptionIsAccessible && (
          <ContactCard
            key={item}
            email={eMailIsAccessible ? partner?.eMail : ''}
            phoneNumber={phoneNumberIsAccessible ? partner?.phoneNumber : ''}
            website={websiteIsAccessible ? partner?.website : ''}
            onPhoneNumberClick={() =>
              setCallModal({
                data: partner,
                businessPartner: partner,
                telephoneNumbers: completeTelephoneNumbers,
              })
            }
            onEmailClick={() => {
              openEmailMaskController({
                businessPartner: partner,
                email: partner?.eMail,
              });
            }}
          />
        )
      );

    case IFileInfoPanelItems.ContactList:
      return (
        contactListPanelIsAccessible && (
          <FileContactsCard
            key={item}
            contacts={contacts}
            mainContactId={
              mainContactIdIsAccessible ? partner?.mainContactId : -1
            }
            selectedContactId={selectedContactId}
            onSelectedContactIdChange={onSelectedContactIdChange}
            onAddContact={
              partner?.objectGroupProtectionState !==
                ObjectProtectionState.LOCKED && onAddContact
            }
            onEditContact={
              partner?.objectGroupProtectionState !==
                ObjectProtectionState.LOCKED && onEditContact
            }
          />
        )
      );

    case IFileInfoPanelItems.FirstContact:
      return (
        firstContactIsAccessible && (
          <React.Fragment key={item}>
            <FirstContactCard
              firstContactPersonName={
                firstContactPersonIsAccessible
                  ? partner?.firstContactPerson
                  : undefined
              }
              firstContactDate={
                firstContactDateIsAccessible
                  ? new Date(partner?.firstContactDate)
                  : undefined
              }
            />
          </React.Fragment>
        )
      );

    case IFileInfoPanelItems.Categories:
      return (
        categoryAssignmentListIsAccessible && (
          <CategoryCard
            key={item}
            categories={partner?.categoryAssignmentList}
            entity={entity}
            id={partner?.id}
            disabled={
              partner?.objectGroupProtectionState ===
              ObjectProtectionState.LOCKED
            }
          />
        )
      );

    case IFileInfoPanelItems.Relations:
      return (
        <RelationsCard
          key={item}
          entity={entity}
          entityData={partner}
          id={partner?.id}
          disabled={
            partner?.objectGroupProtectionState === ObjectProtectionState.LOCKED
          }
        />
      );

    case IFileInfoPanelItems.Group:
      return (
        groupIsAccessible && (
          <PreviewInputsProvider
            loading={loading}
            activePicker={activePicker}
            handleLock={handleLock}
            commonFields={[]}
            isMultiselect={false}
            entity={entity}
            locked={locked}
            lockedByUser={lockedByUser}
            entries={[partner?.note]}
            canEditAllSelected={canEdit}
          >
            <FileGroupPickerInput
              labelTranslationKey="COMMON.GROUP"
              onClose={onPopoverClose}
              value={partner?.group}
              onChange={(value) =>
                onPropEdit({
                  groupId: value ? value.id : 0,
                })
              }
              accessor="group"
              content={
                <InlineData
                  key={item}
                  label={t('FILE_CONTACTS.GROUP')}
                  content={
                    <MuiLink
                      variant="body2"
                      style={{ cursor: 'pointer', textDecoration: 'none' }}
                    >
                      {partner?.group?.name ?? '-'}
                    </MuiLink>
                  }
                />
              }
            />
          </PreviewInputsProvider>
        )
      );

    case IFileInfoPanelItems.Payment:
      return (
        paymentIsAccessible && (
          <PaymentCard
            key={item}
            paymentDeadline={
              paymentDeadlineIsAccessible ? partner?.paymentDeadline : 0
            }
            skonto={skontoIsAccessible ? partner?.skonto : 0}
            skonto2={skonto2IsAccessible ? partner?.skonto2 : 0}
            skontoDurationDays={
              skontoDurationDaysIsAccessible ? partner?.skontoDurationDays : 0
            }
            skonto2DurationDays={
              skonto2DurationDaysIsAccessible ? partner?.skonto2DurationDays : 0
            }
          />
        )
      );

    case IFileInfoPanelItems.Note:
      return (
        noteIsAccessible && (
          <PreviewInputsProvider
            loading={loading}
            activePicker={activePicker}
            handleLock={handleLock}
            commonFields={[]}
            isMultiselect={false}
            entity={entity}
            locked={locked}
            lockedByUser={lockedByUser}
            entries={[partner?.note]}
            canEditAllSelected={canEdit}
          >
            <Stack>
              <Divider size="body" title={t('COMMON.NOTE')} />
              <Box margin="0 0 0 -1.5rem">
                <PreviewTextEditorInput
                  value={partner?.note}
                  onClose={(note) => {
                    if (note !== partner?.note) {
                      onPropEdit({ note });
                    }
                    onPopoverClose();
                  }}
                  accessor="note"
                  previewMode="text"
                  disabled={
                    partner?.objectGroupProtectionState ===
                    ObjectProtectionState.LOCKED
                  }
                />
              </Box>
            </Stack>
          </PreviewInputsProvider>
        )
      );

    case IFileInfoPanelItems.OpenDuePositions: {
      return (
        <OpenDueCard
          partner={partner}
          isCustomer={isCustomer}
          loading={loading}
          item={item}
        />
      );
    }

    case IFileInfoPanelItems.Sales: {
      return (
        <SalesCard
          partner={partner}
          isCustomer={isCustomer}
          loading={loading}
          item={item}
        />
      );
    }

    case IFileInfoPanelItems.SubscriptionContract:
      return !checkUserRight(
        UserRights.WartungsmodulNutzen,
        user.benutzerRechte
      ) ? null : (
        <InlineData
          key={item}
          label={t('FILE_SETTINGS.SUBSCRIPTION_CONTRACT')}
          content={
            serviceContractList.length
              ? `${serviceContractList.length} ${t('COMMON.AVAILABLE')}`
              : '-'
          }
        />
      );

    case IFileInfoPanelItems.Opportunities: {
      const available = salesOpportunityList.length;
      const open = salesOpportunityList.filter(
        (x) => x.frozen === 0 && x.status === SalesOpportunityStatus.ACTIVE
      ).length;

      return !checkUserRight(
        UserRights.VerkaufschancenNutzen,
        user.benutzerRechte
      ) ? null : (
        <InlineData
          key={item}
          label={t('FILE_SETTINGS.OPPORTUNITIES')}
          content={
            available === 0 ? (
              '-'
            ) : (
              <Typography variant="body2">
                <Link className={styles.link} to={salesOpportunitiesDeeplink}>
                  {available + ' ' + t('COMMON.AVAILABLE')}
                </Link>{' '}
                {open > 0 ? (
                  <>
                    &nbsp;|&nbsp;
                    <Link
                      className={styles.link}
                      to={openSalesOpportunitiesDeeplink}
                    >
                      {open + ' ' + t('COMMON.OPEN')}
                    </Link>
                  </>
                ) : null}
              </Typography>
            )
          }
        />
      );
    }
    default:
      return null;
  }
}
