import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArticleKindPicker,
  ArticleKindPickerProps,
} from '@work4all/components/lib/components/entity-picker/article-kind-picker/ArticleKindPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { reactRefSetter } from '@work4all/utils';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends ArticleKindPickerProps<false> {
  error?: string;
  label: string;
  disabled?: boolean;
}

export const ArticleKindPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();

    const wrapperRef = useRef(null);

    const { label, disabled, error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const onClear = pickerProps.clearable ? () => onChange(null) : undefined;

    return (
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={wrapperRef}
          disabled={disabled}
          picker={<ArticleKindPicker {...pickerProps} multiple={false} />}
        >
          <PickerTargetButton
            onClear={onClear}
            label={label}
            error={error}
            disabled={disabled}
            value={value ? t(`ARTICLE_KIND.LONG.${value?.id}`) : ''}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
