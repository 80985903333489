import React from 'react';

import { StepWrapper } from './components/step-wrapper/StepWrapper';
import { StepsWrapper } from './components/steps-wrapper/StepsWrapper';
import { IMutltiStepControlProps } from './types';

export const MultiStepControls = (
  props: IMutltiStepControlProps
): React.ReactElement<any> => {
  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];

  const filteredChildren = children.filter((child) => {
    return child?.props?.active;
  });

  const mappedChildren = filteredChildren.map((child, i) => {
    const key = child.props.index;

    if (filteredChildren.length === 1) {
      return <StepWrapper key={key}>{child}</StepWrapper>;
    }

    if (i === 0) {
      return (
        <StepWrapper
          key={key}
          noRightRadiuses={!props.isColumnDirection}
          noBottomRadiuses={props.isColumnDirection}
        >
          {child}
        </StepWrapper>
      );
    }

    return (
      <React.Fragment key={key}>
        <StepWrapper
          noLeftRadiuses={!props.isColumnDirection}
          noRightRadiuses={
            !props.isColumnDirection && i < filteredChildren.length - 1
          }
          noTopRadiuses={props.isColumnDirection}
          noBottomRadiuses={
            props.isColumnDirection && i < filteredChildren.length - 1
          }
        >
          {child}
        </StepWrapper>
      </React.Fragment>
    );
  });

  return (
    <StepsWrapper isColumnDirection={props.isColumnDirection}>
      {mappedChildren}
    </StepsWrapper>
  );
};
