import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ReactComponent as ColumnsIcon } from '@work4all/assets/icons/columns.svg';
import { ReactComponent as AddIcon } from '@work4all/assets/icons/outline-add-24.svg';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ColumnsVisibility } from '../../../../../../../../components/data-tables/table/components/toolbar/components/columns-visibility/ColumnsVisibility';
import { settings, useSetting } from '../../../../../../../../settings';
import { ClonePositionButton } from '../../../../erp/components/tab-panels/positions/components/clone-position-button/ClonePositionButton';
import { IdArray } from '../../../../erp/components/tab-panels/positions/components/edit-table/types';
import { useResetColumns } from '../../../../erp/components/tab-panels/positions/components/hooks/use-reset-columns';
import { PositionsSettings } from '../../../../erp/components/tab-panels/positions/components/positions-settings/PositionsSettings';
import { RemovePositionButton } from '../../../../erp/components/tab-panels/positions/components/remove-position-button/RemovePositionButton';
import { RowSizePicker } from '../../../../erp/components/tab-panels/positions/components/row-size-picker/RowSizePicker';
import {
  BOMComponentPosition,
  DEFAULT_BOM_COLUMNS,
  DEFAULT_VISIBLE_BOM_COLUMNS,
} from '../types';

interface ComponentsTabPanelTableHeaderProps {
  disabled: boolean;
  onClonePosition: (copyIds: IdArray) => void;
  onRemovePosition: (positionId: IdArray) => void;
  addCacheRow: () => void;
  positions: BOMComponentPosition[];
  tableInstanceRef: React.RefObject<TableInstance | null>;
}

const emptyColumns = [];
export const ComponentsTabPanelTableHeader = (
  props: ComponentsTabPanelTableHeaderProps
) => {
  const { t } = useTranslation();
  const {
    tableInstanceRef,
    disabled,
    onClonePosition,
    onRemovePosition,
    addCacheRow,
    positions,
  } = props;
  const columnsPopoverState = usePopoverState();
  const openColumnsPopover = columnsPopoverState.handleClick;

  const columnSettings = useSetting(
    settings.erpPositionsConfig(Entities.bOMComponent, DEFAULT_BOM_COLUMNS)
  );
  const resetColumns = useResetColumns({
    columnSettings,
    tableInstanceRef,
    defaultColumns: DEFAULT_VISIBLE_BOM_COLUMNS,
  });

  const erpSize = useSetting(settings.erpRowSize());

  return (
    <Box padding="0.5rem 1.75rem" display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" gap="0.5rem">
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addCacheRow}
        >
          {t('COMMON.COMPONENT')}
        </Button>
        <RemovePositionButton
          disabled={disabled}
          onRemovePosition={onRemovePosition}
        />
        <ClonePositionButton
          disabled={disabled}
          onClonePosition={onClonePosition}
          positions={positions}
        />
      </Box>

      <Box display="flex">
        <RowSizePicker value={erpSize.value} onChange={erpSize.set} />
        <IconButton
          color="primary"
          onClick={(event) => {
            return openColumnsPopover(event);
          }}
        >
          <ColumnsIcon />
        </IconButton>
        <PositionsSettings
          entityType={Entities.bOMComponent}
          settingsKey={'BOM'}
        />
        <ColumnsVisibility
          popoverState={columnsPopoverState}
          visibleColumns={tableInstanceRef.current?.allColumns ?? emptyColumns}
          onResetColumns={resetColumns}
        />
      </Box>
    </Box>
  );
};
