import React, { useRef } from 'react';

import { useInaccessibleFields } from '@work4all/data';

import { CustomerGroup } from '@work4all/models/lib/Classes/CustomerGroup.entity';
import { ProjectGroup } from '@work4all/models/lib/Classes/ProjectGroup.entity';
import { SupplierGroup } from '@work4all/models/lib/Classes/SupplierGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerPopover } from '../../../../../entity-picker/components';
import {
  CustomerGroupPicker,
  ProjectGroupPicker,
  SupplierGroupPicker,
} from '../../../../../entity-picker/entity-group-picker/EntityGroupPicker';
import { usePreviewInput, usePreviewInputsContext } from '../../hooks';
import { EntityPreviewPickerInputProps } from '../../types';

type Group = ProjectGroup | CustomerGroup | SupplierGroup;
const getPicker = (entity: Entities) => {
  switch (entity) {
    case Entities.project:
      return ProjectGroupPicker;
    case Entities.customer:
      return CustomerGroupPicker;
    case Entities.supplier:
      return SupplierGroupPicker;
    default:
      throw new Error(
        `There is no definition for ${entity} in FileGroupPickerInput.`
      );
  }
};

interface FileGroupPickerInputProps
  extends EntityPreviewPickerInputProps<Group> {
  content?: React.ReactElement;
}

export const FileGroupPickerInput = ({
  value,
  labelTranslationKey,
  accessor,
  content,
  disabled,
  onChange,
  ...rest
}: FileGroupPickerInputProps) => {
  const { entity } = usePreviewInputsContext();
  const { isInaccessible } = useInaccessibleFields();

  const ref = useRef<EntityPickerPopover>(null);

  const { onClick } = usePreviewInput<Group>({
    formattedValue: value?.name,
    labelTranslationKey,
    accessor,
    ref,
    entity,
    value,
    disabled,
  });
  if (isInaccessible(entity, accessor)) return null;

  const Picker = getPicker(entity);
  return (
    <EntityPickerPopover
      {...rest}
      ref={ref}
      onTargetClick={onClick}
      picker={
        <Picker
          value={value ? [value] : null}
          onChange={(groups) => {
            if (groups && groups.length > 0) {
              if (groups[0].id === value?.id) {
                onChange(null);
              } else {
                onChange(groups[0]);
              }
            } else {
              onChange(null);
            }
          }}
          multiple={false}
        />
      }
    >
      {content}
    </EntityPickerPopover>
  );
};
