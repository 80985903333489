import styles from './PickerHeader.module.scss';

import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { t } from 'i18next';
import { HTMLInputTypeAttribute, RefObject } from 'react';

import { FilterTextInput } from '../../FilterTextInput';

export interface EntityPickerHeaderProps {
  placeholder?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  autoFocus?: boolean;
  focusRef?: RefObject<HTMLInputElement>;
  query?: string;
  onChange?: (val: string) => void;
  inputType?: HTMLInputTypeAttribute;
  smallPadding?: boolean;
}

export const PickerHeader: React.FC<EntityPickerHeaderProps> = ({
  placeholder = t('PICKER.SEARCH.DEFAULT'),
  inputType,
  prepend = null,
  append = null,
  autoFocus = true,
  query,
  onChange,
  focusRef,
  smallPadding = false,
}) => {
  return (
    <Stack
      direction={'row'}
      className={clsx(styles.textWrapper, {
        [styles.smallPadding]: smallPadding,
      })}
    >
      {prepend}
      <Box sx={{ flexGrow: 1, paddingLeft: '0.5rem' }}>
        <FilterTextInput
          ref={focusRef}
          autoFocus={autoFocus}
          value={query}
          onChange={onChange}
          placeholder={placeholder}
          inputType={inputType}
          className={styles['list-picker-filter-input']}
        />
      </Box>
      {append}
    </Stack>
  );
};
