import styles from './ImprintPreview.module.scss';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { type JSX, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { Heading4 } from '@work4all/components/lib/typography/heading4/Heading4';

import { DndTypes } from '@work4all/utils/lib/variables';

export interface DropItemProps {
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (value: any) => void;
  icon: JSX.Element;
  hasPermission: boolean;
}

export const DropItem: React.FC<DropItemProps> = (props: DropItemProps) => {
  const { action, text, icon, hasPermission } = props;
  const { t } = useTranslation();

  const [hover, setHover] = useState<boolean>(false);
  const [_, drop] = useDrop(() => ({
    accept: DndTypes.HTML,
    drop(item) {
      action(item);
    },
  }));

  return (
    <Stack
      onDragEnter={() => setHover(true)}
      onDragLeave={() => setHover(false)}
      ref={(node) => {
        drop(node);
      }}
      className={clsx(styles.item, {
        [styles['item--active']]: hover,
      })}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Stack alignItems="center">
        <Stack alignItems="center">{icon}</Stack>
        <Heading4 className={styles.text}>{text}</Heading4>
        {!hasPermission && (
          <Typography variant="body1" className={styles.text}>
            {t('DRAG.NO_PERMISSON')}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
