import styles from './SearchHistory.module.scss';

import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeepReadonly } from 'rxdb/dist/types/types';

import { Card } from '@work4all/components/lib/dataDisplay/card';

import { Link, useLocation } from '@work4all/data';
import { useHasMounted } from '@work4all/data/lib/hooks/useHasMounted';

import { FileData } from '@work4all/models/lib/File';

import { OnElementSelected } from '../../types';
import { CardActionArea } from '../card-action-area/CardActionArea';
import { SearchHistoryListItem } from '../search-history-item/SearchHistoryListItem';

interface ISearchHistoryProps {
  historyItems: DeepReadonly<FileData[]>;
  onSelect: OnElementSelected;
  /**
   * Will change all text and button colors to white. Should be set based on the
   * background color.
   */
  inverseColor?: boolean;
  maxItems?: number;
  maxItemsSM?: number;
}

export const SearchHistory: React.FC<ISearchHistoryProps> = (props) => {
  const { inverseColor = false, maxItems, maxItemsSM } = props;

  const { t } = useTranslation();
  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const sm = !upSm;
  const hasMounted = useHasMounted();
  const location = useLocation();

  if (!hasMounted) {
    return null;
  }

  const items = (
    <Grid container spacing={2} columns={2}>
      {props.historyItems.map((el, i) => {
        if (maxItems && i >= maxItems) {
          return null;
        }
        if (sm && maxItemsSM && i >= maxItemsSM) {
          return null;
        }
        return (
          <Grid
            key={el.id}
            item
            className={clsx({
              [styles['half-width']]: upSm,
              [styles['max-width']]: sm,
            })}
          >
            <CardActionArea
              component={Link}
              to={`${location.pathname}/${el.id}`}
              onClick={() => props.onSelect(el)}
            >
              <Card noPadding>
                <Box padding="0.5rem 1rem">
                  <SearchHistoryListItem
                    divider={false}
                    name={el.name}
                    id={el.id}
                    number={el.number}
                    category={el.category}
                    website={el?.website}
                    noPadding={true}
                    isPrivateCustomer={el.isPrivateCustomer}
                  />
                </Box>
              </Card>
            </CardActionArea>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <>
      <Typography
        className={clsx({
          [styles.heading]: true,
        })}
        variant="h4"
        color={inverseColor ? 'text.inverse' : 'text.tertiary'}
      >
        {t('CUSTOMER.LAST_HITS')}
      </Typography>

      {items}
    </>
  );
};
