import styles from './SimpleEntityWidget.module.scss';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePrepareFilters } from '@work4all/components';
import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

import { Link, useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { mutationEntityToViewEntity } from '@work4all/utils';

import { stringifyTableSettings } from '../../../../components/data-tables/make-table-settings';
import { HomeWidget } from '../../../../settings/validation/HomeWidget';

import { widgetDataByEntity } from './default-entity-widget-data';

export const SimpleEntityWidget = (
  props: HomeWidget & {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
  }
) => {
  const { t } = useTranslation();

  const settings = stringifyTableSettings({
    filter:
      props.definition?.filters?.map((filter) => {
        return {
          ...filter,
          value: filter?.value?.value,
        };
      }) || [],
  });

  const preparedFilters = usePrepareFilters(props?.definition?.filters);

  // We are doing this for backward compatibility for entities like `inboundInvoice`.
  const viewEntity = mutationEntityToViewEntity(props?.definition?.entity);

  const requestData = useMemo<DataRequest>(() => {
    return {
      filter: preparedFilters.filter,
      entity: viewEntity,
      data: widgetDataByEntity[viewEntity],
    };
  }, [preparedFilters.filter, viewEntity]);

  const { total } = useDataProvider(requestData, false, 1);

  return (
    <div>
      <Link
        to={`/more/entity/${viewEntity}?settings=${settings}`}
        className={styles.link}
      >
        <div style={{ width: '100%' }}>
          <Body1>
            {props.title || t(`COMMON.${viewEntity.toUpperCase()}_other`)}
          </Body1>
        </div>
        <div>
          <CountIndicator value={total} variant="secondary" />
        </div>
      </Link>
    </div>
  );
};
