import React from 'react';
import { useDrag } from 'react-dnd';

import { DragItem } from './types';

interface BoardItemProps {
  item: unknown;
  children: string;
}

export const BoardItem: React.FC<BoardItemProps> = ({ item, children }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BOARD_ITEM',
    item: { item } as DragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={(node) => {
        drag(node);
      }}
      style={{
        margin: '8px',
        cursor: 'move',
        flex: 1,
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      {children}
    </div>
  );
};
