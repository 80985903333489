import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';

export function DeductionSection() {
  const { t } = useTranslation();

  const projectTimeTrackingAllowEditForDeducableItems = useSetting(
    settings.projectTimeTrackingAllowEditForDeducableItems()
  );

  const projectTimeTrackingMarkItemsAsDeductableAutomatically = useSetting(
    settings.projectTimeTrackingMarkItemsAsDeductableAutomatically()
  );

  const projectTimeTrackingDeductableByActivity = useSetting(
    settings.projectTimeTrackingDeductableByActivity()
  );

  const projectTimeTrackingDeductableWithoutActivity = useSetting(
    settings.projectTimeTrackingDeductableWithoutActivity()
  );

  const user = useUser();

  return (
    <Collapse title={t('SETTINGS.PROJECT_TIME.DEDUCTION')} defaultOpen>
      <CheckboxRadioItem
        disabled={!user.isMaster}
        defaultChecked={
          projectTimeTrackingMarkItemsAsDeductableAutomatically.value ||
          projectTimeTrackingDeductableByActivity.value
        }
        onChange={(e) => {
          if (e.target.checked) {
            projectTimeTrackingMarkItemsAsDeductableAutomatically.set(true);
            projectTimeTrackingDeductableByActivity.set(false);
            projectTimeTrackingDeductableWithoutActivity.set(false);
          } else {
            projectTimeTrackingMarkItemsAsDeductableAutomatically.set(false);
            projectTimeTrackingDeductableByActivity.set(false);
            projectTimeTrackingDeductableWithoutActivity.set(false);
          }
        }}
        label={t('SETTINGS.PROJECT_TIME.MARK_ITEMS_AS_DEDUCTABLE_GENERAL')}
      />
      {(projectTimeTrackingMarkItemsAsDeductableAutomatically.value ||
        projectTimeTrackingDeductableByActivity.value) && (
        <div style={{ paddingLeft: '2rem' }}>
          <CheckboxRadioItem
            control="radio"
            disabled={!user.isMaster}
            checked={
              projectTimeTrackingMarkItemsAsDeductableAutomatically.value
            }
            onChange={(_e) => {
              projectTimeTrackingMarkItemsAsDeductableAutomatically.set(true);
              setTimeout(() => {
                projectTimeTrackingDeductableByActivity.set(false);
              });
            }}
            label={t('SETTINGS.PROJECT_TIME.MARK_ITEMS_AS_DEDUCTABLE')}
          />
          <CheckboxRadioItem
            control="radio"
            disabled={!user.isMaster}
            checked={projectTimeTrackingDeductableByActivity.value}
            onChange={(_e) => {
              projectTimeTrackingDeductableByActivity.set(true);
              setTimeout(() => {
                projectTimeTrackingMarkItemsAsDeductableAutomatically.set(
                  false
                );
              });
            }}
            label={t('SETTINGS.PROJECT_TIME.DEDUCTABLE_BY_ACTIVITY')}
          />
          {projectTimeTrackingDeductableByActivity.value && (
            <div style={{ paddingLeft: '2rem' }}>
              <CheckboxRadioItem
                disabled={!user.isMaster}
                defaultChecked={
                  projectTimeTrackingDeductableWithoutActivity.value
                }
                onChange={(e) => {
                  projectTimeTrackingDeductableWithoutActivity.set(
                    e.target.checked
                  );
                }}
                label={t(
                  'SETTINGS.PROJECT_TIME.MARK_ITEMS_AS_DEDUCTABLE_WITHOUT_ACTIVITY'
                )}
              />
            </div>
          )}
        </div>
      )}
      <CheckboxRadioItem
        disabled={!user.isMaster}
        checked={projectTimeTrackingAllowEditForDeducableItems.value}
        onChange={(e) =>
          projectTimeTrackingAllowEditForDeducableItems.set(e.target.checked)
        }
        label={t('SETTINGS.PROJECT_TIME.ALLOW_EDIT_DEDUCTED_ITEMS')}
      />
    </Collapse>
  );
}
