import styles from './../Positions.module.scss';

import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, TableInstance } from 'react-table';

import {
  CURRENCY_PARAMS,
  CurrencyCell,
  DateCell,
  ForbiddenPriceCell,
  HoursCell,
  NUMBER_PARAMS,
  TextCell,
} from '@work4all/components';
import {
  EditableCell as EditableCellImported,
  EditableCellProps,
} from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';

import { useUser } from '@work4all/data';
import { useCustomFieldsConfigContext } from '@work4all/data/lib/custom-fields';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { currencyAsSign } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import {
  canViewERPPrices,
  canViewPurchasePriceColumn,
} from '@work4all/utils/lib/permissions';

import { settings, useSetting } from '../../../../../../../../../../settings';
import {
  DEFAULT_VISIBLE_ERP_COLUMNS,
  SUPPLIER_ERP_ENTITIES,
} from '../../../../../../../../../../settings/settings';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { CostCenterCell } from '../../../../../../components/table-cells/CostCenterCell';
import {
  LEDGER_PREFILTER,
  LedgerAccountCell,
} from '../../../../../../components/table-cells/LedgerAccountCell';
import { UserPickerCell } from '../../../../../../components/table-cells/UserPickerCell';
import { ErpData } from '../../../../../ErpData';
import { ShadowObjectAddPositionArgs } from '../../../../../hooks/use-bz-shadow-object-api';
import {
  EditTableColumns,
  EditTableEntry,
  OnEditPosition,
} from '../edit-table/types';
import { ArticlePickerCell } from '../positions-table/ArticlePickerCell';

import { CustomCell } from './components/CustomCell';
import { editableDisabledCellWrapper } from './components/EditableDisabledCell';
import { useResetColumns } from './use-reset-columns';

const EditableCell: React.FC<Partial<EditableCellProps>> = EditableCellImported;

const pickerStyles = {
  margin: 0,
};

type PositionColumnData = EditTableColumns<Position>;

interface UsePositionsColumnsProps {
  tableInstanceRef: React.RefObject<TableInstance<object>>;
  allowedColumns?: string[];
  total: number;
  onAddPosition: (context: ShadowObjectAddPositionArgs) => void;
  onEditPosition: (result: OnEditPosition<Position>) => void;
  disabled?: boolean;
}

const KINDS_TO_DISABLE_PICKERS = [
  ErpPositionsKind.TITEL,
  ErpPositionsKind.UMWANDLUNGSHISTORIE,
  ErpPositionsKind.ZWISCHENSUMME,
  ErpPositionsKind.TEXTZEILE,
  ErpPositionsKind.TITELSUMME,
];

export const usePositionsColumns = (props: UsePositionsColumnsProps) => {
  const {
    tableInstanceRef,
    allowedColumns,
    total,
    onAddPosition,
    onEditPosition,
    disabled,
  } = props;

  const mask = useMaskContext();
  const columnSettings = useSetting(settings.erpPositionsConfig(mask.entity));

  const { t } = useTranslation();
  const { watch } = useFormContextPlus<ErpData>();
  const currency = watch('currency');
  const formatTitleWithCurrency = useCallback(
    (title: string) => {
      return currency ? `${title} ${currencyAsSign(currency.name)}` : title;
    },
    [currency]
  );

  const isSupplierEntity = SUPPLIER_ERP_ENTITIES.includes(mask.entity);

  const width = useDeepMemo(
    () => columnSettings.value.width,
    [columnSettings.value.width]
  );

  const { customFields } = useCustomFieldsConfigContext();

  const user = useUser();
  const showERPPrices = canViewERPPrices(user);

  const columns = useMemo(() => {
    const baseColumns: PositionColumnData[] = [
      {
        Header: t('COMMON.ERP.NUMBER'),
        accessor: 'number',
        width: remToPx(6),
        sticky: 'left',
        type: 'text',
        editable: false,
      },
      {
        Header: t('COMMON.ERP.DESCRIPTION'),
        accessor: 'longtext',
        sticky: 'left',
        width: remToPx(25),
        type: 'text',
        isLongText: true,
        multiLinesRowKindsAllowed: [
          ErpPositionsKind.STANDARD,
          ErpPositionsKind.STUECKLISTE,
          ErpPositionsKind.INTERNE_STUECKLISTE,
          ErpPositionsKind.INTERNE_POSITION,
          ErpPositionsKind.TEXTZEILE,
        ],
        shouldRender: (position: Position & EditTableEntry) => {
          return (
            position.cacheOnly && !position.longtext && !position.articleNumber
          );
        },
        render: (
          _cell: Cell<Position & EditTableEntry>,
          { isEditMode, tableInstance }
        ) => {
          return (
            <ArticlePickerCell
              cell={_cell}
              isEditMode={isEditMode}
              onAddPosition={onAddPosition}
              tableInstance={tableInstance}
            />
          );
        },
      },
      {
        Header: t('COMMON.ERP.AMOUNT_PARTS'),
        accessor: 'partsListAmount',
        disableOrder: true,
        width: remToPx(5),
        Cell: (cell) =>
          cell.row.original.posId ? <EditableCell {...cell} /> : <div></div>,
        ...NUMBER_PARAMS,
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.AMOUNT'),
        accessor: 'amount',
        id: 'amount',
        width: remToPx(5),
        ...NUMBER_PARAMS,
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.UNIT'),
        accessor: 'unit',
        width: remToPx(4.5),
        type: 'text',
      },
    ];

    const pricingColumns: PositionColumnData[] = [
      {
        Header: formatTitleWithCurrency(t('COMMON.ERP.PRICE')),
        accessor: 'singlePriceNet',
        Cell: (cellProps) => {
          if (
            cellProps.row.original.positionKind ===
              ErpPositionsKind.INTERNE_POSITION ||
            cellProps.row.original.positionKind ===
              ErpPositionsKind.INTERNE_STUECKLISTE
          )
            return (
              <div
                style={{
                  color: `var(--text03)`,
                  textAlign: 'right',
                  width: '100%',
                }}
              >
                {t('COMMON.ERP.INTERN').toUpperCase()}
              </div>
            );
          return showERPPrices ? (
            <EditableCell {...(cellProps as object)} />
          ) : (
            <ForbiddenPriceCell />
          );
        },
        editable: showERPPrices,
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        type: 'number',
      },
      {
        Header: formatTitleWithCurrency(t('COMMON.ERP.TOTAL_PRICE')),
        accessor: 'totalPriceNet',
        type: 'number',
        skeleton: true,
        editable: false,
        Cell: showERPPrices
          ? editableDisabledCellWrapper(EditableCell)
          : ForbiddenPriceCell,
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        Footer: (footerProps) => {
          return (
            <span>
              {showERPPrices ? (
                <CurrencyCell
                  value={total || 0}
                  {...footerProps}
                  currency={currency?.name}
                />
              ) : (
                <ForbiddenPriceCell />
              )}
            </span>
          );
        },
      },
      {
        Header: t('COMMON.ERP.ORDER_AMOUNT'),
        accessor: 'orderAmount',
        width: remToPx(7),
        ...NUMBER_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'number',
        editable: false,
      },
      {
        Header: t('COMMON.ERP.DISCOUNT'),
        accessor: 'discount',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: showERPPrices,
        ...CURRENCY_PARAMS,
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.INSTEAD_OF_PRICE'),
        accessor: 'insteadOfTotalPrice',
        //eslint-disable-next-line
        //@ts-ignore
        Cell: showERPPrices ? TextCell : ForbiddenPriceCell,
        editable: showERPPrices,
        width: remToPx(14),
        type: 'text',
      },
      canViewPurchasePriceColumn(user) && {
        Header: formatTitleWithCurrency(t('COMMON.ERP.PURCHASE_PRICE')),
        accessor: 'purchasePrice',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: showERPPrices,
        ...CURRENCY_PARAMS,
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.VAT'),
        accessor: 'vat',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: showERPPrices,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.GROSS_PROFIT'),
        accessor: 'rohertrag',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: false,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.PURCHASE_PRICE_SURCHARGE'),
        accessor: 'purchasePriceSurcharge',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: showERPPrices,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.SINGLE_PRICE_GROSS'),
        accessor: 'singlePriceGross',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: false,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.TOTAL_PRICE_GROSS'),
        accessor: 'totalPriceGross',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'number',
        editable: false,
        skeleton: true,
      },
      {
        Header: t('COMMON.ERP.MINUTE_PRICE'),
        accessor: 'minutePrice',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: showERPPrices,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'number',
      },
    ];

    const createAccountCell = (
      field: 'ledgerAccount' | 'costAccountEntity'
    ) => ({
      render: (cell: Cell<Position>, { isEditMode }) => (
        <LedgerAccountCell
          label={(value) => value?.number || ''}
          value={cell?.row?.original?.[field]}
          className={clsx(styles.customCell, {
            [styles.justLabel]: !isEditMode,
          })}
          style={{
            ...pickerStyles,
            background: isEditMode ? undefined : 'unset',
            border: isEditMode ? undefined : 'unset',
          }}
          onChange={(value) => {
            onEditPosition({
              position: {
                id: cell.row.original.id,
                [cell.column.id]: value?.number || 0,
              },
            });
          }}
          hover
          disabled={
            disabled ||
            KINDS_TO_DISABLE_PICKERS.includes(cell.row.original.positionKind)
          }
          overridePrefilter={
            field === 'ledgerAccount' ? LEDGER_PREFILTER : undefined
          }
        />
      ),
    });

    const additionalColumns: PositionColumnData[] = [
      {
        Header: t('COMMON.ERP.SHORT_TEXT'),
        accessor: 'shortText',
        width: remToPx(25),
        type: 'text',
      },
      {
        Header: t('COMMON.ERP.INTERNAL_TEXT'),
        accessor: 'internalText',
        width: remToPx(25),
        type: 'text',
        isLongText: true,
        multiLinesRowKindsAllowed: [
          ErpPositionsKind.STANDARD,
          ErpPositionsKind.STUECKLISTE,
          ErpPositionsKind.INTERNE_STUECKLISTE,
          ErpPositionsKind.INTERNE_POSITION,
          ErpPositionsKind.TEXTZEILE,
        ],
      },
      isSupplierEntity
        ? {
            Header: t('COMMON.ERP.COST_ACCOUNT'),
            accessor: 'costAccount',
            width: remToPx(7),
            Cell: (item) => item.value || '',
            filterSubgroupPath: ['COMMON.ERP.BOOKING'],
            type: 'picker',
            ...createAccountCell('costAccountEntity'),
          }
        : {
            Header: t('COMMON.ERP.LEDGER_ACCOUNT'),
            accessor: 'ledgerAccountNumber',
            width: remToPx(7),
            Cell: (item) => item.value || '',
            filterSubgroupPath: ['COMMON.ERP.BOOKING'],
            type: 'picker',
            ...createAccountCell('ledgerAccount'),
          },
      {
        Header: t('COMMON.ERP.COST_CENTER'),
        accessor: 'costCenter1Number',
        width: remToPx(7),
        Cell: (item) => {
          return item.value || '';
        },
        filterSubgroupPath: ['COMMON.ERP.BOOKING'],
        type: 'picker',
        render: (cell: Cell<Position>, { isEditMode }) => (
          <CostCenterCell
            label={(value) => value?.number || ''}
            value={cell?.row?.original?.costCenter1}
            className={clsx(styles.customCell, {
              [styles.justLabel]: !isEditMode,
            })}
            style={{
              ...pickerStyles,
              background: isEditMode ? undefined : 'unset',
              border: isEditMode ? undefined : 'unset',
            }}
            onChange={(value) => {
              onEditPosition({
                position: {
                  id: cell.row.original.id,
                  [cell.column.id]: value?.number || 0,
                },
              });
            }}
            hover
            disabled={
              disabled ||
              KINDS_TO_DISABLE_PICKERS.includes(cell.row.original.positionKind)
            }
          />
        ),
      },
      {
        Header: t('COMMON.ERP.MEASUREMENT'),
        accessor: 'measurement',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
        type: 'text',
      },
      {
        Header: t('COMMON.ERP.WIDTH'),
        accessor: 'width',
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.WEIGHT_TOTAL'),
        accessor: 'weight',
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.VOLUME'),
        accessor: 'volume',
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
        type: 'number',
        editable: false,
      },
      {
        Header: t('COMMON.ERP.VOLUME_TOTAL'),
        accessor: 'totalVolume',
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
        type: 'number',
        editable: false,
      },
      {
        Header: t('COMMON.ERP.WEIGHT_SINGLE'),
        accessor: 'singleWeight',
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
        type: 'number',
        editable: false,
      },
      {
        Header: t('COMMON.ERP.LENGTH'),
        accessor: 'length',
        width: remToPx(7),
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.DIMENSIONS'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.FACTOR'),
        accessor: 'factor',
        width: remToPx(7),
        Cell: showERPPrices ? EditableCell : ForbiddenPriceCell,
        editable: showERPPrices,
        ...CURRENCY_PARAMS,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'number',
      },
      {
        Header: t('COMMON.ERP.START_TIME'),
        accessor: 'startTime',
        width: remToPx(7),
        Cell: HoursCell,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'time',
      },
      {
        Header: t('COMMON.ERP.END_TIME'),
        accessor: 'endTime',
        width: remToPx(7),
        Cell: HoursCell,
        filterSubgroupPath: ['COMMON.ERP.CALCULATION'],
        type: 'time',
      },
      {
        Header: t('COMMON.ERP.ARTICLE_NUMBER'),
        accessor: 'articleNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'text',
      },
      {
        Header: t('COMMON.ERP.DISPO_START'),
        accessor: 'dispositionStart',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'date',
      },
      {
        Header: t('COMMON.ERP.DISPO_END'),
        accessor: 'dispositionEnd',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'date',
      },
      {
        Header: t('COMMON.ERP.OWN_ARTICLE_NUMBER'),
        accessor: 'ownArticleNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'text',
      },
      {
        Header: t('COMMON.ERP.SCHEDULED_DELIVERY_DATE'),
        accessor: 'deliveryDate',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'date',
      },
      {
        Header: t('COMMON.ERP.ACTUAL_DELIVERY_DATE'),
        accessor: 'actualDeliveryDate',
        width: remToPx(7),
        Cell: DateCell,
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'date',
      },
      {
        Header: t('COMMON.ERP.MANUFACTURER_NUMBER'),
        accessor: 'manufacturerNumber',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'text',
      },
      {
        Header: t('COMMON.ERP.PROCEDURE'),
        accessor: 'process',
        width: remToPx(7),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.SUPPLIER'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        accessor: 'supplier.name' as any,
        width: remToPx(10),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
      },
      {
        Header: t('COMMON.RESPONSIBLE'),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        accessor: 'responsibleUser.displayName' as any,
        width: remToPx(10),
        filterSubgroupPath: ['COMMON.ERP.MISCELLANEOUS'],
        type: 'picker',
        render: (cell: Cell<Position>, { isEditMode }) => (
          <UserPickerCell
            value={cell?.row?.original?.responsibleUser}
            className={clsx(styles.customCell, {
              [styles.justLabel]: !isEditMode,
            })}
            style={{
              ...pickerStyles,
              background: isEditMode ? undefined : 'unset',
              border: isEditMode ? undefined : 'unset',
            }}
            hideIcon
            onChange={(value) => {
              onEditPosition({
                position: {
                  id: cell.row.original.id,
                  editorUserId: value?.id || 0,
                },
              });
            }}
            hover
            disabled={disabled}
          />
        ),
      },
    ];

    const filtered = [
      ...baseColumns,
      ...pricingColumns,
      ...additionalColumns,
    ].filter((col) => {
      return (
        Boolean(col) &&
        (allowedColumns
          ? allowedColumns.includes(col.accessor as string)
          : true)
      );
    });

    if (!allowedColumns)
      Object.entries(width).forEach((colWidth) => {
        const [prop, value] = colWidth;
        const colIdx = filtered.findIndex((col) => col.accessor === prop);
        if (filtered[colIdx]) {
          filtered[colIdx].width = value;
        }
      });

    return filtered;
  }, [
    t,
    showERPPrices,
    formatTitleWithCurrency,
    user,
    isSupplierEntity,
    allowedColumns,
    width,
    onAddPosition,
    total,
    currency?.name,
    disabled,
    onEditPosition,
  ]);

  const allColumns = useMemo(() => {
    if (!customFields || allowedColumns) return columns;
    return [
      ...columns,
      ...customFields
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((cField, order) => ({
          Header: cField.name,
          accessor: cField.id.toString(),
          width: 120,
          filterSubgroupPath: ['MASK.INDIVIDUAL'],
          order,
          Cell: (data) => <CustomCell field={cField} cellData={data} />,
        })),
    ] as Column<Position>[];
  }, [allowedColumns, columns, customFields]);

  // resetColumns
  const resetColumns = useResetColumns({
    columnSettings,
    tableInstanceRef,
    defaultColumns: DEFAULT_VISIBLE_ERP_COLUMNS,
  });
  return {
    columns: allColumns,
    resetColumns,
  };
};
