import styles from './PositionCorrection.module.scss';

import { Button, Popover, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useUser } from '@work4all/data';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { useShadowBzObjectApi } from '../../../../containers/mask-overlays/mask-overlay/views/erp/hooks/use-bz-shadow-object-api';
import { settings, useSetting } from '../../../../settings';
import { getFactor } from '../utils';

interface IProps {
  entity: Entities;
  parentEntity: Entities.customer | Entities.supplier;
  parentId: number;
  bzObjId: number;
  positions: Position[];
  cellRef: RefObject<HTMLDivElement>;
  onEditStart: () => void;
  onEditComplete: () => void;
}

export const PositionCorrection = (props: IProps) => {
  const {
    bzObjId,
    entity,
    parentEntity,
    parentId,
    positions,
    cellRef,
    onEditStart,
    onEditComplete,
  } = props;

  const user = useUser();

  /**
   * Currently the createShadowObj is called twice
   * because of the cell being mounted and unmounted.
   * Will be fixed in WW-2782
   */
  const [shadowBzObject, shadowBzObjectApi] = useShadowBzObjectApi({
    entity: entity,
    id: bzObjId,
    parentEntity,
    parentId,
  });

  const [positionUpdate, setPositionUpdate] = useState<{
    id: number;
    amount: number;
    longtext: string;
  }>(null);

  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    setShowPopover(true);
  }, []);

  useEffect(() => {
    if (!positionUpdate) {
      return;
    }

    setPositionUpdate(null);

    if (
      !shadowBzObject?.data.positionList.find(
        (pos) => pos.id === positionUpdate.id
      )
    ) {
      return;
    }
    shadowBzObjectApi.editPosition(
      {
        position: positionUpdate,
        forceUpdate: true,
      },
      {
        onComplete: async () => {
          await shadowBzObjectApi.persist();
          onEditComplete();
        },
      }
    );
  }, [
    onEditComplete,
    positionUpdate,
    shadowBzObject?.data?.positionList,
    shadowBzObjectApi,
  ]);

  const handlePositionCorrection = useCallback(
    async (props: { position: Position; newAmount: number }) => {
      const { position, newAmount } = props;

      setShowPopover(false);
      onEditStart();

      shadowBzObjectApi.addPosition(
        {
          positionType: ErpPositionsKind.STANDARD,
          article: position.article,
        },
        {
          onComplete: async (data) => {
            const newPositions =
              data.modifyShadowBzObjectAddPosition.data.positionList;
            const newPosition: Position = newPositions[newPositions.length - 1];
            const longtext = `${user?.displayName}, ${DateTime.now().toFormat(
              'dd.MM.yyyy'
            )}: ${newPosition.longtext}`;

            setPositionUpdate({
              id: newPosition.id,
              amount: newAmount,
              longtext,
            });
          },
        }
      );
    },
    [shadowBzObjectApi, user?.displayName, onEditStart]
  );

  const groupedPositions = useMemo<Position[]>(() => {
    const articleIds = [...new Set(positions.map((pos) => pos.article?.id))];

    return articleIds.map((id) => {
      const articlePositions = positions.filter(
        (pos) => pos.article?.id === id
      );
      return {
        ...articlePositions[0],
        amount: articlePositions
          .map((pos) => pos.amount)
          .reduce((a, b) => a + b, 0),
      } as Position;
    });
  }, [positions]);

  if (groupedPositions.length === 0) {
    return null;
  }

  return (
    <Popover
      open={showPopover}
      anchorEl={cellRef?.current}
      onClose={() => {
        setShowPopover(false);
        onEditComplete();
      }}
    >
      <table cellSpacing="10">
        {groupedPositions.map((pos) => {
          return (
            <PositionRow
              position={pos}
              onPositionCorrection={handlePositionCorrection}
            />
          );
        })}
      </table>
    </Popover>
  );
};

const PositionRow = (props: {
  position: Position;
  onPositionCorrection: (props: {
    position: Position;
    newAmount: number;
  }) => void;
}) => {
  const { position, onPositionCorrection } = props;

  const { t } = useTranslation();
  const cellRef = useRef(null);

  const resourcePlanningColoumnTimeUnit = useSetting(
    settings.resourcePlanningColoumnTimeUnit()
  );

  const [amount, setAmount] = useState(
    position.amount *
      getFactor(position.unit, resourcePlanningColoumnTimeUnit.value)
  );

  const factoredAmount = useMemo(
    () =>
      amount / getFactor(position.unit, resourcePlanningColoumnTimeUnit.value),
    [amount, position.unit, resourcePlanningColoumnTimeUnit.value]
  );

  const handlePositionCorrection = useCallback(() => {
    onPositionCorrection({
      position,
      newAmount: factoredAmount - position.amount,
    });
  }, [factoredAmount, onPositionCorrection, position]);

  return (
    <tr key={position.id}>
      <td align="right">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handlePositionCorrection();
          }}
        >
          <LabeledInput
            onFocus={(e) => e.target.select()}
            style={{ width: '6rem' }}
            defaultValue={amount}
            type="number"
            onChange={(e) => setAmount(parseFloat(e.target.value))}
            autoFocus
            inputProps={{
              step: 'any',
            }}
            endAdornment={
              <Typography color="var(--text03)" pl="0.25rem">
                {t(
                  `COMMON.${resourcePlanningColoumnTimeUnit.value.toUpperCase()}_SHORT`
                )}
              </Typography>
            }
          />
        </form>
      </td>
      <td style={{ maxWidth: '10rem' }}>
        <Tooltip title={position?.article?.name}>
          <Typography className={styles.positionText}>
            {position?.article?.name}
          </Typography>
        </Tooltip>
      </td>
      <td>
        <Button
          ref={cellRef}
          variant="contained"
          size="small"
          onClick={handlePositionCorrection}
          disabled={factoredAmount === position.amount}
        >
          {t('COMMON.CORRECT')}
        </Button>
      </td>
    </tr>
  );
};
