import styles from './PopoverList.module.scss';

import { ListItem, ListItemText } from '@mui/material';

import { List } from './components/list/List';

interface Item {
  id: string;
  label: string;
}

interface IFiltersListProps<T> {
  items: Array<Item & T>;
  handleClick: (item: Item & T) => void;
  withDivider?: boolean;
}

export const PopoverList = <T extends Record<string, unknown>>(
  props: IFiltersListProps<T>
) => {
  return (
    <List>
      {props.items.map((item, i) => {
        const divider = i < props.items.length - 1;

        return (
          <ListItem
            key={item.id}
            className={styles.item}
            button
            divider={divider}
            onClick={() => props.handleClick(item)}
          >
            <ListItemText
              className={styles.itemText}
              classes={{ primary: styles.primary }}
            >
              {item.label}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};
