import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { GrouPickerItem, GroupPicker } from '../GroupPicker';

interface UserClassPickerProps {
  value: GrouPickerItem<string>[] | null;
  onChange: (value: GrouPickerItem<string>[]) => void;
  multiple?: boolean;
}

export function UserClassPicker(props: UserClassPickerProps) {
  const { value, onChange, multiple } = props;

  const values = Object.values(UserClass);
  const { t } = useTranslation();

  const groups = useMemo(() => {
    return values.map((x) => ({
      id: x,
      name: t(`ENUMS.${x.toUpperCase()}_other`),
    }));
  }, [t]);

  return (
    <GroupPicker
      value={value}
      onChange={onChange}
      groups={groups}
      multiple={multiple}
    />
  );
}
