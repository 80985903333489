import styles from './PullRefresh.module.scss';

import { useApolloClient } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';

const PullRefresh: React.FC<{ children: ReactElement<any> }> = ({
  children,
}) => {
  const client = useApolloClient();
  const [updated, setUpdated] = useState(false);

  const height = 200;

  const component = (
    <div className={styles.root}>
      <div className={styles.top}>
        <RefreshIcon
          fontSize="large"
          className={clsx(styles.icon, styles.rotate, {
            [styles.hidden]: updated,
          })}
        />
        <CheckCircleIcon
          fontSize="large"
          className={clsx(styles.icon, {
            [styles.hidden]: !updated,
          })}
        />
      </div>
    </div>
  );
  return (
    <PullToRefresh
      className={styles.container}
      maxPullDownDistance={height}
      pullDownThreshold={height}
      pullingContent={component}
      refreshingContent={component}
      onRefresh={() =>
        new Promise((resolve) => {
          client.resetStore().then(() => {
            setUpdated(true);
            setTimeout(() => {
              setUpdated(false);
              resolve(1);
            }, 500);
          });
        })
      }
    >
      {children}
    </PullToRefresh>
  );
};

export default PullRefresh;
