import styles from './ToolBar.module.scss';

import { TableRows } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Theme, useMediaQuery } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import {
  ChangeEvent,
  type JSX,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import {
  ColumnInstance,
  FilterType,
  prepareFilters,
  useTableStateBag,
} from '@work4all/components';
import { ConvertPopover } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { useTablePrefilterContext } from '@work4all/components/lib/components/table/TablePrefilterProvider';
import { useTableVariant } from '@work4all/components/lib/components/table/TableVariantProvider';
import { ActionsBar } from '@work4all/components/lib/dataDisplay/actions-bar/ActionsBar';
import { useToolbarCustomActions } from '@work4all/components/lib/dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import {
  ButtonWithLink,
  CustomToolbar,
  DisabledParams,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import {
  filterIsEmpty,
  genFilterTitle,
} from '@work4all/components/lib/dataDisplay/basic-table/utils/genFilterTitle';
import {
  SortAscIcon,
  SortDescIcon,
  ViewColumnSelectedIcon,
} from '@work4all/components/lib/icons';
import { IActionConfig } from '@work4all/components/lib/input/actions/types';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useNavigate } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import {
  ICustomCellConfigBase,
  ITableSchema,
} from '@work4all/models/lib/table-schema/table-schema';

import { ListPageContext } from '../../../../../containers/file-entities-lists/list-page-context';
import { HomeWidgetsContext } from '../../../../../containers/home/components/home-widgets/HomeWidgetsContextProvider';
import { settings, useSetting } from '../../../../../settings';
import { HomeWidgetType } from '../../../../../settings/validation/HomeWidget';
import { stringifyTableSettings } from '../../../make-table-settings';
import { useTableLayoutState } from '../../../table-layout';
import { transformedFilterFields } from '../../../use-transformed-filter-fields';
import { TableFilter } from '../../TableFilter';
import { isBoardEnabled } from '../kanban-board/Board';
import { WithBubble } from '../with-bubble/WithBubble';

import { ActionsBarMobile } from './components/actions-bar-mobile/ActionsBarMobile';
import { ActionConfig as ActionsMenuMobileActionConfig } from './components/actions-bar-mobile/ActionsMenuMobile';
import { ColumnsVisibility } from './components/columns-visibility';
import { FiltersOverlay } from './components/filters/filters-overlay/FiltersOverlay';
import { SelectedFiltersList } from './components/filters/selected-filters-list/SelectedFiltersList';
import { ToolbarFilterPickerContext } from './components/filters/ToolbarFilterPickerContext';
import { GroupingsVisibilty } from './components/groupings/GroupingsVisibilty';
import { SelectedGroupsList } from './components/groupings/selected-groups-list/SelectedGroupsList';
import { LayoutAction } from './components/layout-action/LayoutAction';
import { SearchAction } from './components/search-action/SearchAction';
import { SortColumnPopover } from './components/SortColumnPopover';
import { TableLayoutPopover } from './components/TableLayoutPopover';
import { TableSettingsOverlay } from './components/TableSettingsOverlay';
import { useToolbarDeleteAction } from './hooks/use-toolbar-delete-action';
import { RemoveToolbarAction } from './type';

interface ConvertToolbarAction extends DisabledParams {
  exclude: Entities;
  handler: (entity: Entities) => void;
}

export interface IToolBar {
  mobile?: boolean;
  hideLayoutSelect?: boolean;
  hideMoreButton?: boolean;
  listEntityType?: Entities;
  tableInstanceRef: React.RefObject<TableInstance | null>;
  exportCSV?: JSX.Element;
  actions?: {
    add?: ButtonWithLink;
    edit?: ButtonWithLink<string> | null;
    remove?: RemoveToolbarAction | null;
    createWidget?: boolean;
    convert?: ConvertToolbarAction | null;
    transform?: ((ids: string[]) => void) | null;
    export?: ((ids: string[]) => void) | null;
    resetColumns?: () => void;
    custom?: CustomToolbar;
    openInNewTab?: {
      getPath: (id: string) => string;
    };
    /**
     * Default: true.
     */
    groupingEnabled?: boolean;
    clone?: {
      handler: () => void;
      disabled: boolean;
    };
  };
  hideSearch?: boolean;
  schema?: ITableSchema<Record<string, ICustomCellConfigBase>>;
}

const emptyColumns: ColumnInstance[] = [];

export const Toolbar: React.FC<IToolBar> = (props) => {
  const {
    tableInstanceRef,
    actions = {},
    mobile = false,
    hideLayoutSelect = false,
    hideMoreButton = false,
    hideSearch = false,
    listEntityType,
    schema,
  } = props;
  const { tableState, columnsById, setUnderPressSelect } = useTableStateBag();
  const { t } = useTranslation();
  const listPageContext = useContext(ListPageContext);

  const homeWidgetsContext = useContext(HomeWidgetsContext);
  const addWidget = homeWidgetsContext?.addWidget;
  const closedEntitiesSetting = useSetting(
    settings.hideClosedEntities({ entityType: listPageContext.entityType })
  );

  const setHideClosedEntitiesSetting = useEventCallback((value: boolean) => {
    closedEntitiesSetting.set(value);
  });

  const columns: ColumnInstance[] = useMemo(() => {
    const result = Object.keys(columnsById).map((key) => {
      return columnsById[key];
    });

    return [
      ...result.filter((c) => c.isPrimaryFilter),
      ...result.filter((c) => !c.isPrimaryFilter),
    ];
  }, [columnsById]);

  const [filterPickerActive, setFilterPickerActive] = useState(false);

  const getChips = useCallback(
    () =>
      columns
        // Filter non-selected and primary filters
        ?.filter((col) => !(!col.isPrimaryFilter && filterIsEmpty(col)))
        // Move all selected filters before empty ones.
        ?.sort((a, b) => {
          if (!filterIsEmpty(a) && filterIsEmpty(b)) {
            return -1;
          }
          return 0;
        })
        ?.map((col) => ({
          id: col.id,
          title: filterIsEmpty(col)
            ? (col.Header as string)
            : genFilterTitle(col),
          readOnly: !!col.filterValue?.readOnly,
          isPrimaryFilter: col.isPrimaryFilter,
          renderFilter: (item) => (
            <TableFilter column={col} listEntityType={listEntityType}>
              {item}
            </TableFilter>
          ),
          handleDelete: filterIsEmpty(col)
            ? undefined
            : () => {
                if (
                  (col as ColumnInstance).filterType === FilterType.ClosedStatus
                ) {
                  col.setFilter({
                    filterType: FilterType.ClosedStatus,
                    value: false,
                  });

                  setHideClosedEntitiesSetting(false);
                } else {
                  col.setFilter(null);
                }
              },
        }))
        .filter(Boolean)
        // Move all read-only filter to the beginning of the list.
        .sort((a, b) => {
          if (a.readOnly && !b.readOnly) {
            return -1;
          }
          return 0;
        }) ?? [],
    [columns, listEntityType, setHideClosedEntitiesSetting]
  );

  const [chips, setChips] = useState(getChips);

  const groupingsPopoverState = usePopoverState();
  const columnsPopoverState = usePopoverState();
  const convertPopoverState = usePopoverState(mobile ? 'top' : 'bottom');
  const sortColumnPopoverState = usePopoverState(mobile ? 'top' : 'bottom');
  const layoutPopoverState = usePopoverState(mobile ? 'top' : 'bottom');

  const settingsRootRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const filtersRootRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const groupingsRootRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const columnsRootRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const convertRootRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const mobileMenuButtonRef = useRef<HTMLButtonElement>(null);
  const desktopMenuButtonRef = useRef<HTMLButtonElement>(null);

  const [showSettingsOverlay, setSettingsOverlay] = useState(false);
  const [showFilterOverlay, setFilterOverlay] = useState(false);

  const selectedRowsIds = tableInstanceRef.current?.state.selectedRowIds;
  const selectedRowsIdsList = useMemo(() => {
    if (!selectedRowsIds) return [];

    return (
      Object.entries(selectedRowsIds)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_id, isSelected]) => isSelected)
        .map(([id]) => id)
    );
  }, [selectedRowsIds]);

  const openColumnsPopover = columnsPopoverState.handleClick;
  const openConvertPopover = convertPopoverState.handleClick;
  const openGroupingPopover = groupingsPopoverState.handleClick;
  const openSortColumnPopover = sortColumnPopoverState.handleClick;
  const openLayoutPopover = layoutPopoverState.handleClick;

  const tableStateBag = useTableStateBag();

  const navigate = useNavigate();

  const handleSearchFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e?.target?.value || e?.target?.value.length === 0) {
        tableStateBag.setSearchFilterText('');
      } else {
        tableStateBag.setSearchFilterText(e?.target?.value);
      }
    },
    [tableStateBag]
  );

  const customMappedActions = useToolbarCustomActions({
    selectedRowsIdsList,
    custom: props?.actions?.custom,
  });

  const [layoutValue] = useTableLayoutState();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const { prefilter } = useTablePrefilterContext();
  const { variant } = useTableVariant();

  const { onDeleteClick } = useToolbarDeleteAction({
    ids: selectedRowsIdsList,
    action: actions.remove,
  });

  const mappedActions = useMemo(() => {
    const isSomethingSelected = selectedRowsIdsList.length > 0;
    const isExactlyOneSelected = selectedRowsIdsList.length === 1;

    const add = actions.add;
    const edit = actions.edit;
    const remove = actions.remove;
    const convert = actions.convert;
    const exportAction = actions.export;
    const transform = actions.transform;
    const createWidget = actions.createWidget;
    const clone = actions.clone;

    const config: Record<
      | 'add'
      | 'edit'
      | 'filter'
      | 'columns'
      | 'remove'
      | 'convert'
      | 'group'
      | 'export'
      | 'go'
      | 'search'
      | 'sortColumn'
      | 'sortOrder'
      | 'layout'
      | 'createWidget'
      | 'clone'
      | 'settings',
      (IActionConfig & ActionsMenuMobileActionConfig) | null
    > = {
      add: add
        ? add.getHref
          ? {
              ...add,
              href: add.getHref(),
              dataTestId: `add-${listPageContext.entityType}`,
            }
          : {
              ...add,
              onClick: () => add.handler(),
            }
        : null,
      edit:
        edit && isExactlyOneSelected
          ? edit.getHref
            ? {
                href: edit.getHref(cleanIds(selectedRowsIdsList)[0]),
                disabled: edit.disabled,
                disableReason: edit.disableReason,
              }
            : {
                onClick: () => {
                  edit.handler(cleanIds(selectedRowsIdsList)[0]);
                },
                disableReason: edit.disableReason,
                disabled: edit.disabled,
              }
          : null,

      filter: tableStateBag?.tableInstance?.allColumns.find((x) => x.canFilter)
        ? {
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              sendAmplitudeData(EventType.TabFilter, {
                name: listPageContext.entityType,
              });
              setFilterOverlay(true);
            },
            children: <SelectedFiltersList list={chips} />,
            rootRef: filtersRootRef,
          }
        : null,

      settings: {
        onClick: () => {
          setSettingsOverlay(true);
        },
        rootRef: settingsRootRef,
        label: t('SETTINGS.SETTINGS'),
      },

      columns: {
        onClick: (event) => {
          return openColumnsPopover(
            event,
            (mobile ? mobileMenuButtonRef : desktopMenuButtonRef).current
          );
        },
        rootRef: columnsRootRef,
        label: layoutValue === 'cards' ? t('MASK.ROWS') : undefined,
        icon: layoutValue === 'cards' ? <TableRows /> : undefined,
      },

      remove:
        remove && isSomethingSelected
          ? {
              onClick: onDeleteClick,
              disabled: remove.disabled,
              disableReason: remove.disableReason,
              dataTestId: `delete-${listPageContext.entityType}`,
            }
          : null,

      convert:
        convert && isSomethingSelected
          ? {
              onClick: (event) => {
                openConvertPopover(
                  event,
                  (mobile ? mobileMenuButtonRef : convertRootRef).current
                );
              },
              margin: 'lg',
              rootRef: convertRootRef,
            }
          : null,

      group:
        actions.groupingEnabled === undefined || actions.groupingEnabled
          ? {
              onClick: (event) => {
                openGroupingPopover(
                  event,
                  (mobile ? mobileMenuButtonRef : desktopMenuButtonRef).current
                );
              },
              margin: 'lg',
              rootRef: groupingsRootRef,
              children: (
                <WithBubble count={tableState?.groupBy?.length}>
                  <SelectedGroupsList
                    columnsById={columnsById}
                    tableInstanceRef={tableInstanceRef}
                    groupedBy={tableState?.groupBy}
                  />
                </WithBubble>
              ),
            }
          : undefined,

      export:
        exportAction && isSomethingSelected
          ? {
              onClick: () => {
                exportAction(selectedRowsIdsList);
              },
            }
          : null,

      go:
        transform && isSomethingSelected
          ? {
              onClick: () => {
                transform(selectedRowsIdsList);
              },
            }
          : null,

      search: {
        onClick: () => {
          console.warn("This shouldn't be used");
        },
        render: () =>
          hideSearch ? null : (
            <SearchAction handleSearchFilter={handleSearchFilter} />
          ),
      },
      sortColumn: (() => {
        const sortByColumn =
          (tableState?.sortBy.length ?? 0) > 0
            ? tableInstanceRef.current?.allColumns.find(
                (column) => column.id === tableState.sortBy[0].id
              )
            : null;

        const columnOrRow = layoutValue === 'cards' ? 'row' : 'column';

        return {
          onClick: (event) => {
            openSortColumnPopover(event, mobileMenuButtonRef.current);
          },
          label: sortByColumn
            ? t(`COMMON.SORT_${columnOrRow.toUpperCase()}`, {
                [columnOrRow]: sortByColumn.Header,
              })
            : t(`COMMON.SORT_${columnOrRow.toUpperCase()}_EMPTY`),
          icon: <ViewColumnSelectedIcon />,
        };
      })(),

      sortOrder:
        (tableState?.sortBy.length ?? 0) > 0
          ? {
              onClick: () => {
                tableInstanceRef.current.setSortBy([
                  {
                    id: tableState.sortBy[0].id,
                    desc: !tableState.sortBy[0].desc,
                  },
                ]);
              },
              label: tableState.sortBy[0].desc
                ? t('COMMON.SORT_ORDER_DESC')
                : t('COMMON.SORT_ORDER_ASC'),
              icon: tableState.sortBy[0].desc ? (
                <SortDescIcon />
              ) : (
                <SortAscIcon />
              ),
            }
          : null,

      layout: !hideLayoutSelect
        ? {
            onClick: (event) => {
              openLayoutPopover(
                event,
                (mobile ? mobileMenuButtonRef : desktopMenuButtonRef).current
              );
            },
            render: mobile
              ? undefined
              : () => (
                  <LayoutAction
                    isBoardEnabled={isBoardEnabled(listEntityType)}
                  />
                ),
            label: t('COMMON.LAYOUT_MODE'),
            icon: <RemoveRedEyeIcon />,
          }
        : undefined,

      createWidget:
        createWidget && !isMobile
          ? {
              onClick: () => {
                const transformedTableFilterFields = transformedFilterFields(
                  tableStateBag,
                  schema
                );
                const { filter } = prepareFilters(
                  transformedTableFilterFields,
                  tableStateBag.tableState?.sortBy,
                  undefined,
                  listPageContext.entityType
                );

                addWidget({
                  id: new Date().toISOString(),
                  type: HomeWidgetType.entityWidget,
                  definition: {
                    entity: listPageContext.entityType,
                    filter,
                    prefilter,
                    variant,
                    tableUrl: stringifyTableSettings({
                      filter:
                        tableStateBag.tableState.filters?.map((filter) => {
                          return {
                            ...filter,
                            value: filter?.value?.value,
                          };
                        }) || [],
                    }),
                  },
                });
                navigate('/home');
              },
            }
          : null,

      clone:
        clone && isSomethingSelected
          ? { onClick: clone.handler, disabled: clone.disabled }
          : null,
    };

    return config;
  }, [
    selectedRowsIdsList,
    actions.add,
    actions.edit,
    actions.remove,
    actions.convert,
    actions.export,
    actions.transform,
    actions.createWidget,
    actions.groupingEnabled,
    tableStateBag?.tableInstance?.allColumns,
    tableStateBag.tableState,
    chips,
    layoutValue,
    t,
    tableState?.groupBy,
    tableState?.sortBy,
    columnsById,
    tableInstanceRef,
    mobile,
    listPageContext.entityType,
    openColumnsPopover,
    openConvertPopover,
    openGroupingPopover,
    handleSearchFilter,
    openSortColumnPopover,
    openLayoutPopover,
    addWidget,
    prefilter,
    variant,
    navigate,
    isMobile,
    actions.clone,
    hideLayoutSelect,
    onDeleteClick,
  ]);

  const removeGroupingHandler = useCallback(() => {
    tableInstanceRef.current.allColumns.forEach(
      (col) => col.isGrouped && col.toggleGroupBy()
    );
  }, [tableInstanceRef]);

  useEffect(() => {
    if (!filterPickerActive) {
      const chips = getChips();
      setChips(chips);
    }
  }, [tableState?.filters, filterPickerActive, getChips]);

  return (
    <ToolbarFilterPickerContext.Provider
      value={{
        setFilterPickerActive,
        filterPickerActive,
      }}
    >
      {showFilterOverlay && (
        <FiltersOverlay
          overlayOpen={showFilterOverlay}
          onClose={() => setFilterOverlay(false)}
          listEntityType={listEntityType}
        />
      )}
      <TableSettingsOverlay
        entityType={listPageContext.entityType}
        isOpen={showSettingsOverlay}
        close={() => setSettingsOverlay(false)}
      />

      <GroupingsVisibilty
        popoverState={groupingsPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns}
        onResetColumns={removeGroupingHandler}
      />
      <ColumnsVisibility
        popoverState={columnsPopoverState}
        visibleColumns={tableInstanceRef.current?.allColumns ?? emptyColumns}
        onResetColumns={actions.resetColumns}
        title={layoutValue === 'cards' ? t('MASK.ROWS') : undefined}
      />
      <SortColumnPopover
        sortBy={tableState?.sortBy}
        popoverState={sortColumnPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns ?? emptyColumns}
      />
      <TableLayoutPopover popoverState={layoutPopoverState} />

      {actions.convert ? (
        <ConvertPopover
          exclude={actions.convert.exclude}
          onClick={(entity) => {
            actions.convert.handler(entity);
            convertPopoverState.onClose();
          }}
          popoverState={convertPopoverState}
        />
      ) : null}

      {mobile ? (
        <ActionsBarMobile
          actions={actions}
          mappedActions={mappedActions}
          onSearchFilter={handleSearchFilter}
          openFilterOverlay={() => setFilterOverlay(true)}
          onUnderPressDeselect={() => setUnderPressSelect(false)}
          filtersCount={chips.length}
          mobile={mobile}
          hideMoreButton={hideMoreButton}
          hideSearch={hideSearch}
          tableInstanceRef={tableInstanceRef}
          mobileMenuButtonRef={mobileMenuButtonRef}
        />
      ) : (
        <div className={styles.root}>
          <ActionsBar
            margin="xs"
            {...mappedActions}
            isGrouped={tableState?.groupBy?.length > 0}
            menuButtonRef={desktopMenuButtonRef}
            customActions={customMappedActions}
            hideMoreButton={hideMoreButton}
          />
        </div>
      )}
    </ToolbarFilterPickerContext.Provider>
  );
};

export const cleanIds = (ids: string[]) => {
  return ids.filter(Boolean).map((id) => {
    if (!id.includes('.')) return id;
    const parts = id.split('.');
    return parts[parts.length - 1];
  });
};
