import { cloneDeep, isEqual } from 'lodash';
import { DependencyList, useMemo, useRef } from 'react';

const useDeepCompareMemoize = <T>(value: T) => {
  const ref = useRef<T>(null);
  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
  }
  return ref.current;
};

export const useDeepMemo = <T>(
  callback: () => T,
  dependencies: DependencyList
): T => {
  // to make a deep equal comparison here not a comparison based on reference
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<T>(callback, useDeepCompareMemoize(dependencies));
};
