import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ISalutationPickerProps,
  SalutationPicker,
} from '@work4all/components/lib/components/entity-picker/salutation-picker/SalutationPicker';

import { reactRefSetter } from '@work4all/utils';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface SalutationPickerFieldProps
  extends Omit<ISalutationPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
  label?: string;
}

export const SalutationPickerField = React.forwardRef<
  HTMLDivElement,
  SalutationPickerFieldProps
>(function SalutationPickerField(props, ref) {
  const { error: _error, label, ...pickerProps } = props;
  const { value } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        picker={<SalutationPicker multiple={false} {...pickerProps} />}
      >
        <PickerTargetButton
          onClear={null}
          value={`${value?.name ? value?.name : ''}${
            value?.standardletterSalutation
              ? ' / ' + value?.standardletterSalutation
              : ''
          }`}
          label={label ?? t('INPUTS.SALUTATION')}
        />
      </EntityPickerPopover>
    </div>
  );
});
