import { z } from 'zod';

import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';

export const savedTableConfigZod = z
  .object({
    order: z.string().array().optional(),
    columns: z.record(
      z.string(),
      z.object({
        width: z.number().optional(),
        hidden: z.boolean().optional(),
      })
    ),
  })
  .nullable();
export type SavedTableConfig = DeepRequired<
  z.infer<typeof savedTableConfigZod>
>;

export interface IMaskUserConfig {
  fullscreen?: boolean;
}

const ProjectZod = z
  .object({
    id: z.number(),
    number: z.string(),
    name: z.string(),
    __typename: z.string(),
  })
  .optional();

const ProjectProcessTimeTrackingZod = z.object({
  id: z.number(),
  timeTrackings: z.number(),
});

const ProjectProcessZod = z
  .object({
    id: z.number(),
    process: z.string(),
    planningAmount: z.number(),
    timeTrackings: z.array(ProjectProcessTimeTrackingZod),
    __typename: z.string(),
  })
  .optional();

const BusinessPartnerZod = z.object({
  id: z.number(),
  name: z.string(),
  website: z.string(),
  __typename: z.string(),
});

const ContractZod = z.object({
  id: z.number(),
  contractNumber: z.number(),
  note: z.string(),
  __typename: z.string(),
});

const TicketZod = z.object({
  id: z.string(),
  title: z.string(),
});

const TimeTrackTemplateZod = z.object({
  title: z.string(),
  subtitle1: z.string().optional(),
  subtitle2: z.string().optional(),
  subtitle3: z.string().optional(),
  subtitle4: z.string().optional(),
  toApply: z
    .object({
      project: ProjectZod.nullish(),
      projectProcess: ProjectProcessZod.nullish(),
      businessPartner: BusinessPartnerZod.nullish(),
      contract: ContractZod.nullish(),
      ticket: TicketZod.nullish(),
    })
    .nullish(),
});

export type TimeTrackTemplate = z.infer<typeof TimeTrackTemplateZod>;

export const TimeTrackTemplateListZod = TimeTrackTemplateZod.array();
export type TimeTrackTemplateList = z.infer<typeof TimeTrackTemplateListZod>;

const timeTrackSettingZod = z.object({
  show: z.boolean(),
  count: z.number(),
});

export const timeTrackSettingsZod = z.record(
  z.enum([
    'last',
    Entities.task,
    Entities.contract,
    Entities.project,
    Entities.appointment,
    Entities.ticket,
  ]),
  timeTrackSettingZod
);

export type TimeTrackSetting = DeepRequired<
  z.infer<typeof timeTrackSettingZod>
>;

export type TimeTrackSettings = DeepRequired<
  z.infer<typeof timeTrackSettingsZod>
>;

export interface SelectedMailboxFolder {
  mailbox: string;
  folder: string;
}

export type StandardEmailTemplates = {
  [K in EMailTemplateKind]?: string;
};
