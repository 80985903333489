import styles from './styles.module.scss';

import clsx from 'clsx';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { NavigationItem } from '@work4all/components/lib/navigation/desktop-navigation/components/navigation-item/NavigationItem';

import { DndTypes } from '@work4all/utils/lib/variables';

import {
  IFavoriteLink,
  useFavoriteLinks,
} from '../../../containers/more/data/favorite-links';
import {
  FavoriteLinkDragObject,
  FavoriteLinksFolderDragObject,
  LinkDragObject,
} from '../../../dnd/drag-objects';
import { useEmptyDragPreview } from '../../../dnd/use-empty-drag-preview';
import { addDateParamToLink } from '../add-date-param-to-link';

import { FavoriteLinkContextMenu } from './context-menu/FavoriteLinkContextMenu';

export interface IFavoriteLinkProps {
  link: IFavoriteLink;
  active?: boolean;
}

export function FavoriteLink({ link, active = false }: IFavoriteLinkProps) {
  const {
    addFavoriteLink,
    moveFavoriteLink,
    combineLinks,
    moveFavoriteLinksFolder,
  } = useFavoriteLinks();

  const { t } = useTranslation();

  const [, dropOn] = useDrop({
    accept: [DndTypes.LINK, DndTypes.FAVORITE_LINK],
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        switch (monitor.getItemType()) {
          case DndTypes.LINK: {
            const linkItem = item as LinkDragObject;
            combineLinks({
              targetId: link.id,
              name: linkItem.text,
              translationKey: linkItem.translationKey,
              href: linkItem.href,
            });
            break;
          }
          case DndTypes.FAVORITE_LINK: {
            const favoriteLinkItem = item as FavoriteLinkDragObject;

            if (favoriteLinkItem.id === link.id) {
              return;
            }

            combineLinks({
              targetId: link.id,
              folderId: favoriteLinkItem.folderId,
              id: favoriteLinkItem.id,
            });
            break;
          }
        }
      }
    },
  });

  const [{ isOver }, dropAfter] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    drop: (item, monitor) => {
      switch (monitor.getItemType()) {
        case DndTypes.LINK: {
          const linkItem = item as LinkDragObject;
          addFavoriteLink({
            link: {
              href: linkItem.href,
              name: linkItem.text,
              translationKey: linkItem.translationKey,
            },
            position: { after: link.id },
          });
          break;
        }
        case DndTypes.FAVORITE_LINK: {
          const favoriteLinkItem = item as FavoriteLinkDragObject;
          moveFavoriteLink({
            target: {
              link: favoriteLinkItem.id,
              folder: favoriteLinkItem.folderId,
            },
            position: { after: link.id },
          });

          break;
        }

        case DndTypes.FAVORITE_LINKS_FOLDER: {
          const favoriteLinksFolderItem = item as FavoriteLinksFolderDragObject;

          moveFavoriteLinksFolder({
            id: favoriteLinksFolderItem.id,
            position: { after: link.id },
          });

          break;
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [, drag, preview] = useDrag<FavoriteLinkDragObject, unknown, unknown>({
    type: DndTypes.FAVORITE_LINK,
    item: {
      folderId: null,
      id: link.id,
      text: link.name,
      translationKey: link.translationKey,
    },
  });

  useEmptyDragPreview(preview);

  return (
    <FavoriteLinkContextMenu link={link}>
      {({ onContextMenu }) => (
        <div
          ref={(node) => {
            drag(node);
            dropOn(node);
          }}
          className={styles.navWrapper}
        >
          <NavigationItem
            title={link.translationKey ? t(link.translationKey) : link.name}
            active={active}
            href={addDateParamToLink(`${link.href}`)}
            onContextMenu={onContextMenu}
          ></NavigationItem>

          <div
            ref={(node) => {
              dropAfter(node);
            }}
            className={clsx(styles.dropTarget, { [styles.dndActive]: isOver })}
          />
        </div>
      )}
    </FavoriteLinkContextMenu>
  );
}
