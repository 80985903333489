import { noop } from 'lodash';
import { createContext, useContext, useState } from 'react';

interface DragLayerContextType {
  show: (newView: React.ReactElement<any>) => void;
  clear: () => void;
}

const DragLayerContext = createContext<DragLayerContextType>({
  show: noop,
  clear: noop,
});

export const DragLayerProvider = ({ children }) => {
  const [view, show] = useState<React.ReactElement<any> | undefined>(undefined);

  return (
    <DragLayerContext.Provider value={{ show, clear: () => show(undefined) }}>
      {view}
      {children}
    </DragLayerContext.Provider>
  );
};

export const useDragLayer = () => useContext(DragLayerContext);
