import { HomeWidgetType } from '../../../../settings/validation/HomeWidget';

export const DEFAULT_HOME_WIDGETS = [
  {
    id: HomeWidgetType.calendarWidget,
    type: HomeWidgetType.calendarWidget,
  },
  {
    id: HomeWidgetType.overviewWidget,
    type: HomeWidgetType.overviewWidget,
  },
  {
    id: HomeWidgetType.relevantWidget,
    type: HomeWidgetType.relevantWidget,
  },
  {
    id: HomeWidgetType.timeTracker,
    type: HomeWidgetType.timeTracker,
  },
];
