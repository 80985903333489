import styles from './InlineData.module.scss';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, MouseEventHandler } from 'react';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

export interface InlineDataProps {
  label: string;
  content: string | React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  column?: boolean;
  onlyContent?: boolean;
  onLabelClick?: () => void;
}

export const InlineData = forwardRef<HTMLDivElement, InlineDataProps>(
  (props, ref) => {
    const {
      label,
      content = '-',
      onClick,
      column = false,
      onlyContent = false,
      onLabelClick,
    } = props;

    return (
      <Stack
        ref={ref}
        onClick={onClick}
        flexDirection={column ? 'column' : 'row'}
        gap="0.5rem"
        alignItems="start"
      >
        {onlyContent ? (
          content
        ) : (
          <>
            <Tooltip
              title={label}
              enterNextDelay={1000}
              placement="right"
              showOnlyOnOverflow
            >
              <Typography
                variant="body2"
                className={clsx(styles.label, {
                  [styles.clickable]: onLabelClick,
                })}
                onClick={onLabelClick}
              >
                {`${label}:`}
              </Typography>
            </Tooltip>
            {typeof content === 'string' ? (
              <Typography variant="body2">{content || '-'}</Typography>
            ) : (
              content
            )}
          </>
        )}
      </Stack>
    );
  }
);
