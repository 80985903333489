import { useCallback, useEffect, useRef, useState } from 'react';

export function useOverrideProps<T>(initialProps: T) {
  const updatedPropsRef = useRef<T | null>(null);
  const [hasUpdatedProps, setHasUpdatedProps] = useState(false);

  const overrideProps = useCallback((updater: Partial<T>) => {
    updatedPropsRef.current = { ...updatedPropsRef.current, ...updater };
    setHasUpdatedProps(true);
  }, []);

  const lastProps =
    hasUpdatedProps && updatedPropsRef.current
      ? updatedPropsRef.current
      : initialProps;

  useEffect(() => {
    updatedPropsRef.current = initialProps;
  }, [initialProps]);

  return { lastProps, overrideProps };
}
