import styles from './InfoCard.module.scss';

import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, { MouseEventHandler, useRef } from 'react';

import { reactRefSetter } from '@work4all/utils';

import { Tooltip } from '../../../tooltip/Tooltip';
import { InfoCardLabel } from '../info-card-label/InfoCardLabel';

export type IInfoCardProps = {
  disabled?: boolean;
  staticField?: boolean;
  column?: string;
  label: string;
  /**
   * If true, the text content will not wrap, but instead will truncate with a text
   * overflow ellipsis.
   *
   * @default false
   */
  truncate?: boolean;
  children?: React.ReactNode;
  decorator?: React.ReactNode;
  decoratorPos?: 'left' | 'right';
  onClick?: MouseEventHandler<HTMLDivElement>;
  tooltip?: string;
  beforeContentElement?: React.ReactElement<any>;
  /**
   * Set the color for a string or number children, if `disabled` value is `false`.
   */
  color?: string;
};

export const InfoCard = React.forwardRef<HTMLDivElement, IInfoCardProps>(
  (props: IInfoCardProps, ref) => {
    const {
      disabled = false,
      staticField = false,
      column,
      label,
      children,
      truncate,
      decorator,
      decoratorPos = 'left',
      tooltip,
      onClick,
      beforeContentElement,
      color = 'text.primary',
    } = props;
    const cardWrapperRef = useRef(null);

    function renderContent(): React.ReactElement<any> {
      if (!children && children !== 0) {
        return <Typography>-</Typography>;
      }

      if (typeof children === 'string' || typeof children === 'number') {
        return (
          <Typography
            color={disabled ? 'text.tertiary' : color}
            noWrap={truncate}
            className={truncate ? styles['width-full'] : undefined}
          >
            {children}
          </Typography>
        );
      }
      return <>{children}</>;
    }

    return (
      <div
        onClick={onClick}
        className={clsx(styles['info-card-wrapper'], {
          [styles.interactive]: !disabled && !staticField,
        })}
        style={column !== undefined ? { gridColumn: column } : null}
      >
        <div
          ref={reactRefSetter(cardWrapperRef, ref)}
          className={styles['info-card']}
        >
          {decorator && decoratorPos === 'left' && (
            <div className={styles['info-card-decorator']}>{decorator}</div>
          )}
          <div className={styles['info-card-content']}>
            <InfoCardLabel>{label}</InfoCardLabel>
            <div
              className={clsx(styles['info-card-content-inner'], {
                [styles['info-card-content-inner-gap']]: !!beforeContentElement,
              })}
            >
              {beforeContentElement}

              {tooltip ? (
                <Tooltip title={tooltip}>{renderContent()}</Tooltip>
              ) : (
                renderContent()
              )}
            </div>
          </div>
          {decorator && decoratorPos === 'right' && (
            <div className={styles['info-card-decorator']}>{decorator}</div>
          )}
        </div>
      </div>
    );
  }
);
