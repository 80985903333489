import styles from './MultiStepSearch.module.scss';

import clsx from 'clsx';
import React, {
  ChangeEventHandler,
  createRef,
  type JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';

import { LabeledSelectInput } from '../labeled-select-input/LabeledSelectInput';
import { MultiStepControls, Step } from '../multi-step-controls';
import { SearchResultItem } from '../search-result-item/SearchResultItem';

import { Search } from './components/search/Search';
import { useInputsStates } from './hooks/useInputsStates';
import { useSearch } from './hooks/useSearch';
import { IMultiStepSearchProps, InputRefsMap } from './types';
import { getInputKey } from './utils/getInputKey';

export const MultiStepSearch = React.memo(
  <
    T extends {
      title: string;
      id: string;
    },
    ControlProps
  >(
    props: IMultiStepSearchProps<T, ControlProps>
  ) => {
    const {
      SearchItem = SearchResultItem,
      onSearch,
      onItemSelect,
      isColumnDirection,
      searchValueAsActiveInputValue = true,
      disabled,
    } = props;
    const inputsRefs = useRef<InputRefsMap>({});
    const { setValue, getValue, getInputProps, clear } = useInputsStates(
      props.inputs,
      inputsRefs,
      props.alwaysActive,
      props.orderCanChange
    );
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(
      null
    );

    const [searchInputValue, setSearchInputValue] = useState('');
    const { itemsData, setSearchValue: search } = useSearch(onSearch);

    const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const val = e.target.value;
      setSearchInputValue(val);
      if (searchValueAsActiveInputValue) {
        setValue(activeDropdownId, val);
      }
    };

    const onHideDropDown = () => {
      setSearchInputValue('');
      setActiveDropdownId(null);
    };

    const setValueOfActiveInput = useCallback(
      (value: string, data?: T) => {
        // TODO: instead of "ValOrEvent" always use "string";
        const isClear = !value;
        if (isClear) {
          clear(activeDropdownId);
          return;
        }

        setValue(activeDropdownId, value, data);
      },
      [activeDropdownId, clear, setValue]
    );

    const activeInputProps = getInputProps(activeDropdownId);
    const canSearch = Boolean(onSearch);

    useEffect(() => {
      if (!activeDropdownId || !canSearch) {
        return;
      }

      search([activeDropdownId, searchInputValue, activeInputProps?.items]);
    }, [
      searchInputValue,
      activeDropdownId,
      canSearch,
      search,
      activeInputProps?.items,
    ]);

    const searchItems = itemsData || activeInputProps?.items;

    const items = useMemo(() => {
      return (
        searchItems?.map((item) => {
          return (
            <SearchItem
              id={item.id}
              key={item.id}
              {...(item as T)}
              onClick={() => {
                setValueOfActiveInput(item.title, item as T);
                if (onItemSelect) {
                  onItemSelect(activeDropdownId, item as T);
                }
                setActiveDropdownId(null);
              }}
            />
          );
        }) || null
      );
    }, [
      SearchItem,
      activeDropdownId,
      searchItems,
      onItemSelect,
      setValueOfActiveInput,
    ]);

    const activeInputRef = useRef<HTMLInputElement>(null);

    return (
      <>
        {props.inputs.map((input) => {
          const key = getInputKey(input);
          let inputRef = inputsRefs.current[key];
          if (!inputRef) {
            inputRef = inputsRefs.current[key] = createRef<HTMLInputElement>();
          }

          const {
            ref,
            items,
            actions = [],
            controlProps,
            controlType,
            multiline,
            multilineWithDynamicHeight,
            withUnclear,
            ...rest
          } = input;
          return (
            <input
              key={key}
              ref={reactRefSetter(inputRef, ref)}
              {...rest}
              hidden
            />
          );
        })}
        {/** Use "invis" css styles instead of just "not rendering" because it guarantees that
         * external consumer (like "react-hook-form") will receive always the same refs (like input ref).
         * Otherwise UI can be inconsistent / glitchy.
         */}
        <div className={styles.inputsWrapper}>
          <div
            className={clsx({
              [styles.invis]: activeDropdownId && !isColumnDirection,
            })}
          >
            <MultiStepControls isColumnDirection={isColumnDirection}>
              {props.inputs.map((input, idx) => {
                const inputKey = getInputKey(input);
                const active = props.alwaysActive
                  ? true
                  : !props.inputs[idx - 1] ||
                    Boolean(getValue(getInputKey(props.inputs[idx - 1])));

                let inputComp: JSX.Element;

                const {
                  ref: _ref,
                  items: _items,
                  actions = [],
                  position = '',
                  defaultValue: _defaultValue,
                  withUnclear = true,
                  controlType,
                  controlProps,
                  ...restProps
                } = input;

                const onClear =
                  withUnclear &&
                  (() => {
                    clear(inputKey);
                  });

                if (controlType) {
                  // TODO: types
                  inputComp = (
                    <input.controlType
                      {...restProps}
                      {...(controlProps as ControlProps)}
                      key={inputKey}
                      onChange={(e) => setValue(inputKey, e.target.value)}
                      value={getValue(inputKey)}
                      onClear={onClear}
                      disabled={disabled}
                    />
                  );
                } else {
                  inputComp = (
                    <LabeledSelectInput
                      {...restProps}
                      className={clsx({
                        [styles.textAreaWrapper]:
                          isColumnDirection &&
                          position === 'top' &&
                          getValue(inputKey),
                      })}
                      key={inputKey}
                      value={getValue(inputKey)}
                      onClick={() => {
                        setActiveDropdownId(inputKey);
                      }}
                      onClear={onClear}
                      actions={actions}
                      disabled={disabled}
                    />
                  );
                }

                const isDropdownActive = activeDropdownId === inputKey;

                return (
                  <Step
                    ref={isDropdownActive ? activeInputRef : undefined}
                    className={clsx({
                      [styles.invis]: isColumnDirection && isDropdownActive,
                    })}
                    active={active}
                    key={inputKey}
                    index={inputKey}
                  >
                    {inputComp}
                  </Step>
                );
              })}
            </MultiStepControls>
          </div>
          <Search
            activeInputRef={activeInputRef}
            inputValue={
              searchValueAsActiveInputValue
                ? getValue(activeDropdownId)
                : searchInputValue
            }
            active={Boolean(activeDropdownId)}
            onHideDropDown={onHideDropDown}
            onChange={onSearchChange}
            disabled={disabled}
            dropdown={{
              items: items,
              footer: props.searchFooter,
            }}
          />
        </div>
      </>
    );
  }
);
