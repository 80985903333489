import styles from './ContextMenu.module.scss';

import { Popover } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import React, { useState } from 'react';

export type IContextMenuProps = {
  className?: string;
  content: React.ReactNode;
  children:
    | React.ReactNode
    | ((props: IContextMenuChilldrenProps) => React.ReactElement<any>);
};

export type IContextMenuChilldrenProps = {
  onContextMenu: React.MouseEventHandler;
};

type IContextMenuPosition = {
  left: number;
  top: number;
};

/** CSS class you should apply to elements, clicking on which  should close the context menu. */
export const DISMISS_POPOVER = 'ContextMenuDismissTrigger';

export function ContextMenu({
  content,
  children,
  className,
}: IContextMenuProps) {
  const [position, setPosition] = useState<IContextMenuPosition | null>(null);

  const open = position !== null;

  const onContextMenu: React.MouseEventHandler = useEventCallback((event) => {
    if (event.defaultPrevented) return;

    event.preventDefault();

    const top = event.clientY;
    const left = event.clientX;

    setPosition({ top, left });
  });

  const onClick: React.MouseEventHandler = (event) => {
    // Check if clicking the target should close the context menu.
    // To close the context menu when an element is clicked
    // you should add the exported `DISMISS_POPOVER` class to it.
    const target = event.target as HTMLElement;
    if (target.closest(`.${DISMISS_POPOVER}`)) {
      setPosition(null);
    }
  };

  const onClose = () => {
    setPosition(null);
  };

  // Close the menu when right-clicking on a backdrop.
  const onBackdropClick: React.MouseEventHandler = (event) => {
    event.preventDefault();

    setPosition(null);
  };

  const preventDefault: React.MouseEventHandler = (event) => {
    event.preventDefault();
  };

  const target =
    typeof children === 'function' ? (
      children({ onContextMenu })
    ) : (
      <div className={className} onContextMenu={onContextMenu}>
        {children}
      </div>
    );

  return (
    <>
      {target}
      <Popover
        open={open}
        anchorReference="anchorPosition"
        onClose={onClose}
        anchorPosition={position}
        onClick={onClick}
        onContextMenu={onBackdropClick}
        classes={{ paper: styles.menuPaper }}
      >
        <div onContextMenu={preventDefault}>{content}</div>
      </Popover>
    </>
  );
}
