import styles from '../appointmentview/AppointmentView.module.scss';

import { Grid, Theme, Tooltip, useMediaQuery } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '@work4all/assets/icons/info.svg';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { BusinessPartnerPickerField } from '../../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../../components/entity-picker/ContactPickerField';
import { ContractDeliveryNotePickerField } from '../../../../../../../../../../components/entity-picker/ContractDeliveryNotePickerField';
import { ProjectPickerField } from '../../../../../../../../../../components/entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../../../../../../../../../components/entity-picker/ProjectProcessPickerField';
import { TopicPickerField } from '../../../../../../../../../../components/entity-picker/TopicPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInputWithUnit } from '../../../../../../../../locked-inputs';
import { TabPanelSectionProps } from '../../../../../../..';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { AppointmentMaskFormValue } from '../../../../../types';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

const HOURS_PER_DAY = 8;

export const Assignment: React.FC<TabPanelSectionProps> = () => {
  const { t } = useTranslation();
  const businessPartnerWrapperRef = useRef<HTMLDivElement>(null);
  const projectWrapperRef = useRef<HTMLDivElement>(null);
  const topicWrapperRef = useRef<HTMLDivElement>(null);
  const projectProcessWrapperRef = useRef<HTMLDivElement>(null);

  const { control, setValue, watch } =
    useFormContextPlus<AppointmentMaskFormValue>();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const businessPartnerType = watch('businessPartnerType');
  const businessPartnerId = watch('businessPartnerId');
  const bp = watch('businessPartner');
  const project = watch('project');
  const projectProcess = watch('projectProcess');
  const topicMarkList = watch('topicMarkList');
  const contract = watch('contract');
  const deliveryNote = watch('deliveryNote');

  const contractDeliveryNotePickerPrefilter = useMemo(() => {
    return [
      {
        'businessPartnerContactCombined.businessPartner.id': {
          $eq: businessPartnerId,
        },
      },
      {
        isClosed: {
          $eq: 0,
        },
      },
    ];
  }, [businessPartnerId]);

  return (
    <Collapse
      classes={collapseClasses}
      title={t('COMMON.ASSIGNMENT')}
      className={styles.collapse}
      defaultOpen={true}
    >
      <Card>
        <Grid container spacing="1rem">
          <Grid item xs={12} md={8}>
            <Grid container spacing="1rem">
              <Grid item xs={12} ref={businessPartnerWrapperRef}>
                <ControllerPlus
                  name="businessPartner.data"
                  control={control}
                  render={({ field: businessPartner }) => {
                    return (
                      <ControllerPlus
                        name="contact"
                        control={control}
                        render={({ field: contact }) => {
                          return (
                            <MultiStepControls isColumnDirection={isMobile}>
                              <Step active={true} index={0}>
                                <BusinessPartnerPickerField
                                  {...businessPartner}
                                  data={BUSINESS_PARTNER_PICKER_DATA}
                                  onChange={(value) => {
                                    businessPartner.onChange(
                                      value?.data || null
                                    );
                                    setValue('contract', null);
                                    setValue('deliveryNote', null);
                                  }}
                                />
                              </Step>
                              <Step
                                active={
                                  businessPartner.value &&
                                  !businessPartner.value.isPrivateCustomer
                                }
                                index={1}
                              >
                                {businessPartner.value && (
                                  <ContactPickerField
                                    {...contact}
                                    value={contact.value}
                                    error={contact?.error}
                                    businessPartnerType={businessPartnerType}
                                    businessPartnerId={businessPartner.value.id}
                                    businessPartner={bp}
                                    project={project}
                                    projectProcess={projectProcess}
                                    topicMarkList={topicMarkList?.[0]}
                                  />
                                )}
                              </Step>
                            </MultiStepControls>
                          );
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} ref={projectWrapperRef}>
                <ControllerPlus
                  name="project"
                  control={control}
                  render={({ field: project }) => {
                    return (
                      <MultiStepControls isColumnDirection={isMobile}>
                        <Step active={true} index={0}>
                          <ControlWrapper>
                            <ProjectPickerField
                              {...project}
                              data={PROJECT_DATA}
                              clearable
                            />
                          </ControlWrapper>
                        </Step>
                        <Step
                          active={project.value?.id !== undefined}
                          index={1}
                        >
                          <ControlWrapper ref={projectProcessWrapperRef}>
                            <ControllerPlus
                              name="projectProcess"
                              control={control}
                              render={({ field: projectProcess }) => {
                                return (
                                  <ProjectProcessPickerField
                                    {...projectProcess}
                                    projectId={project?.value?.id}
                                  />
                                );
                              }}
                            />
                          </ControlWrapper>
                        </Step>
                      </MultiStepControls>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing="1rem">
              <Grid item xs={6} md={12} ref={topicWrapperRef}>
                <ControllerPlus
                  name="topicMarkList"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TopicPickerField
                        type={Entities.appointment}
                        {...field}
                        error={fieldState.error?.message}
                        value={field.value ? field.value[0] : null}
                        onChange={(val) => {
                          field.onChange(val ? [val] : null);
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} md={12}>
                <Controller
                  name="projectProcessDuration"
                  control={control}
                  render={({ field: duration }) => {
                    return (
                      <LabeledInputWithUnit
                        label={t('INPUTS.TIME_ESTIMATION')}
                        data-test-id="time-estimate-picker-field"
                        placeholder={'0,00'}
                        unit={'h'}
                        type="Number"
                        inputProps={{ step: '1', min: '0' }}
                        value={duration?.value * HOURS_PER_DAY || 0}
                        onChange={(event) => {
                          duration.onChange(
                            parseFloat(event.target.value) / HOURS_PER_DAY
                          );
                        }}
                        iconRight={
                          <Tooltip
                            title={t('MASK.USED_FOR_RESSOURCE_PLANNING')}
                            arrow
                          >
                            <InfoIcon className={styles.infoIcon} />
                          </Tooltip>
                        }
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {!!businessPartnerId && (
            <Grid item xs={12}>
              <MultiStepControls>
                <Step index={0} active={!!businessPartnerId}>
                  <ContractDeliveryNotePickerField
                    onChange={(value, entity) => {
                      setValue(
                        Entities.contract === entity
                          ? 'contract'
                          : 'deliveryNote',
                        value
                      );
                    }}
                    value={contract ?? deliveryNote}
                    prefilter={contractDeliveryNotePickerPrefilter}
                  />
                </Step>
              </MultiStepControls>
            </Grid>
          )}
        </Grid>
      </Card>
    </Collapse>
  );
};

const BUSINESS_PARTNER_PICKER_DATA: Customer | Supplier = {
  id: null,
  mainContactId: null,
  contactList: [
    {
      id: null,
      displayName: null,
      role: null,
      layedOff: null,
      salutation: {
        id: null,
        isMale: null,
        isFemale: null,
      },
    },
  ],
};
