import env from '../env';
import type { Environment } from '../environment.types';

export function resolveReplacedConfiguration(
  fallbackConfiguration: Environment['configuration']
): Environment['configuration'] {
  const configuration = env.configuration;
  // Use `startsWith()` instead of `!==` so that this code path is not removed
  // by webpack during optimization.
  if (!configuration || configuration.startsWith('__INJECTED')) {
    const errorMessage = [
      `The configuration name was not injected.`,
      `Falling back to "${fallbackConfiguration}".`,
    ].join(' ');

    console.error(errorMessage);

    return fallbackConfiguration;
  }

  const existingConfig = ConfigurationMapper[configuration];
  if (!existingConfig)
    throw new Error(
      'Injected configuration is unknown please update ConfigurationMapper.'
    );

  return existingConfig;
}

const ConfigurationMapper: Record<string, Environment['configuration']> = {
  preview: 'preview',
  development: 'development',
  staging: 'staging',
  production: 'production',
  // As it is injected on Docker level
  dev: 'development',
  prod: 'production',
};
