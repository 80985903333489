import { useEffect, useState } from 'react';

const getPreviousPatchVersion = (ver: string) => {
  const parts = ver.split('.').map(Number);
  if (parts.length === 3 && parts[2] > 0) {
    parts[2] -= 1;
    return parts.join('.');
  }
  return null;
};

export const useReleaseNotesFile = () => {
  const initialVersion = process.env.APP_VERSION ?? '';
  const [releaseNotesFile, setReleaseNotesFile] = useState(null);
  const [loadedVersion, setLoadedVersion] = useState(initialVersion);

  useEffect(() => {
    const loadMarkdown = async (currentVersion) => {
      while (currentVersion) {
        try {
          const { default: markdown } = await import(
            `../../release-notes/v${currentVersion}.md`
          );
          setReleaseNotesFile(markdown);
          setLoadedVersion(currentVersion);
          return;
        } catch (error) {
          currentVersion = getPreviousPatchVersion(currentVersion);
        }
      }
    };

    if (initialVersion) {
      loadMarkdown(initialVersion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { releaseNotesFile, version: loadedVersion };
};
