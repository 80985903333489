import i18next, { PostProcessorModule } from 'i18next';

const v4Fallback: PostProcessorModule = {
  type: 'postProcessor',
  name: 'v4Fallback',
  process(
    value: string,
    key: string | string[],
    options: Record<string, unknown> = {}
  ) {
    const keys = Array.isArray(key) ? key : [key];
    const lang = i18next.language || 'de';

    // Manually looks up the key - I cannot call translation function as
    // it might trigger the post-processor again, creating an infinite loop.
    const translations = i18next.store?.data?.[lang]?.translation || {};

    // Apply interpolation if exists
    const getInterpolatedValue = (translation: string) =>
      i18next.services.interpolator.interpolate(
        translation,
        options,
        lang,
        i18next.options.interpolation
      );

    for (const keyStr of keys) {
      // Process if the key already includes a recognized suffix, use it directly
      // (Warning: need to cover more suffixes when application starts to handle
      // e.g. different languages with multiple plurals)
      if (keyStr.match(/_(one|other)$/)) {
        if (translations[keyStr]) {
          return getInterpolatedValue(translations[keyStr]);
        }
      }

      // Process keys that still end with '_plural'
      if (keyStr.endsWith('_plural')) {
        const otherKey = keyStr.replace('_plural', '_other');

        // Return plural key if exists or fallback to '_other' key
        const resolvedValue = translations[keyStr] || translations[otherKey];

        if (resolvedValue) {
          return getInterpolatedValue(resolvedValue);
        }
      }

      // Process when no 'count' is provided - fallback to the singular key with _one suffix
      if (options.count === undefined) {
        const oneKey = `${keyStr}_one`;
        if (translations[oneKey]) {
          return getInterpolatedValue(translations[oneKey]);
        }
      }

      // Process when a 'count' is provided - use _one if count is 1 or _other otherwise
      if (options.count !== undefined) {
        const suffix = options.count === 1 ? '_one' : '_other';
        const pluralKey = `${keyStr}${suffix}`;
        if (translations[pluralKey]) {
          return getInterpolatedValue(translations[pluralKey]);
        }
      }
    }

    return value;
  },
};

export default v4Fallback;
