import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '@work4all/assets/icons/outline-add-24.svg';

import { SplitButton } from '@work4all/components/lib/components/split-button/SplitButton';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import {
  usePositionTableActions,
  UsePositionTableActionsOptions,
} from '../hooks/use-position-table-actions';

type NewPostitionButtonProps = UsePositionTableActionsOptions;

export const NewPostitionButton = (props: NewPostitionButtonProps) => {
  const { disabled } = props;
  const { t } = useTranslation();

  const actions = usePositionTableActions(props);
  const options = useMemo(() => {
    const first = actions[0];
    return [
      {
        id: first.actionKey,
        name: first.title,
        icon: <AddIcon />,
        onClick: first.onClick,
      },
      ...actions.map((action) => ({
        id: action.actionKey,
        name: action.title,
        icon: <action.IconComponent style={{ color: 'var(--text03)' }} />,
        onClick: action.onClick,
        group: [
          ErpPositionsKind.STANDARD,
          ErpPositionsKind.TEXTZEILE as string,
        ].includes(action.actionKey)
          ? ''
          : [
              ErpPositionsKind.TITEL,
              ErpPositionsKind.TITELSUMME as string,
            ].includes(action.actionKey)
          ? t('COMMON.FUNCTIONS')
          : t('COMMON.ERP.MISCELLANEOUS'),
      })),
    ];
  }, [actions, t]);
  return (
    <SplitButton
      disabled={disabled}
      hideGroupLabels
      hideSelection
      options={options}
      firstIsTitle
      onClick={(option, event) => {
        option.onClick(event);
      }}
      variant="outlined"
      renderItem={(option) => {
        return (
          <Box display="flex" gap="0.75rem" alignItems="center">
            {option.icon}
            {option.name}
          </Box>
        );
      }}
    />
  );
};
