import { useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

export type UseResizeObserverCallback = (
  entry: ResizeObserverEntry,
  observer: ResizeObserver
) => void;

export function useResizeObserver<T extends HTMLElement>(
  target: React.RefObject<T | null> | T | null,
  callback: UseResizeObserverCallback
): void {
  const resizeObserver = getResizeObserver();
  const storedCallback = useLatest(callback);

  useLayoutEffect(() => {
    const targetEl = target
      ? 'current' in target
        ? target.current
        : target
      : null;

    if (!targetEl) return;

    function callback(entry: ResizeObserverEntry, observer: ResizeObserver) {
      storedCallback.current(entry, observer);
    }

    resizeObserver.subscribe(targetEl, callback);

    return () => {
      resizeObserver.unsubscribe(targetEl, callback);
    };
  }, [target, resizeObserver, storedCallback]);
}

let resizeObserver: ReturnType<typeof createResizeObserver>;

function getResizeObserver() {
  if (!resizeObserver) {
    resizeObserver = createResizeObserver();
  }
  return resizeObserver;
}

function createResizeObserver() {
  const callbacks = new Map<HTMLElement, UseResizeObserverCallback>();

  const observer = new ResizeObserver(
    (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      for (const entry of entries) {
        const callback = callbacks.get(entry.target as HTMLElement);
        callback?.(entry, observer);
      }
    }
  );

  return {
    observer,
    subscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      observer.observe(target);
      callbacks.set(target, callback);
    },
    unsubscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      observer.unobserve(target);
      callbacks.delete(target);
    },
  };
}
