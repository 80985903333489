import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Theme, useMediaQuery } from '@mui/material';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Menu, { menuClasses } from '@mui/material/Menu';
import { menuItemClasses } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import React, { type JSX } from 'react';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

const CustomizedMenu = styled(Menu)({
  [`& .${menuClasses.paper}`]: {
    minWidth: 160,
  },
  [`& .${menuItemClasses.root}`]: {
    paddingTop: 12,
    paddingRight: 16,
    paddingBottom: 12,
    paddingLeft: 16,
  },
});

interface Props {
  children: JSX.Element | JSX.Element[];
  forceDesktop?: boolean;
}
export const MaskOverlayMenuWrapper: React.FC<Props> = (props: Props) => {
  const { children, forceDesktop = false } = props;

  const isSmUp = useMediaQuery<Theme>((t) => t.breakpoints.up('sm'));
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const popoverPlacement = isSmUp ? 'bottom' : 'top';
  const { handleClick, ...popoverState } = usePopoverState(popoverPlacement);

  const handleMenuClick: React.MouseEventHandler = (event) => {
    // Only close the popover if the clicked element is not the list itself.
    // This way clicking a disabled list item will not close the menu.
    if (event.target !== event.currentTarget) {
      popoverState.onClose();
    }
  };

  return (
    <>
      {isDesktop || forceDesktop ? (
        <IconButton
          size="large"
          data-test-id={'more-icon-button'}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Fab
          size="medium"
          data-test-id={'more-icon-button'}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </Fab>
      )}

      <CustomizedMenu
        {...popoverState}
        MenuListProps={{
          disablePadding: true,
          onClick: handleMenuClick,
        }}
      >
        {children}
      </CustomizedMenu>
    </>
  );
};
