import { RefObject, useCallback } from 'react';
import { TableInstance } from 'react-table';

import { useColumnVisibilityManager } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../../../../settings';

type Props = {
  tableInstanceRef: RefObject<TableInstance> | undefined;
};

export const useCheckListPositionsColumnHelpers = ({
  tableInstanceRef,
}: Props) => {
  const columnSettings = useSetting(
    settings.crmPositionsConfig(Entities.checkList)
  );

  const handleVisibilityChange = useCallback(
    (columns: string[]) => {
      columnSettings.set({
        ...columnSettings.value,
        order: columns,
        visibility: columns,
      });
    },
    [columnSettings]
  );

  const columnVisibilityManager = useColumnVisibilityManager({
    tableInstanceRef,
    onVisibilityChange: handleVisibilityChange,
  });

  return { columnSettings, columnVisibilityManager };
};
