import styles from './FilterCardList.module.scss';

import { useEventCallback } from '@mui/material/utils';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnInstance,
  FilterType,
  usePrepareFilters,
  useTableState,
} from '@work4all/components';
import { IFilterPickerConfig } from '@work4all/components/lib/components/entity-picker/components/list-entity-picker';
import { AppointmentStateFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/appointment-status-filter-card/AppointmentStatusFilterCard';
import { ArticleKindFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/article-kind-filter-kind/ArticleKindFilterCard';
import { BooleanFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/boolean-filter-card/BooleanFilterCard';
import { BusinessPartnerFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/business-partner-filter-card/BusinessPartnerFilterCard';
import { BusinessPartnerGroupFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/business-partner-group-filter-card/BusinessPartnerGroupFilterCard';
import { ClosedStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/ClosedStatusFilterCard/ClosedStatusFilterCard';
import { ContactFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/contact-filter-card/ContactFilterCard';
import { CostCenterFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/cost-center-filter-card/CostCenterFilterCard';
import { CustomerFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/customer-filter-card/CustomerFilterCard';
import { DateFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/date-filter-card/DateFilterCard';
import { getTimeSpanUnit } from '@work4all/components/lib/dataDisplay/basic-table/components/date-range-picker/DateRangePicker';
import { DueDateClassifiedFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/due-date-classified-filter-card/DueDateClassifiedFilterCard';
import { EInvoiceFormatFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/e-invoice-format-filter-card/EInvoiceFormatFilterCard';
import { EmailKindFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/email-kind-filter-card/EmailKindFilterCard';
import {
  ArticleGroupFilterCard,
  CustomerGroupFilterCard,
  ProjectGroupFilterCard,
  SupplierGroupFilterCard,
} from '@work4all/components/lib/dataDisplay/basic-table/components/entity-group-filter-card/EntityGroupFilterCard';
import { InvoiceFormFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/invoice-form-filter-card/InvoiceFormFilterCard';
import { InvoiceKindFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/invoice-kind-filter-card/InvoiceKindFilterCard';
import { LookUpFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/look-up-filter-card/LookUpFilterCard';
import { MailAssignStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/MailAssignStatusFilterCard/MailAssignStatusFilterCard';
import { NumberFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/number-filter-card/NumberFilterCard';
import { PaymentStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/paymnet-status-filter-card/PaymentStatusFilterCard';
import { PermitStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/permit-status-filter-card/PermitStatusFilterCard';
import { ProjectFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/project-filter-card/ProjectFilterCard';
import { RaAccountingStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/ra-accounting-status-filter-card/RaAccountingStatusFilterCard';
import { ReAccountingStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/re-accounting-status-filter-card/ReAccountingStatusFilterCard';
import { SalesOpportunitiesStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/sales-opportunities-status-filter-card/SalesOpportunitiesStatusFilterCard';
import { SearchFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/search-filter-card/SearchFilterCard';
import { SupplierFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/supplier-filter-card/SupplierFilterCard';
import { TaskStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/task-status-filter-card/TaskStatusFilterCard';
import { TicketCategory1FilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/ticket-category-1-filter-card/TicketCategory1FilterCard';
import { TicketKindFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/ticket-kind-filter-card/TicketKindFilterCard';
import { TicketStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/ticket-status-filter-card/TicketStatusFilterCard';
import { TravelReceiptsStatusCard } from '@work4all/components/lib/dataDisplay/basic-table/components/travel-receipts-status-card/TravelReceiptsStatusCard';
import { UserFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/user-filter-card/UserFilterCard';
import { VacationKindFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/vacation-kind-filter-card/VacationKindFilterCard';
import { VacationStatusFilterCard } from '@work4all/components/lib/dataDisplay/basic-table/components/vacation-status-filter-card/VacationStatusFilterCard';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListPageContext } from '../../../../../../../../../../containers/file-entities-lists/list-page-context';
import { settings, useSetting } from '../../../../../../../../../../settings';
import { getFilterConfig } from '../../../../../../../TableFilter';
import { FilterOverlayContext } from '../../FiltersOverlay';

export interface IFilterCardListProps {
  cols: ColumnInstance[];
}

function getEntityFilterCardComponent(entity: Entities | string) {
  switch (entity) {
    case Entities.user:
      return UserFilterCard;
    case Entities.project:
      return ProjectFilterCard;
    case Entities.costCenter:
      return CostCenterFilterCard;
    case Entities.lookUp:
      return LookUpFilterCard;
    case Entities.customer:
      return CustomerFilterCard;
    case Entities.supplier:
      return SupplierFilterCard;
    case Entities.ticketKind:
      return TicketKindFilterCard;
    case Entities.supplierGroup:
      return SupplierGroupFilterCard;
    case Entities.customerGroup:
      return CustomerGroupFilterCard;
    case Entities.articleGroup:
      return ArticleGroupFilterCard;
    case Entities.projectGroup:
      return ProjectGroupFilterCard;
    case Entities.businessPartner:
      return BusinessPartnerFilterCard;
    case Entities.contact:
      return ContactFilterCard;
    case Entities.group:
      return BusinessPartnerGroupFilterCard;
    case Entities.ticketCategory1:
      return TicketCategory1FilterCard;
    case 'mailAssignStatus':
      return MailAssignStatusFilterCard;
    default:
      return null;
  }
}

const warnedAboutPickerEntityTypeUsage = new Set<string>();
let warnedAboutSingleOptionUsage = false;

export const FilterCardList: React.FC<IFilterCardListProps> = (props) => {
  const { cols } = props;

  const { t } = useTranslation();

  const { filterCardExpandStates, listEntityType, setFilterCardExpandStates } =
    useContext(FilterOverlayContext);

  const listPageContext = useContext(ListPageContext);
  const entityType = listEntityType ?? listPageContext?.entityType ?? null;

  const closedEntitiesSetting = useSetting(
    settings.hideClosedEntities({ entityType })
  );

  const setHideClosedEntitiesSetting = useEventCallback((value: boolean) => {
    closedEntitiesSetting.set(value);
  });

  // TODO This double the work already done in the top-level table component.
  // We parse the filter from the table state here again to use in in pickers
  // to enable "smart filtering". This work is now done twice. Once here and
  // once in the table component. Probably not a big deal, but it can be
  // refactored to avoid recomputing the same value here again.
  const tableState = useTableState();
  const { filter } = usePrepareFilters(tableState?.filters, tableState?.sortBy);

  function renderFixedDataFilterCard(
    filterType: FilterType,
    label: string,
    col: ColumnInstance
  ) {
    let FilterCard = null;

    switch (filterType) {
      case FilterType.TravelReceiptStatus:
        FilterCard = TravelReceiptsStatusCard;
        break;
      case FilterType.TicketStatus:
        FilterCard = TicketStatusFilterCard;
        break;
      case FilterType.AppointmentState:
        FilterCard = AppointmentStateFilterCard;
        break;
      case FilterType.EmailKind:
        FilterCard = EmailKindFilterCard;
        break;
      case FilterType.VacationKind:
        FilterCard = VacationKindFilterCard;
        break;
      case FilterType.EInvoiceFormat:
        FilterCard = EInvoiceFormatFilterCard;
        break;
      case FilterType.TaskStatus:
        FilterCard = TaskStatusFilterCard;
        break;
      case FilterType.SalesOpportunitiesStatusPicker:
        FilterCard = SalesOpportunitiesStatusFilterCard;
        break;
      case FilterType.PaymentStatus:
        FilterCard = PaymentStatusFilterCard;
        break;
      case FilterType.InvoiceForm:
        FilterCard = InvoiceFormFilterCard;
        break;
      case FilterType.ReAccountingStatus:
        FilterCard = ReAccountingStatusFilterCard;
        break;
      case FilterType.RaAccountingStatus:
        FilterCard = RaAccountingStatusFilterCard;
        break;
      case FilterType.PermitStatus:
        FilterCard = PermitStatusFilterCard;
        break;
      case FilterType.VacationStatus:
        FilterCard = VacationStatusFilterCard;
        break;
      case FilterType.InvoiceKind:
        FilterCard = InvoiceKindFilterCard;
        break;
      case FilterType.DueDateClassified:
        FilterCard = DueDateClassifiedFilterCard;
        break;
      case FilterType.ArticleKind:
        FilterCard = ArticleKindFilterCard;
        break;
      default:
        break;
    }
    return !FilterCard ? null : (
      <FilterCard
        onExpandedChange={(val) => {
          setFilterCardExpandStates({
            ...filterCardExpandStates,
            [label]: val,
          });
        }}
        expanded={filterCardExpandStates[label]}
        value={col.filterValue?.value ?? ''}
        onChange={(value) => {
          if (value === null || (Array.isArray(value) && value.length === 0)) {
            col.setFilter(null);
          } else {
            col.setFilter({
              value: value,
              filterType: col.filterType,
            });
          }
        }}
        key={label}
        title={label}
      />
    );
  }

  function renderPickerFilter(col: ColumnInstance) {
    const label = col.Header as string;
    const filterParams = col.filterParams;

    if (!filterParams) {
      console.warn(
        '`filterParams` object must be present in the column config when using Picker filter type.'
      );

      return null;
    }

    const { entity, multiple } = filterParams;

    if (
      multiple !== undefined &&
      multiple !== true &&
      !warnedAboutSingleOptionUsage
    ) {
      warnedAboutSingleOptionUsage = true;
      console.warn(
        `Found option \`multiple=${multiple}\`, but Picker filter cards currently only support multiple mode.`
      );
    }

    const EntityFilterCardComponent = getEntityFilterCardComponent(entity);

    if (!EntityFilterCardComponent) {
      if (!warnedAboutPickerEntityTypeUsage.has(entity)) {
        warnedAboutPickerEntityTypeUsage.add(entity);
        console.warn(
          `Picker filter for entity type "${entity}" is not configured.` +
            ` If this is a new filter, add it to the <FilterCardList> component.`
        );
      }

      return null;
    }

    const filterPickerConfig: IFilterPickerConfig | null =
      listPageContext === null
        ? null
        : {
            parentEntityType: entityType,
            field: col.id,
            filter,
          };

    return (
      <EntityFilterCardComponent
        lookupType={
          entity === Entities.lookUp ? col?.filterParams?.lookupType : undefined
        }
        key={label}
        filterConfig={getFilterConfig(filterPickerConfig)}
        title={label}
        value={col.filterValue?.value ?? null}
        onChange={(value) => {
          if (value.length === 0) {
            col.setFilter(null);
          } else {
            col.setFilter({
              value: value,
              filterType: col.filterType,
            });
          }
        }}
        expanded={filterCardExpandStates[label]}
        onExpandedChange={(val) => {
          setFilterCardExpandStates({
            ...filterCardExpandStates,
            [label]: val,
          });
        }}
      />
    );
  }

  return (
    <div className={styles.filterCardsWrap}>
      {cols?.map((col) => {
        const label = col.Header as string;
        switch (col.filterType) {
          case FilterType.Date:
            return (
              <DateFilterCard
                key={label}
                title={label}
                expanded={
                  filterCardExpandStates[col.Header as string] !== undefined &&
                  filterCardExpandStates[col.Header as string]
                }
                onExpandedChange={(val) => {
                  setFilterCardExpandStates({
                    ...filterCardExpandStates,
                    [col.Header as string]: val,
                  });
                }}
                startDate={
                  col.filterValue?.value?.startDate
                    ? col.filterValue.value.startDate
                    : null
                }
                endDate={
                  col.filterValue?.value?.endDate
                    ? col.filterValue.value.endDate
                    : null
                }
                onDelete={() => {
                  col.setFilter(null);
                }}
                onChange={(startDate, endDate) => {
                  if (startDate === null && endDate === null) {
                    col.setFilter(null);
                  }
                  col.setFilter({
                    value: {
                      startDate: startDate ? startDate : undefined,
                      endDate: endDate ? endDate : undefined,
                      span: getTimeSpanUnit(startDate, endDate),
                    },
                    filterType: col.filterType,
                  });
                  return;
                }}
                {...col.filterParams}
              />
            );
          case FilterType.Number:
            return (
              <NumberFilterCard
                key={label}
                title={label}
                expanded={
                  filterCardExpandStates[col.Header as string] !== undefined &&
                  filterCardExpandStates[col.Header as string]
                }
                onExpandedChange={(val) => {
                  setFilterCardExpandStates({
                    ...filterCardExpandStates,
                    [col.Header as string]: val,
                  });
                }}
                from={
                  col.filterValue?.value?.from
                    ? col.filterValue.value.from
                    : null
                }
                to={
                  col.filterValue?.value?.to ? col.filterValue.value.to : null
                }
                onDelete={() => {
                  col.setFilter(null);
                }}
                onChange={(from, to) => {
                  if (from === null && to === null) {
                    col.setFilter(null);
                  }
                  col.setFilter({
                    value: {
                      from: from !== null ? from : undefined,
                      to: to !== null ? to : undefined,
                    },
                    filterType: col.filterType,
                  });
                  return;
                }}
              />
            );
          case FilterType.ClosedStatus:
            return (
              <ClosedStatusFilterCard
                trueText={t(
                  `${entityType?.toUpperCase()}.CLOSED_STATUS.HIDE_CLOSED`
                )}
                falseText={t(
                  `${entityType?.toUpperCase()}.CLOSED_STATUS.SHOW_ALL`
                )}
                onExpandedChange={(val) => {
                  setFilterCardExpandStates({
                    ...filterCardExpandStates,
                    [label]: val,
                  });
                }}
                expanded={filterCardExpandStates[label]}
                value={col.filterValue?.value ?? false}
                onChange={(value) => {
                  col.setFilter({
                    value,
                    filterType: FilterType.ClosedStatus,
                  });

                  setHideClosedEntitiesSetting(value);
                }}
                key={label}
                title={label}
              />
            );
          case FilterType.BooleanNumber:
          case FilterType.Boolean:
            return (
              <BooleanFilterCard
                onExpandedChange={(val) => {
                  setFilterCardExpandStates({
                    ...filterCardExpandStates,
                    [label]: val,
                  });
                }}
                expanded={filterCardExpandStates[label]}
                value={col.filterValue?.value ?? ''}
                onChange={(value) => {
                  if (
                    value === null ||
                    (Array.isArray(value) && value.length === 0)
                  ) {
                    col.setFilter(null);
                  } else {
                    col.setFilter({
                      value: value,
                      filterType: col.filterType,
                    });
                  }
                }}
                key={label}
                title={label}
              />
            );
          case FilterType.Search:
            return (
              <SearchFilterCard
                key={label}
                title={label}
                onExpandedChange={(val) => {
                  setFilterCardExpandStates({
                    ...filterCardExpandStates,
                    [label]: val,
                  });
                }}
                expanded={filterCardExpandStates[label]}
                onDelete={() => {
                  col.setFilter(null);
                }}
                value={col.filterValue?.value ?? ''}
                onChange={(value) => {
                  const normalizedValue =
                    value.trim().length > 0 ? value : null;

                  // If the actual search string didn't change, don't update the
                  // filter to avoid unnesessary re-renders.
                  if (
                    (col.filterValue == null && normalizedValue === null) ||
                    col.filterValue?.value === normalizedValue
                  ) {
                    return;
                  }

                  col.setFilter(
                    normalizedValue !== null
                      ? {
                          value,
                          filterType: col.filterType,
                        }
                      : null
                  );
                }}
              />
            );

          case FilterType.Picker:
            return renderPickerFilter(col);
          case FilterType.EmailKind:
          case FilterType.VacationKind:
          case FilterType.EInvoiceFormat:
          case FilterType.TicketStatus:
          case FilterType.AppointmentState:
          case FilterType.TaskStatus:
          case FilterType.TravelReceiptStatus:
          case FilterType.SalesOpportunitiesStatusPicker:
          case FilterType.PaymentStatus:
          case FilterType.InvoiceForm:
          case FilterType.ReAccountingStatus:
          case FilterType.RaAccountingStatus:
          case FilterType.PermitStatus:
          case FilterType.VacationStatus:
          case FilterType.InvoiceKind:
          case FilterType.DueDateClassified:
          case FilterType.ArticleKind:
            return renderFixedDataFilterCard(col.filterType, label, col);
          default:
            return null;
        }
      })}
    </div>
  );
};
