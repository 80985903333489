/* eslint-disable @typescript-eslint/no-explicit-any */

import { z } from 'zod';

import { ReportType } from '@work4all/components/lib/components/entity-picker/report-type-picker/ReportTypePicker';
import { TableLayout } from '@work4all/components/lib/components/table';
import { TIME_UNIT_TYPES } from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import { LayoutResponsiveDefinition } from '@work4all/components/lib/utils/react-grid-layout/react-grid-layout-models';

import { fileViewModeZod } from '@work4all/data/lib/hooks/useWidgetsBag';
import { UseSettingOptions } from '@work4all/data/lib/settings';

import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';
import { ReceiptKindInternal } from '@work4all/models/lib/Enums/ReceiptKindInternal.enum';
import { FileType } from '@work4all/models/lib/File';

import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';
import { Breakpoint } from '@work4all/utils/lib/variables';

import {
  TableLayoutDefaultValue,
  tableLayoutDefaultValueZod,
} from '../components/data-tables/table-layout/table-layout-default-value';
import { ORGANISATION_AREA_SECTIONS } from '../components/organisation-area/constants';
import {
  AppointmentCalendarLastParams,
  AppointmentCalendarView,
  CalendarCardColorBy,
  CalendarUserAppearance,
} from '../containers/calendar/types';
import { DEFAULT_HOME_WIDGET_LAYOUTS } from '../containers/home/components/home-widgets/layouts';
import { DEFAULT_HOME_WIDGETS } from '../containers/home/components/home-widgets/widgets';
import {
  IFavoriteLinkOrFolderArray,
  IFavoriteLinkOrFolderArraySchema,
} from '../containers/more/data/favorite-links';
import { ProjectTimeDisplayMode } from '../containers/time-tracker/ProjectTimeDisplayMode';
import { TimeTrackerMenuTabs } from '../containers/time-tracker/TimeTrackerMenuTabs';

import {
  SavedTableConfig,
  savedTableConfigZod,
  StandardEmailTemplates,
  TimeTrackSettings,
  timeTrackSettingsZod,
  TimeTrackTemplateList,
  TimeTrackTemplateListZod,
} from './validation';
import {
  AppointmentCalendarViewArraySchema,
  NullableAppointmentCalendarLastParamsSchema,
} from './validation/AppointmentCalendarLastParamsSchema';
import {
  EMailSignatureSchema,
  EMailSignatureZod,
} from './validation/EMailSignatureSchema';
import { HomeWidget, HomeWidgetLayoutsSchema } from './validation/HomeWidget';
import { WordLetterTemplateSchema } from './validation/WordLetterTemplateSchema';

const EMPTY_ARRAY = [];
const defaultProjectPlanningColumns = {
  actual: true,
  planned: true,
  rest: true,
  target: true,
};

// Entities enum as zod enum
const EntitiesZod = z.nativeEnum(Entities);

// Re-export all shared settings.
export * from '@work4all/data/lib/settings/settings';

// Configure keys for all app setting here.

/** Table configuration (columns order, size, visibility, etc.) */
export function tableConfig(options: {
  entityType: string;
  layout: TableLayout;
}): UseSettingOptions<SavedTableConfig> {
  const { entityType, layout } = options;

  return {
    name: `list.${entityType}.${layout}`,
    scope: 'tenant',
    defaultValue: null,
    parseFn: savedTableConfigZod.parse as any,
  };
}

const widthSizeZod = z.object({ width: z.number() });
type WidthSize = DeepRequired<z.infer<typeof widthSizeZod>>;

const heightSizeZod = z.object({ height: z.number() });
type HeightSize = DeepRequired<z.infer<typeof heightSizeZod>>;

const DEFAULT_LIST_PREVIEW_SIZE: WidthSize = { width: 320 };

/** Preview size on list pages. */
export function listPreviewSize(): UseSettingOptions<WidthSize> {
  return {
    name: 'list.previewSize',
    scope: 'user',
    defaultValue: DEFAULT_LIST_PREVIEW_SIZE,
    parseFn: widthSizeZod.parse as any,
  };
}

const erpArticleSizeZod = z.object({
  height: z.number(),
});
type ErpArticleSize = DeepRequired<z.infer<typeof erpArticleSizeZod>>;
const ERP_ARTICLE_SIZE: ErpArticleSize = { height: 420 };

/** Size of article component in erp positions. */
export function erpArticleSize(): UseSettingOptions<ErpArticleSize> {
  return {
    name: 'list.erpArticleSize',
    scope: 'user',
    defaultValue: ERP_ARTICLE_SIZE,
    parseFn: erpArticleSizeZod.parse as any,
  };
}

const erpShowArticleZod = z.boolean();
type ErpShowArticle = DeepRequired<z.infer<typeof erpShowArticleZod>>;

/** Size of article component in erp positions. */
export function erpShowArticle(): UseSettingOptions<ErpShowArticle> {
  return {
    name: 'list.erpShowArticle',
    scope: 'user',
    defaultValue: false,
    parseFn: erpShowArticleZod.parse,
  };
}

const DEFAULT_FILE_PREVIEW_SIZE: WidthSize = { width: 320 };

/** Left Area size of list */
export function leftAreaSize(): UseSettingOptions<WidthSize> {
  return {
    name: 'list.leftAreaSize',
    scope: 'user',
    defaultValue: DEFAULT_FILE_PREVIEW_SIZE,
    parseFn: widthSizeZod.parse as any,
  };
}

const DEFAULT_LIST_BOTTOM_SIZE: HeightSize = { height: 320 };
/** Bottom Area size of list */
export function bottomAreaSize(props: {
  entityType: Entities;
}): UseSettingOptions<HeightSize> {
  return {
    name: `list.${props.entityType}.bottomAreaSize`,
    scope: 'user',
    defaultValue: DEFAULT_LIST_BOTTOM_SIZE,
    parseFn: heightSizeZod.parse as any,
  };
}

const DEFAULT_LIST_CENTER_SIZE: WidthSize = { width: 720 };
/** Bottom Area size of list */
export function centerAreaSize(props: {
  entityType: Entities;
}): UseSettingOptions<WidthSize> {
  return {
    name: `list.${props.entityType}.centerAreaSize`,
    scope: 'user',
    defaultValue: DEFAULT_LIST_CENTER_SIZE,
    parseFn: widthSizeZod.parse as any,
  };
}

const DEFAULT_POSITION_MASK_AREA_SIZE: WidthSize = { width: 600 };
export function positionMaskAreaSize(): UseSettingOptions<WidthSize> {
  return {
    name: 'erp.positionMaskAreaSize',
    scope: 'user',
    defaultValue: DEFAULT_POSITION_MASK_AREA_SIZE,
    parseFn: widthSizeZod.parse as any,
  };
}

/** Preview size on list pages. */
export function filePreviewSize(options: {
  fileType: string;
}): UseSettingOptions<WidthSize> {
  const { fileType } = options;

  return {
    name: `file.${fileType}.previewSize`,
    scope: 'user',
    defaultValue: DEFAULT_FILE_PREVIEW_SIZE,
    parseFn: widthSizeZod.parse as any,
  };
}

const DEFAULT_FAVORITE_LINKS: IFavoriteLinkOrFolderArray = [];
/** Favorite links. */
export function favoriteLinks(): UseSettingOptions<IFavoriteLinkOrFolderArray> {
  return {
    name: 'favoriteLinks',
    scope: 'user',
    defaultValue: DEFAULT_FAVORITE_LINKS,
    parseFn: IFavoriteLinkOrFolderArraySchema.parse,
  };
}

const collapsedWidgetsZod = z.record(z.string(), z.boolean());
type CollapsedWidgets = DeepRequired<z.infer<typeof collapsedWidgetsZod>>;
const DEFAULT_COLLAPSED_WIDGETS: CollapsedWidgets = {};

/** Collapsed widgets state. */
export function collapsedWidgets(options: {
  fileType: FileType;
}): UseSettingOptions<CollapsedWidgets> {
  const { fileType } = options;

  return {
    name: `file.${fileType}.collapsedWidgets`,
    scope: 'user',
    defaultValue: DEFAULT_COLLAPSED_WIDGETS,
    parseFn: collapsedWidgetsZod.parse,
  };
}

const hiddenWidgetsZod = z.record(z.string(), z.boolean());
type HiddenWidgets = DeepRequired<z.infer<typeof hiddenWidgetsZod>>;
const DEFAULT_HIDDEN_WIDGETS: HiddenWidgets = {};

/** Hidden widgets state. */
export function hiddenWidgets(options: {
  fileType: FileType;
}): UseSettingOptions<HiddenWidgets> {
  const { fileType } = options;

  return {
    name: `file.${fileType}.hiddenWidgets`,
    scope: 'user',
    defaultValue: DEFAULT_HIDDEN_WIDGETS,
    parseFn: hiddenWidgetsZod.parse,
  };
}

/** Collapsed widget group state. */
const collapsedWidgetGroupZod = z.boolean();
type CollapsedWidgetGroup = DeepRequired<
  z.infer<typeof collapsedWidgetGroupZod>
>;
export function collapsedWidgetGroup(options: {
  fileType: FileType;
  id: string;
  defaultValue: boolean;
}): UseSettingOptions<CollapsedWidgetGroup> {
  const { fileType, id, defaultValue } = options;

  return {
    name: `file.${fileType}.collapsedWidgetGroup.${id}`,
    scope: 'user',
    defaultValue,
    parseFn: collapsedWidgetGroupZod.parse,
  };
}

export function lastUsedDocTemplate(options: {
  docType: string;
  entity: Entities;
  defaultValue?: WordLetterTemplate | WordDocumentTemplate;
}): UseSettingOptions<WordLetterTemplate | WordDocumentTemplate> {
  const { defaultValue, docType, entity } = options;
  return {
    name: `${entity}.${docType}.lastUsedDocTemplateId`,
    scope: `tenant`,
    defaultValue,
    parseFn: WordLetterTemplateSchema.parse,
  };
}

export function lastUsedEmailSignature(): UseSettingOptions<EMailSignatureZod> {
  return {
    name: `lastUsedEmailSignature`,
    scope: `tenant`,
    defaultValue: null,
    parseFn: EMailSignatureSchema.parse,
  };
}

export const ReportTypeSchema = z.enum(['Simple', 'CrystalReports']);
export type ReportTypeZod = z.infer<typeof ReportTypeSchema>;

export function lastUsedReportType(): UseSettingOptions<ReportTypeZod> {
  return {
    name: `lastUsedReportType`,
    scope: `tenant`,
    defaultValue: ReportType.CrystalReports,
    parseFn: ReportTypeSchema.parse,
  };
}

/** Table layout. (Table or cards view.) */
export function tableLayout(options: {
  defaultValue: TableLayoutDefaultValue;
}): UseSettingOptions<TableLayoutDefaultValue> {
  const { defaultValue } = options;

  //as of jira story 1037 list view modes apply to all tables if changed
  return {
    name: `list.layout`,
    scope: 'user',
    defaultValue,
    parseFn: tableLayoutDefaultValueZod.parse as any,
  };
}

const DEFAULT_STANDARD_EMAIL_KINDS: StandardEmailTemplates = Object.keys(
  EMailTemplateKind
).reduce((obj, key) => {
  obj[EMailTemplateKind[key]] = null;
  return obj;
}, {});

export function standardEmailTemplates(): UseSettingOptions<StandardEmailTemplates> {
  return {
    name: `StandardEMailTemplates`,
    scope: 'user',
    defaultValue: DEFAULT_STANDARD_EMAIL_KINDS,
  };
}

const DEFAULT_TIME_TRACK_HISTORY: TimeTrackTemplateList = [];

export function timeTrackHistory(): UseSettingOptions<TimeTrackTemplateList> {
  return {
    name: `timeTrackHistory`,
    scope: 'user',
    defaultValue: DEFAULT_TIME_TRACK_HISTORY,
    parseFn: TimeTrackTemplateListZod.parse,
  };
}

const DEFAULT_TIME_TRACK_SETTINGS: TimeTrackSettings = {
  last: {
    show: true,
    count: 8,
  },
  [Entities.task]: {
    show: true,
    count: 8,
  },
  [Entities.contract]: {
    show: true,
    count: 8,
  },
  [Entities.project]: {
    show: true,
    count: 8,
  },
  [Entities.appointment]: {
    show: true,
    count: 8,
  },
  [Entities.ticket]: {
    show: true,
    count: 8,
  },
};

export function timeTrackSettings(): UseSettingOptions<TimeTrackSettings> {
  return {
    name: `timeTrackSettings`,
    scope: 'user',
    defaultValue: DEFAULT_TIME_TRACK_SETTINGS,
    parseFn: timeTrackSettingsZod.parse as any,
  };
}

const overviewEntityPattern = new RegExp(
  `^(${Object.values(Entities).join('|')})\\([^)]+\\)$`
);
const overviewWidgetConfigZod = z.record(
  z.union([EntitiesZod, z.string().regex(overviewEntityPattern)]),
  z.boolean()
);
type OverviewWidgetConfig = z.infer<typeof overviewWidgetConfigZod>;

const DEFAULT_OVERVIEW_WIDGET_CONFIG: OverviewWidgetConfig = {};

export function overviewWidgetConfig(): UseSettingOptions<OverviewWidgetConfig> {
  return {
    name: `overview.widgetConfig`,
    scope: 'tenant',
    defaultValue: DEFAULT_OVERVIEW_WIDGET_CONFIG,
    parseFn: overviewWidgetConfigZod.parse,
  };
}

const timeUnitsZod = z.enum(TIME_UNIT_TYPES);
type OverviewTimeUnitConfig = z.infer<typeof timeUnitsZod>;
export function overviewTimeUnitConfig(): UseSettingOptions<OverviewTimeUnitConfig> {
  return {
    name: `overview.timeUnitConfig`,
    scope: 'tenant',
    defaultValue: 'day',
    parseFn: timeUnitsZod.parse,
  };
}

export enum PersonFilterType {
  all = 'all',
  department = 'department',
  me = 'mine',
}

const overviewPersonFilterConfigZod = z.nativeEnum(PersonFilterType);

export function overviewPersonFilterConfig(): UseSettingOptions<PersonFilterType> {
  return {
    name: `overview.personFilterConfig`,
    scope: 'tenant',
    defaultValue: PersonFilterType.all,
    parseFn: overviewPersonFilterConfigZod.parse,
  };
}

export const DEFAULT_VISIBLE_ERP_COLUMNS = [
  'number',
  'longtext',
  'amount',
  'unit',
  'singlePriceNet',
  'discount',
  'insteadOfTotalPrice',
  'totalPriceNet',
];

export const DEFAULT_ERP_POSITIONS_CONFIG = {
  visibility: DEFAULT_VISIBLE_ERP_COLUMNS,
  order: [] as string[],
  width: {} as Record<string, number>,
};

export type ErpPositionConfigType = typeof DEFAULT_ERP_POSITIONS_CONFIG | null;

const erpPostionSchema = z
  .object({
    visibility: z.array(z.string()),
    order: z.array(z.string()),
    width: z.record(z.string(), z.number()),
  })
  .nullable();

export function erpPositionsConfig(
  key: string,
  defaultValue: typeof DEFAULT_ERP_POSITIONS_CONFIG = DEFAULT_ERP_POSITIONS_CONFIG
): UseSettingOptions<ErpPositionConfigType> {
  return {
    name: `erp.positions.config.${key}`,
    scope: 'user',
    defaultValue,
    parseFn: erpPostionSchema.parse as any,
  };
}

export const DEFAULT_VISIBLE_INCOMING_INVOICE_COLUMNS = [
  'konto',
  'longtext',
  'costCenter',
  'project',
  'costGroup',
  'note',
  'taxKeyId',
  'vat',
  'valueNet',
  'vatAmount',
  'proportionDM',
];

export function incomingInvoicePositionsColumn(): UseSettingOptions<ErpPositionConfigType> {
  return {
    name: `incoming-invoice.positions.column`,
    scope: 'user',
    defaultValue: {
      visibility: DEFAULT_VISIBLE_INCOMING_INVOICE_COLUMNS,
      order: [],
      width: {},
    },
    parseFn: erpPostionSchema.parse as any,
  };
}

const showTimeTrackingSuggestionsZod = z.boolean();
type ShowTimeTrackingSuggestions = DeepRequired<
  z.infer<typeof showTimeTrackingSuggestionsZod>
>;
const SHOW_TIME_TRACKING_SUGGESTIONS_DEFAULT_VALUE: ShowTimeTrackingSuggestions =
  false;
export function showTimeTrackingSuggestions(): UseSettingOptions<ShowTimeTrackingSuggestions> {
  return {
    name: 'showTimeTrackingSuggestions',
    scope: 'user',
    defaultValue: SHOW_TIME_TRACKING_SUGGESTIONS_DEFAULT_VALUE,
    parseFn: showTimeTrackingSuggestionsZod.parse,
  };
}

const calendarWorkDayStartZod = z.string().nullable();
type CalendarWorkDayStart = DeepRequired<
  z.infer<typeof calendarWorkDayStartZod>
>;
export function calendarWorkDayStart(): UseSettingOptions<CalendarWorkDayStart> {
  return {
    name: `WorkingDaySetting_CalendarWorkDayStart`,
    scope: 'user',
    defaultValue: null,
    parseFn: calendarWorkDayStartZod.parse,
  };
}

const calendarWorkDayEndZod = z.string().nullable();
type CalendarWorkDayEnd = DeepRequired<z.infer<typeof calendarWorkDayEndZod>>;
export function calendarWorkDayEnd(): UseSettingOptions<CalendarWorkDayEnd> {
  return {
    name: `WorkingDaySetting_CalendarWorkDayEnd`,
    scope: 'user',
    defaultValue: null,
    parseFn: calendarWorkDayEndZod.parse,
  };
}

const projectTimeTrackingIntervalUnitZod = z.string().nullable();
type ProjectTimeTrackingIntervalUnit = DeepRequired<
  z.infer<typeof projectTimeTrackingIntervalUnitZod>
>;
export function projectTimeTrackingIntervalUnit(): UseSettingOptions<ProjectTimeTrackingIntervalUnit> {
  return {
    name: `TimeTrackingTimeUnits`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingIntervalUnitZod.parse,
  };
}

export function calendarLastViewParams(): UseSettingOptions<AppointmentCalendarLastParams | null> {
  return {
    name: 'calendar.lastViewParams',
    scope: 'tenant',
    defaultValue: null,
    parseFn: NullableAppointmentCalendarLastParamsSchema.parse as any,
  };
}

const projectTimeTrackingMarkItemsAsDeductableAutomaticallyZod = z
  .boolean()
  .nullable();
type ProjectTimeTrackingMarkItemsAsDeductableAutomatically = DeepRequired<
  z.infer<typeof projectTimeTrackingMarkItemsAsDeductableAutomaticallyZod>
>;
export function projectTimeTrackingMarkItemsAsDeductableAutomatically(): UseSettingOptions<ProjectTimeTrackingMarkItemsAsDeductableAutomatically> {
  return {
    name: `Kostenerfassung direkt abrechenbar`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingMarkItemsAsDeductableAutomaticallyZod.parse,
  };
}

const projectTimeTrackingDeductableWithoutActivityZod = z.boolean().nullable();
type ProjectTimeTrackingDeductableWithoutActivityZod = DeepRequired<
  z.infer<typeof projectTimeTrackingDeductableWithoutActivityZod>
>;
export function projectTimeTrackingDeductableWithoutActivity(): UseSettingOptions<ProjectTimeTrackingDeductableWithoutActivityZod> {
  return {
    name: `kostenerfassung ohne Tätigkeit abrechenbar`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingDeductableWithoutActivityZod.parse,
  };
}

const projectTimeTrackingDeductableByActivityZod = z.boolean().nullable();
type ProjectTimeTrackingDeductableByActivity = DeepRequired<
  z.infer<typeof projectTimeTrackingDeductableByActivityZod>
>;
export function projectTimeTrackingDeductableByActivity(): UseSettingOptions<ProjectTimeTrackingDeductableByActivity> {
  return {
    name: `kostenerfassung nach Tätigkeit abrechenbar`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingDeductableByActivityZod.parse,
  };
}

const projectTimeTrackingShowCostsZod = z.boolean().nullable();
type ProjectTimeTrackingShowCosts = DeepRequired<
  z.infer<typeof projectTimeTrackingShowCostsZod>
>;
export function projectTimeTrackingShowCosts(): UseSettingOptions<ProjectTimeTrackingShowCosts> {
  return {
    name: `Kostenerfassung, Kosten anzeigen`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingShowCostsZod.parse,
  };
}

const projectTimeTrackingAllowEditForDeducableItemsZod = z.boolean().nullable();
type ProjectTimeTrackingAllowEditForDeducableItems = DeepRequired<
  z.infer<typeof projectTimeTrackingAllowEditForDeducableItemsZod>
>;
export function projectTimeTrackingAllowEditForDeducableItems(): UseSettingOptions<ProjectTimeTrackingAllowEditForDeducableItems> {
  return {
    name: `Zeiterfassung, Abrechenbare ändern`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingAllowEditForDeducableItemsZod.parse,
  };
}

const projectTimeTrackingCustomerMandatoryZod = z.boolean().nullable();
type ProjectTimeTrackingCustomerMandatory = DeepRequired<
  z.infer<typeof projectTimeTrackingCustomerMandatoryZod>
>;
export function projectTimeTrackingCustomerMandatory(): UseSettingOptions<ProjectTimeTrackingCustomerMandatory> {
  return {
    name: `Kostenerfassung nur mit Kunde`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingCustomerMandatoryZod.parse,
  };
}

const projectTimeTrackingProjectMandatoryZod = z.boolean().nullable();
type ProjectTimeTrackingProjectMandatory = DeepRequired<
  z.infer<typeof projectTimeTrackingProjectMandatoryZod>
>;
export function projectTimeTrackingProjectMandatory(): UseSettingOptions<ProjectTimeTrackingProjectMandatory> {
  return {
    name: `Kostenerfassung nur mit Projekt`,
    scope: 'global',
    defaultValue: true,
    parseFn: projectTimeTrackingProjectMandatoryZod.parse,
  };
}

const projectTimeTrackingProjectProcessMandatoryZod = z.boolean().nullable();
type ProjectTimeTrackingProjectProcessMandatory = DeepRequired<
  z.infer<typeof projectTimeTrackingProjectProcessMandatoryZod>
>;
export function projectTimeTrackingProjectProcessMandatory(): UseSettingOptions<ProjectTimeTrackingProjectProcessMandatory> {
  return {
    name: `Kostenerfassung nur mit Vorgang`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingProjectProcessMandatoryZod.parse,
  };
}

const projectTimeTrackingContractMandatoryZod = z.boolean().nullable();
type ProjectTimeTrackingContractMandatory = DeepRequired<
  z.infer<typeof projectTimeTrackingContractMandatoryZod>
>;
export function projectTimeTrackingContractMandatory(): UseSettingOptions<ProjectTimeTrackingContractMandatory> {
  return {
    name: `Kostenerfassung nur mit Auftrag`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingContractMandatoryZod.parse,
  };
}

const projectTimeTrackingPositionMandatoryZod = z.boolean().nullable();
type ProjectTimeTrackingPositionMandatory = DeepRequired<
  z.infer<typeof projectTimeTrackingPositionMandatoryZod>
>;
export function projectTimeTrackingPositionMandatory(): UseSettingOptions<ProjectTimeTrackingPositionMandatory> {
  return {
    name: `Kostenerfassung nur mit Position`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingPositionMandatoryZod.parse,
  };
}

const projectTimeTrackingActivityMandatoryZod = z.boolean().nullable();
type ProjectTimeTrackingActivityMandatory = DeepRequired<
  z.infer<typeof projectTimeTrackingActivityMandatoryZod>
>;
export function projectTimeTrackingActivityMandatory(): UseSettingOptions<ProjectTimeTrackingActivityMandatory> {
  return {
    name: `Kostenerfassung nur mit Tätigkeit`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingActivityMandatoryZod.parse,
  };
}

const projectTimeTrackingTicketMandatoryZod = z.boolean().nullable();
type ProjectTimeTrackingTicketMandatory = DeepRequired<
  z.infer<typeof projectTimeTrackingTicketMandatoryZod>
>;
export function projectTimeTrackingTicketMandatory(): UseSettingOptions<ProjectTimeTrackingTicketMandatory> {
  return {
    name: `Kostenerfassung nur mit Ticket`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingTicketMandatoryZod.parse,
  };
}

const projectTimeTrackingShowCustomerZod = z.boolean().nullable();
type ProjectTimeTrackingShowCustomer = DeepRequired<
  z.infer<typeof projectTimeTrackingShowCustomerZod>
>;
export function projectTimeTrackingShowCustomer(): UseSettingOptions<ProjectTimeTrackingShowCustomer> {
  return {
    name: `project time tracking show customer`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingShowCustomerZod.parse,
  };
}

const projectTimeTrackingShowProjectZod = z.boolean();
type ProjectTimeTrackingShowProject = DeepRequired<
  z.infer<typeof projectTimeTrackingShowProjectZod>
>;
export function projectTimeTrackingShowProject(): UseSettingOptions<ProjectTimeTrackingShowProject> {
  return {
    name: `project time tracking show project`,
    scope: 'global',
    defaultValue: true,
    parseFn: projectTimeTrackingShowProjectZod.parse,
  };
}

const projectTimeTrackingShowProjectProcessZod = z.boolean().nullable();
type ProjectTimeTrackingShowProjectProcess = DeepRequired<
  z.infer<typeof projectTimeTrackingShowProjectProcessZod>
>;
export function projectTimeTrackingShowProjectProcess(): UseSettingOptions<ProjectTimeTrackingShowProjectProcess> {
  return {
    name: `project time tracking show project step`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingShowProjectProcessZod.parse,
  };
}

const projectTimeTrackingShowContractZod = z.boolean().nullable();
type ProjectTimeTrackingShowContract = DeepRequired<
  z.infer<typeof projectTimeTrackingShowContractZod>
>;
export function projectTimeTrackingShowContract(): UseSettingOptions<ProjectTimeTrackingShowContract> {
  return {
    name: `project time tracking show order confirmation`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingShowContractZod.parse,
  };
}

const projectTimeTrackingShowPositionZod = z.boolean();
type ProjectTimeTrackingShowPosition = DeepRequired<
  z.infer<typeof projectTimeTrackingShowPositionZod>
>;
export function projectTimeTrackingShowPosition(): UseSettingOptions<ProjectTimeTrackingShowPosition> {
  return {
    name: `project time tracking show order confirmation line`,
    scope: 'global',
    defaultValue: true,
    parseFn: projectTimeTrackingShowPositionZod.parse,
  };
}

const projectTimeTrackingShowActivityZod = z.boolean().nullable();
type ProjectTimeTrackingShowActivity = DeepRequired<
  z.infer<typeof projectTimeTrackingShowActivityZod>
>;
export function projectTimeTrackingShowActivity(): UseSettingOptions<ProjectTimeTrackingShowActivity> {
  return {
    name: `project time tracking show service item`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingShowActivityZod.parse,
  };
}

const projectTimeTrackingShowTicketZod = z.boolean().nullable();
type ProjectTimeTrackingShowTicket = DeepRequired<
  z.infer<typeof projectTimeTrackingShowTicketZod>
>;
export function projectTimeTrackingShowTicket(): UseSettingOptions<ProjectTimeTrackingShowTicket> {
  return {
    name: `project time tracking show ticket`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingShowTicketZod.parse,
  };
}

const projectTimeTrackingPriceOfCostCalculationZod = z.boolean().nullable();
type ProjectTimeTrackingPriceOfCostCalculation = DeepRequired<
  z.infer<typeof projectTimeTrackingPriceOfCostCalculationZod>
>;
export function projectTimeTrackingPriceOfCostCalculation(): UseSettingOptions<ProjectTimeTrackingPriceOfCostCalculation> {
  return {
    name: `Eigenleistungen aus Tätigkeit`,
    scope: 'global',
    defaultValue: null,
    parseFn: projectTimeTrackingPriceOfCostCalculationZod.parse,
  };
}

export const DEFAULT_VIEWS = [];
export function calendarViews(): UseSettingOptions<AppointmentCalendarView[]> {
  return {
    name: 'calendar.views',
    scope: 'tenant',
    defaultValue: DEFAULT_VIEWS,
    parseFn: AppointmentCalendarViewArraySchema.parse as any,
  };
}

const fileSettingsZod = z.record(z.string(), z.boolean());
type IFileSettings = DeepRequired<z.infer<typeof fileSettingsZod>>;

const FILE_SETTINGS_DEFAULT_VALUE: IFileSettings = {
  address: true,
  number: true,
  phone: true,
  contactOptions: true,
  group: true,
  firstContact: true,
  subscriptionContract: false,
  opportunities: false,
  payment: true,
  sales: true,
  contactList: true,
  categories: true,
  relations: true,
  note: true,
  budget: true,
  created: true,
  customerSupplier: true,
  kind: true,
  parentProject: true,
  projectPeriod: false,
  projectDirectory: true,
  showFunctionInAddress: true,
  status: true,
  website: true,
  openDuePositions: false,
  internalProjectLeaders: false,
  customerProjectLeaders: false,
  eventPeriod: false,
  projectPlanning: true,
};

export function fileSettings(
  entity: Entities
): UseSettingOptions<IFileSettings> {
  return {
    name: `file.settings.${entity}`,
    scope: 'user',
    defaultValue: FILE_SETTINGS_DEFAULT_VALUE,
    parseFn: fileSettingsZod.parse as any,
  };
}

const fileHideLeftContactsZod = z.boolean();
type FileHideLeftContacts = DeepRequired<
  z.infer<typeof fileHideLeftContactsZod>
>;
export function fileHideLeftContacts(): UseSettingOptions<FileHideLeftContacts> {
  return {
    name: `file.hideLeftContacts.`,
    scope: 'user',
    defaultValue: false,
    parseFn: fileHideLeftContactsZod.parse,
  };
}

const fileViewModeBreakpointsZod = z.enum(['sm', 'md', 'lg', 'xl']);
const fileViewBreakpointsModeZod = z.record(
  fileViewModeBreakpointsZod,
  fileViewModeZod
);
type IFileViewMode = DeepRequired<z.infer<typeof fileViewBreakpointsModeZod>>;

const FILES_DEFAULT_VIEW_MODE: IFileViewMode = {
  sm: 'individual',
  md: 'individual',
  lg: 'individual',
  xl: 'individual',
};

export function fileViewMode(): UseSettingOptions<IFileViewMode> {
  return {
    name: 'files.viewmode',
    scope: 'user',
    defaultValue: FILES_DEFAULT_VIEW_MODE,
    parseFn: fileViewBreakpointsModeZod.parse as any,
  };
}

const canChangeApprovalPersonZod = z.boolean();
type CanChangeApprovalPerson = DeepRequired<
  z.infer<typeof canChangeApprovalPersonZod>
>;
const CAN_CHANGE_APPROVAL_PERSON_DEFAULT_VALUE: CanChangeApprovalPerson = false;
export function canChangeApprovalPerson(): UseSettingOptions<CanChangeApprovalPerson> {
  return {
    name: 'wechseldesurlaubsgenehmigerserlauben',
    scope: 'global',
    defaultValue: CAN_CHANGE_APPROVAL_PERSON_DEFAULT_VALUE,
    parseFn: canChangeApprovalPersonZod.parse,
  };
}

const calendarAppointmentCardShowBusinesspartnerZod = z.boolean();
type CalendarAppointmentCardShowBusinesspartner = DeepRequired<
  z.infer<typeof calendarAppointmentCardShowBusinesspartnerZod>
>;
export function calendarAppointmentCardShowBusinesspartner(): UseSettingOptions<CalendarAppointmentCardShowBusinesspartner> {
  return {
    name: 'calendar.appointment.card.showBusinessPartner',
    scope: 'user',
    defaultValue: true,
    parseFn: calendarAppointmentCardShowBusinesspartnerZod.parse,
  };
}

const calendarAppointmentCardShowProjectZod = z.boolean();
type CalendarAppointmentCardShowProject = DeepRequired<
  z.infer<typeof calendarAppointmentCardShowProjectZod>
>;
export function calendarAppointmentCardShowProject(): UseSettingOptions<CalendarAppointmentCardShowProject> {
  return {
    name: 'calendar.appointment.card.showProject',
    scope: 'user',
    defaultValue: true,
    parseFn: calendarAppointmentCardShowProjectZod.parse,
  };
}

const calendarAppointmentShowTooltipZod = z.boolean();
type CalendarAppointmentShowTooltip = DeepRequired<
  z.infer<typeof calendarAppointmentShowTooltipZod>
>;
export function calendarAppointmentShowTooltip(): UseSettingOptions<CalendarAppointmentShowTooltip> {
  return {
    name: 'calendar.appointment.tooltip.show',
    scope: 'user',
    defaultValue: true,
    parseFn: calendarAppointmentShowTooltipZod.parse,
  };
}

const calendarAppointmentTooltipShowBusinesspartnerZod = z.boolean();
type CalendarAppointmentTooltipShowBusinesspartner = DeepRequired<
  z.infer<typeof calendarAppointmentTooltipShowBusinesspartnerZod>
>;
export function calendarAppointmentTooltipShowBusinesspartner(): UseSettingOptions<CalendarAppointmentTooltipShowBusinesspartner> {
  return {
    name: 'calendar.appointment.tooltip.showBusinesspartner',
    scope: 'user',
    defaultValue: true,
    parseFn: calendarAppointmentTooltipShowBusinesspartnerZod.parse,
  };
}

const calendarAppointmentTooltipShowProjectZod = z.boolean();
type CalendarAppointmentTooltipShowProject = DeepRequired<
  z.infer<typeof calendarAppointmentTooltipShowProjectZod>
>;
export function calendarAppointmentTooltipShowProject(): UseSettingOptions<CalendarAppointmentTooltipShowProject> {
  return {
    name: 'calendar.appointment.tooltip.showProject',
    scope: 'user',
    defaultValue: true,
    parseFn: calendarAppointmentTooltipShowProjectZod.parse,
  };
}

const calendarAppointmentTooltipShowNoteZod = z.boolean();
type CalendarAppointmentTooltipShowNote = DeepRequired<
  z.infer<typeof calendarAppointmentTooltipShowNoteZod>
>;
export function calendarAppointmentTooltipShowNote(): UseSettingOptions<CalendarAppointmentTooltipShowNote> {
  return {
    name: 'calendar.appointment.tooltip.showNote',
    scope: 'user',
    defaultValue: true,
    parseFn: calendarAppointmentTooltipShowNoteZod.parse,
  };
}

const calendarAppointmentCardColorByZod = z.nativeEnum(CalendarCardColorBy);
export function calendarAppointmentCardColorBy(): UseSettingOptions<CalendarCardColorBy> {
  return {
    name: 'calendar.appointment.card.colorBy',
    scope: 'user',
    defaultValue: CalendarCardColorBy.AppointmentStatus,
    parseFn: calendarAppointmentCardColorByZod.parse,
  };
}

const calendarUserAppearanceZod = z.nativeEnum(CalendarUserAppearance);
export function calendarUserAppearance(options: {
  breakpoint: Breakpoint;
}): UseSettingOptions<CalendarUserAppearance> {
  const { breakpoint } = options;

  return {
    name: `calendar.userDisplayStyle.${breakpoint}`,
    scope: 'user',
    defaultValue: CalendarUserAppearance.Avatar,
    parseFn: calendarUserAppearanceZod.parse,
  };
}

const mainContactFlagZod = z.boolean();
type MainContactFlag = DeepRequired<z.infer<typeof mainContactFlagZod>>;
//Set as main contact check input default value
export function mainContactFlag(): UseSettingOptions<MainContactFlag> {
  return {
    name: 'hauptansprechpartner nicht setzen',
    scope: 'global',
    defaultValue: false,
    parseFn: mainContactFlagZod.parse,
  };
}

const defaultPaymentKindIdZod = z.number();
type DefaultPaymentKindId = DeepRequired<
  z.infer<typeof defaultPaymentKindIdZod>
>;
export function defaultPaymentKindId(): UseSettingOptions<DefaultPaymentKindId> {
  return {
    name: 'Standardzahlungsart',
    scope: 'global',
    defaultValue: 0,
    parseFn: defaultPaymentKindIdZod.parse,
  };
}
const defaultPaymentDeadlineDaysZod = z.number();
type DefaultPaymentDeadlineDays = DeepRequired<
  z.infer<typeof defaultPaymentDeadlineDaysZod>
>;
export function defaultPaymentDeadlineDays(): UseSettingOptions<DefaultPaymentDeadlineDays> {
  return {
    name: 'Standard Zahlungsfrist /Tg.',
    scope: 'global',
    defaultValue: 0,
    parseFn: defaultPaymentDeadlineDaysZod.parse,
  };
}

const defaultDunningToleranceDaysZod = z.number();
type DefaultDunningToleranceDays = DeepRequired<
  z.infer<typeof defaultSkontoPercentZod>
>;
export function defaultDunningToleranceDays(): UseSettingOptions<DefaultDunningToleranceDays> {
  return {
    name: 'Standard Mahntoleranz /tg.',
    scope: 'global',
    defaultValue: 0,
    parseFn: defaultDunningToleranceDaysZod.parse,
  };
}

const defaultSkontoPercentZod = z.number();
type DefaultSkontoPercent = DeepRequired<
  z.infer<typeof defaultSkontoPercentZod>
>;
export function defaultSkontoPercent(): UseSettingOptions<DefaultSkontoPercent> {
  return {
    name: 'Standard Skonto (%)',
    scope: 'global',
    defaultValue: 0,
    parseFn: defaultSkontoPercentZod.parse,
  };
}

const defaultSkontoDeadlineDaysZod = z.number();
type DefaultSkontoDeadlineDays = DeepRequired<
  z.infer<typeof defaultSkontoDeadlineDaysZod>
>;
export function defaultSkontoDeadlineDays(): UseSettingOptions<DefaultSkontoDeadlineDays> {
  return {
    name: 'Standard Skontofrist /tg.',
    scope: 'global',
    defaultValue: 0,
    parseFn: defaultSkontoDeadlineDaysZod.parse,
  };
}

const callModalAddCallMemoZod = z.boolean();
type CallModalAddCallMemo = DeepRequired<
  z.infer<typeof callModalAddCallMemoZod>
>;
export function callModalAddCallMemo(): UseSettingOptions<CallModalAddCallMemo> {
  return {
    name: 'callModal.AddCallMemo',
    scope: 'user',
    defaultValue: false,
    parseFn: callModalAddCallMemoZod.parse,
  };
}

const erpAdditionalAddress3NameZod = z.string();
type ErpAdditionalAddress3Name = DeepRequired<
  z.infer<typeof erpAdditionalAddress3NameZod>
>;
export function erpAdditionalAddress3Name(): UseSettingOptions<ErpAdditionalAddress3Name> {
  return {
    name: 'Nebenadresse 3',
    scope: 'global',
    defaultValue: '',
    parseFn: erpAdditionalAddress3NameZod.parse,
  };
}

const erpShowAdditionalAddress3Zod = z.boolean();
type ErpShowAdditionalAddress3 = DeepRequired<
  z.infer<typeof erpShowAdditionalAddress3Zod>
>;
export function erpShowAdditionalAddress3(): UseSettingOptions<ErpShowAdditionalAddress3> {
  return {
    name: 'ERP.ShowAdditionalAddress3',
    scope: 'global',
    defaultValue: false,
    parseFn: erpShowAdditionalAddress3Zod.parse,
  };
}

const defaultCustomerGroupIdZod = z.number();
type DefaultCustomerGroupId = DeepRequired<
  z.infer<typeof defaultCustomerGroupIdZod>
>;
export function defaultCustomerGroupId(): UseSettingOptions<DefaultCustomerGroupId> {
  return {
    name: 'StandardGroup_KundenGr',
    scope: 'global',
    defaultValue: 0,
    parseFn: defaultCustomerGroupIdZod.parse,
  };
}

const defaultSupplierGroupIdZod = z.number();
type DefaultSupplierGroupId = DeepRequired<
  z.infer<typeof defaultSupplierGroupIdZod>
>;
export function defaultSupplierGroupId(): UseSettingOptions<DefaultSupplierGroupId> {
  return {
    name: 'StandardGroup_LieferantenGr',
    scope: 'global',
    defaultValue: 0,
    parseFn: defaultSupplierGroupIdZod.parse,
  };
}

const calendarDragAndDropAppointmentsZod = z.boolean();
type CalendarDragAndDropAppointments = DeepRequired<
  z.infer<typeof calendarDragAndDropAppointmentsZod>
>;
export function calendarDragAndDropAppointments(): UseSettingOptions<CalendarDragAndDropAppointments> {
  return {
    name: 'calendar.dragAndDropAppointments',
    scope: 'tenant',
    defaultValue: true,
    parseFn: calendarDragAndDropAppointmentsZod.parse,
  };
}

const defaultTicketSenderAddressZod = z.string().nullable();
type DefaultTicketSenderAddress = z.infer<typeof defaultTicketSenderAddressZod>;
export function defaultTicketSenderAddress(): UseSettingOptions<DefaultTicketSenderAddress | null> {
  return {
    name: 'Standardabsender für Ticketbenachrichtigungen',
    scope: 'global',
    defaultValue: '',
    parseFn: defaultTicketSenderAddressZod.parse,
  };
}

/** consider invoice ledger for all statistics */
const statisticConsiderInvoiceLedgerZod = z.boolean();
type StatisticConsiderInvoiceLedger = DeepRequired<
  z.infer<typeof statisticConsiderInvoiceLedgerZod>
>;
export function statisticConsiderInvoiceLedger(): UseSettingOptions<StatisticConsiderInvoiceLedger> {
  return {
    name: 'Stistic.Dashboard.onlyRa',
    scope: 'user',
    defaultValue: true,
    parseFn: statisticConsiderInvoiceLedgerZod.parse,
  };
}

/** consider invoice ledger for all statistics */
const statisticUseDeliveryPeriodZod = z.boolean();
type StatisticUseDeliveryPeriod = DeepRequired<
  z.infer<typeof statisticUseDeliveryPeriodZod>
>;
export function statisticUseDeliveryPeriod(): UseSettingOptions<StatisticUseDeliveryPeriod> {
  return {
    name: 'Stistic.Dashboard.useDeliveryPeriod',
    scope: 'user',
    defaultValue: false,
    parseFn: statisticUseDeliveryPeriodZod.parse,
  };
}

const projectTimeTrackingWidgetZod = z
  .enum(['templates', 'history'])
  .nullable();
type ProjectTimeTrackingWidget = DeepRequired<
  z.infer<typeof projectTimeTrackingWidgetZod>
>;
export function projectTimeTrackingWidget(): UseSettingOptions<ProjectTimeTrackingWidget> {
  return {
    name: 'timeTracking.projectTimeTrackingWidget',
    scope: 'user',
    defaultValue: null,
    parseFn: projectTimeTrackingWidgetZod.parse,
  };
}

const inboundInvoiceExpireDateCalculationByDateOfReceiptZod = z.boolean();
type InboundInvoiceExpireDateCalculationByDateOfReceipt = DeepRequired<
  z.infer<typeof inboundInvoiceExpireDateCalculationByDateOfReceiptZod>
>;
export function inboundInvoiceExpireDateCalculationByDateOfReceipt(): UseSettingOptions<InboundInvoiceExpireDateCalculationByDateOfReceipt> {
  return {
    name: 'RE Fälligkeitsberechnung nach Eingangsdatum',
    scope: 'global',
    defaultValue: false,
    parseFn: inboundInvoiceExpireDateCalculationByDateOfReceiptZod.parse,
  };
}

const timeTrackingTabZod = z.nativeEnum(TimeTrackerMenuTabs);
export function timeTrackingTab(): UseSettingOptions<TimeTrackerMenuTabs> {
  return {
    name: 'timeTracking.timeTrackingTab',
    scope: 'user',
    defaultValue: TimeTrackerMenuTabs.workingTime,
    parseFn: timeTrackingTabZod.parse,
  };
}

const projectTimeDisplayModeZod = z.nativeEnum(ProjectTimeDisplayMode);
export function projectTimeDisplayMode(): UseSettingOptions<ProjectTimeDisplayMode> {
  return {
    name: 'timeTracking.projectTimeDisplayMode',
    scope: 'user',
    defaultValue: ProjectTimeDisplayMode.list,
    parseFn: projectTimeDisplayModeZod.parse,
  };
}

const inboundInvoiceShowOnlyOpenObjectsZod = z.boolean();
type InboundInvoiceShowOnlyOpenObjects = DeepRequired<
  z.infer<typeof inboundInvoiceShowOnlyOpenObjectsZod>
>;
export function inboundInvoiceShowOnlyOpenObjects(): UseSettingOptions<InboundInvoiceShowOnlyOpenObjects> {
  return {
    name: 'inboundInvoice.ShowOnlyOpenObjects',
    scope: 'user',
    defaultValue: true,
    parseFn: inboundInvoiceShowOnlyOpenObjectsZod.parse,
  };
}

const inboundInvoiceCloseConnectedObjectsZod = z.boolean();
type InboundInvoiceCloseConnectedObjects = DeepRequired<
  z.infer<typeof inboundInvoiceCloseConnectedObjectsZod>
>;
export function inboundInvoiceCloseConnectedObjects(): UseSettingOptions<InboundInvoiceCloseConnectedObjects> {
  return {
    name: 'inboundInvoice.CloseConnectedObjects',
    scope: 'user',
    defaultValue: true,
    parseFn: inboundInvoiceCloseConnectedObjectsZod.parse,
  };
}

const yellowNotePositionZod = z
  .object({
    x: z.number(),
    y: z.number(),
  })
  .nullable();

type YellowNotePosition = DeepRequired<z.infer<typeof yellowNotePositionZod>>;

export function yellowNotePosition(): UseSettingOptions<YellowNotePosition> {
  return {
    name: 'file.yellowNote.position',
    scope: 'user',
    defaultValue: null,
    parseFn: yellowNotePositionZod.parse as any,
  };
}

const DEFAULT_HOME_WIDGET_LAYOUT_DEF = {
  widgets: DEFAULT_HOME_WIDGETS,
  layouts: DEFAULT_HOME_WIDGET_LAYOUTS,
};

export function homeWidgetLayouts(): UseSettingOptions<{
  widgets: HomeWidget[];
  layouts: LayoutResponsiveDefinition;
}> {
  return {
    name: 'home.widgetLayouts',
    scope: 'user',
    defaultValue: DEFAULT_HOME_WIDGET_LAYOUT_DEF,
    parseFn: HomeWidgetLayoutsSchema.parse as any,
  };
}

const rtfTextsInERPZod = z.boolean();
export type RtfTextsInERP = DeepRequired<z.infer<typeof rtfTextsInERPZod>>;
export function rtfTextsInERP(): UseSettingOptions<RtfTextsInERP> {
  return {
    name: 'RTF Kopf-/Fusstexte in ERP-Dokumenten',
    scope: 'global',
    defaultValue: true,
    parseFn: rtfTextsInERPZod.parse,
  };
}

const relevantWidgetVisibleViewsZod = z.array(EntitiesZod);
export type RelevantWidgetVisibleViews = DeepRequired<
  z.infer<typeof relevantWidgetVisibleViewsZod>
>;
export const DEFAULT_RELEVANT_WIDGET_VIEW: RelevantWidgetVisibleViews = [
  Entities.task,
  Entities.appointment,
  Entities.ticket,
  Entities.timeTracking,
  Entities.vacation,
  Entities.salesOpportunities,
  Entities.travelReceipts,
];
export function relevantWidgetVisibleViews(): UseSettingOptions<RelevantWidgetVisibleViews> {
  return {
    name: 'Relevant Widget Visible Entities',
    scope: 'user',
    defaultValue: DEFAULT_RELEVANT_WIDGET_VIEW,
    parseFn: relevantWidgetVisibleViewsZod.parse,
  };
}

const adminEnabledShowingBirthdaysZod = z.boolean();
export type AdminEnabledShowingBirthdays = DeepRequired<
  z.infer<typeof adminEnabledShowingBirthdaysZod>
>;
export function adminEnabledShowingBirthdays(): UseSettingOptions<AdminEnabledShowingBirthdays> {
  return {
    name: 'Admin Enabled Showing Birthdays',
    scope: 'global',
    defaultValue: true,
    parseFn: adminEnabledShowingBirthdaysZod.parse,
  };
}

const userEnabledShowingBirthdaysZod = z.boolean();
export type UserEnabledShowingBirthdays = DeepRequired<
  z.infer<typeof userEnabledShowingBirthdaysZod>
>;
export function userEnabledShowingBirthdays(): UseSettingOptions<UserEnabledShowingBirthdays> {
  return {
    name: 'User Enabled Showing Birthdays',
    scope: 'user',
    defaultValue: true,
    parseFn: userEnabledShowingBirthdaysZod.parse,
  };
}

const showingPermitsZod = z.boolean();
export type showingPermits = DeepRequired<z.infer<typeof showingPermitsZod>>;
export function showingPermits(): UseSettingOptions<showingPermits> {
  return {
    name: 'Showing Permits',
    scope: 'user',
    defaultValue: true,
    parseFn: showingPermitsZod.parse,
  };
}

const tablesLayoutBordersZod = z.object({
  vertical: z.boolean(),
  horizontal: z.boolean(),
});

export type TablesLayoutBorders = DeepRequired<
  z.infer<typeof tablesLayoutBordersZod>
>;

export const DEFAULT_TABLE_LAYOUT_BORDERS: TablesLayoutBorders = {
  vertical: false,
  horizontal: true,
};

export type TablesLayoutBordersSettingsKey =
  | 'ERP'
  | 'INCOMING_INVOICE'
  | 'STANDARD'
  | 'BOM';

export function tablesLayoutBorders(
  settingsKey: TablesLayoutBordersSettingsKey = 'STANDARD'
): UseSettingOptions<TablesLayoutBorders> {
  const name =
    settingsKey === 'STANDARD'
      ? 'Web.App.Tables.Layout.Borders'
      : `Web.App.Tables.Layout.Borders.${settingsKey}`;

  return {
    name,
    scope: 'user',
    defaultValue: DEFAULT_TABLE_LAYOUT_BORDERS,
    parseFn: tablesLayoutBordersZod.parse as any,
  };
}

export function tablesLayoutBordersStatistics(): UseSettingOptions<TablesLayoutBorders> {
  return {
    name: 'Web.App.Tables.Layout.Borders.Statistics',
    scope: 'user',
    defaultValue: DEFAULT_TABLE_LAYOUT_BORDERS,
    parseFn: tablesLayoutBordersZod.parse as any,
  };
}

const favoritesRegisterZod = z.record(
  EntitiesZod.or(z.string()),
  z.array(z.string().or(z.number()))
);
export type FavoritesRegister = DeepRequired<
  z.infer<typeof favoritesRegisterZod>
>;

export function favoritesRegister(): UseSettingOptions<FavoritesRegister> {
  return {
    name: 'Web.App.Favorites.Register',
    scope: 'tenant',
    defaultValue: {},
    parseFn: favoritesRegisterZod.parse,
  };
}

const hideClosedEntitiesZod = z.boolean();
export function hideClosedEntities(options: {
  entityType: string;
}): UseSettingOptions<DeepRequired<z.infer<typeof hideClosedEntitiesZod>>> {
  const { entityType } = options;

  return {
    name: `hideClosedEntities.${entityType}`,
    scope: 'user',
    defaultValue: true,
    parseFn: hideClosedEntitiesZod.parse,
  };
}

const tableCollapsedAreasZod = z.object({
  left: z.record(z.boolean()),
});
export type TableCollapseAreasMap = DeepRequired<
  z.infer<typeof tableCollapsedAreasZod>
>;

const TABLE_COLLAPSED_AREAS: TableCollapseAreasMap = {
  left: {},
};
export function tableCollapsedAreas(): UseSettingOptions<TableCollapseAreasMap> {
  return {
    name: `tableCollapsedAreas`,
    scope: 'user',
    defaultValue: TABLE_COLLAPSED_AREAS,
    parseFn: tableCollapsedAreasZod.parse as any,
  };
}

const vacationTimeRangeZod = z.union([z.literal('month'), z.literal('year')]);

export type VacationTimeRange = DeepRequired<
  z.infer<typeof vacationTimeRangeZod>
>;

export function vacationTimeRange(): UseSettingOptions<VacationTimeRange> {
  return {
    name: 'vacationTimeRange',
    scope: 'user',
    defaultValue: 'month',
    parseFn: vacationTimeRangeZod.parse,
  };
}

const vacationVisibleKindsZod = z.array(z.union([z.string(), z.number()]));
export type VacationVisibleKindsList = DeepRequired<
  z.infer<typeof vacationVisibleKindsZod>
>;

const DEFAULT_VACATION_VISIBLE_KINDS: VacationVisibleKindsList = [
  'vacation',
  'sickness',
  33184105, // Teilzeitausgleich
  50448622, // Überstundenausgleich
  152409305, // Kurzarbeit
  666716357, // Sonderurlaub
  1169570473, // Berufsschule
];
export function vacationVisibleKinds(): UseSettingOptions<VacationVisibleKindsList> {
  return {
    name: 'vacationVisibleKinds',
    scope: 'user',
    defaultValue: DEFAULT_VACATION_VISIBLE_KINDS,
    parseFn: vacationVisibleKindsZod.parse,
  };
}

const createTimeTrackingForOthersZod = z.boolean();
export function createTimeTrackingForOthers(): UseSettingOptions<
  DeepRequired<z.infer<typeof createTimeTrackingForOthersZod>>
> {
  return {
    name: 'createTimeTrackingForOthers',
    scope: 'user',
    defaultValue: false,
    parseFn: createTimeTrackingForOthersZod.parse,
  };
}

const projectCaptionsZod = z
  .object({
    CaptionProjektleiterExtern: z.string(),
    CaptionProjektleiterIntern: z.string(),
    CaptionDatum1: z.string(),
    CaptionDatum2: z.string(),
    CaptionDatum3: z.string(),
    CaptionDatum4: z.string(),
  })
  .nullable();

export type ProjectCaptions = DeepRequired<z.infer<typeof projectCaptionsZod>>;

export function projectCaptions(): UseSettingOptions<ProjectCaptions> {
  return {
    name: 'Projekt-Captions',
    scope: 'global',
    defaultValue: null,
    parseFn: projectCaptionsZod.parse as any,
  };
}

const selectedMailboxFolderZod = z
  .object({
    mailbox: z.string(),
    folder: z.string(),
  })
  .nullable();

export type MailboxFolder = DeepRequired<
  z.infer<typeof selectedMailboxFolderZod>
>;
export function selectedMailboxFolder(): UseSettingOptions<MailboxFolder> {
  return {
    name: 'selectedMailboxFolder',
    scope: 'user',
    defaultValue: null,
    parseFn: selectedMailboxFolderZod.parse as any,
  };
}

const bomVariantsZod = z.object({
  articleId: z.number(),
  variant: z.nativeEnum(InsertBomStyle),
  noShow: z.boolean(),
});

export type BomVariantSettingList = DeepRequired<
  z.infer<typeof bomVariantsZod>
>;

export const SUPPLIER_ERP_ENTITIES = [
  Entities.order,
  Entities.inboundDeliveryNote,
  Entities.demand,
];

export function lastBomVariant(
  entity: Entities
): UseSettingOptions<BomVariantSettingList> {
  const group = SUPPLIER_ERP_ENTITIES.includes(entity)
    ? 'suppliers'
    : 'customers';
  return {
    name: `lastBomVariant.${group}`,
    scope: 'user',
    defaultValue: null,
    parseFn: bomVariantsZod.parse as any,
  };
}

const showProjectAccessGroupRightQuestionZod = z.boolean();
export function showProjectAccessGroupRightQuestion(): UseSettingOptions<
  z.infer<typeof showProjectAccessGroupRightQuestionZod>
> {
  return {
    name: 'showProjectAccessGroupRightQuestion',
    scope: 'user',
    defaultValue: true,
    parseFn: showProjectAccessGroupRightQuestionZod.parse,
  };
}

export function isInDemoMode(): UseSettingOptions<boolean> {
  return {
    name: 'isInDemoMode',
    scope: 'user',
    defaultValue: false,
  };
}

const mailboxFolderVisibilitySchema = z.record(
  z.string(),
  z.record(z.string(), z.boolean())
);

export type MailboxFolderVisibility = DeepRequired<
  z.infer<typeof mailboxFolderVisibilitySchema>
>;

const MAILBOX_FOLDER_VISIBILITY_DEFAULT_VALUE: MailboxFolderVisibility = {};

export function mailboxFolderVisibility(): UseSettingOptions<MailboxFolderVisibility> {
  return {
    name: 'mailboxFolderVisibility',
    scope: 'tenant',
    defaultValue: MAILBOX_FOLDER_VISIBILITY_DEFAULT_VALUE,
    parseFn: mailboxFolderVisibilitySchema.parse,
  };
}

const rowSizeModeZod = z.union([
  z.literal('AUTO'),
  z.literal('SINGLE'),
  z.literal('SINGLE-AUTO'),
]);
export type RowSizeMode = DeepRequired<z.infer<typeof rowSizeModeZod>>;

export function erpRowSize(): UseSettingOptions<RowSizeMode> {
  return {
    name: 'erp.positions.row.size',
    scope: 'user',
    defaultValue: 'SINGLE',
    parseFn: rowSizeModeZod.parse,
  };
}

export function crmPositionsRowSize(): UseSettingOptions<RowSizeMode> {
  return {
    name: 'crm.positions.row.size',
    scope: 'user',
    defaultValue: 'SINGLE',
    parseFn: rowSizeModeZod.parse,
  };
}

const TravelReceiptDefaultsZod = z
  .object({
    paymentMethod: z.object({
      id: z.number(),
      name: z.string(),
    }),
    paymentKind: z.number(),
    receiptKind: z.object({
      id: z.number(),
      name: z.string(),
      typeOfReceiptType: z.nativeEnum(ReceiptKindInternal),
    }),
    receiptKindId: z.number(),
  })
  .optional();

type TravelReceiptDefaults = z.infer<typeof TravelReceiptDefaultsZod>;

const CONST_TRAVEL_RECEIPTS: TravelReceiptDefaults = {};
export function travelReceiptDefaults(): UseSettingOptions<TravelReceiptDefaults> {
  return {
    name: 'travelReceiptDefaults',
    scope: 'tenant',
    defaultValue: CONST_TRAVEL_RECEIPTS,
    parseFn: TravelReceiptDefaultsZod.parse,
  };
}

const SimpleReportConfZod = z
  .object({
    pageHeaderHtml: z.string(),
    pageFooterHtml: z.string(),
    logoWidth: z.number(),
    senderName: z.string(),
    senderStreet: z.string(),
    senderCity: z.string(),
    senderPostalCode: z.string(),
    senderFontColor: z.string(),
    headerMarginTop: z.number(),
    headerMarginBottom: z.number(),
    headerMarginRight: z.number(),
    headerMarginLeft: z.number(),
    footerMarginTop: z.number(),
    footerMarginBottom: z.number(),
    footerMarginRight: z.number(),
    footerMarginLeft: z.number(),
    footerColumns: z.array(z.any()),
    metaShowClientNumber: z.boolean(),
    metaShowContact: z.boolean(),
    metaShowPhoneNumber: z.boolean(),
    metaShowEMail: z.boolean(),
    metaShowDate: z.boolean(),
    metaShowHeaderText: z.boolean(),
    metaShowFooterText: z.boolean(),
    positionShowPrices: z.boolean(),
    positionShowAdditionalText: z.boolean(),
    pageMarginTop: z.number(),
    pageMarginBottom: z.number(),
    pageMarginRight: z.number(),
    pageMarginLeft: z.number(),
    positionText: z.enum(['longtext', 'shortText']),
  })
  .optional();

export type SimpleReportSettings = z.infer<typeof SimpleReportConfZod>;

const DEFAULT_SIMPLE_REPORT_CONF: SimpleReportSettings = {
  pageFooterHtml: '',
  pageHeaderHtml: '',
  logoWidth: 150,
  senderName: '',
  senderStreet: '',
  senderCity: '',
  senderPostalCode: '',
  senderFontColor: '',
  headerMarginTop: 30,
  headerMarginBottom: 0,
  headerMarginRight: 50,
  headerMarginLeft: 50,
  footerMarginTop: 20,
  footerMarginBottom: 30,
  footerMarginRight: 50,
  footerMarginLeft: 50,
  footerColumns: [],
  metaShowClientNumber: true,
  metaShowContact: true,
  metaShowDate: true,
  metaShowEMail: true,
  metaShowPhoneNumber: true,
  metaShowHeaderText: true,
  metaShowFooterText: true,
  positionShowAdditionalText: true,
  positionShowPrices: true,
  pageMarginTop: 100,
  pageMarginBottom: 75,
  pageMarginRight: 50,
  pageMarginLeft: 50,
  positionText: 'longtext',
};

export function simpleReportConf(): UseSettingOptions<SimpleReportSettings> {
  return {
    name: 'simpleReportConf',
    scope: 'global-tenant',
    defaultValue: DEFAULT_SIMPLE_REPORT_CONF,
    parseFn: SimpleReportConfZod.parse,
  };
}

export enum SimpleReportSignatureNameSource {
  user = 'user',
  user2 = 'user2',
  contact = 'contact',
  additionalContact1 = 'additionalContact1',
  additionalContact2 = 'additionalContact2',
  additionalContact3 = 'additionalContact3',
}

const SimpleReportSignatureConfZod = z
  .object({
    nameSource: z.enum([
      'user',
      'user2',
      'contact',
      'additionalContact1',
      'additionalContact2',
      'additionalContact3',
    ]),
  })
  .optional();

export type SimpleReportSignatureSettings = z.infer<
  typeof SimpleReportSignatureConfZod
>;

const DEFAULT_SIMPLE_REPORT_SIGNATURE_CONF: SimpleReportSignatureSettings = {
  nameSource: 'additionalContact1',
};

export function simpleReportSignatureConf(): UseSettingOptions<SimpleReportSignatureSettings> {
  return {
    name: 'simpleReportSignatureConf',
    scope: 'user',
    defaultValue: DEFAULT_SIMPLE_REPORT_SIGNATURE_CONF,
    parseFn: SimpleReportSignatureConfZod.parse,
  };
}

export const CrystalReportOptConfZod = z.object({
  reportId: z.number(),
  options: z.array(
    z.object({
      optionId: z.number(),
      defaultValue: z.boolean(),
      userEditAllowed: z.boolean(),
    })
  ),
});

export const CrystalReportOptValuesZod = z.object({
  reportId: z.number(),
  options: z.array(
    z.object({
      optionId: z.number(),
      value: z.boolean(),
    })
  ),
});

export const CrystalReportOptGlobalConfZod = z.record(
  z.array(CrystalReportOptConfZod)
);

export const CrystalReportOptUserConfZod = z.record(
  z.array(CrystalReportOptValuesZod)
);

export type CrystalReportOptGlobalConf = z.infer<
  typeof CrystalReportOptGlobalConfZod
>;
export type CrystalReportOptUserConf = z.infer<
  typeof CrystalReportOptUserConfZod
>;
export type CrystalReportOptConf = z.infer<typeof CrystalReportOptConfZod>;
export type CrystalReportOptValues = z.infer<typeof CrystalReportOptValuesZod>;

const DEFAULT_CRYSTAL_REPORT_OPT_GLOBAL_CONF: CrystalReportOptGlobalConf = {};

export function crystalReportOptGlobalConf(): UseSettingOptions<CrystalReportOptGlobalConf> {
  return {
    name: 'crystalReportOptGlobalConf',
    scope: 'global-tenant',
    defaultValue: DEFAULT_CRYSTAL_REPORT_OPT_GLOBAL_CONF,
    parseFn: CrystalReportOptGlobalConfZod.parse,
  };
}

const DEFAULT_CRYSTAL_REPORT_OPT_USER_CONF: CrystalReportOptUserConf = {};

export function crystalReportOptUserConf(): UseSettingOptions<CrystalReportOptUserConf> {
  return {
    name: 'crystalReportOptUserConf',
    scope: 'global-tenant',
    defaultValue: DEFAULT_CRYSTAL_REPORT_OPT_USER_CONF,
    parseFn: CrystalReportOptUserConfZod.parse,
  };
}

const tableRightAreaVisibleZod = z.boolean();
export function tableRightAreaVisible(options: {
  entityType: string;
}): UseSettingOptions<DeepRequired<z.infer<typeof tableRightAreaVisibleZod>>> {
  const { entityType } = options;

  return {
    name: `tableRightAreaVisible.${entityType}`,
    scope: 'user',
    defaultValue: true,
    parseFn: tableRightAreaVisibleZod.parse,
  };
}

const upperCaseBoolean = (arg: unknown) => {
  return arg === 'True';
};
export function isOnPremiseEnvironment(): UseSettingOptions<boolean> {
  return {
    name: `IsOnpremiseEnvironment`,
    scope: 'global',
    defaultValue: null,
    parseFn: upperCaseBoolean,
  };
}

const erpConvertionOriginalNameZod = z.boolean();
export function erpConvertionOriginalName(): UseSettingOptions<
  DeepRequired<z.infer<typeof erpConvertionOriginalNameZod>>
> {
  return {
    name: 'erpConvertionOriginalName',
    scope: 'user',
    defaultValue: true,
    parseFn: erpShowArticleZod.parse,
  };
}

const organisationAreaVisibleSectionsZod = z.array(
  z.enum(ORGANISATION_AREA_SECTIONS)
);

const ORGANISATION_AREA_VISIBLE_SECTIONS_VALUE: z.infer<
  typeof organisationAreaVisibleSectionsZod
> = ['groups'];

export function organisationAreaVisibleSections(
  entity: Entities
): UseSettingOptions<z.infer<typeof organisationAreaVisibleSectionsZod>> {
  return {
    name: `organisationAreaVisibleSections.${entity}`,
    scope: 'user',
    defaultValue: ORGANISATION_AREA_VISIBLE_SECTIONS_VALUE,
    parseFn: organisationAreaVisibleSectionsZod.parse,
  };
}

const languageManagerIsServiceProviderZod = z.boolean();
export function languageManagerIsServiceProvider(): UseSettingOptions<
  DeepRequired<z.infer<typeof languageManagerIsServiceProviderZod>>
> {
  return {
    name: `DomainServiceProvider`,
    scope: 'global',
    defaultValue: false,
    parseFn: languageManagerIsServiceProviderZod.parse,
  };
}

const lastUsedMailboxIdZod = z.string();
export function lastUsedMailboxId(): UseSettingOptions<
  DeepRequired<z.infer<typeof lastUsedMailboxIdZod>>
> {
  return {
    name: `lastUsedMailboxId`,
    scope: 'tenant',
    defaultValue: null,
    parseFn: lastUsedMailboxIdZod.parse,
  };
}

const defaultVatRateValueSchema = z.number();
export function defaultVatRateValue(): UseSettingOptions<number> {
  return {
    name: `Standardmehrwertsteuersatz`,
    scope: 'global',
    defaultValue: null,
    parseFn: defaultVatRateValueSchema.parse,
  };
}

const erpConvertQuantitySuggestionSchema = z.enum(['SHOULD', 'REST']);
export function erpConvertQuantitySuggestion(): UseSettingOptions<
  'SHOULD' | 'REST'
> {
  return {
    name: `erp.convert.quantity.suggestion`,
    scope: 'user',
    defaultValue: 'SHOULD',
    parseFn: erpConvertQuantitySuggestionSchema.parse,
  };
}

const convertDialogPositionMaximizedSchema = z.boolean();
export function convertDialogPositionMaximized(): UseSettingOptions<boolean> {
  return {
    name: `erp.convert.dialog.position.maximized`,
    scope: 'global',
    defaultValue: false,
    parseFn: convertDialogPositionMaximizedSchema.parse,
  };
}
const fileLockedDisableDeliveryNoteZod = z.boolean();

export function fileLockedDisableDeliveryNote(): UseSettingOptions<boolean> {
  return {
    name: `liefersperre lieferschein`,
    scope: 'global',
    defaultValue: null,
    parseFn: fileLockedDisableDeliveryNoteZod.parse,
  };
}

const fileLockedDisableContractZod = z.boolean();

export function fileLockedDisableContract(): UseSettingOptions<boolean> {
  return {
    name: `liefersperre auftrag`,
    scope: 'global',
    defaultValue: null,
    parseFn: fileLockedDisableContractZod.parse,
  };
}

const fileLockedDisableOrderZod = z.boolean();

export function fileLockedDisableOrder(): UseSettingOptions<boolean> {
  return {
    name: `liefersperre bestellung`,
    scope: 'global',
    defaultValue: null,
    parseFn: fileLockedDisableOrderZod.parse,
  };
}

const projectProcessOverBookingNotPossibleZod = z.boolean();
export function projectProcessOverBookingNotPossible(): UseSettingOptions<
  z.infer<typeof projectProcessOverBookingNotPossibleZod>
> {
  return {
    name: `Kostenerfassung Vorgänge auf Überbuchung prüfen`,
    scope: 'global',
    defaultValue: true,
    parseFn: projectProcessOverBookingNotPossibleZod.parse,
  };
}

const resourcePlanningUserIdsZod = z.array(z.number());
export function resourcePlanningUserIds(): UseSettingOptions<
  z.infer<typeof resourcePlanningUserIdsZod>
> {
  return {
    name: `resourcePlanning.lastResourcePlanningUserIds`,
    scope: 'tenant',
    defaultValue: EMPTY_ARRAY,
    parseFn: resourcePlanningUserIdsZod.parse,
  };
}

const resourcePlanningIncludeSubProjectsZod = z.boolean();
export function resourcePlanningIncludeSubProjects(): UseSettingOptions<
  z.infer<typeof resourcePlanningIncludeSubProjectsZod>
> {
  return {
    name: `resourcePlanning.resourcePlanningIncludeSubProjects`,
    scope: 'user',
    defaultValue: true,
    parseFn: resourcePlanningIncludeSubProjectsZod.parse,
  };
}

const resourcePlanningShowConnectionsZod = z.boolean();
export function resourcePlanningShowConnections(): UseSettingOptions<
  z.infer<typeof resourcePlanningShowConnectionsZod>
> {
  return {
    name: `resourcePlanning.showConnections`,
    scope: 'global',
    defaultValue: true,
    parseFn: resourcePlanningShowConnectionsZod.parse,
  };
}

type ResourcePlanningLayout = 'compact' | 'full';
export function resourcePlanningLayout(): UseSettingOptions<ResourcePlanningLayout> {
  return {
    name: `resourcePlanning.resourcePlanningLayout`,
    scope: 'tenant',
    defaultValue: 'compact',
  };
}

const isEmailProjectRequiredZod = z.boolean();
export function isEmailProjectRequired(): UseSettingOptions<
  z.infer<typeof isEmailProjectRequiredZod>
> {
  return {
    name: `PflichtfeldProjekt_E-Mail`,
    scope: 'global',
    defaultValue: false,
    parseFn: isEmailProjectRequiredZod.parse,
  };
}

const paymentDefaultLedgerAccountIdZod = z.number();
export function paymentDefaultLedgerAccountId(
  entity: Entities
): UseSettingOptions<z.infer<typeof paymentDefaultLedgerAccountIdZod>> {
  return {
    name: `payments.defaultLedgerAccountId.${entity}`,
    scope: 'tenant',
    defaultValue: 0,
    parseFn: paymentDefaultLedgerAccountIdZod.parse,
  };
}

const paymentDefaultSkontoLedgerAccountIdZod = z.number();
export function paymentDefaultSkontoLedgerAccountId(
  entity: Entities
): UseSettingOptions<z.infer<typeof paymentDefaultSkontoLedgerAccountIdZod>> {
  return {
    name: `payments.defaultSkontoLedgerAccountId.${entity}`,
    scope: 'tenant',
    defaultValue: 0,
    parseFn: paymentDefaultSkontoLedgerAccountIdZod.parse,
  };
}

export const projectPlanningColumnsZod = z.object({
  target: z.boolean(),
  planned: z.boolean(),
  rest: z.boolean(),
  actual: z.boolean(),
});

export function projectPlanningColumns(): UseSettingOptions<
  z.infer<typeof projectPlanningColumnsZod>
> {
  return {
    name: `projectPlanning.columns`,
    scope: 'global',
    defaultValue: defaultProjectPlanningColumns,
    parseFn: projectPlanningColumnsZod.parse,
  };
}

const lastFilterZod = z.array(
  z.object({
    id: z.string(),
    value: z.any(),
  })
);
export function lastFilter(
  entity: Entities
): UseSettingOptions<z.infer<typeof lastFilterZod>> {
  return {
    name: `table.filter.${entity}`,
    scope: 'tenant',
    defaultValue: [],
    parseFn: lastFilterZod.parse,
  };
}

const positionPickerDescriptionFieldZod = z.enum(['longtext', 'shortText']);
export function positionPickerDescriptionField(): UseSettingOptions<
  z.infer<typeof positionPickerDescriptionFieldZod>
> {
  return {
    name: `positionPickerDescriptionField`,
    scope: 'tenant',
    defaultValue: 'longtext',
    parseFn: positionPickerDescriptionFieldZod.parse,
  };
}

const projectTimeTrackingDisplayHistoryZod = z.boolean();
type ProjectTimeTrackingDisplayHistory = DeepRequired<
  z.infer<typeof projectTimeTrackingDisplayHistoryZod>
>;
export function projectTimeTrackingDisplayHistory(): UseSettingOptions<ProjectTimeTrackingDisplayHistory> {
  return {
    name: 'timeTracking.projectTimeTrackingDisplayHistory',
    scope: 'user',
    defaultValue: false,
    parseFn: projectTimeTrackingDisplayHistoryZod.parse,
  };
}

const resourcePlanningColumnTimeUnitZod = z.enum(['day', 'hour', 'minute']);
export type ResourcePlanningColumnTimeUnit = DeepRequired<
  z.infer<typeof resourcePlanningColumnTimeUnitZod>
>;
export function resourcePlanningColoumnTimeUnit(): UseSettingOptions<ResourcePlanningColumnTimeUnit> {
  return {
    name: 'resourcePlanning.resourcePlanningColoumnTimeUnit',
    scope: 'user',
    defaultValue: 'hour',
    parseFn: resourcePlanningColumnTimeUnitZod.parse,
  };
}

const resourcePlanningUtilizationTimeUnitZod = z.enum(['day', 'week', 'month']);
type ResourcePlanningUtilizationTimeUnit = DeepRequired<
  z.infer<typeof resourcePlanningUtilizationTimeUnitZod>
>;
export function resourcePlanningUtilizationTimeUnit(): UseSettingOptions<ResourcePlanningUtilizationTimeUnit> {
  return {
    name: 'resourcePlanning.resourcePlanningUtilizationTimeUnit',
    scope: 'user',
    defaultValue: 'month',
    parseFn: resourcePlanningUtilizationTimeUnitZod.parse,
  };
}

const ticketKindVisibilityZod = z.array(z.number());
export function ticketKindVisibility(
  defaultValue: number[]
): UseSettingOptions<number[]> {
  return {
    name: 'ticket.kindVisibility',
    scope: 'user',
    defaultValue,
    parseFn: ticketKindVisibilityZod.parse,
  };
}

const admin2faEnabledZod = z.boolean();
export function admin2faEnabled(): UseSettingOptions<boolean> {
  return {
    name: 'TwoFactorMandatory',
    scope: 'global',
    defaultValue: false,
    parseFn: admin2faEnabledZod.parse,
  };
}

const leftAreaVisibleFiltersZod = z.string().array();
export function leftAreaVisibleFilters(
  entity: Entities,
  defaultValue: string[]
): UseSettingOptions<z.infer<typeof leftAreaVisibleFiltersZod>> {
  return {
    name: `leftAreaVisibleFilters.${entity}`,
    scope: 'user',
    defaultValue,
    parseFn: leftAreaVisibleFiltersZod.parse,
  };
}

const crmPostionZod = z
  .object({
    visibility: z.array(z.string()),
    order: z.array(z.string()),
    width: z.record(z.string(), z.number()),
  })
  .nullable();

export type CrmPositionConfigType = DeepRequired<z.infer<typeof crmPostionZod>>;

export const DEFAULT_VISIBLE_CRM_COLUMNS: string[] = [
  'priority',
  'done',
  'positionNumber',
  'name',
  'note',
  'plannedBy',
  'date',
  'endDate',
  'userDone',
];

export const DEFAULT_CRM_POSITIONS_CONFIG: CrmPositionConfigType = {
  visibility: DEFAULT_VISIBLE_CRM_COLUMNS,
  order: [],
  width: {},
};

export function crmPositionsConfig(
  key: string,
  defaultValue: typeof DEFAULT_CRM_POSITIONS_CONFIG = DEFAULT_CRM_POSITIONS_CONFIG
): UseSettingOptions<CrmPositionConfigType> {
  return {
    name: `crm.positions.config.${key}`,
    scope: 'user',
    defaultValue,
    parseFn: crmPostionZod.parse as any,
  };
}

const crmPositionsAutomaticNumberingZod = z.boolean();
export type CrmPositionsAutomaticNumbering = z.infer<
  typeof crmPositionsAutomaticNumberingZod
>;

export function crmPositionsAutomaticNumbering(): UseSettingOptions<CrmPositionsAutomaticNumbering> {
  return {
    name: 'crm.positions.automaticNumbering',
    scope: 'user',
    defaultValue: true,
    parseFn: crmPositionsAutomaticNumberingZod.parse,
  };
}

const treeViewExpandedItemsZod = z.string().array();
export function treeViewExpandedItems(
  entity: Entities,
  type: string
): UseSettingOptions<z.infer<typeof treeViewExpandedItemsZod>> {
  return {
    name: `treeViewExpandedItems.${entity}.${type}`,
    scope: 'user',
    defaultValue: EMPTY_ARRAY,
    parseFn: treeViewExpandedItemsZod.parse,
  };
}
