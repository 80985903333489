import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ExpandIcon from '@mui/icons-material/Expand';
import FunctionsIcon from '@mui/icons-material/Functions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '@work4all/assets/icons/outline-add-24.svg';
import { ReactComponent as TitleRow } from '@work4all/assets/icons/title-row.svg';
import { ReactComponent as TitleSum } from '@work4all/assets/icons/title-sum.svg';

import {
  CustomToolbarAction,
  CustomToolbarMappedAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { IEditTable } from '../edit-table/types';

export interface UsePositionTableActionsOptions {
  onAddSpecial: (positionType: ErpPositionsKind, article?: Article) => void;
  disabled: boolean;
  editTableRef?: React.RefObject<IEditTable>;
}

export const usePositionTableActions = (
  options: UsePositionTableActionsOptions
): CustomToolbarMappedAction[] => {
  const { onAddSpecial, disabled } = options;

  const { t } = useTranslation();
  return useMemo(() => {
    const actions: CustomToolbarAction[] = [
      {
        actionKey: ErpPositionsKind.STANDARD,
        IconComponent: ControlPointIcon,
        title: t('COMMON.ERP.POSITION'),
        disabled,
        handler: () => {
          onAddSpecial(ErpPositionsKind.TEXTZEILE, { id: 0 });
        },
        mode: 'Add',
      },
      {
        actionKey: ErpPositionsKind.STANDARD,
        IconComponent: AddIcon,
        title: t('COMMON.ERP.POSITION_NO_ARTICLE'),
        disabled,
        handler: () => {
          onAddSpecial(ErpPositionsKind.STANDARD, { id: 0 });
        },
        mode: 'Add',
      },

      {
        actionKey: ErpPositionsKind.TEXTZEILE,
        IconComponent: AddIcon,
        title: t('COMMON.ERP.POSITION_EMPTY_TEXT'),
        disabled,
        handler: () => {
          onAddSpecial(ErpPositionsKind.TEXTZEILE);
        },
        mode: 'Add',
      },
      {
        actionKey: ErpPositionsKind.TITEL,
        IconComponent: TitleRow,
        title: t('COMMON.ERP.TITLE'),
        disabled,
        handler: () => {
          onAddSpecial(ErpPositionsKind.TITEL);
        },
        mode: 'Add',
      },
      {
        actionKey: ErpPositionsKind.TITELSUMME,
        IconComponent: TitleSum,
        title: t('COMMON.ERP.TITLE_TOTAL'),
        disabled,
        handler: () => {
          onAddSpecial(ErpPositionsKind.TITELSUMME);
        },
        mode: 'Add',
      },
      {
        actionKey: ErpPositionsKind.ZWISCHENSUMME,
        IconComponent: FunctionsIcon,
        title: t('COMMON.ERP.SUBTOTAL'),
        disabled,
        handler: () => {
          onAddSpecial(ErpPositionsKind.ZWISCHENSUMME);
        },
        mode: 'Add',
      },
      {
        actionKey: ErpPositionsKind.SEITENUMBRUCH,
        IconComponent: ExpandIcon,
        title: t('COMMON.PAGE_BREAK'),
        disabled,
        handler: () => {
          onAddSpecial(ErpPositionsKind.SEITENUMBRUCH);
        },
        mode: 'Add',
      },
    ];

    const mappedActions: CustomToolbarMappedAction[] = actions.map((x) => ({
      ...x,
      onClick: () => x.handler(''),
    }));

    return mappedActions;
  }, [t, disabled, onAddSpecial]);
};
