import { z } from 'zod';

export const AppointmentsTimeRangeSchema = z.enum([
  'agenda',
  'day',
  'week-5',
  'week-7',
  'month',
]);
export type AppointmentsTimeRange = z.infer<typeof AppointmentsTimeRangeSchema>;

export const AppointmentsGroupModeSchema = z.enum([
  'grouped',
  'horizontal',
  'vertical',
]);
export type AppointmentsGroupMode = z.infer<typeof AppointmentsGroupModeSchema>;

export const AppointmentCalendarParamsSchema = z.object({
  userIds: z.array(z.number()),
  focusedUserIds: z.array(z.number()),
  date: z.date(),
  range: AppointmentsTimeRangeSchema,
  groupMode: AppointmentsGroupModeSchema,
  appointmentStates: z.array(z.number()),
  planningAppointments: z.boolean().optional(),
});

export type AppointmentCalendarParams = z.infer<
  typeof AppointmentCalendarParamsSchema
>;

export const AppointmentCalendarLastParamsSchema =
  AppointmentCalendarParamsSchema.omit({ date: true });
export type AppointmentCalendarLastParams = z.infer<
  typeof AppointmentCalendarLastParamsSchema
>;

export const NullableAppointmentCalendarLastParamsSchema =
  AppointmentCalendarLastParamsSchema.nullable();
export type NullableAppointmentCalendarLastParams = z.infer<
  typeof NullableAppointmentCalendarLastParamsSchema
>;

const AppointmentCalendarViewSchema = z.object({
  id: z.string(),
  name: z.string(),
  readOnly: z.boolean().optional(),
  params: AppointmentCalendarLastParamsSchema,
});

export type AppointmentCalendarView = z.infer<
  typeof AppointmentCalendarViewSchema
>;

export const AppointmentCalendarViewArraySchema = z.array(
  AppointmentCalendarViewSchema
);
export type AppointmentCalendarViewArray = z.infer<
  typeof AppointmentCalendarViewArraySchema
>;

export const UserSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const AppointmentCalendarStateSchema = z.object({
  users: z.array(UserSchema),
  focusedUserIds: z.array(z.number()),
  date: z.date(),
  range: AppointmentsTimeRangeSchema,
  groupMode: AppointmentsGroupModeSchema,
  appointmentStates: z.array(z.number()),
  planningAppointments: z.boolean().optional(),
});

export type AppointmentCalendarState = z.infer<
  typeof AppointmentCalendarStateSchema
>;
