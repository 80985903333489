import { useCallback } from 'react';

import { MailboxContentItem, MailboxContentState } from './types';

export interface MailboxContentStateHelpers {
  getSelected: () => MailboxContentItem[];
}

export type UseMailboxContentStateHelpersOptions = MailboxContentState;

export function useMailboxContentStateHelpers(
  options: UseMailboxContentStateHelpersOptions
): MailboxContentStateHelpers {
  const { itemsById, selectedIds } = options;

  const getSelected = useCallback<
    MailboxContentStateHelpers['getSelected']
  >(() => {
    return selectedIds
      .map((id) => itemsById[id])
      .filter((item) => item != null);
  }, [itemsById, selectedIds]);

  return { getSelected };
}
