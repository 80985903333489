import { noop } from 'lodash';
import {
  createContext,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

interface IdSelectionRange {
  start: number;
  end: number;
  rowId: string;
  cellId: string;
}

interface EditRowSelectionState {
  selectionRangeRef: RefObject<IdSelectionRange | null>;
  onSelectionChange: (
    start?: number,
    end?: number,
    rowId?: string,
    cellId?: string
  ) => void;
}

const EditRowSelectionContext = createContext<EditRowSelectionState>({
  onSelectionChange: noop,
  selectionRangeRef: null,
});

export const EditRowSelectionProvider = (props: PropsWithChildren) => {
  const selectionRangeRef = useRef<IdSelectionRange | null>(null);

  const onSelectionChange = useCallback(
    (start?: number, end?: number, rowId?: string, cellId?: string) => {
      if (typeof start === 'number' && typeof end === 'number') {
        selectionRangeRef.current = {
          start,
          end,
          rowId,
          cellId,
        };
      } else {
        selectionRangeRef.current = null;
      }
    },
    []
  );

  const value = useMemo(
    () => ({
      selectionRangeRef,
      onSelectionChange,
    }),
    [onSelectionChange]
  );

  return (
    <EditRowSelectionContext.Provider value={value}>
      {props.children}
    </EditRowSelectionContext.Provider>
  );
};

export const useEditRowSeleciton = () => useContext(EditRowSelectionContext);
