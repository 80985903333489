import styles from './Sorter.module.scss';

import React, { useCallback, useEffect, useRef } from 'react';

import { ReactComponent as DownIcon } from '@work4all/assets/icons/arrow_drop_down.svg';
import { ReactComponent as UpIcon } from '@work4all/assets/icons/arrow_drop_up.svg';

import { Truncation } from '../../../../../../components/tooltip/Tooltip';
import { ColumnInstance } from '../../../../types';

interface ISorterProps extends Truncation {
  children?: React.ReactNode;
  column: ColumnInstance;
}

export const Sorter: React.FC<ISorterProps> = (_props) => {
  const { onTruncation, ...props } = _props;
  const { title: _, ...sorterProps } =
    props.column.getSortByToggleProps?.() ?? {};

  const childRef = useRef<HTMLDivElement>(null);

  const isElementTruncated = useCallback((element: HTMLElement): boolean => {
    const hasEllipsis =
      element.scrollWidth > element.clientWidth ||
      element.scrollHeight > element.clientHeight;
    if (hasEllipsis) return true;
    return false;
  }, []);

  useEffect(() => {
    if (childRef.current) {
      onTruncation(isElementTruncated(childRef.current));
    }
  }, [isElementTruncated, onTruncation]);

  return (
    <div className={styles.sorter} {...sorterProps}>
      {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <DownIcon className={styles.icon} />
        ) : (
          <UpIcon className={styles.icon} />
        )
      ) : undefined}
      <div ref={childRef} className={styles.content}>
        {props.children}
      </div>
    </div>
  );
};
