import styles from './MenuSearch.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase } from '@mui/material';
import { useEffect, useRef } from 'react';

import { MenuSearchProps } from './types';

export const MenuSearch = ({
  placeholder,
  searchedValue = '',
  setSearchValue,
}: MenuSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }, []);

  return (
    <div className={styles.wrapper}>
      <InputBase
        value={searchedValue}
        onChange={(e) => {
          setSearchValue(e.target.value ?? '');
        }}
        placeholder={placeholder}
        inputRef={inputRef}
      />

      {searchedValue.length === 0 ? (
        <SearchIcon color="disabled" />
      ) : (
        <IconButton
          onClick={() => {
            inputRef.current.focus();
            setSearchValue('');
          }}
          className={styles.closeIconButton}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};
