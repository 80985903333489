import styles from './EntityPickerActions.module.scss';

import React, { type JSX, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArticlePicker,
  IArticlePickerProps,
} from '@work4all/components/lib/components/entity-picker/article-picker/ArticlePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';
import { usePositionTableActions } from '../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/hooks/use-position-table-actions';
import { settings, useSetting } from '../../settings';

interface ArticlePickerFieldProps
  extends Omit<IArticlePickerProps<false>, 'multiple' | 'size'> {
  error?: string;
  name?: string;
  label?: string;
  onChange: (value: Article | ErpPositionsKind) => void;
  showFunctions?: boolean;
  autoFocus?: boolean;
  input?: JSX.Element;
}

export const ArticlePickerField = React.forwardRef<
  HTMLDivElement,
  ArticlePickerFieldProps
>(function ArticlePickerField(props, ref) {
  const {
    error,
    placeholder,
    label,
    input,
    autoFocus,
    showFunctions = false,
    favorites = true,
    ...pickerProps
  } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);
  const placeholderText = placeholder ?? t('COMMON.ARTICLE');
  const lastAutoFocus = useRef(false);

  useEffect(() => {
    if (!popoverRef.current) return;
    if (autoFocus) {
      popoverRef.current.open();
    } else if (lastAutoFocus.current) {
      // detect failing edge
      popoverRef.current.close();
    }
    lastAutoFocus.current = autoFocus;
  }, [autoFocus]);

  const entityTemplate = useEntityTemplate();

  const previewSize = useSetting(
    settings.filePreviewSize({
      fileType: 'article-position',
    })
  );

  const functions = usePositionTableActions({
    disabled: false,
    onAddSpecial: () => {},
  });
  const functionsWithoutFirst = useMemo(
    () => functions.slice(1, functions.length - 1),
    [functions]
  );

  return (
    <EntityPickerPopover
      ref={popoverRef}
      onClose={pickerProps.onClose}
      width={'content'}
      picker={
        <ArticlePicker
          favorites={favorites}
          {...pickerProps}
          multiple={false}
          functions={showFunctions ? functionsWithoutFirst : undefined}
          size={previewSize.value}
          onSizeChange={previewSize.set}
        />
      }
      footer={entityTemplate.renderPickerFooter({
        popoverRef,
        entity: Entities.article,
        ...props,
      })}
      size={previewSize.value}
      onSizeChange={previewSize.set}
    >
      {input ?? (
        <PickerTargetButton
          ref={ref}
          name={props.name}
          value={value ? value?.name : ''}
          onClick={() => {
            popoverRef.current.open();
          }}
          label={
            label || (
              <>
                {!value && placeholderText}
                {value && (
                  <>
                    <span className={styles.primaryInfo}>
                      {placeholderText} &nbsp;
                    </span>
                    <span className={styles.secondaryInfo}>
                      {value?.number}
                    </span>
                  </>
                )}
              </>
            )
          }
          error={error}
          onClear={() => {
            onChange(null);
          }}
        />
      )}
    </EntityPickerPopover>
  );
});
