import styles from './SplitButton.module.scss';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Fab, Theme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import clsx from 'clsx';
import { groupBy } from 'lodash';
import React, { type JSX, useRef, useState } from 'react';

import { Divider } from '../../dataDisplay/divider/Divider';

export interface Option {
  id: number | string;
  name: string;
  group?: string;
}

export interface SplitButtonProps<T extends Option = Option>
  extends Pick<ButtonGroupProps, 'variant'> {
  firstIsTitle?: boolean;
  options: T[];
  onClick: (
    option: T,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  disabled?: boolean;
  renderItem?: (option: T) => JSX.Element;
  mobileIcon?: JSX.Element;
  hideGroupLabels?: boolean;
  hideSelection?: boolean;
}

export const SplitButton = <T extends Option = Option>(
  props: SplitButtonProps<T>
) => {
  const {
    options,
    firstIsTitle,
    onClick,
    disabled,
    variant = 'contained',
    mobileIcon,
    hideGroupLabels,
    hideSelection,
  } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const [selected, setSelected] = useState(options[0]);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClick(selected, event);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: T
  ) => {
    if (!firstIsTitle) setSelected(option);
    setOpen(false);
    onClick(option, event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const md = useMediaQuery<Theme>((t) => t.breakpoints.down('md'));

  const style =
    variant === 'contained'
      ? undefined
      : {
          color: 'var(--brand01)',
        };
  const calculatedOptions = firstIsTitle ? options.slice(1) : options;
  const groupedOptions = groupBy(calculatedOptions, 'group');

  return (
    <React.Fragment>
      {md ? (
        <Fab
          ref={anchorRef as React.RefObject<HTMLButtonElement>}
          color="primary"
          size="medium"
          data-test-id={'more-icon-button'}
          onClick={handleToggle}
        >
          {mobileIcon || <KeyboardArrowDownIcon />}
        </Fab>
      ) : (
        <ButtonGroup
          variant={variant}
          ref={anchorRef as React.RefObject<HTMLDivElement>}
          aria-label="split button"
          style={style}
        >
          <Button
            disabled={disabled}
            variant={variant}
            style={{
              borderRightColor: variant === 'contained' ? 'white' : undefined,
              ...style,
            }}
            className={clsx(styles['border'], {
              [styles['not-bold']]: variant === 'contained',
            })}
            onClick={handleClick}
          >
            {props.renderItem ? props.renderItem(selected) : selected?.name}
          </Button>
          <Button
            style={style}
            disabled={disabled}
            variant={variant}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            //   aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </ButtonGroup>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        className={styles.popper}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  className={styles.menu}
                >
                  {Object.entries(groupedOptions).map((group) => {
                    return (
                      <React.Fragment key={group[0]}>
                        {group[0] && group[0] !== 'undefined' && (
                          <Divider title={hideGroupLabels ? '' : group[0]} />
                        )}
                        {group[1].map((option) => (
                          <MenuItem
                            key={option.id}
                            className={styles['menu-item']}
                            selected={
                              !hideSelection && option.id === selected.id
                            }
                            onClick={(event) =>
                              handleMenuItemClick(event, option)
                            }
                          >
                            {props.renderItem
                              ? props.renderItem(option)
                              : option.name}
                          </MenuItem>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
