import styles from './styles.module.scss';

import { useContext, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { ReactComponent as ChevronLeftIcon } from '@work4all/assets/icons/chevron-left.svg';

import { ListItem2 } from '@work4all/components/lib/input/filter-components/popover-list/components/list-item2/ListItem2';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useNavigate } from '@work4all/data';

import {
  DND_POPOVER_OPEN_DELAY,
  DndTypes,
} from '@work4all/utils/lib/variables';

import { IFavoriteLinksFolder } from '../../../../containers/more/data/favorite-links';
import { useDropEnabled } from '../../../../dnd/use-drop-enabled';
import { addDateParamToLink } from '../../add-date-param-to-link';
import { PopoverList } from '../popover/PopoverList';

import { LinksOverflowContext } from './links-overflow-context';

export interface IFavoriteLinksOverflowFolderViewProps {
  folderId: string;
  onClose: () => void;
}

export function OverflowFolderView({
  folderId,
  onClose,
}: IFavoriteLinksOverflowFolderViewProps) {
  const { items, activeLink } = useContext(LinksOverflowContext);

  const folder = useMemo(() => {
    const folder = items.find(
      (item) => item.id === folderId
    ) as IFavoriteLinksFolder;
    return folder;
  }, [items, folderId]);

  const { goBack } = useHistoryStack();
  const navigate = useNavigate();

  const [backButton, backButtonDrop] = useDrop({
    accept: [DndTypes.LINK, DndTypes.FAVORITE_LINK],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const dropEnabled = useDropEnabled(backButton.isOver);

  useEffect(() => {
    if (dropEnabled && backButton.isOver) {
      const timeout = setTimeout(() => {
        goBack();
      }, DND_POPOVER_OPEN_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [goBack, dropEnabled, backButton.isOver]);

  if (!folder) {
    return null;
  }

  return (
    <>
      <ListItem2
        ref={(node) => {
          backButtonDrop(node);
        }}
        className={styles.popoverItem}
        onClick={() => goBack()}
      >
        <ChevronLeftIcon className={styles.folderIcon} /> Back | {folder?.name}
      </ListItem2>

      <PopoverList
        folder={folder}
        items={folder.links}
        activeLink={activeLink}
        onLinkClick={(link) => {
          navigate(addDateParamToLink(link.href));
          onClose();
        }}
        onFolderClick={() => void 0}
      />
    </>
  );
}
