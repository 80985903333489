import styles from './CommentEditor.module.scss';

import { useTranslation } from 'react-i18next';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { TextEditor } from '../../../../input/format-text/TextEditor';
import { TextEditorConfig } from '../../../../input/format-text/TextEditor/types';
import { generateFileUploadUrl } from '../../../../utils/generateFileUploadUrl';

interface CommentEditorProps {
  value?: string;
  setValue: (value: string) => void;
  canAddOrInsertImage: boolean;
  editorConf?: TextEditorConfig;
  hideToolbar?: boolean;
  disabled?: boolean;
}

export const CommentEditor = ({
  value,
  setValue,
  canAddOrInsertImage,
  editorConf,
  hideToolbar,
  disabled,
}: CommentEditorProps) => {
  const { activeTenant } = useTenant();
  const user = useUser();

  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <TextEditor
        defaultValue={value}
        // Workaround: keep it for reset TextEditor
        value={value === '' ? '' : undefined}
        disabled={disabled}
        onChange={setValue}
        enableMentions
        enableTextBuildingBlocks={true}
        config={{
          placeholderText: t('MASK.ADD_COMMENT'),
          requestHeaders: {
            'x-work4all-mandant': activeTenant,
          },
          imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
          height: 43,
          heightMin: 150,
          heightMax: 300,
          pastePlain: true,
          ...editorConf,
        }}
        canAddOrInsertImage={canAddOrInsertImage}
        hideToolbar={hideToolbar}
      />
    </div>
  );
};
