import React, { useMemo, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { ColumnInstance, TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { IDragObject } from '@work4all/components/lib/components/draggable-item/DraggableItem';
import { MoreMenu } from '@work4all/components/lib/dataDisplay/actions-bar/MoreMenu';
import { Columns } from '@work4all/components/lib/input/actions/columns/Columns';
import { IActionConfig } from '@work4all/components/lib/input/actions/types';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { DndTypes } from '@work4all/utils/lib/variables';

import { ActionConfig } from '../../../../../../../../../../../components/data-tables/table/components/toolbar/components/actions-bar-mobile/ActionsMenuMobile';
import { ColumnsVisibility } from '../../../../../../../../../../../components/data-tables/table/components/toolbar/components/columns-visibility';
import { GroupingsVisibilty } from '../../../../../../../../../../../components/data-tables/table/components/toolbar/components/groupings/GroupingsVisibilty';
import { SelectedGroupsList } from '../../../../../../../../../../../components/data-tables/table/components/toolbar/components/groupings/selected-groups-list/SelectedGroupsList';
import { WithBubble } from '../../../../../../../../../../../components/data-tables/table/components/with-bubble/WithBubble';
import { useTableLayoutState } from '../../../../../../../../../../../components/data-tables/table-layout';

interface PositionMoreProps {
  disabled: boolean;
  tableInstanceRef: React.RefObject<TableInstance | null>;
  resetColumns: () => void;
}
const emptyGroupBy: string[] = [];
const emptyColumns: ColumnInstance[] = [];

export const PositionMore = (props: PositionMoreProps) => {
  const { tableInstanceRef, resetColumns } = props;
  const { t } = useTranslation();

  const groupingsRootRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const columnsRootRef = useRef<HTMLDivElement | HTMLButtonElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const layoutState = useTableLayoutState();
  const { tableState, columnsById } = useTableStateBag();
  const groupBy = tableState?.groupBy || emptyGroupBy;

  const [layout] = layoutState;

  const [, drop] = useDrop(
    () => ({
      accept: DndTypes.BASIC_TABLE_COL,
      drop: (draggedItem: IDragObject) => {
        tableInstanceRef.current?.setGroupBy([...groupBy, draggedItem.id]);
      },
    }),
    [groupBy]
  );

  const groupingsPopoverState = usePopoverState();
  const columnsPopoverState = usePopoverState();
  const openColumnsPopover = columnsPopoverState.handleClick;
  const openGroupingPopover = groupingsPopoverState.handleClick;

  const actions: Record<
    'columns' | 'group',
    Omit<(IActionConfig & ActionConfig) | null, 'href'>
  > = useMemo(() => {
    return {
      columns: {
        onClick: (event) => {
          return openColumnsPopover(event, menuButtonRef.current);
        },
        rootRef: columnsRootRef,
        label: layout === 'cards' ? t('MASK.ROWS') : undefined,
      },
      group: {
        onClick: (event) => {
          openGroupingPopover(event, menuButtonRef.current);
        },
        margin: 'lg',
        rootRef: (node) => {
          groupingsRootRef.current = node;
          drop(node);
        },
        children: (
          <WithBubble count={tableState?.groupBy?.length}>
            <SelectedGroupsList
              columnsById={columnsById}
              tableInstanceRef={tableInstanceRef}
              groupedBy={tableState?.groupBy}
            />
          </WithBubble>
        ),
      },
    };
  }, [
    columnsById,
    drop,
    layout,
    openColumnsPopover,
    openGroupingPopover,
    t,
    tableInstanceRef,
    tableState?.groupBy,
  ]);

  return (
    <React.Fragment>
      <GroupingsVisibilty
        popoverState={groupingsPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns}
      />
      <Columns {...actions.columns} />
      <ColumnsVisibility
        popoverState={columnsPopoverState}
        visibleColumns={tableInstanceRef.current?.allColumns ?? emptyColumns}
        onResetColumns={resetColumns}
      />
      <MoreMenu {...props} {...actions} buttonRef={menuButtonRef} />
    </React.Fragment>
  );
};
