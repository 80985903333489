import React, { type JSX, useRef } from 'react';
import { ConnectableElement, useDrag, useDrop } from 'react-dnd';

type Identifier = string | symbol;

export interface IDragObject {
  type: Identifier;
  id: string;
}

export interface IDraggableCellProps {
  id: string;
  type: Identifier;
  accept: Identifier | Identifier[];
  children: (drag: (instance: ConnectableElement) => void) => JSX.Element;
  onDragEnd: (draggedItem: IDragObject, dropTarget: IDragObject) => void;
}

export const DraggableItem: React.FC<IDraggableCellProps> = (props) => {
  const { type, accept, id, onDragEnd } = props;

  const [, drag] = useDrag(() => ({
    type,
    item: {
      id: props.id,
      type,
    },
  }));

  const [, drop] = useDrop(
    () => ({
      accept,
      drop: (draggedItem: IDragObject) => {
        const dropTarget = { id, type };
        onDragEnd(draggedItem, dropTarget);
      },
    }),
    [onDragEnd]
  );

  const cellRef = useRef((node) => {
    drag(node);
    drop(node);
  });
  return props.children(cellRef.current);
};
