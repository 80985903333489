import styles from './NavGroup.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useDrag } from 'react-dnd';

import { SearchTextResult } from '@work4all/components/lib/utils/search-text-result/SearchTextResult';

import { DndTypes } from '@work4all/utils/lib/variables';

import {
  MatchedNavigationGroup,
  NavigationLink,
} from '../../../containers/more/types';
import { LinksFolderDragObject } from '../../../dnd/drag-objects';
import { useEmptyDragPreview } from '../../../dnd/use-empty-drag-preview';
import { NavSubgroup } from '../nav-subgroup/NavSubgroup';

export interface INavGroupProps {
  group: MatchedNavigationGroup;
  onItemFavoriteChange?: (item: NavigationLink, favorite: boolean) => void;
}

export const NavGroup = memo(function NavGroup(props: INavGroupProps) {
  const { group, onItemFavoriteChange } = props;

  const links = useMemo(() => {
    return group.subgroups.flatMap((subgroup) => subgroup.links);
  }, [group]);

  const nonDisabledLinks = useMemo(() => {
    return links.filter((link) => !link.disabled);
  }, [links]);

  const isGroupDraggable = nonDisabledLinks.length > 0;

  const [, drag, preview] = useDrag<LinksFolderDragObject, unknown, unknown>({
    canDrag() {
      return isGroupDraggable;
    },
    type: DndTypes.LINKS_FOLDER,
    item: { text: group.name, links: nonDisabledLinks },
  });

  useEmptyDragPreview(preview);

  const showAll = group.result === undefined || group.result.match === true;

  return (
    <div className={clsx(styles.root)}>
      <Typography
        ref={(node) => {
          drag(node);
        }}
        variant="h4"
        className={clsx(styles.name, isGroupDraggable && styles.draggable)}
      >
        {group.result ? <SearchTextResult result={group.result} /> : group.name}
      </Typography>

      {group.subgroups.map((subgroup) => {
        if (subgroup.matches === 0 && !showAll) {
          return null;
        }

        return (
          <NavSubgroup
            key={subgroup.name ?? ''}
            showAll={showAll}
            subgroup={subgroup}
            onItemFavoriteChange={onItemFavoriteChange}
          />
        );
      })}
    </div>
  );
});
