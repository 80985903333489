import { type JSX, RefObject, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { DndTypes } from '@work4all/utils/lib/variables';

export type IDraggableColumnDndItem = {
  id: string;
  index: number | null;
  canGroupBy: boolean;
};

export type IDraggableColumnHeaderWrapperProps = {
  id: string;
  index: number;
  onHover: (item: IDraggableColumnDndItem) => void;
  onDrop: (item: IDraggableColumnDndItem) => void;
  onDragEnd: () => void;
  children: (props: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dragRef: RefObject<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dropRef: RefObject<any>;
  }) => JSX.Element;
  canGroupBy: boolean;
};
export function DraggableColumnHeaderWrapper(
  props: IDraggableColumnHeaderWrapperProps
) {
  const { id, index, onHover, onDragEnd, onDrop, children, canGroupBy } = props;

  const dragRef = useRef<HTMLElement>(null);
  const dropRef = useRef<HTMLElement>(null);

  const [, drag] = useDrag<IDraggableColumnDndItem, unknown, never>({
    type: DndTypes.BASIC_TABLE_COL,
    item: { id, canGroupBy, index: null },
    end() {
      onDragEnd();
    },
  });

  const [, drop] = useDrop<IDraggableColumnDndItem, unknown, never>({
    accept: DndTypes.BASIC_TABLE_COL,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }

      // If hovering over oneself, no need to calculate the position
      if (id === item.id) {
        if (index !== item.index) {
          item.index = index;
          onHover(item);
        }

        return;
      }

      // If hovering over the left part of the column, insert before.
      // Otherwise insert after.

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      const targetIndex = hoverClientX < hoverMiddleX ? index : index + 1;

      if (item.index !== targetIndex) {
        item.index = targetIndex;
        onHover(item);
      }
    },
    drop(item) {
      onDrop(item);
    },
  });

  drag(dragRef);
  drop(dropRef);

  return children({ dragRef, dropRef });
}
