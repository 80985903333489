import styles from './SearchWrapper.module.scss';

import { styled, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { type JSX, useState } from 'react';
import { DeepReadonly } from 'rxdb/dist/types/types';

import { FixedBackground } from '@work4all/components/lib/components/FixedBackground';
import { DropdownFooter } from '@work4all/components/lib/input/search-bar-with-fullscreen/components/dropdown-footer/DropdownFooter';
import { ActionType } from '@work4all/components/lib/input/search-bar-with-fullscreen/types';
import { DoubleClickShield } from '@work4all/components/lib/utils/double-click-shield/DoubleClickShield';

import { useCustomBackgroundsContext } from '@work4all/data';

import { FileData } from '@work4all/models/lib/File';

import { OnElementSelected } from '../types';

import { Actions } from './actions/Actions';
import { FileNavigation } from './file-navigation/FileNavigation';
import { Header } from './Header';
import { SearchHistory } from './search-history/SearchHistory';

interface Props {
  header: {
    title: string;
    description: string;
  };
  footerActions?: ActionType[];
  actions: ActionType[];
  search: {
    onChange: (search: string) => void;
    inputValue: string;
    placeholder?: string;
    showGoogleSearch?: boolean;
  };
  list?: JSX.Element[];
  suggestions?: React.ReactNode;
  history: {
    lastSearchItems: DeepReadonly<FileData[]>;
    onSelect: OnElementSelected;
  };
}

export const SearchWrapper: React.FC<Props> = (props) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const xs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const desktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const { activeBackground } = useCustomBackgroundsContext();

  const dropdownFooterElements = props.footerActions ? (
    <DropdownFooter actions={props.footerActions} />
  ) : undefined;

  const showCustomBackground = !!activeBackground;
  return (
    <Wrapper>
      {showCustomBackground && <FixedBackground src={activeBackground.url} />}
      <div className={styles.content}>
        {!dropDownActive && (
          <Header {...props.header} inverseColor={showCustomBackground} />
        )}
        <FileNavigation
          {...props.search}
          onChangeValue={props.search.onChange}
          onChange={(e) => props.search.onChange(e.currentTarget.value)}
          onKeyUp={() => setDropDownActive(true)}
          onHideDropDown={() => setDropDownActive(false)}
          placeholder={props.search.placeholder}
          active={dropDownActive}
          dropdown={{
            items: props.list,
            footer: dropdownFooterElements,
            suggestions: props.suggestions,
          }}
          mobile={xs}
          autofocus={desktop}
          footerActions={props.footerActions}
        />
        <SearchHistoryWrapper hidden={dropDownActive}>
          <DoubleClickShield active={dropDownActive} />
          {props.history.lastSearchItems.length > 0 && (
            <SearchHistory
              onSelect={props.history.onSelect}
              historyItems={props.history.lastSearchItems}
              inverseColor={showCustomBackground}
              maxItems={6}
              maxItemsSM={3}
            />
          )}
          <Actions
            actions={props.actions}
            inverseColor={showCustomBackground}
          />
        </SearchHistoryWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: '100%',
  padding: '1rem',
  paddingTop: 'var(--w4a-safe-area-top, 0px)',
  isolation: 'isolate',

  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.vars.palette.ui3.main,
  },

  [theme.breakpoints.up('xl')]: {
    paddingTop: '10rem',
  },
}));

const SearchHistoryWrapper = styled('div')({
  position: 'relative',
});
