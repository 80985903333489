import styles from './MaskOverlayHeader.module.scss';

import LinearProgress from '@mui/material/LinearProgress';
import React, { type JSX } from 'react';

import { BanderoleInfo } from '@work4all/components/lib/components/banderole/BanderoleInfo';

import { NavigationOverlayHeader } from '../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { AutoLockedBanderole } from '../../../components/banderoles/AutoLockedBanderole';
import { MaskActions } from '../MaskActions';

export interface IMaskOverlayHeaderProps {
  title?: string;
  subTitle?: string;
  isLoading?: boolean;
  tabs?: JSX.Element;
  actions?: JSX.Element;
  infoBanderoleText?: string | JSX.Element;
}

export const MaskOverlayHeader: React.FC<IMaskOverlayHeaderProps> = (props) => {
  const { title, subTitle, isLoading, tabs, actions, infoBanderoleText } =
    props;

  return (
    <NavigationOverlayHeader
      title={title}
      subTitle={subTitle}
      breadcrumbTitle={subTitle}
      breadcrumbsChildren={
        actions ? <MaskActions>{actions}</MaskActions> : null
      }
    >
      {tabs ? <div className={styles.tabsWrapper}>{tabs}</div> : null}
      {isLoading ? (
        <LinearProgress className="linearProgress" />
      ) : (
        <>
          {!infoBanderoleText && <AutoLockedBanderole />}
          <BanderoleInfo
            visible={Boolean(infoBanderoleText)}
            text={infoBanderoleText}
          />
        </>
      )}
    </NavigationOverlayHeader>
  );
};
