import { z } from 'zod';

const FavoriteLinkSchema = z.object({
  kind: z.literal('link'),
  id: z.string(),
  name: z.string(),
  translationKey: z.string().optional().nullable(),
  href: z.string(),
});

const FavoriteLinksFolderSchema = z.object({
  kind: z.literal('folder'),
  id: z.string(),
  name: z.string(),
  links: z.array(FavoriteLinkSchema),
});

export const FavoriteLinkOrFolderSchema = z.union([
  FavoriteLinkSchema,
  FavoriteLinksFolderSchema,
]);

export type IFavoriteLink = z.infer<typeof FavoriteLinkSchema>;
export type IFavoriteLinksFolder = z.infer<typeof FavoriteLinksFolderSchema>;
export type IFavoriteLinkOrFolder = z.infer<typeof FavoriteLinkOrFolderSchema>;

export const IFavoriteLinkOrFolderArraySchema = z.array(
  FavoriteLinkOrFolderSchema
);
export type IFavoriteLinkOrFolderArray = z.infer<
  typeof IFavoriteLinkOrFolderArraySchema
>;
