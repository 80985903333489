import styles from './DropIndicator.module.scss';

import { useDrop } from 'react-dnd';

import { DndTypes } from '@work4all/utils/lib/variables';

export type IDropIndicatorProps = {
  onDrop: (type: unknown, item: unknown) => void;
};

export function DropIndicator(props: IDropIndicatorProps) {
  const { onDrop } = props;

  const [, drop] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    drop: (item, monitor) => {
      onDrop(monitor.getItemType(), item);
    },
  });

  return (
    <div
      ref={(node) => {
        drop(node);
      }}
      className={styles.dropIndicator}
    />
  );
}
