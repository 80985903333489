import styles from './EnitityPickerDefaultSettings.module.scss';

import { IconButton, Paper, Popover, RadioGroup } from '@mui/material';
import { t } from 'i18next';
import { useCallback, useRef, useState } from 'react';

import { ReactComponent as ContextMenuIcon } from '@work4all/assets/icons/context-menu-icon.svg';

import { EntityPickerSettingsFilterBy } from '@work4all/data/lib/settings/settings';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Divider } from '../../../../../dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '../../../../../input/checkbox-radio-item';
import { usePickerSettings } from '../../../hooks/use-picker-settings';

export const EntityPickerDefaultSettings = (props: {
  entity: Entities;
  inputRef: React.RefObject<HTMLInputElement>;
}) => {
  const { entity, inputRef } = props;
  const {
    settings,
    filteredEntitiesSetting,
    setHideClosedPickerEntities,
    sortedPickerEntities,
    setSortedPickerEntities,
  } = usePickerSettings(entity);

  const handleSettingsSortChange = (_e, value: string) => {
    setSortedPickerEntities(value);
  };

  const handleSettingsFiltersChange = (value: EntityPickerSettingsFilterBy) => {
    const filterIndex = filteredEntitiesSetting?.findIndex(
      (filter) => filter.key === value.key
    );
    const updatedFilters = [...filteredEntitiesSetting];

    if (filterIndex !== -1) {
      // If the filter exists, delete it
      updatedFilters.splice(filterIndex, 1);
    } else {
      // If the filter does not exist, add it
      updatedFilters.push(value);
    }
    setHideClosedPickerEntities(updatedFilters.filter(Boolean));
  };

  const contextMenuRef = useRef(null);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const handleToggleSettings = useCallback(() => {
    setShowSettings(!showSettings);
    inputRef.current?.focus();
  }, [inputRef, showSettings]);

  return (
    settings && (
      <>
        <IconButton ref={contextMenuRef} onClick={handleToggleSettings}>
          <ContextMenuIcon />
        </IconButton>
        {showSettings && (
          <Popover
            classes={{ paper: styles['settings-popover'] }}
            open={showSettings}
            onClose={() => setShowSettings(false)}
            anchorEl={contextMenuRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Paper className={styles['settings-paper']}>
              {settings.filter?.options?.length && (
                <>
                  <Divider title={t('COMMON.FILTER')} size="body" />
                  {settings.filter.options.map((option) => (
                    <CheckboxRadioItem
                      key={option.key}
                      checked={
                        !filteredEntitiesSetting
                          ?.map((setting) => setting.key)
                          ?.includes(option.key)
                      }
                      label={t(`INPUTS.SHOW_${option.key.toUpperCase()}`)}
                      value={option.key}
                      onChange={() => handleSettingsFiltersChange(option)}
                    />
                  ))}
                </>
              )}

              <Divider title={t('COMMON.SORTING')} size="body" />
              <RadioGroup onChange={handleSettingsSortChange}>
                {settings.sort?.options?.map((option) => (
                  <CheckboxRadioItem
                    key={option}
                    checked={sortedPickerEntities === option}
                    value={option}
                    control="radio"
                    label={t(`INPUTS.SORT.${option.toUpperCase()}`)}
                  />
                ))}
              </RadioGroup>
            </Paper>
          </Popover>
        )}
      </>
    )
  );
};
