import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '../types';

import { ArticlePreview } from './ArticlePreview';

type ArticlePreviewContainerProps = EntityPreviewContainerProps;
export const articlePreviewFields: Article<EMode.query> = {
  id: null,
  name: null,
  number: null,
  unit: {
    id: null,
    name: null,
  },
  longtext: null,
  entryPrice: null,
  articleGroup: {
    id: null,
    name: null,
  },
  groupId: null,
  articleDocumentList: {
    file: {
      id: null,
      fileInfos: {
        previewUrl: null,
        previewMimeType: null,
        fileServiceProviderInfos: {
          id: null,
          exists: null,
        },
      },
    },
    articleImage: {
      id: null,
      fileInfos: {
        previewUrl: null,
        previewMimeType: null,
        fileServiceProviderInfos: {
          id: null,
          exists: null,
        },
      },
    },
  },
  standardImageId: null,
};

export const ArticlePreviewContainer = (
  props: ArticlePreviewContainerProps
) => {
  const {
    ids,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;

  const articleId = ids[0];
  const articles = useEntityPreviewData<Article>(
    [articleId],
    Entities.article,
    articlePreviewFields
  );

  if (!articles) {
    return null;
  }

  return (
    <ArticlePreview
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      articles={articles}
      onShareClicked={onShareClicked}
      convertProps={convertProps}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
};
