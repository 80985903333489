import { z } from 'zod';

export const WordLetterTemplateSchema = z.object({
  id: z.number().optional(),
  templateFilename: z.string().optional(),
  body: z.string().optional(),
  title: z.string().optional(),
  serialLetter: z.boolean().optional(),
  templateType: z.any().optional(),
  groupId: z.number().optional(),
  folder: z.string().optional(),
  documentSubClass: z.string().optional(),
  templateGroup: z.any().optional(),
  fileInfos: z.any().optional(),
  settings: z.string().optional(),
  __typename: z.string().optional(),
});
