import { useMemo } from 'react';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';

import { BOM_COMPONENT_DATA } from './tabs/components/constants';

export const ARTICLE_DOCUMENT_LIST = {
  file: {
    id: null,
    name: null,
    fileInfos: {
      fileSize: null,
      fileEntityFilename: null,
      previewUrl: null,
      downloadUrl: null,
      previewMimeType: null,
      downloadMimeType: null,
    },
  },
  articleImage: {
    id: null,
    name: null,
    fileInfos: {
      fileSize: null,
      fileEntityFilename: null,
      previewUrl: null,
      downloadUrl: null,
      previewMimeType: null,
      downloadMimeType: null,
    },
    deactivated: null,
    asAttachmentCalculation: null,
    asAttachmentDeliveryNote: null,
    asAttachmentDemand: null,
    asAttachmentInvoice: null,
    asAttachmentOffer: null,
    asAttachmentOrder: null,
    asAttachmentOrderConfirmation: null,
    showInShop: null,
  },
};

export function useArticleRequest({ id }: { id?: string | number }) {
  const request = useMemo(() => {
    const filter = [{ id: { $eq: id } }];

    const data: Article<EMode.query> = {
      id: null,
      articleKind: null,
      name: null,
      longtext: null,
      internalText: null,
      number: null,
      factor: null,
      isNoStockArticle: null,
      hideInShop: null,
      shopOrderWithoutStock: null,
      isRentalArticle: null,
      isCharge: null,
      isOutsideService: null,
      isShutDown: null,
      internalArticle: null,
      serialNumberInbound: null,
      articleGroup: {
        id: null,
        name: null,
      },
      groupId: null,
      unit: {
        id: null,
        name: null,
        factor: null,
      },
      unitId: null,
      maintenanceShare: null,
      customsTariffNumber: null,
      summarizeForPackingLists: null,
      isArticleForRessourcePlaning: null,
      ressourceClass: {
        id: null,
        name: null,
      },
      ressourceClassId: null,
      manufacturer: {
        id: null,
        name: null,
        number: null,
        website: null,
      },
      manufacturerId: null,
      manufacturerNumber: null,
      ean: null,
      costCenterNew: { id: null, name: null },
      costCenterId: null,
      landOfOrigin: null,
      vatRate: {
        id: null,
        sentence: null,
      },
      vatId: null,
      articleLedgerAccountAssignements: [
        {
          id: null,
          taxGroup: {
            id: null,
            name: null,
            type: null,
          },
          vatRate: {
            id: null,
            sentence: null,
          },
          ledgerAccount: {
            id: null,
            name: null,
            number: null,
          },
        },
      ],
      articleTextList: [
        {
          id: null,
          articleId: null,
          kurztext: null,
          longtext: null,
          einheitCode: null,
          sprachCode: null,
        },
      ],
      entryPrice: null,
      articlePrices: {
        singlePriceList: [
          {
            id: null,
            priceGroup: { id: null, name: null },
            price: null,
            surcharge: null,
            minuteWage: null,
            isFixedPrice: null,
          },
        ],
        purchasePriceList: [
          {
            id: null,
            supplier: {
              id: null,
              number: null,
              name: null,
            },
            validFromDate: null,
            listPrice: null,
            discount: null,
            purchasePrice: null,
          },
        ],
      },
      articleDocumentList: ARTICLE_DOCUMENT_LIST,
      discountable: null,
      hasNoCommission: null,
      noSkonto: null,
      standardSupplierId: null,
      standardImageId: null,
      standardPurchasePriceAsEntryPrice: null,
      customFieldList: [CUSTOM_FIELDS_DATA],
      bomComponents: [BOM_COMPONENT_DATA],
    };

    const request: DataRequest = {
      operationName: 'GetArticle',
      entity: Entities.article,
      data,
      filter,
    };

    return request;
  }, [id]);

  return request;
}
