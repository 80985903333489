import styles from './BoardColumn.module.scss';

import { Typography } from '@mui/material';
import { isEqual } from 'lodash';
import React, { type JSX, useEffect, useMemo, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { Row, TableInstance } from 'react-table';
import { Subject } from 'rxjs';

import {
  BasicTable,
  TableStateBagProvider,
  useTableStateBag,
} from '@work4all/components';

import { ICustomCellConfigBase } from '@work4all/models/lib/table-schema/table-schema';

import { useOpenMask } from '../../../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import {
  IUseEntityDataOptions,
  useEntityDataTable,
} from '../../../entity-table/use-entity-data-table';
import { useTableHandlers } from '../../../use-table-handlers';
import { useUserColumnConfigs } from '../../../use-user-column-configs';
import { useTableColumns } from '../../hooks/use-table-columns';

import { BoardItem } from './BoardItem';
import { DragItem } from './types';

interface BoardColumnProps {
  status: string;
  prefilter: unknown[];
  onDrop: (item: unknown) => void;
  tableProps: IUseEntityDataOptions<
    unknown,
    Record<string, ICustomCellConfigBase>
  >;
}

export const BoardColumn: React.FC<BoardColumnProps> = ({
  status,
  prefilter,
  onDrop,
  tableProps,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'BOARD_ITEM',
    drop: (item: DragItem) => onDrop(item.item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const tableInstanceRef = useRef<TableInstance>(null);
  const dataTable = useTableKanbanColumn(prefilter, tableProps);

  const cardConfig = useMemo(() => {
    return {
      ...dataTable.cardConfig,
      secondaryIcons: [],
      wrapper: BoardItem,
    };
  }, [dataTable.cardConfig]);

  // Remove selected if status is different
  useEffect(() => {
    const subscription = BoardIdObserver.subscribe((event) => {
      if (!event) return;

      if (event.status === status || !event.ids.length) return;
      tableInstanceRef.current.toggleAllRowsSelected(false);
    });
    return () => subscription.unsubscribe();
  }, [status]);

  const bag = useTableStateBag();

  return (
    <div
      ref={(node) => {
        drop(node);
      }}
      className={styles.board}
      style={{
        backgroundColor: isOver ? 'var(--ui03)' : undefined,
      }}
    >
      <Typography variant="caption" className={styles.header}>
        {status.toUpperCase()}
      </Typography>
      <div className={styles.boardContent}>
        <TableStateBagProvider tableInstance={tableInstanceRef.current}>
          <BoardColumnTableWrapper
            dataTable={dataTable}
            groupBy={bag.tableState?.groupBy}
          >
            {(columnsCache, tableHandlers) => (
              <BasicTable
                mode="server"
                ref={tableInstanceRef}
                cardsView
                manualGroupBy
                columns={columnsCache.columns}
                className={styles.table}
                allItemsCount={dataTable.total}
                classes={{
                  row: styles.cardRow,
                  tableBody: styles.tableBody,
                }}
                selectableRows={true}
                selectableMultiple={false}
                defaultHidden={columnsCache.defaultHiddenColumnids}
                onRowDoubleClick={(id) => {
                  const entityId = id.substring(id.lastIndexOf('.') + 1);
                  tableHandlers.edit?.handler(entityId);
                }}
                {...dataTable}
                cardConfig={cardConfig}
                loadMoreItems={dataTable.fetchMore}
                onSelectedRowsChange={(rows: Row[]) => {
                  const idRows = rows as unknown as Row<{ id: number }>[];
                  updateState({
                    ids: idRows.map((x) => x.original.id),
                    status,
                  });
                }}
              />
            )}
          </BoardColumnTableWrapper>
        </TableStateBagProvider>
      </div>
    </div>
  );
};

export const useTableKanbanColumn = (
  prefilter: unknown[],
  tableProps: IUseEntityDataOptions<
    unknown,
    Record<string, ICustomCellConfigBase>
  >
) => {
  const dataTable = useEntityDataTable({
    ...tableProps,
    prefilter,
    pageSize: 20,
    skipSyncBag: true,
  });

  return dataTable;
};

export const BoardIdObserver = new Subject<{
  ids: number[];
  status: string;
}>();
const updateState = (newState) => {
  BoardIdObserver.next(newState);
};

interface BoardColumnTableWrapperProps {
  children: (
    columnsCache: ReturnType<typeof useTableColumns>,
    tableHandlers: ReturnType<typeof useTableHandlers>
  ) => JSX.Element;
  dataTable: ReturnType<typeof useTableKanbanColumn>;
  groupBy: string[];
}

const BoardColumnTableWrapper = ({
  children,
  dataTable,
  groupBy,
}: BoardColumnTableWrapperProps) => {
  const [userConfig] = useUserColumnConfigs({
    layout: 'board',
    entityType: dataTable.entityType,
    columnConfigs: dataTable.columnConfigs,
  });

  const columnsCache = useTableColumns({
    columnConfigs: dataTable.columnConfigs ?? userConfig,
    listEntityType: dataTable.entityType,
  });

  const bag = useTableStateBag();

  const lastGrupBy = useRef<string[]>([]);
  useEffect(() => {
    if (!groupBy || !bag.tableState) return;
    if (isEqual(lastGrupBy.current, groupBy)) return;
    lastGrupBy.current = groupBy;
    bag.tableState.groupBy = groupBy;
  }, [groupBy]);

  const onOpenMask = useOpenMask({ entityType: dataTable.entityType });
  const tableHandlers = useTableHandlers(dataTable.entityType, onOpenMask);

  return children(columnsCache, tableHandlers);
};
