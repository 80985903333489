import styles from './EntityPickerActions.module.scss';

import { Theme, Typography, useMediaQuery } from '@mui/material';
import React, { forwardRef, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IUserPickerProps,
  UserPicker,
} from '@work4all/components/lib/components/entity-picker/user-picker/UserPicker';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { ChipList } from '@work4all/components/lib/dataDisplay/chip/ChipList';

import {
  Chip,
  PickerTargetButton,
} from '../../containers/mask-overlays/locked-inputs';

interface UserPickerFieldProps<TMultiple extends boolean>
  extends IUserPickerProps<TMultiple> {
  label?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  fieldComp?: React.ReactNode;
}

const UserPickerField = function UserPickerField<TMultiple extends boolean>(
  props: UserPickerFieldProps<TMultiple>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    error,
    disabled,
    required,
    label,
    multiple = false,
    favorites = true,
    fieldComp,
    ...pickerProps
  } = props;
  const { value, onChange, clearable = true } = pickerProps;

  const { t } = useTranslation();

  let labelValue: string | JSX.Element = '';

  if (multiple && Array.isArray(value)) {
    labelValue = (
      <ChipList>
        {value.map((x) => {
          return (
            <Chip
              key={x.id}
              maxWidth={18}
              label={x.displayName}
              handleDelete={() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange(value.filter((y) => y.id !== x.id) as any);
              }}
            />
          );
        })}
      </ChipList>
    );
  } else if (!Array.isArray(value) && value?.id) {
    labelValue = (
      <div className={styles.userItem}>
        <HookedUserIcon userId={value.id} size="m" />
        <Typography
          variant="body1"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value.displayName}
        </Typography>
      </div>
    );
  }

  const field = fieldComp ? (
    <div>{fieldComp}</div>
  ) : (
    <PickerTargetButton
      ref={ref}
      value={labelValue}
      label={label ?? t('COMMON.EMPLOYEE')}
      error={error}
      multiline={multiple}
      disabled={disabled}
      required={required}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              onChange(null);
            }
      }
    />
  );

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  if (disabled) {
    return field;
  }

  return (
    <EntityPickerPopover
      picker={
        <UserPicker
          favorites={favorites}
          placeholder={t('SEARCH.USER_SEARCH_BY')}
          {...pickerProps}
          multiple={multiple}
        />
      }
      fullscreen={isMobile}
    >
      {field}
    </EntityPickerPopover>
  );
};

type UserPickerFieldPropsWithOptionalMultiple<T extends boolean> =
  T extends true
    ? UserPickerFieldProps<T>
    : Omit<UserPickerFieldProps<T>, 'multiple'> &
        Partial<Pick<UserPickerFieldProps<T>, 'multiple'>>;

const UserPickerFieldWithRef = forwardRef(UserPickerField) as <
  TMultiple extends boolean = false
>(
  props: UserPickerFieldPropsWithOptionalMultiple<TMultiple> &
    React.RefAttributes<HTMLDivElement>
) => JSX.Element;

export { UserPickerFieldWithRef as UserPickerField };
