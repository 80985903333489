import styles from './TableCell.module.scss';

import clsx from 'clsx';
import React, { ForwardedRef, forwardRef, useContext } from 'react';

import { Tooltip } from '../../../../components/tooltip/Tooltip';
import { ColumnVisibilityContext } from '../../hooks/useColumnVisibility';
import { ITableCell } from '../../types';
import { getAlignment } from '../../utils/alignment';

export interface ITableCellProps
  extends ITableCell,
    React.HTMLAttributes<HTMLDivElement> {
  columnId: string;
  component?: React.ElementType;
  cardsView?: boolean;
  tooltipTitle?: React.ReactNode;
  disableTooltip?: boolean;
}

export const TableCell = forwardRef(
  (props: ITableCellProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      component: Component = 'div',
      children,
      noPaddingLeft,
      noPaddingRight,
      className,
      dataType,
      noFlex,
      alignment: userAlignment,
      columnId,
      cardsView = false,
      tooltipTitle,
      disableTooltip,
      ...rest
    } = props;

    const columnVisibilityContext = useContext(ColumnVisibilityContext);

    const alignment = getAlignment(userAlignment, dataType);

    const renderCellContent = () => (
      <div
        className={clsx(
          styles.content,
          {
            [styles.card]: cardsView,
          },
          {
            [styles['cell--no-padding-left']]: noPaddingLeft,
            [styles['cell--no-padding-right']]: noPaddingRight,
          }
        )}
      >
        {children}
      </div>
    );

    return (
      <Component
        ref={ref}
        className={clsx(
          {
            [styles.highlighted]:
              columnVisibilityContext?.toggledColumnId === columnId,
          },
          styles.cell,
          styles[alignment],
          {
            [styles.noFlex]: noFlex,
          },
          className
        )}
        {...rest}
      >
        {!disableTooltip ? (
          <Tooltip
            title={tooltipTitle ?? children}
            color="white"
            placement="bottom"
            enterNextDelay={1000}
            hideOnClickIfUnhoverd
            showOnlyOnOverflow
          >
            {renderCellContent()}
          </Tooltip>
        ) : (
          renderCellContent()
        )}
      </Component>
    );
  }
);

TableCell.displayName = 'TableCell';
