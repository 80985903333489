import UndoIcon from '@mui/icons-material/Undo';
import React, { RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Menu } from '@work4all/components';
import { ColumnInstance } from '@work4all/components/lib/dataDisplay/basic-table/types';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { columnsToMenuSections } from '../../utils/columns-to-menu-sections';
import { ColumnsMenuItem } from '../ColumnsMenuItem';

export interface IGroupingsProps {
  visibleColumns: ColumnInstance[];
  tableInstanceRef: RefObject<TableInstance | null>;
  popoverState: ReturnType<typeof usePopoverState>;
  onResetColumns?: () => void;
  title?: string;
}

export const GroupingsVisibilty: React.FC<IGroupingsProps> = React.memo(
  function GroupingsVisibilty(props) {
    const { visibleColumns = [], popoverState, onResetColumns, title } = props;

    const columns = useMemo(() => {
      return visibleColumns.filter(
        (col) =>
          col.id !== SELECTION_COLUMN_ID &&
          !col.sticky &&
          !col.disableColumnVisibility &&
          col.canGroupBy
      );
    }, [visibleColumns]);

    const { t } = useTranslation();
    const sections = columnsToMenuSections(columns);

    return (
      <Menu<ColumnInstance>
        title={title ?? t('INPUTS.GROUPING')}
        sections={sections}
        renderItem={(item) => {
          return (
            <ColumnsMenuItem
              label={item.label}
              isChecked={item.isGrouped}
              hasSections={Boolean(item.sections)}
            />
          );
        }}
        open={popoverState.open}
        anchorEl={popoverState.anchorEl}
        onClose={popoverState.onClose}
        onItemClick={(item) => {
          item.toggleGroupBy();
        }}
        actions={[
          {
            name: t('LISTS_PAGES.RESET_COLUMNS'),
            icon: <UndoIcon color="disabled" />,
            onClick: onResetColumns,
          },
        ]}
      />
    );
  }
);
