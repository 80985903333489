import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

interface CheckListLabelProps {
  value: string;
  rightElement?: React.ReactElement<any>;
}

export const CheckListLabel = ({
  value,
  rightElement,
}: CheckListLabelProps) => {
  return (
    <div
      style={{
        padding: '0.5rem 1rem 0.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Divider title={value} style={{ width: '100%', paddingTop: 0 }} />

      <div>{rightElement}</div>
    </div>
  );
};
