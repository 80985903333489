import styles from './Assignment.module.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components/EntityPickerPopover';
import { EntityGroupPicker } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';
import { ProjectCategoryClassPicker } from '@work4all/components/lib/components/entity-picker/project-category-class-picker/ProjectCategoryClassPicker';
import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';
import { ChipList } from '@work4all/components/lib/dataDisplay/chip/ChipList';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ProjectPickerField } from '../../../../../../../../../../components/entity-picker/ProjectPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { PickerTargetButton } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { ProjectMaskFormValue } from '../../../../../types';

export const Assignment: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContextPlus<ProjectMaskFormValue>();
  const projectWrapperRef = useRef<HTMLDivElement>(null);
  const categoryFieldRef = useRef(null);

  return (
    <Collapse
      defaultOpen
      title={t('MASK.ASSIGNMENT')}
      classes={{ wrapperInner: styles.wrapperInner }}
      className={styles.root}
    >
      <ControlWrapper paddingBottom={false}>
        <div ref={projectWrapperRef}>
          <ControllerPlus
            name="parentProject"
            control={control}
            render={({ field: project }) => {
              return (
                <ProjectPickerField
                  {...project}
                  label={t('INPUTS.HEAD_PROJECT')}
                  data={PROJECT_DATA}
                  clearable
                />
              );
            }}
          />
        </div>

        <ControllerPlus
          name="group"
          control={control}
          render={({ field }) => {
            return (
              <EntityPickerPopover
                picker={
                  <EntityGroupPicker
                    {...field}
                    entity={Entities.projectGroup}
                    value={[field?.value]}
                    onChange={(value) => field.onChange(value?.[0] || null)}
                    multiple={false}
                  />
                }
              >
                <PickerTargetButton
                  onClear={() => {
                    field?.onChange(null);
                  }}
                  label={t('INPUTS.PROJECT_GROUP')}
                  value={field?.value?.name || ''}
                />
              </EntityPickerPopover>
            );
          }}
        />
      </ControlWrapper>
      <ControlWrapper ref={categoryFieldRef}>
        <ControllerPlus
          name="categoryAssignmentList"
          control={control}
          render={({ field }) => {
            return (
              <EntityPickerPopover
                anchorEl={categoryFieldRef.current}
                picker={
                  <ProjectCategoryClassPicker
                    multiple
                    value={
                      !Array.isArray(field?.value)
                        ? []
                        : field?.value?.map((categoryAssignment) => ({
                            id: categoryAssignment?.categoryId,
                          }))
                    }
                    onChange={(value) => {
                      const newValue: CategoryAssignment[] = value?.map(
                        (category) => {
                          return {
                            categoryId: category.id,
                            categoryName: category.name,
                            categoryKindName: category?.topLevelCategory?.name,
                          };
                        }
                      );

                      field.onChange(newValue);
                    }}
                  />
                }
              >
                <PickerTargetButton
                  onClear={null}
                  value={
                    <ChipList>
                      {!Array.isArray(field?.value)
                        ? []
                        : field?.value?.map((categoryAssignment, idx) => {
                            return (
                              <Chip
                                key={idx}
                                maxWidth={18}
                                label={`${categoryAssignment.categoryKindName} / ${categoryAssignment.categoryName}`}
                                handleDelete={() => {
                                  const newValue = field?.value?.filter(
                                    (x) =>
                                      x.categoryId !==
                                      categoryAssignment.categoryId
                                  );
                                  field.onChange(newValue);
                                }}
                              />
                            );
                          })}
                    </ChipList>
                  }
                  label={t('INPUTS.CATEGORIES')}
                />
              </EntityPickerPopover>
            );
          }}
        />
      </ControlWrapper>
    </Collapse>
  );
};

const PROJECT_DATA: Project = {
  projectAccessGroups: [
    {
      id: null,
      name: null,
    },
  ],
};
