import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TIME_TRACKING_DATA } from './constants';

type Return = {
  data: TimeTracking | undefined;
  isLoading: boolean;
};

export const useTimeTrackingData = (id: number | undefined): Return => {
  const request = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetTimeTracking',
      entity: Entities.timeTracking,
      skip: !id,
      data: TIME_TRACKING_DATA,
      filter: [{ id: { $eq: id } }],
    };
  }, [id]);

  const { data, loading } = useDataProvider<TimeTracking>(request);

  return useMemo(() => {
    return {
      data: data?.[0],
      isLoading: loading,
    };
  }, [data, loading]);
};
