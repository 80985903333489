import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import {
  HomeWidget,
  HomeWidgetType,
} from '../../../../settings/validation/HomeWidget';
import { CollapsibleCard } from '../CollapsibleCard/CollapsibleCard';

import { SimpleEntityWidget } from './SimpleEntityWidget';

export const HomeWidgetGroup = (
  props: HomeWidget & {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
    onBreakupClick?: () => void;
    subWidgets: HomeWidget[];
  }
) => {
  const { t } = useTranslation();

  const { handleClick, ...popoverState } = usePopoverState('bottom');

  return (
    <>
      <Popover
        {...popoverState}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <List disablePadding>
          {props?.onEditClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  props?.onEditClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('SETTINGS.SETTINGS')} />
              </ListItemButton>
            </ListItem>
          )}
          {props?.onBreakupClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  props?.onBreakupClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('WIDGET.BREAKUP_GROUP')} />
              </ListItemButton>
            </ListItem>
          )}
          {props?.onDeleteClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  props?.onDeleteClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('COMMON.REMOVE')} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
      <CollapsibleCard
        sx={{ height: '100%' }}
        collapsed={false}
        onCollapsedChange={() => {
          return;
        }}
        title={
          props.title ||
          t(`COMMON.${props?.definition?.entity.toUpperCase()}_other`)
        }
        headerContent={
          <IconButton size="medium" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
      >
        <div>
          {props?.subWidgets?.map((item) => {
            if (!item) {
              return null;
            }
            return item.type === HomeWidgetType.entityWidget ? (
              <SimpleEntityWidget {...item} />
            ) : item.type === HomeWidgetType.divider ? (
              <Divider
                title={item.title}
                style={{ padding: '0.5rem 1rem', width: '100%' }}
              />
            ) : null;
          })}
        </div>
      </CollapsibleCard>
    </>
  );
};
