import { Theme, useMediaQuery } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IVatPickerProps,
  VatPicker,
} from '@work4all/components/lib/components/entity-picker/vat-picker/VatPicker';

import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

import { useMapPickerValueTypes } from './hooks/use-map-picker-valute-types';

interface VatPickerFieldProps
  extends Omit<IVatPickerProps<false>, 'multiple' | 'onChange' | 'value'> {
  error?: string;
  name?: string;
  label?: string;
  value: number;
  onChange: (vat: number) => void;
}

export const VatPickerField = React.forwardRef<
  HTMLDivElement,
  VatPickerFieldProps
>(function VatPickerField(props, ref) {
  const { error, label, ...pickerProps } = props;
  const { value } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<EntityPickerPopover>(null);
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const mapToPicker = useCallback(
    (items: VatRate[]) => {
      return items.find((x) => x.sentence === value);
    },
    [value]
  );

  const { onListChanged, pickerValue } =
    useMapPickerValueTypes<VatRate>(mapToPicker);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        fullscreen={isMobile}
        ref={popoverRef}
        anchorEl={wrapperRef}
        picker={
          <VatPicker
            {...pickerProps}
            clearable={false}
            value={pickerValue}
            onChange={(vat) => {
              pickerProps.onChange(vat?.sentence);
            }}
            onListChanged={onListChanged}
            multiple={false}
          />
        }
      >
        <LabeledInput
          value={value === undefined || value === null ? '' : value}
          name={props.name}
          label={label ?? t('COMMON.VAT')}
          error={error}
        />
      </EntityPickerPopover>
    </div>
  );
});
