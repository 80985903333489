import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { useOpenMask } from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import {
  MaskTemplateEntity,
  OnOpenMask,
} from '../../../containers/mask-overlays/mask-overlay/types';
import { useLeftAreaVisibleFilters } from '../../../hooks/use-left-area-visible-filters';
import { settings, useSetting } from '../../../settings';
import { PersonalAndGeneralView } from '../../filters/PersonalAndGeneralView';
import { ITableProps, Table } from '../table/Table';
import { TableLayoutContext, useTableLayoutState } from '../table-layout';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useTableHandlers } from '../use-table-handlers';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { EntityPreview } from './EntityPreview';

type TableEntity = Task[] | Ticket[] | CallMemo[] | SalesOpportunities[];

export interface IEntityTable
  extends Pick<
    ITableProps,
    | 'forceRequestFields'
    | 'selectableMultiple'
    | 'onSelectedRowsChange'
    | 'disabledColumns'
    | 'displayFooter'
    | 'hideMoreButton'
    | 'areas'
    | 'availableDateSections'
  > {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
  schema?: ITableSchema<never>;
}

interface EntityTableProps extends ITableProps {
  entityType: Entities;
  settingsEntityType?: Entities | string;
  selectedEntities?: TableEntity;
  template?: MaskTemplateEntity;
  /**
   * This is for override actions and areas when you want to replace them not only add.
   */
  overrides?: Partial<ITableProps>;
}

export const EntityTable = React.forwardRef<TableInstance, EntityTableProps>(
  function EntityTable(props, ref) {
    const {
      entityType,
      settingsEntityType,
      columnConfigs,
      actions,
      areas,
      selectedEntities,
      template,
      overrides,
      ...tableProps
    } = props;

    const { t } = useTranslation();
    const tableStateBag = useTableStateBag();
    const layoutState = useTableLayoutState();
    const [layout] = layoutState;
    const onOpenMask = useOpenMask({ entityType });
    const tableHandlers = useTableHandlers(entityType, onOpenMask, template);
    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType: settingsEntityType ?? entityType,
      columnConfigs,
    });

    const { set: setPreviewVisible } = useSetting(
      settings.tableRightAreaVisible({
        entityType,
      })
    );

    const { action: leftAreaVisibleFiltersAction, isFilterVisible } =
      useLeftAreaVisibleFilters({
        entity: entityType,
        filters: [
          { id: 'personal', label: t('COMMON.PERSONAL_FILTERS') },
          { id: 'general', label: t('COMMON.GENERAL_FILTERS') },
        ],
      });

    if (!userConfig) return null;

    // TODO: no preview for outgoing invoices - should I filter it out here or just leave not implemented?
    return (
      <TableLayoutContext value={layoutState}>
        <Table
          ref={ref}
          layout={layout}
          areas={{
            left: {
              content: (
                <PersonalAndGeneralView
                  entity={entityType}
                  hidePersonalFilters={!isFilterVisible('personal')}
                  hideGeneralFilters={!isFilterVisible('general')}
                />
              ),
              resizable: true,
              title: t('COMMON.FILTER'),
              collapsible: true,
              actions: [leftAreaVisibleFiltersAction],
            },
            right: selectedEntities &&
              selectedEntities.length > 0 && {
                content: areas?.right?.content ?? (
                  <EntityPreview
                    entries={selectedEntities}
                    entityType={entityType}
                    onCloseClick={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onVisibilityToggle={(e) => {
                      setPreviewVisible(e);
                    }}
                    custom={actions?.custom}
                  />
                ),
              },
            ...areas,
          }}
          actions={{
            add: tableHandlers.create,
            edit: tableHandlers.edit,
            resetColumns: userConfigMethods.remove,
            remove: deleteConfig,
            convert: tableHandlers.convert
              ? { exclude: entityType, handler: tableHandlers.convert }
              : undefined,
            createWidget: true,
            clone: tableHandlers.clone,
            openInNewTab: tableHandlers.openInNewTab,
            ...actions,
          }}
          columnConfigs={userConfig}
          {...tableProps}
          {...overrides}
        />
      </TableLayoutContext>
    );
  }
);
