import styles from './ImageGallery.module.scss';

import { ChevronLeft, Delete } from '@mui/icons-material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
import { useDrag } from '@use-gesture/react';
import clsx from 'clsx';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { ScaleToOptions } from 'react-quick-pinch-zoom/esm/PinchZoom/types';

import { Body1 } from '../../typography/body1/Body1';

export interface IImageItem {
  id: string;
  fileName: string;
  fileSize: number;
  imgUrl: string;
  imgThumbUrl: string;
}

export interface IImageGalleryProps {
  items: IImageItem[];
  height: string;
  showThumbs?: boolean;
  onImgDelete?: (imgItem: IImageItem) => void;
  onImgChange?: (imgItem: IImageItem) => void;
}
//copied from react-quick-pinch-zoom as mobile sevices never get closer that 1% on force zooming
const isCloseTo = (value: number, expected: number) =>
  value > expected - 0.01 && value < expected + 0.01;

export const ImageGallery: React.FC<IImageGalleryProps> = (props) => {
  const { onImgDelete, onImgChange, height, items, showThumbs = true } = props;
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  const { t } = useTranslation();

  const [imgScale, setImgScale] = useState<number>(1);

  const thumbContainerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);
  const pinchZoomContainer = useRef<QuickPinchZoom>(null);

  const deleteImg = useCallback(() => {
    onImgDelete && onImgDelete(items[currentImgIndex]);
  }, [currentImgIndex, onImgDelete, items]);

  const switchImg = (direction: 1 | -1) => {
    let newIndex = 0;

    switch (direction) {
      case 1:
        newIndex = currentImgIndex < items.length - 1 ? currentImgIndex + 1 : 0;
        break;
      case -1:
        newIndex = currentImgIndex > 0 ? currentImgIndex - 1 : items.length - 1;
        break;
    }

    setCurrentImgIndex(newIndex);
    onImgChange && onImgChange(items[newIndex]);
  };

  const resetImageTransforms = useCallback((index: number) => {
    const options: ScaleToOptions = {
      x: 0,
      y: 0,
      scale: 1,
      animated: false,
      duration: 0,
    };

    pinchZoomContainer.current.scaleTo(options);
    pinchZoomContainer.current.alignCenter(options);
    thumbContainerRef.current.children[index]?.scrollIntoView();
  }, []);

  const bindSwipe = useDrag(({ swipe: [swipeX] }) => {
    if (isCloseTo(imgScale, 1) && (swipeX === 1 || swipeX === -1)) {
      switchImg(swipeX);
    }
  }, {});

  const thumbnailList = useMemo(() => {
    const thumbs = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      thumbs.push(
        <div
          key={i}
          onClick={() => {
            setCurrentImgIndex(i);
            onImgChange && onImgChange(items[i]);
          }}
          className={clsx(styles['thumb'], {
            [styles['selected']]: i === currentImgIndex,
          })}
          style={{ backgroundImage: `url(${item.imgThumbUrl})` }}
        ></div>
      );
    }
    return thumbs;
  }, [items, currentImgIndex, onImgChange]);

  const setImgPinchZoom = useCallback(
    ({ x, y, scale }) => {
      setImgScale(scale);
      const { current: img } = imgRef;

      if (img) {
        const value = make3dTransformValue({ x, y, scale });
        img.style.setProperty('transform', value);
      }
    },
    [imgRef]
  );

  return (
    <div className={styles['image-gallery']} style={{ height }}>
      <div {...bindSwipe} className={styles['img-wrap']}>
        <QuickPinchZoom
          ref={pinchZoomContainer}
          shouldInterceptWheel={(e) => false}
          onUpdate={setImgPinchZoom}
          draggableUnZoomed={true}
        >
          <div className={styles['img-holder']} ref={imgRef}>
            <img
              src={items[currentImgIndex].imgUrl}
              alt={items[currentImgIndex].fileName}
              onLoad={() => {
                resetImageTransforms(currentImgIndex);
              }}
            />
          </div>
        </QuickPinchZoom>
        {items.length > 1 && imgScale === 1 && (
          <Button className={styles['img-prev']} onClick={() => switchImg(-1)}>
            <ChevronLeft></ChevronLeft>
          </Button>
        )}

        {items.length > 1 && imgScale === 1 && (
          <Button className={styles['img-next']} onClick={() => switchImg(1)}>
            <ChevronRight></ChevronRight>
          </Button>
        )}
      </div>
      <div className={styles['img-infos']}>
        <Body1>
          {items[currentImgIndex].fileName}
          <span className={styles['file-size']}>
            <span className={styles['separator']}> | </span>
            {(items[currentImgIndex].fileSize / 1000).toFixed(2)} KB
          </span>
        </Body1>
        <div>
          <Button color="secondary" onClick={deleteImg}>
            <Delete></Delete> {t('MASK.REMOVE')}
          </Button>
        </div>
      </div>
      <div className={styles['thumbs-container']}>
        <div className={styles['thumbs-wrap']}>
          {showThumbs && (
            <div ref={thumbContainerRef} className={styles['thumbs']}>
              {items.length > 1 && thumbnailList}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
