import React, { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  UnitPicker,
  UnitPickerProps,
} from '@work4all/components/lib/components/entity-picker/unit-picker/UnitPicker';

import { reactRefSetter } from '@work4all/utils';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<UnitPickerProps<false>, 'multiple'> {
  error?: string;
  label: string;
  disabled?: boolean;
}

export const UnitPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const wrapperRef = useRef(null);

    const { label, disabled, error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const onClear = pickerProps.clearable ? () => onChange(null) : undefined;

    return (
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={wrapperRef}
          disabled={disabled}
          picker={<UnitPicker {...pickerProps} multiple={false} />}
        >
          <PickerTargetButton
            onClear={onClear}
            label={label}
            error={error}
            disabled={disabled}
            value={value ? value.name : ''}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
