import { useEventCallback } from '@mui/material/utils';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import { RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uuid } from 'short-uuid';

import { useDialogs } from '@work4all/components';
import { sortPositionsByPositionNumber } from '@work4all/components/lib/components/entity-preview/checklist-preview/ChecklistPreview';

import { useUser } from '@work4all/data';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';
import { ChecklistPositionKind } from '@work4all/models/lib/Enums/ChecklistPositionKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { RowSizeMode } from '../../../../../../../../../../settings/settings';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  EditStateContext,
  UseEditableStateResult,
} from '../../../../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import { useEditableStateForm } from '../../../../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state-form';
import {
  EditTableEntry,
  IdArray,
  IEditTable,
} from '../../../../../../erp/components/tab-panels/positions/components/edit-table/types';
import { useSelectedPositionState } from '../../../../../../erp/components/tab-panels/positions/components/hooks/use-selected-position';

type Props = {
  editTableRef: RefObject<IEditTable> | undefined;
};

type StatePosition = CheckListPosition & {
  localId: string;
};

type Return = Omit<
  UseEditableStateResult<CheckListPosition<EMode.entity>, EditStateContext>,
  'onClonePosition' | 'onRemovePosition'
> & {
  onClonePosition: () => void;
  onRemovePosition: () => void;
  selectedPositionIds: IdArray;
  onSelectedItemIdsChange: (ids: IdArray) => void;
  rowSize: RowSizeMode;
  onRowSizeChange: (value: RowSizeMode) => void;
  automaticNumbering: boolean;
  onAutomaticNumberingChange: (value: boolean) => void;
  editableRows: Partial<Record<ChecklistPositionKind, string[]>>;
  defaultFocusedColumn: string;
};

type EditTableCheckListPositionEntry = CheckListPosition & EditTableEntry;

const mapInitialize = (position: CheckListPosition[]) =>
  sortPositionsByPositionNumber([...position]);

const recalculatePositionsNumber = (
  positions: EditTableCheckListPositionEntry[]
) => {
  const result: EditTableCheckListPositionEntry[] = [];

  let number = 0;
  let titleNumber = 0;
  positions.forEach((position) => {
    switch (position.kind) {
      case ChecklistPositionKind.DEFAULT: {
        number++;
        result.push({
          ...position,
          positionNumber: titleNumber
            ? `${titleNumber}.${number}`
            : `${number}`,
        });
        break;
      }
      case ChecklistPositionKind.HEADLINE: {
        titleNumber = titleNumber === 0 ? number + 1 : titleNumber + 1;
        number = 0;
        result.push({
          ...position,
          positionNumber: `${titleNumber}`,
        });
        break;
      }
      case ChecklistPositionKind.EMPTY_ROW:
      default: {
        result.push({
          ...position,
          positionNumber: titleNumber
            ? `${titleNumber}.${number}`
            : `${number}`,
        });
        break;
      }
    }
  });

  return result;
};

export const useCheckListPositionsState = ({ editTableRef }: Props): Return => {
  const user = useUser();
  const dialogs = useDialogs();

  const form = useFormContextPlus<PathsOf<CheckList, 3>>();
  const { t } = useTranslation();
  const { selectedPositionIds, setSelectedPositionIds } =
    useSelectedPositionState();

  const rowSizeSetting = useSetting(settings.crmPositionsRowSize());
  const automaticNumberingSetting = useSetting(
    settings.crmPositionsAutomaticNumbering()
  );

  const mapAddLocal = useEventCallback(
    (
      position: EditTableCheckListPositionEntry
    ): EditTableCheckListPositionEntry => {
      setTimeout(() => {
        if (position.index) {
          editTableRef.current?.setEditMode(position.localId, 'name');
        } else {
          editTableRef?.current?.scrollTo();
        }
      }, 100);

      return position;
    }
  );

  const mutateState = useEventCallback(
    (positions: EditTableCheckListPositionEntry[]) => {
      if (!automaticNumberingSetting.value) {
        return positions;
      }

      const recalculatedPositions = recalculatePositionsNumber(positions);

      const sortedPositions = sortPositionsByPositionNumber(
        recalculatedPositions
      );

      return sortedPositions;
    }
  );

  const state = useEditableStateForm<CheckListPosition, PathsOf<CheckList, 3>>({
    mapAddLocal,
    mutateState,
    mapInitialize,
    property: 'positionList',
  });

  const onAddPosition = useEventCallback((context?: EditStateContext) => {
    const localId = uuid();

    let position: EditTableCheckListPositionEntry = {
      kind: ChecklistPositionKind.DEFAULT,
      positionNumber: '',
      ...context,
      id: -1 * Math.random(),
      localId,
    };

    if (position.kind === ChecklistPositionKind.DEFAULT) {
      const projectProcess = form.watch('projectProcess');
      position = {
        ...position,
        plannedBy: user.displayName,
        plannedByUserId: user.benutzerCode,
        projectProcess,
        projectProcessId: projectProcess?.id,
        userPlanned: {
          id: user.benutzerCode,
          displayName: user.displayName,
        },
        date: DateTime.now().toString(),
      };
    }

    state.onAddPosition({ ...position });
  });

  const onClonePosition = useEventCallback(() => {
    state.positions.forEach((position: EditTableCheckListPositionEntry) => {
      const isPositionSelected = (selectedPositionIds as string[]).includes(
        position.localId
      );

      if (isPositionSelected) {
        onAddPosition({
          ...omit(position, [
            'id',
            'localId',
            'index',
            'positionNumber',
            '__typename',
          ]),
        });
      }
    });

    setSelectedPositionIds([]);
  });

  const onRemovePosition = useEventCallback(async () => {
    let canDelete = true;
    const positionsIdsToDelete = selectedPositionIds.filter((id) =>
      Boolean(
        (state.positions as StatePosition[]).find((pos) => pos.localId === id)
      )
    );
    const count = positionsIdsToDelete.length;

    if (count > 1) {
      canDelete = await dialogs.confirm({
        title: t('ALERTS.HINT'),
        description: t('ALERTS.DELETE.REASSURE', {
          count,
        }),
        confirmLabel: t('ALERTS.BTN_DELETE'),
        cancelLabel: t('ALERTS.BTN_ABORT'),
      });
    }

    if (canDelete) {
      state.onRemovePosition(positionsIdsToDelete);
      setSelectedPositionIds([]);
    }
  });

  const positions = useDeepMemo(() => {
    return [...state.positions].map((position) => {
      return {
        ...position,
        // We are passing `positionKind` to the edit table for row styles.
        positionKind: position.kind,
      };
    });
  }, [state.positions]);

  const editableRows = useMemo(() => {
    const result = {
      [ChecklistPositionKind.HEADLINE]: ['name'],
      [ChecklistPositionKind.EMPTY_ROW]: [],
    };

    if (automaticNumberingSetting.value) {
      result[ChecklistPositionKind.DEFAULT] = [
        'priority',
        'name',
        'note',
        'plannedBy',
        'date',
        'endDate',
        'userDone',
        'category1',
        'category2',
        'supplier',
        'projectProcess',
      ];
    }

    return result;
  }, [automaticNumberingSetting.value]);

  return {
    ...state,
    positions,
    onAddPosition,
    onClonePosition,
    onRemovePosition,
    selectedPositionIds,
    rowSize: rowSizeSetting.value,
    onRowSizeChange: rowSizeSetting.set,
    onSelectedItemIdsChange: setSelectedPositionIds,
    automaticNumbering: automaticNumberingSetting.value,
    onAutomaticNumberingChange: automaticNumberingSetting.set,
    editableRows,
    defaultFocusedColumn: 'name',
  };
};
