import styles from './ActionsBarMobile.module.scss';

import { Close, TableRows } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FastForwardIcon from '@mui/icons-material/FastForward';
import TuneIcon from '@mui/icons-material/Tune';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Badge, IconButton, Stack } from '@mui/material';
import Fab from '@mui/material/Fab';
import React, { ChangeEvent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { actions as tableActions, TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Search } from '@work4all/components/lib/input/actions/search/Search';
import { IActionConfig } from '@work4all/components/lib/input/actions/types';

import { useTableLayoutState } from '../../../../../table-layout';
import { IToolBar } from '../../ToolBar';

import {
  ActionConfig,
  ActionsMenuMobile,
  ActionType,
} from './ActionsMenuMobile';

interface ActionsBarMobileProps {
  onSearchFilter: (e: ChangeEvent<HTMLInputElement>) => void;
  openFilterOverlay: () => void;
  onUnderPressDeselect: () => void;
  mappedActions: Record<ActionType, ActionConfig | null>;
  actions: IToolBar['actions'];
  filtersCount: number;
  mobile?: boolean;
  tableInstanceRef: RefObject<TableInstance<object> | null>;
  mobileMenuButtonRef: RefObject<HTMLButtonElement>;
  hideMoreButton?: boolean;
  hideSearch?: boolean;
}

export const ActionsBarMobile: React.FC<ActionsBarMobileProps> = (props) => {
  const {
    onSearchFilter,
    openFilterOverlay,
    onUnderPressDeselect,
    mappedActions,
    actions,
    filtersCount,
    mobile,
    tableInstanceRef,
    mobileMenuButtonRef,
    hideMoreButton,
    hideSearch,
  } = props;

  const { t } = useTranslation();
  const tableStateBag = useTableStateBag();
  const [layoutValue] = useTableLayoutState();

  return (
    <>
      <Stack p="0.5rem" pb="1rem" gap="1rem">
        <div className={styles.mobileFilters}>
          {!hideSearch && (
            <Search
              value={tableStateBag.searchFilterText}
              onChange={onSearchFilter}
              fieldStyle="fill"
            />
          )}

          <IconButton
            color="primary"
            onClick={(mappedActions.columns as IActionConfig).onClick}
            sx={{ flex: 'none' }}
          >
            {layoutValue === 'cards' ? <TableRows /> : <ViewColumnIcon />}
          </IconButton>

          {!hideMoreButton &&
            tableStateBag?.tableInstance?.allColumns?.find(
              (x) => x.canFilter
            ) && (
              <ActionsMenuMobile
                buttonRef={mobileMenuButtonRef}
                {...(mappedActions as Partial<
                  Record<ActionType, ActionConfig | null>
                >)}
              />
            )}
        </div>

        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap="1rem"
        >
          <div className={styles.selectedFilters}>
            {(mappedActions.filter as IActionConfig)?.children}
          </div>

          <IconButton
            color="primary"
            onClick={() => openFilterOverlay()}
            sx={{ flex: 'none' }}
          >
            <Badge badgeContent={mobile ? 0 : filtersCount} color="warning">
              <TuneIcon />
            </Badge>
          </IconButton>
        </Stack>
      </Stack>

      <div className={styles.mobileToolbar}>
        <div className={styles.inner}>
          {tableInstanceRef.current?.selectedFlatRows.length > 0 && (
            <Fab
              size="medium"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                tableInstanceRef?.current?.dispatch({
                  type: tableActions.resetSelectedRows,
                });
                onUnderPressDeselect();
              }}
            >
              <Close />
            </Fab>
          )}
        </div>
        <div className={styles.inner}>
          {mappedActions.edit ? (
            <Tooltip
              activateForDisabled={{ activeateOnClick: true }}
              title={
                mappedActions.edit.disabled ? t('RIGHTS.MISSING') : undefined
              }
            >
              <Fab
                size="medium"
                onClick={mappedActions.edit.onClick}
                disabled={mappedActions.edit.disabled}
              >
                <EditIcon />
              </Fab>
            </Tooltip>
          ) : null}

          {actions?.add ? (
            <Tooltip
              activateForDisabled={{ activeateOnClick: true }}
              title={actions.add.disabled ? t('RIGHTS.MISSING') : undefined}
            >
              <Fab
                size="medium"
                color="primary"
                onClick={() => actions.add.handler()}
                disabled={actions.add.disabled}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          ) : null}

          {mappedActions.convert ? (
            <Fab
              size="medium"
              onClick={(mappedActions.convert as IActionConfig).onClick}
            >
              <FastForwardIcon />
            </Fab>
          ) : null}
        </div>
      </div>
    </>
  );
};
