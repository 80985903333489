import styles from './RelevantCard.module.scss';

import { Divider, IconButton, Skeleton } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';
import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import {
  AppParts,
  Link,
  useCanView,
  useHiddenEntities,
  useModuleRights,
  useUser,
} from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';
import { RePaymentStatus } from '@work4all/models/lib/Enums/RePaymentStatus.enum';
import { RePermitStatus } from '@work4all/models/lib/Enums/RePermitStatus.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';
import { VacationRequestStatus } from '@work4all/models/lib/Enums/VacationRequestStatus.enum';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';
import {
  canApproveVacations,
  canViewInboundInvoicesApprovalCenter,
} from '@work4all/utils/lib/permissions';

import {
  makeTableSettings,
  stringifyTableSettings,
  TableSettings,
} from '../../../../../components/data-tables/make-table-settings';
import { settings, useSetting } from '../../../../../settings';
import { WEEK_IN_MILLISECONDS } from '../../../../calendar/constants';
import { TimeTrackerMenuTabs } from '../../../../time-tracker/TimeTrackerMenuTabs';
import { CollapsibleCard } from '../../CollapsibleCard/CollapsibleCard';
import { CardWidgetProps } from '../types';

import { BirthdaysMenu } from './components/BirthdaysMenu';
import { SettingsMenu } from './components/SettingsMenu';
import { useRelevantData } from './use-relevant-data';

interface RelevantCardProps extends CardWidgetProps {
  onActiveRowsChange?: (rows: number) => void;
}

export const RelevantCard: React.FC<RelevantCardProps> = ({
  onActiveRowsChange,
  ...props
}) => {
  const user = useUser();

  const { rights } = useModuleRights();

  const canViewProjectTimeTracking = useCanView(AppParts.PROJECTTIMETRACKING);
  const canViewBirthdays = useCanView(AppParts.BIRTHDAYS);
  const canViewVacations = useCanView(AppParts.VACATIONS);
  const canViewApprovalCenter = useCanView(AppParts.VACATIONS);
  const canViewReApprovalCenter =
    canViewApprovalCenter && canViewInboundInvoicesApprovalCenter(user);
  const canViewVacationApprovalCenter =
    canViewApprovalCenter && canApproveVacations(user);

  const startDate = useMemo(
    // adding a millisecond to avoid catching whole-day appointments from the day before
    () => DateTime.now().startOf('day').plus({ millisecond: 1 }),
    []
  );
  const endDate = useMemo(() => DateTime.now().endOf('day'), []);
  const endOfWeek = useMemo(
    () => DateTime.fromMillis(startDate.toMillis() + WEEK_IN_MILLISECONDS),
    [startDate]
  );

  const { isHidden } = useHiddenEntities();

  const {
    resAppointment,
    resTask,
    projectHoursState,
    vacation,
    resTicketS1,
    resTicketS2,
    resSalesOpportunities,
    resTravelReceipts,
    unreadEmailsToAssign,
    birthdays,
    permits,
  } = useRelevantData({ user, startDate, endDate, endOfWeek });

  const { t } = useTranslation();

  const [showRelevantSettingsMenu, setShowRelevantSettingsMenu] =
    useState<boolean>(false);
  const [initialView, setInitialView] = useState<IStackItem | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSettingsClick = (event) => {
    setInitialView({
      title: t('COMMON.BIRTHDAY_other'),
      view: <BirthdaysMenu data={birthdays.data} />,
    });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const relevantWidgetView = useSetting(settings.relevantWidgetVisibleViews());

  const adminEnabledShowingBirthdays = useSetting(
    settings.adminEnabledShowingBirthdays()
  );

  const userEnabledShowingBirthdays = useSetting(
    settings.userEnabledShowingBirthdays()
  );

  const showingPermits = useSetting(settings.showingPermits());

  const ticketsDeeplinkS1 = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'status1',
          value: [
            {
              id: TicketStatus.OFFEN,
              name: t('TICKET_STATUS.' + TicketStatus.OFFEN),
            },
            {
              id: TicketStatus.IN_BEARBEITUNG,
              name: t('TICKET_STATUS.' + TicketStatus.IN_BEARBEITUNG),
            },
          ],
        },
        {
          id: 'editor1.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
        {
          id: 'followUpDate',
          value: {
            startDate: RelativeDateFilter.todayAndPast,
            endDate: null,
          },
        },
      ],
    };
    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const ticketsDeeplinkS2 = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'status2',
          value: [
            {
              id: TicketStatus.OFFEN,
              name: t('TICKET_STATUS.' + TicketStatus.OFFEN),
            },
            {
              id: TicketStatus.IN_BEARBEITUNG,
              name: t('TICKET_STATUS.' + TicketStatus.IN_BEARBEITUNG),
            },
          ],
        },
        {
          id: 'editor2.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
        {
          id: 'followUpDate',
          value: {
            startDate: RelativeDateFilter.todayAndPast,
            endDate: null,
          },
        },
      ],
    };
    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const tasksDeeplink = useMemo(() => {
    const filter = makeTableSettings(Entities.task, {
      date: {
        value: {
          startDate: RelativeDateFilter.todayAndPast,
          endDate: null,
        },
      },
      status: {
        value: [
          {
            id: TaskStatus.OFFEN,
            name: t('TASK_STATUS.' + TaskStatus.OFFEN),
          },
          {
            id: TaskStatus.IN_BEARBEITUNG,
            name: t('TASK_STATUS.' + TaskStatus.IN_BEARBEITUNG),
          },
          {
            id: TaskStatus.WARTET,
            name: t('TASK_STATUS.' + TaskStatus.WARTET),
          },
        ],
      },
      user: {
        value: [
          {
            id: user.benutzerCode.toString(),
            displayName: user?.displayName,
          },
        ],
      },
    });

    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const salesOpportunitiesDeeplink = useMemo(() => {
    const filter: unknown = {
      filter: [
        {
          id: 'status',
          value: [
            {
              id: SalesOpportunityStatus.ACTIVE,
              name: t(
                'COMMON.SALESOPPORTUNITIES.' + SalesOpportunityStatus.ACTIVE
              ),
            },
          ],
        },
        {
          id: 'user2.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
      ],
    };
    return stringifyTableSettings(filter);
  }, [t, user.benutzerCode, user?.displayName]);

  const travelReceiptsDeepLink = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = {
      filter: [
        {
          id: 'travelExpenses.closedByuserid',
          value: {
            value: 'parked',
            filterType: 'TravelReceiptStatus',
          },
        },
      ],
    };
    filter.filter.push({
      id: 'travelExpenses.businessPartner.data.id',
      value: [
        {
          id: user.supplierCode.toString(),
          displayName: user.displayName,
        },
      ],
    });

    return stringifyTableSettings(filter);
  }, [user.supplierCode, user.displayName]);

  const reApprovalDeeplink = useMemo(() => {
    const userFilter: TableSettings = {
      filter: [
        {
          id: 'approvedByUser.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
        {
          id: 'permitStatus',
          value: [
            {
              id: RePermitStatus.EMPTY,
              name: t('PERMIT_STATUSES.EMPTY'),
            },
          ],
        },
        {
          id: 'paymentStatus',
          value: [
            {
              id: RePaymentStatus.DUE,
              name: t('PAYMENT_STATUSES.DUE'),
            },
            {
              id: RePaymentStatus.OPEN,
              name: t('PAYMENT_STATUSES.OPEN'),
            },
            {
              id: RePaymentStatus.OPEN_CREDIT,
              name: t('PAYMENT_STATUSES.OPEN_CREDIT'),
            },
            {
              id: RePaymentStatus.PARTIAL_PAYED,
              name: t('PAYMENT_STATUSES.PARTIAL_PAYED'),
            },
            {
              id: RePaymentStatus.PARTIAL_PAYED_CREDIT,
              name: t('PAYMENT_STATUSES.PARTIAL_PAYED_CREDIT'),
            },
          ],
        },
      ],
    };

    return stringifyTableSettings(userFilter);
  }, [t, user.benutzerCode, user?.displayName]);

  const vacationApprovalDeeplink = useMemo(() => {
    const userFilter: TableSettings = {
      filter: [
        {
          id: 'releasedByUser.id',
          value: [
            {
              id: user.benutzerCode.toString(),
              displayName: user?.displayName,
            },
          ],
        },
        {
          id: 'status',
          value: [
            {
              id: VacationRequestStatus.OPEN,
              name: t('APPROVAL_CENTER.VACATION.STATUS.OPEN'),
            },
          ],
        },
        {
          id: 'dateFrom',
          value: {
            startDate: RelativeDateFilter.todayAndFuture,
            endDate: null,
          },
        },
      ],
    };

    return stringifyTableSettings(userFilter);
  }, [t, user.benutzerCode, user?.displayName]);

  const canViewTravelAndReceipt = useCanView(AppParts.TRAVEL_RECEIPTS);
  const canViewCalendar = useCanView(AppParts.CALENDAR);
  const canViewOverviewWidget = useCanView(AppParts.OVERVIEW_WIDGET);

  const canSeeAppointments = !isHidden(Entities.appointment) && canViewCalendar;
  const canSeeTasks = !isHidden(Entities.task) && canViewOverviewWidget;
  const canSeeTickets = !isHidden(Entities.ticket) && canViewOverviewWidget;
  const canSeeSalesOpportunities =
    !isHidden(Entities.salesOpportunities) && canViewOverviewWidget;
  const canSeeContacts = !isHidden(Entities.contact) && canViewOverviewWidget;
  const canSeeTravelReceipts =
    !isHidden(Entities.travelReceipts) && canViewTravelAndReceipt;
  const canSeeInboundInvoices = !isHidden(Entities.inboundInvoice);
  const canSeeVacations = !isHidden(Entities.vacation);

  const taskVisible =
    !isTimeTrackingUser(rights) &&
    canSeeTasks &&
    relevantWidgetView.value.includes(Entities.task);
  const appointmentsVisible =
    canSeeAppointments &&
    relevantWidgetView.value.includes(Entities.appointment);
  const ticketVisible =
    canSeeTickets && relevantWidgetView.value.includes(Entities.ticket);
  const timeTrackingVisible =
    canViewProjectTimeTracking &&
    relevantWidgetView.value.includes(Entities.timeTracking);
  const vacationsVisible =
    canViewVacations && relevantWidgetView.value.includes(Entities.vacation);
  const salesOpportunitiesVisible =
    canSeeSalesOpportunities &&
    relevantWidgetView.value.includes(Entities.salesOpportunities);
  const birthdaysVisible =
    canSeeContacts &&
    canViewBirthdays &&
    adminEnabledShowingBirthdays.value &&
    userEnabledShowingBirthdays.value;
  const travelReceiptsVisible =
    canSeeTravelReceipts &&
    relevantWidgetView.value.includes(Entities.travelReceipts);
  const reApprovalVisible =
    canViewReApprovalCenter && canSeeInboundInvoices && !!showingPermits.value;
  const vacationApprovalVisible =
    canViewVacationApprovalCenter && canSeeVacations && !!showingPermits.value;
  const mailboxVisible = true;

  useEffect(() => {
    if (onActiveRowsChange) {
      const activeItems = [
        taskVisible,
        appointmentsVisible,
        ticketVisible,
        timeTrackingVisible,
        vacationsVisible,
        salesOpportunitiesVisible,
        birthdaysVisible,
        travelReceiptsVisible,
        mailboxVisible,
        reApprovalVisible,
        vacationApprovalVisible,
      ].filter(Boolean);
      onActiveRowsChange(activeItems.length);
    }
  }, [
    appointmentsVisible,
    birthdaysVisible,
    mailboxVisible,
    salesOpportunitiesVisible,
    onActiveRowsChange,
    taskVisible,
    ticketVisible,
    timeTrackingVisible,
    travelReceiptsVisible,
    vacationsVisible,
    reApprovalVisible,
    vacationApprovalVisible,
  ]);

  const mailboxDeeplink = useMemo(() => {
    const f1: TableSettings = {
      filter: [
        {
          id: 'status',
          value: [
            {
              id: MailAssignStatus.NOT_YET_ASSIGNED,
              name: t('MAIL_ASSIGN_STATUS.NOT_YET_ASSIGNED'),
            },
          ],
        },
      ],
    };

    return stringifyTableSettings(f1);
  }, [endDate, t]);

  const projectTimeTracked = useMemo(() => {
    const time = (projectHoursState?.selectedDay?.trackedTime ?? 0) / 60;
    const trackedTime = t('RELEVANT.PROJECT.TIME.HOURS_TRACKED', {
      hours: parseFloat(time.toFixed(1)).toLocaleString(),
    });

    if (isNaN(time)) {
      return <Skeleton width="5rem" height="1rem" />;
    }

    return trackedTime;
  }, [projectHoursState?.selectedDay?.trackedTime, t]);

  const birthdaysText = useMemo(() => {
    if (birthdays.total === 0) return t('BIRTHDAYS.NONE');

    const numberOfBirthdays = birthdays.todayTotal || birthdays.total;
    const when = birthdays.todayTotal > 0 ? 'today' : 'soon';

    return t('BIRTHDAYS', {
      context: when,
      count: numberOfBirthdays,
    });
  }, [birthdays, t]);

  const reApprovalText = useMemo(() => {
    const numberOfRePermits = permits.resOpenInboundInvoices.total;

    if (canViewReApprovalCenter && numberOfRePermits > 0) {
      return t('APPROVAL_CENTER.PERMITS.RE', {
        count: numberOfRePermits,
      });
    }

    return null;
  }, [canViewReApprovalCenter, permits.resOpenInboundInvoices.total, t]);

  const vacationApprovalText = useMemo(() => {
    const numberOfVacationPermits = permits.resOpenVacations.total;

    if (canViewVacationApprovalCenter && numberOfVacationPermits > 0) {
      return t('APPROVAL_CENTER.PERMITS.VACATION', {
        count: numberOfVacationPermits,
      });
    }

    return null;
  }, [canViewVacationApprovalCenter, permits.resOpenVacations.total, t]);

  return (
    <CollapsibleCard
      title={t('RELEVANT_WIDGET.TITLE')}
      headerContent={
        <IconButton
          onClick={() => setShowRelevantSettingsMenu(true)}
          size="medium"
          className={null}
        >
          <SettingsIcon />
        </IconButton>
      }
      {...props}
    >
      <div className={styles.wrap}>
        {taskVisible && (
          <>
            <div className={styles.label}>{t('COMMON.TASK_other')}:</div>
            <div className={styles.value}>
              {resTask.pending ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <Link
                  className={styles.link}
                  to={'/more/entity/task?settings=' + tasksDeeplink}
                >
                  {resTask?.total > 0
                    ? `${resTask?.total} ${t('COMMON.OPEN')}`
                    : t('COMMON.NONE_AVAILABLE')}
                </Link>
              )}
            </div>
          </>
        )}

        {appointmentsVisible && (
          <>
            <div className={styles.label}>{t('COMMON.APPOINTMENT_other')}:</div>
            <div className={styles.value}>
              {resAppointment.pending ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <Link className={styles.link} to={`/more/calendar`}>
                  {resAppointment?.total > 0
                    ? `${resAppointment?.total} ${t('COMMON.TODAY.LOWER')}`
                    : t('COMMON.NONE_AVAILABLE')}
                </Link>
              )}
            </div>
          </>
        )}

        {ticketVisible && (
          <>
            <div className={styles.label}>{t('COMMON.TICKET_other')}:</div>
            <div className={clsx(styles.value, styles.permitsValue)}>
              {resTicketS1.pending || resTicketS2.pending ? (
                <Skeleton width="5rem" height="1rem" />
              ) : resTicketS1?.total === 0 && resTicketS2?.total === 0 ? (
                <Link
                  className={styles.link}
                  to={'/more/entity/ticket?settings=' + ticketsDeeplinkS1}
                >
                  {t('COMMON.NONE_AVAILABLE')}
                </Link>
              ) : (
                <>
                  {resTicketS1?.total ? (
                    <Link
                      className={styles.link}
                      to={'/more/entity/ticket?settings=' + ticketsDeeplinkS1}
                    >
                      {`${resTicketS1?.total} ${t('COMMON.OPEN')}`}
                    </Link>
                  ) : (
                    ''
                  )}
                  {resTicketS1?.total && resTicketS2?.total ? (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      sx={{ height: '1rem' }}
                    />
                  ) : (
                    ''
                  )}
                  {resTicketS2?.total ? (
                    <Link
                      className={styles.link}
                      to={'/more/entity/ticket?settings=' + ticketsDeeplinkS2}
                    >
                      {`${resTicketS2?.total} ${t('COMMON.OPEN')}`}
                    </Link>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </>
        )}

        {timeTrackingVisible && (
          <>
            <div className={styles.label}>{t('COMMON.PROJECTTIME')}:</div>
            <div className={styles.value}>
              <Link
                className={styles.link}
                to={`/more/timetracking?tab=${TimeTrackerMenuTabs.projectTime}`}
              >
                <span className={styles.link}>{projectTimeTracked}</span>
              </Link>
            </div>
          </>
        )}

        {vacationsVisible && (
          <>
            <div className={styles.label}>{t('COMMON.VACATION')}:</div>
            <div className={styles.value}>
              {vacation.isLoading ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <Link
                  className={styles.link}
                  rel="noreferrer"
                  to={'/more/vacation'}
                >
                  {vacation.vacationStats.remaining.toLocaleString()}{' '}
                  {t('COMMON.DAYS_REMAINING')}
                  {` ${t('COMMON.FOR')} ${DateTime.now().year}`}
                </Link>
              )}
            </div>
          </>
        )}
        {salesOpportunitiesVisible && (
          <>
            <div className={styles.label}>
              {t('COMMON.SALESOPPORTUNITIES_other')}:
            </div>
            <div className={styles.value}>
              {resSalesOpportunities.pending ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <Link
                  className={styles.link}
                  to={
                    `/more/entity/salesOpportunities?settings=` +
                    salesOpportunitiesDeeplink
                  }
                >
                  {resSalesOpportunities?.total > 0
                    ? `${resSalesOpportunities?.total} ${t('COMMON.OPEN')}`
                    : t('COMMON.NONE_AVAILABLE')}
                </Link>
              )}
            </div>
          </>
        )}
        {birthdaysVisible && (
          <>
            <div className={styles.label}>{t('COMMON.BIRTHDAY_other')}:</div>
            <div className={styles.value}>
              {resSalesOpportunities.pending ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <span
                  className={birthdays.total > 0 ? styles.link : null}
                  onClick={birthdays.total > 0 ? handleSettingsClick : null}
                >
                  {birthdaysText}
                </span>
              )}
            </div>
            <NavigationPopover
              anchorEl={anchorEl}
              onClose={handleClose}
              open={open}
              initialView={initialView}
            />
          </>
        )}
        {(reApprovalVisible || vacationApprovalVisible) && (
          <>
            <div className={styles.label}>{t('APPROVAL_CENTER.TITLE')}:</div>
            <div className={clsx(styles.value, styles.permitsValue)}>
              {canViewReApprovalCenter &&
                (permits.resOpenInboundInvoices.pending ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <Link
                    className={styles.link}
                    to={`/more/approval/reViewModel?settings=${reApprovalDeeplink}`}
                  >
                    {reApprovalText
                      ? reApprovalText
                      : t('APPROVAL_CENTER.REVIEWMODEL.NONE')}
                  </Link>
                ))}
              {canViewVacationApprovalCenter && canViewReApprovalCenter && (
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{ height: '1rem' }}
                />
              )}
              {canViewVacationApprovalCenter &&
                (permits.resOpenVacations.pending ? (
                  <Skeleton width="5rem" height="1rem" />
                ) : (
                  <Link
                    className={styles.link}
                    to={`/more/approval/vacation?settings=${vacationApprovalDeeplink}`}
                  >
                    {vacationApprovalText
                      ? vacationApprovalText
                      : t('APPROVAL_CENTER.VACATION.NONE')}
                  </Link>
                ))}
            </div>
          </>
        )}
        {canViewOverviewWidget && (
          <React.Fragment>
            <div className={styles.label}>
              {t('LIST.MAILBOXCONTENT.INBOX')}:
            </div>

            <div className={styles.value}>
              {
                <Link
                  className={styles.link}
                  rel="noreferrer"
                  to={`/more/entity/mailboxContent?settings=${mailboxDeeplink}`}
                >
                  {unreadEmailsToAssign.totalUnread > 0
                    ? t('LIST.MAILBOXCONTENT.UNREAD_INCOMING_EMAILS', {
                        count: unreadEmailsToAssign.totalUnread,
                      })
                    : t('LIST.MAILBOXCONTENT.NONE_UNREAD')}
                </Link>
              }
            </div>
          </React.Fragment>
        )}

        {travelReceiptsVisible && (
          <React.Fragment>
            <div className={styles.label}>{t('COMMON.TRAVELRECEIPTS')}:</div>
            <div className={styles.value}>
              {resTravelReceipts.loading ? (
                <Skeleton width="5rem" height="1rem" />
              ) : (
                <Link
                  className={styles.link}
                  rel="noreferrer"
                  to={`/more/entity/travelReceipts?settings=${travelReceiptsDeepLink}`}
                >
                  {resTravelReceipts?.total > 0
                    ? `${resTravelReceipts?.total} ${t('COMMON.OPEN')}`
                    : t('COMMON.NONE_AVAILABLE')}
                </Link>
              )}
            </div>
          </React.Fragment>
        )}
        <NavigationOverlay
          classes={{
            wrapper: styles.dialogWrapper,
            paper: styles.paper,
          }}
          open={showRelevantSettingsMenu}
          initialView={{
            view: <SettingsMenu />,

            title: t('SETTINGS.SETTINGS'),
            subTitle: 'Relevant Widget',
          }}
          close={() => {
            setShowRelevantSettingsMenu(false);
          }}
        />
      </div>
    </CollapsibleCard>
  );
};
