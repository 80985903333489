import styles from './EntityPickerActions.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useEventCallback } from '@mui/material/utils';
import { noop } from 'lodash';
import { type JSX, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';

import {
  ColumnInstance,
  FilterType,
  TableStateBagProvider,
} from '@work4all/components';
import { TablePrefilterProvider } from '@work4all/components/lib/components/table/TablePrefilterProvider';
import { TableInitialState } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useTableStateBag';
import { LockSubscription } from '@work4all/components/lib/hooks/object-lock-subscription/LockSubscription';
import {
  IStackItem,
  StackProvider,
} from '@work4all/components/lib/navigation/history-stack';
import { Content } from '@work4all/components/lib/navigation/navigation-overlay/components/content/Content';
import { Heading3 } from '@work4all/components/lib/typography/heading3/Heading3';

import { CustomFieldsConfigProvider } from '@work4all/data/lib/custom-fields';
import { OverlayFullscreenContext } from '@work4all/data/lib/hooks';
import { PrintBagProvider } from '@work4all/data/lib/hooks/useHandlePrint';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  ListPageContext,
  ListPageContextValue,
} from '../../containers/file-entities-lists/list-page-context';
import { MaskConfig } from '../../containers/mask-overlays/mask-overlay';
import { OpenMaskOverride } from '../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { settings, useSetting } from '../../settings';
import { ArticleTable } from '../data-tables/article-table/ArticleTable';
import { ContractsTable } from '../data-tables/ContractsTable';
import { CustomersTable } from '../data-tables/CustomersTable';
import { EMailSignatureTable } from '../data-tables/EmailSignatureTable';
import { EmailTemplateTable } from '../data-tables/EmailTemplateTable';
import { InboundDeliveryNotesTable } from '../data-tables/InboundDeliveryNotesTable';
import { OrdersTable } from '../data-tables/OrdersTable';
import { ProjectTable } from '../data-tables/project-table/ProjectTable';
import { ServiceContractTable } from '../data-tables/ServiceContractTable';
import { SuppliersTable } from '../data-tables/SuppliersTable';
import { ITableProps } from '../data-tables/table/Table';
import { TextBuildingBlockTable } from '../data-tables/text-building-block-table/TextBuildingBlockTable';
import { TicketsTable } from '../data-tables/TicketsTable';
import { WordDocumentTemplateTable } from '../data-tables/WordDocumentTemplateTable';
import { WordLetterTemplateTable } from '../data-tables/WordLetterTemplateTable';

import { MaskModal } from './MaskModal';

interface PickFromListModalProps<T> {
  open: boolean;
  entity: Entities;
  multiple: boolean;
  onClose: () => void;
  onConfirm: (selected: T[]) => void;
  prefilter?: unknown[];
  data?: T;
  initialState?: TableInitialState;
  overridesTableProps?: Pick<ITableProps, 'onRowDoubleClick'>;
}

function getTableComponent(entity: Entities): typeof CustomersTable {
  switch (entity) {
    case Entities.article:
      return ArticleTable;

    case Entities.customer:
      return CustomersTable;

    case Entities.supplier:
      return SuppliersTable;

    case Entities.project:
      return ProjectTable;

    case Entities.wordLetterTemplate:
      return WordLetterTemplateTable;

    case Entities.wordDocumentTemplate:
      return WordDocumentTemplateTable;

    case Entities.eMailTemplate:
      return EmailTemplateTable;

    case Entities.eMailSignature:
      return EMailSignatureTable;

    case Entities.textBuildingBlock:
      return TextBuildingBlockTable;

    case Entities.inboundDeliveryNote:
      return InboundDeliveryNotesTable;
    case Entities.order:
      return OrdersTable;
    case Entities.ticket:
      return TicketsTable;
    case Entities.contract:
      return ContractsTable;
    case Entities.serviceContract:
      return ServiceContractTable;
    default:
      return null;
  }
}

export function PickFromListModal<T>(props: PickFromListModalProps<T>) {
  const {
    open,
    entity: entityType,
    multiple,
    prefilter,
    data,
    initialState,
    overridesTableProps,
  } = props;

  const onClose = useEventCallback(props.onClose);
  const onConfirm = useEventCallback(props.onConfirm);

  const [initialView, setInitialView] = useState<IStackItem | null>(null);
  const [maskConfig, setMaskConfig] = useState<{
    open: boolean;
    config: MaskConfig;
  }>({
    open: false,
    config: {
      entity: null,
      id: null,
    },
  });

  useEffect(() => {
    if (open) {
      const TableComponent = getTableComponent(entityType);

      if (!TableComponent) {
        setInitialView(null);
        return;
      }

      const onOpenMask = () => {
        return {
          handler: (config) => {
            setMaskConfig({ open: true, config });
          },
        };
      };

      setInitialView({
        view: (
          <OpenMaskOverride value={{ onOpenMask }}>
            <TablePrefilterProvider value={{ prefilter }}>
              <LockSubscription entity={entityType}>
                <TableComponentWrapper
                  entityType={entityType}
                  multiple={multiple}
                  onClose={onClose}
                  onConfirm={onConfirm}
                  initialState={initialState}
                  renderTable={(renderTableProps) => (
                    <TableComponent
                      prefilter={prefilter}
                      onOpenMask={onOpenMask}
                      selectableMultiple={multiple}
                      forceRequestFields={data}
                      {...renderTableProps}
                      {...overridesTableProps}
                    />
                  )}
                />
              </LockSubscription>
            </TablePrefilterProvider>
          </OpenMaskOverride>
        ),
      });
    }
  }, [
    open,
    entityType,
    multiple,
    onClose,
    onConfirm,
    prefilter,
    data,
    initialState,
    overridesTableProps,
  ]);

  const handleExited = () => {
    setInitialView(null);
  };

  const handleMaskClose = () => {
    setMaskConfig((c) => ({ ...c, open: false }));
  };
  return (
    <>
      <OverlayFullscreenContext.Provider
        value={{ fullscreen: true, setFullscreen: noop }}
      >
        <StackProvider onClose={onClose} initialView={initialView}>
          {initialView && (
            <CustomFieldsConfigProvider entity={entityType}>
              <Content open={open} close={onClose} onExited={handleExited} />
            </CustomFieldsConfigProvider>
          )}
        </StackProvider>
      </OverlayFullscreenContext.Provider>

      <MaskModal
        open={maskConfig.open}
        onClose={handleMaskClose}
        onAfterSave={handleMaskClose}
        {...maskConfig.config}
      />
    </>
  );
}

function TableComponentWrapper<T>(props: {
  entityType: Entities;
  multiple: boolean;
  onConfirm: (selected: T[]) => void;
  onClose: () => void;
  renderTable: ({
    ref,
    onSelectedRowsChange,
    isSelectionList,
  }: Pick<ITableProps, 'onSelectedRowsChange' | 'isSelectionList'> & {
    ref: React.Ref<TableInstance>;
  }) => JSX.Element;
  initialState?: TableInitialState;
}) {
  const {
    entityType,
    multiple,
    onConfirm,
    onClose,
    renderTable,
    initialState,
  } = props;

  const { t } = useTranslation();
  const [tableInstance, setTableInstance] = useState<TableInstance | null>(
    null
  );

  const { value: hideClosedEntitiesSetting } = useSetting(
    settings.hideClosedEntities({ entityType })
  );

  const hideClosedEntitiesSettingRef = useRef(hideClosedEntitiesSetting);

  useEffect(() => {
    hideClosedEntitiesSettingRef.current = hideClosedEntitiesSetting;
  }, [hideClosedEntitiesSetting]);

  useEffect(() => {
    function findClosedStatusColumn() {
      return tableInstance.allColumns.find((column) => {
        return (
          (column as ColumnInstance).filterType === FilterType.ClosedStatus
        );
      }) as ColumnInstance;
    }

    if (!tableInstance) return;

    const closedStatusColumn = findClosedStatusColumn();

    if (!closedStatusColumn) return;

    if (!closedStatusColumn.filterValue) {
      closedStatusColumn.setFilter({
        filterType: closedStatusColumn.filterType,
        value: hideClosedEntitiesSettingRef.current,
      });
    }
  }, [tableInstance]);

  const [selected, setSelected] = useState<T[]>([]);

  const isSmUp = useMediaQuery<Theme>((t) => t.breakpoints.up('sm'));

  const handleSelectionChange = useEventCallback((rows: Row[]) => {
    // Remove all grouped rows from the selection and map to the original
    // data.
    const selected = rows
      .filter((row) => !row.isGrouped)
      .map((row) => row.original as T);

    // Automatically select the clicked row on mobile when using single selection.
    if (!isSmUp && !multiple && selected.length > 0) {
      onConfirm(selected);
    } else {
      setSelected(selected);
    }
  });

  const listPageContextValue = useMemo<ListPageContextValue | null>(() => {
    if (!entityType) return null;
    return { entityType };
  }, [entityType]);

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between" padding={2}>
        <Stack direction="row" alignItems="center">
          <IconButton size="large" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Heading3 className={styles['text-02']}>
            {t('COMMON.PICK_FROM', {
              from: t(`COMMON.${entityType.toUpperCase()}`),
            })}
          </Heading3>
        </Stack>

        {selected.length > 0 && (
          <Button
            variant="contained"
            size="large"
            onClick={() => onConfirm(selected)}
          >
            {t('COMMON.APPLY_SELECTION')}
          </Button>
        )}
      </Stack>

      <Box flex={1}>
        <ListPageContext.Provider value={listPageContextValue}>
          <PrintBagProvider>
            <TableStateBagProvider
              tableInstance={tableInstance}
              initialState={initialState}
            >
              {renderTable({
                ref: setTableInstance,
                onSelectedRowsChange: handleSelectionChange,
                isSelectionList: true,
              })}
            </TableStateBagProvider>
          </PrintBagProvider>
        </ListPageContext.Provider>
      </Box>
    </Stack>
  );
}
