import { useMemo } from 'react';

import { usePermissions } from '@work4all/data';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ITableProps } from '../../table/Table';

import { useOpenTimeTrackingMask } from './use-open-time-tracking-mask';

type Return = ITableProps['actions'];

export const useTimeTrackingTableActions = (
  record: TimeTracking | undefined
): Return => {
  const { canAdd, canEdit } = usePermissions();
  const openTimeTrackingMask = useOpenTimeTrackingMask();

  return useMemo<Return>(() => {
    const isAddingDisabled = !canAdd({ entity: Entities.timeTracking });
    const isEditingDisabled =
      !record ||
      !canEdit({
        entity: Entities.timeTracking,
        record,
      });

    return {
      add: {
        handler: () => openTimeTrackingMask({ mode: 'create' }),
        disabled: isAddingDisabled,
      },
      edit: {
        handler: () => openTimeTrackingMask({ id: record.id, mode: 'edit' }),
        disabled: isEditingDisabled,
      },
      openInNewTab: undefined,
    };
  }, [canAdd, record, canEdit, openTimeTrackingMask]);
};
