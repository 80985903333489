import React from 'react';
import { useDrop } from 'react-dnd';

import { useTableStateBag } from '@work4all/components';
import { IDraggableColumnDndItem } from '@work4all/components/lib/dataDisplay/basic-table/components/table-header/components/header-cell-dnd-wrapper/HeaderCellDndWrapper';

import { DndTypes } from '@work4all/utils/lib/variables';

import { ToolbarDndWrapperDropZone } from './toolbar-dnd-wrapper-drop-zone';

type Props = {
  children: React.ReactNode;
};

export const ToolbarDndWrapper = ({ children }: Props) => {
  const { tableInstance } = useTableStateBag();

  const [{ showDropZone }, drop] = useDrop({
    accept: DndTypes.BASIC_TABLE_COL,
    drop: (col: IDraggableColumnDndItem) => {
      tableInstance.toggleGroupBy(col.id);
    },
    collect: (monitor) => ({
      showDropZone: monitor.canDrop() && monitor.isOver(),
    }),
    canDrop: (col: IDraggableColumnDndItem) => {
      return col.canGroupBy;
    },
  });

  const renderContent = () => {
    if (showDropZone) {
      return <ToolbarDndWrapperDropZone />;
    }

    return children;
  };

  return (
    <div
      ref={(node) => {
        drop(node);
      }}
    >
      {renderContent()}
    </div>
  );
};
