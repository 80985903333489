import styles from './FiltersOverlay.module.scss';

import { Check } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import {
  ColumnInstance,
  FilterType,
} from '@work4all/components/lib/dataDisplay/basic-table';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { FiltersOverlayStartPage } from './components/filters-overlay-start-page/FiltersOverlayStartPage';

interface IFiltersOverlayContext {
  additionalFilters: ColumnInstance[];
  addAdditionalFilter: (name: string) => void;
  filterCardExpandStates: { [key: string]: boolean };
  listEntityType?: Entities;
  setFilterCardExpandStates: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

export const FilterOverlayContext = React.createContext<IFiltersOverlayContext>(
  {
    additionalFilters: [],
    addAdditionalFilter: () => null,
    setFilterCardExpandStates: () => null,
    filterCardExpandStates: {},
    listEntityType: undefined,
  }
);

interface IFiltersOverlayProps {
  overlayOpen: boolean;
  listEntityType?: Entities;
  onClose: () => void;
}

export const FiltersOverlay: React.FC<IFiltersOverlayProps> = (props) => {
  const { listEntityType } = props;
  const { t } = useTranslation();
  const { columnsById, tableState } = useTableStateBag();
  const columns: ColumnInstance[] = useMemo(() => {
    return Object.keys(columnsById).map((key) => {
      return columnsById[key];
    });
  }, [columnsById]);
  const [filterCardExpandStates, setFilterCardExpandStates] = useState<{
    [key: string]: boolean;
  }>(() => {
    //all primaries are initally open
    const defaultOpen: Record<string, boolean> = {};
    columns.forEach((col) => {
      defaultOpen[col.Header as string] = false;
    });
    return defaultOpen;
  });

  const [additionalFilters, setAdditionalFilters] = useState<ColumnInstance[]>(
    columns.filter((col) => {
      if (
        col.canFilter &&
        col.filterValue &&
        // Do not show ClosedStatus filter if it is set to `false`.
        !col.filterValue.readOnly &&
        (col.filterType !== FilterType.ClosedStatus ||
          col.filterValue?.value === true)
      ) {
        return true;
      }
      return false;
    })
  );

  const addAdditionalFilter = (name: string) => {
    if (additionalFilters.findIndex((x) => x.Header === name) === -1) {
      const newAdditionalFilters = [
        ...additionalFilters,
        columns.filter((x) => x.Header === name)[0],
      ];
      setAdditionalFilters(newAdditionalFilters);
    }
    setFilterCardExpandStates({ [name]: true });
  };

  const clearAllFilters = () => {
    columns
      .filter((col) => col.filterValue)
      .forEach((col) => col.setFilter(null));
  };

  return (
    <FilterOverlayContext.Provider
      value={{
        additionalFilters,
        addAdditionalFilter,
        filterCardExpandStates,
        setFilterCardExpandStates,
        listEntityType,
      }}
    >
      <NavigationOverlay
        open={props.overlayOpen}
        classes={{
          wrapper: styles.wrapper,
        }}
        initialView={{
          title: t('COMMON.FILTERS'),
          view: <FiltersOverlayStartPage />,
          breadcrumbRightElement: tableState.filters.length > 0 && (
            <div
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
            >
              <Button
                color="primary"
                startIcon={<CancelIcon />}
                onClick={clearAllFilters}
              >
                {t('FILTERS.CLEAR_ALL_FILTERS')}
              </Button>
              <Button
                color="primary"
                startIcon={<Check />}
                onClick={props.onClose}
              >
                {t('FILTERS.APPLY')}
              </Button>
            </div>
          ),
        }}
        withBreadcrumbs={true}
        close={() => props.onClose()}
      />
    </FilterOverlayContext.Provider>
  );
};
