import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EnumPicker,
  EnumPickerProps,
} from '@work4all/components/lib/components/entity-picker/enum-picker/EnumPicker';
import { useLock } from '@work4all/components/lib/hooks';

import { reactRefSetter } from '@work4all/utils';

import { LabeledInput } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<EnumPickerProps<false>, 'multiple'> {
  error?: string;
  label: string;
  disabled?: boolean;
}

export const EnumPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const wrapperRef = useRef(null);
    const { label, clearable, disabled, error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const { locked } = useLock();

    const onClear = clearable ? () => onChange(null) : undefined;
    return (
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <EntityPickerPopover
          anchorEl={wrapperRef}
          disabled={disabled || locked}
          picker={<EnumPicker {...pickerProps} multiple={false} />}
        >
          <LabeledInput
            onClear={onClear}
            label={label}
            error={error}
            disabled={disabled || locked}
            value={value ? (t(`ENUMS.${value}`) as string) : ''}
          />
        </EntityPickerPopover>
      </div>
    );
  }
);
