import styles from './styles.module.scss';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography } from '@mui/material';
import { groupBy } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { ProjectCategoryClassPicker } from '@work4all/components/lib/components/entity-picker/project-category-class-picker/ProjectCategoryClassPicker';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { useDataMutation } from '@work4all/data';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { InputProjektRelation } from '@work4all/models/lib/Classes/InputProjektRelation.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { InlineDataList } from './InlineDataList';

type IProjectCategoryCardProps = {
  categories: Array<CategoryAssignment>;
  id: number;
};

export const ProjectCategoryCard: FC<IProjectCategoryCardProps> = ({
  categories: initialCategories,
  id,
}) => {
  const { t } = useTranslation();
  const sortedCategories = (a: string, b: string) => a?.localeCompare(b);

  const categoryFieldRef = useRef(null);

  const [updatedCategories, setUpdatedCategories] = useState<
    CategoryAssignment<EMode.entity>[]
  >([]);

  useEffect(() => {
    setUpdatedCategories(initialCategories);
  }, [initialCategories]);

  const [mutate] = useDataMutation<Project, EMode.upsert>({
    entity: Entities.project,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
      categoryAssignmentList: [
        {
          categoryId: null,
          id: null,
          categoryName: null,
          categoryKindName: null,
        },
      ],
    },
  });

  const mutateCategories = () => {
    const relations: InputProjektRelation = {
      projectCategories: {
        add: updatedCategories
          ?.filter(
            (currentCategory) =>
              !initialCategories?.find(
                (prevCategories) =>
                  currentCategory.categoryId === prevCategories.categoryId
              )
          )
          ?.map((category) => ({
            categoryCode: category.categoryId,
          })),
        remove: initialCategories
          ?.filter(
            (prevCategory) =>
              !updatedCategories?.find(
                (currentCategories) =>
                  currentCategories.categoryId === prevCategory.categoryId
              )
          )
          ?.map((categories) => categories.categoryId),
      },
    };

    const keysToMutate = { id: id };

    mutate(keysToMutate, { relations });
  };

  const groupedCategories = Object.entries(
    groupBy(initialCategories, 'categoryKindName')
  );

  return (
    <div>
      <div className={styles.categoryHeadWrapper}>
        <div style={{ width: '100%' }}>
          <Divider title={t('COMMON.CATEGORY_other')} size="body" />
        </div>

        <EntityPickerPopover
          anchorEl={categoryFieldRef.current}
          onClose={mutateCategories}
          picker={
            <ProjectCategoryClassPicker
              multiple
              value={
                !Array.isArray(updatedCategories)
                  ? []
                  : updatedCategories.map((categoryAssignment) => ({
                      id: categoryAssignment?.categoryId,
                      name: categoryAssignment?.categoryName,
                    }))
              }
              onChange={(value) => {
                setUpdatedCategories(
                  value.map((cat) => {
                    return {
                      categoryId: cat.id,
                      categoryName: cat.name,
                    };
                  })
                );
              }}
            />
          }
        >
          <IconButton size="large" color="primary" onClick={() => ''}>
            <EditIcon />
          </IconButton>
        </EntityPickerPopover>
      </div>
      <div className={styles.categoryContentWrapper}>
        <InlineDataList
          items={[...groupedCategories]
            .sort((a, b) => sortedCategories(a[0], b[0]))
            .map((item) => ({
              id: item[0],
              label: item[0],
              content: (
                <Typography variant="body2" className={styles['overflow-line']}>
                  {item[1].map((x) => x.categoryName).join(', ')}
                </Typography>
              ),
            }))}
        />
      </div>
    </div>
  );
};
