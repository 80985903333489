import styles from '../ProjectPlanningView.module.scss';

import { MbscCalendarEvent } from '@mobiscroll/react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

const createDateEvent = (props: {
  project: Project;
  start: string;
  end: string;
  compact: boolean;
  name: string;
}) => {
  const { compact, project, start, end, name } = props;
  const id = compact ? 'project-' + project.id : name + '-' + project.id;
  return {
    start: DateTime.fromISO(start).startOf('day').toJSDate(),
    end: DateTime.fromISO(end).endOf('day').toJSDate(),
    resource: id,
    id,
    cssClass: styles[ProjectProcessKind.GLIEDERUNGSPUNKT],
    editable: false,
    dragBetweenResources: false,
    tooltip: name,
    title: name,
  } as MbscCalendarEvent;
};

export const useProjectProcessEvents = (props: {
  projectList: Project[];
  compact?: boolean;
  resourceClassIdList?: number[];
}) => {
  const { projectList, resourceClassIdList, compact = true } = props;
  const { t } = useTranslation();

  return useMemo<MbscCalendarEvent[]>(() => {
    const result: MbscCalendarEvent[] = [];

    for (const project of projectList) {
      //Create events for each of the project date ranges

      if (project.startDateOuter || project.endDateOuter) {
        result.push(
          createDateEvent({
            project,
            compact,
            start: project.startDateOuter || project.endDateOuter,
            end: project.endDateOuter || project.startDateOuter,
            name: t('PROJECTPLANNING.DATERANGE.START_END'),
          })
        );
      }

      if (project.startDateInner || project.endDateInner) {
        result.push(
          createDateEvent({
            project,
            compact,
            start: project.startDateInner || project.endDateInner,
            end: project.endDateInner || project.startDateInner,
            name: t('PROJECTPLANNING.DATERANGE.EVENT_START_END'),
          })
        );
      }

      const filteredProcesses =
        !resourceClassIdList || !resourceClassIdList.length
          ? project?.projectProcessList
          : project?.projectProcessList.filter((x) =>
              resourceClassIdList.includes(x.ressourceClassId)
            );

      const projectEvents = filteredProcesses
        .map((process) => {
          const progress =
            (process?.completionFeedbacks?.length &&
              process?.completionFeedbacks?.[0].completionPercent) ||
            0;

          const resource = compact ? 'project-' + project.id : process.id;

          const tooltip = [
            process.process,
            process.customer?.name,
            process.supplier?.name,
            `${process.planningAmount} ${t('COMMON.DAY_other')}  |  ${
              process?.completionFeedbacks?.[0]?.completionPercent || 0
            }% ${
              process?.ressourceClass
                ? ' |  ' + process?.ressourceClass?.name
                : ''
            }`,
            process.comment
              ? '------------------------\n' + process.comment
              : undefined,
          ]
            .filter(Boolean)
            .join('\n');

          return process.kindId === ProjectProcessKind.GLIEDERUNGSPUNKT
            ? null
            : ({
                id: process.id,
                start: DateTime.fromISO(process.startDatum)
                  .startOf('day')
                  .toJSDate(),
                end: DateTime.fromISO(
                  process.kindId === ProjectProcessKind.VORGANG
                    ? process.endDateInner
                    : process.startDatum
                )
                  .endOf('day')
                  .toJSDate(),
                title: process.process,
                resource,
                cssClass: clsx(
                  styles[process.kindId],
                  styles['progress-' + progress]
                ),
                dragBetweenResources: false,
                resize: process.kindId === ProjectProcessKind.VORGANG,
                tooltip,
                color:
                  process.ressourceClass?.hexColorPair?.split(',')[0] ||
                  'var(--ui04)',
                process,
              } as MbscCalendarEvent);
        })
        .filter((x) => Boolean(x));

      if (!compact) {
        const headers = filteredProcesses.filter(
          (process) => process.kindId === ProjectProcessKind.GLIEDERUNGSPUNKT
        );

        if (headers?.length > 0) {
          for (const header of headers) {
            const subProcesses = filteredProcesses.filter(
              (process) => process.parentId === header.id
            );
            const startDate = subProcesses.sort((a, b) =>
              a.startDatum > b.startDatum ? 1 : -1
            )?.[0]?.startDatum;

            const endDate = subProcesses.sort((a, b) =>
              a.endDateInner > b.endDateInner ? -1 : 1
            )?.[0]?.endDateInner;

            projectEvents.push({
              start: DateTime.fromISO(startDate).startOf('day').toJSDate(),
              end: DateTime.fromISO(endDate).endOf('day').toJSDate(),
              resource: header.id,
              cssClass: styles.range,
              editable: false,
              dragBetweenResources: false,
              tooltip: header.process,
            } as MbscCalendarEvent);
          }
        }

        const projectStartDate = projectEvents.sort((a, b) =>
          a.start > b.start ? 1 : -1
        )?.[0]?.start as Date;
        const projectEndDate = projectEvents.sort((a, b) =>
          a.end > b.end ? -1 : 1
        )?.[0]?.end as Date;

        projectEvents.push({
          start: DateTime.fromJSDate(projectStartDate)
            .startOf('day')
            .toJSDate(),
          end: DateTime.fromJSDate(projectEndDate).endOf('day').toJSDate(),
          resource: 'project-' + project.id,
          cssClass: styles.range,
          editable: false,
          dragBetweenResources: false,
          tooltip: `${project.number} - ${project.name}`,
        } as MbscCalendarEvent);
      }

      result.push(...projectEvents);
    }

    return result;
  }, [compact, projectList, resourceClassIdList, t]);
};
