import styles from './DropOverlay.module.scss';

import { Send } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as CreateNewIcon } from '@work4all/assets/icons/create_new_folder.svg';

import { useLocation, useNavigate } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useBinaryTransfer } from '../../../../hooks/useBinaryTransfer';

import { useDropOverlayItems } from './useDropOverlayItems';

interface FilesItem {
  files: File[];
  items: DataTransferItemList;
}

export interface DropProps {
  entity: Entities;
  onDrop: (dropped) => void;
  disabled?: boolean;
  entityVariant?: string;
}

const DropOverlayElement: React.FC<DropProps> = ({
  entity,
  onDrop,
  disabled = false,
  entityVariant,
}) => {
  const { t } = useTranslation();
  const dragStartCount = useRef<number>(null);
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(item) {
      onDrop(item);
    },
    canDrop: () => {
      if (disabled) return false;
      return true;
    },
    collect: (monitor) => {
      const item = monitor.getItem() as FilesItem;
      if (dragStartCount.current === undefined && item?.items?.length > 0) {
        dragStartCount.current = item.items?.length;
      } else if (item === null) {
        dragStartCount.current = undefined;
      }

      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  }));

  const renderContent = () => {
    const Icon = entity === Entities.eMail ? Send : CreateNewIcon;
    const variant = entityVariant ?? t(`COMMON.${entity.toUpperCase()}`);

    return (
      <>
        <Icon />
        <Typography variant="h4">
          {t('FILE.dropoperation.create', {
            context: entity,
            variant,
          })}
        </Typography>
      </>
    );
  };

  return (
    <div
      ref={(node) => {
        drop(node);
      }}
      className={clsx(styles.element, {
        [styles.accept]: isOver && canDrop,
        [styles.cant]: !canDrop,
      })}
    >
      {renderContent()}
    </div>
  );
};

interface Props {
  active: boolean;
  entity?: Entities;
}
export const DropOverlay: React.FC<Props> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const binaryTransfertCtx = useBinaryTransfer();

  const onDrop = useEventCallback(
    (item: FilesItem, entity: Entities, entityVariant?: string) => {
      binaryTransfertCtx.setTransferData(item.files);

      if (searchParams.get('basedon') !== 'transfer') {
        searchParams.delete('basedon');
        searchParams.append('basedon', 'transfer');
      }

      const entityVariantPath = entityVariant
        ? `${entity}(${entityVariant})`
        : entity;

      navigate({
        pathname: `${location.pathname}/details/${entityVariantPath}/new`,
        search: searchParams.toString(),
      });
    }
  );

  const items = useDropOverlayItems({ openedEntity: props.entity });

  const renderDropOverlay = useCallback(
    (
      { entity, entityVariant, disabled }: Omit<DropProps, 'onDrop'>,
      index: number
    ) => {
      return (
        <DropOverlayElement
          key={`${entityVariant}-overlay-${index}`}
          entity={entity}
          entityVariant={entityVariant}
          disabled={disabled}
          onDrop={(item) => onDrop(item, entity, entityVariant)}
        />
      );
    },
    [onDrop]
  );

  const renderDropOverlays = useCallback(() => {
    return items.map((item, i) => renderDropOverlay(item, i));
  }, [items, renderDropOverlay]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.active]: props.active,
      })}
    >
      {renderDropOverlays()}
    </div>
  );
};
