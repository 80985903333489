import styles from './SelectedGroupsList.module.scss';

import { ListItem } from '@mui/material';
import React, { RefObject, useCallback } from 'react';
import { TableInstance } from 'react-table';

import {
  DraggableItem,
  IDraggableCellProps,
} from '@work4all/components/lib/components/draggable-item/DraggableItem';
import { ITableStateBag } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useTableStateBag';
import { Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';

import { reorder } from '@work4all/utils/lib/reorder';
import { DndTypes } from '@work4all/utils/lib/variables';

interface IGroupListProps {
  groupedBy?: string[];
  columnsById: ITableStateBag['columnsById'];
  tableInstanceRef: RefObject<TableInstance | null>;
}

export const SelectedGroupsList: React.FC<IGroupListProps> = (props) => {
  const { columnsById, groupedBy, tableInstanceRef } = props;
  const removeGroupBy = (colId: string) => {
    const newGroupedBy = groupedBy.filter((groupedId) => {
      return groupedId !== colId;
    });
    tableInstanceRef.current.setGroupBy(newGroupedBy);
  };

  const onDragEnd: IDraggableCellProps['onDragEnd'] = useCallback(
    (dragItem, dropTarget) => {
      if (dragItem.type === DndTypes.GROUP_CHIP) {
        const dragIndex = groupedBy.indexOf(dragItem.id);
        const dropIndex = groupedBy.indexOf(dropTarget.id);
        const newGroupedBy = reorder([...groupedBy], dragIndex, dropIndex);

        tableInstanceRef.current.setGroupBy(newGroupedBy);
        return;
      }

      const moved = dragItem.id;
      const col = columnsById[moved];
      const dropIndex = groupedBy.indexOf(dropTarget.id) + 1;

      if (groupedBy.indexOf(moved) === -1 && col?.canGroupBy) {
        const newGroupedBy = [...groupedBy];
        newGroupedBy.splice(dropIndex, 0, moved);
        tableInstanceRef.current.setGroupBy(newGroupedBy);
      }
    },
    [columnsById, groupedBy, tableInstanceRef]
  );

  if (!groupedBy?.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      {groupedBy.map((g) => {
        return (
          <DraggableItem
            key={g}
            accept={[DndTypes.BASIC_TABLE_COL, DndTypes.GROUP_CHIP]}
            type={DndTypes.GROUP_CHIP}
            id={g}
            onDragEnd={onDragEnd}
          >
            {(itemRef) => {
              return (
                <ListItem className={styles['list-item']} ref={itemRef}>
                  <Chip
                    label={columnsById[g].Header as string}
                    handleDelete={() => {
                      removeGroupBy(g);
                    }}
                  />
                </ListItem>
              );
            }}
          </DraggableItem>
        );
      })}
    </div>
  );
};
