import styles from './DocumentTemplateContent.module.scss';

import { Fullscreen } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, Stack, Theme, useMediaQuery } from '@mui/material';
import { ChangeEvent, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { Collapse } from '@work4all/components/lib/components/collapse';
import { usePreviewNavigation } from '@work4all/components/lib/components/entity-preview/erp-preview/hooks/use-preview-navigation';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { LockContext } from '@work4all/components/lib/hooks';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { Remove } from '@work4all/components/lib/input/actions/remove/Remove';
import { Upload } from '@work4all/components/lib/input/actions/upload/Upload';
import { FileButton } from '@work4all/components/lib/input/FileButton';
import {
  FilePreview,
  FilePreviewProvider,
  useFilePreview,
} from '@work4all/components/lib/preview/FilePreviewProvider';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TemplateGroupPickerField } from '../../../../../components/entity-picker/TemplateGroupPickerField';
import { ControllerPlus } from '../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../locked-inputs';
import { ControlWrapper } from '../../components';
import { useMaskContext } from '../../hooks/mask-context';

import { DocumentTemplateFormValue } from './DocumentTemplateFormValue';

interface DocumentTemplateContentProps {
  onFileRejected?: () => void;
}

export const DocumentTemplateContent = memo(function (
  props: DocumentTemplateContentProps
) {
  const { t } = useTranslation();
  const { register, control } = useFormContextPlus<DocumentTemplateFormValue>();
  const mask = useMaskContext();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <ControlWrapper
      columns={isMobile ? 1 : 2}
      className={styles.documentTemplateWrapper}
    >
      <div>
        <Collapse defaultOpen title={t('COMMON.DESCRIPTION')}>
          <Card>
            <ControlWrapper>
              <LabeledInput
                {...register('body')}
                required
                label={t('COMMON.TITLE')}
              />
            </ControlWrapper>
            <ControlWrapper>
              <LabeledInput
                {...register('title')}
                label={t('COMMON.SUBJECT')}
              />
            </ControlWrapper>
          </Card>
        </Collapse>
        <Collapse defaultOpen title={t('COMMON.ASSIGNMENT')}>
          <Card>
            <ControlWrapper>
              <ControllerPlus
                control={control}
                name="templateGroup"
                render={({ field }) => {
                  return (
                    <TemplateGroupPickerField
                      {...field}
                      entity={
                        mask.entity === Entities.wordDocumentTemplate
                          ? Entities.documentTemplateGroup
                          : Entities.letterTemplateGroup
                      }
                    />
                  );
                }}
              />
            </ControlWrapper>
          </Card>
        </Collapse>
      </div>
      <div>
        <DocumentTemplatePreview {...props} />
      </div>
    </ControlWrapper>
  );
});

const DocumentTemplatePreview = (props: DocumentTemplateContentProps) => {
  const receiptConext = useContext(TempFileManagerContext);
  const { file } = usePreviewNavigation(receiptConext.fileList);
  return (
    <FilePreviewProvider file={file}>
      <DocumentTemplatePreviewInternal {...props} />
    </FilePreviewProvider>
  );
};

const DocumentTemplatePreviewInternal = (
  props: DocumentTemplateContentProps
) => {
  const { onFileRejected } = props;
  const { t } = useTranslation();
  const { openFullscreen, file } = useFilePreview();
  const receiptConext = useContext(TempFileManagerContext);
  const { locked } = useContext(LockContext);

  const onUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files);
      if (!areFilesValid(files, '.pdf,.doc,.docx,.xls,.xlsx')) {
        onFileRejected?.();
        return;
      }
      receiptConext.uploadFiles(files);
    },
    [receiptConext]
  );
  const dialogs = useDialogs();

  const onRemove = useCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('ALERTS.BTN_DELETE'),
      description: t('ALERTS.DELETE.CONFIRM'),
    });
    if (!confirmed) return;
    const id = file?.id;
    receiptConext.markFilesToDelete([id?.toString()]);
  }, [dialogs, t, file?.id, receiptConext]);

  const previewReadable = !!file?.fileInfos?.previewMimeType;

  const headerBar = (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        {!previewReadable && (
          <Upload
            accept=".pdf,.doc,.docx,.xls,.xlsx"
            multiple={false}
            onChange={onUpload}
            noTitle
            disabled={locked}
          />
        )}
        {previewReadable && <Remove onClick={onRemove} disabled={locked} />}
        {previewReadable && (
          <IconButtonWithTooltip
            tooltip={t('COMMON.FULLSCREEN')}
            icon={<Fullscreen />}
            onClick={openFullscreen}
          />
        )}
      </Stack>
      {/* {navigation} */}
    </Stack>
  );
  return (
    <Collapse
      defaultOpen
      headerBar={headerBar}
      title={t('COMMON.TEMPLATE')}
      className={styles.collapse}
      collapseContainerStyles={styles.full}
      classes={{
        wrapper: styles.full,
        wrapperInner: styles.full,
      }}
    >
      <div className={styles.documentTemplatePreview}>
        <div className={styles.documentTemplatePreviewBackground}>
          <InsertDriveFileIcon />
        </div>
        {previewReadable ? (
          <FilePreview file={file} />
        ) : (
          <FileButton
            accept=".pdf,.doc,.docx,.xls,.xlsx"
            name="upload"
            onChange={onUpload}
          >
            {({ onClick }) => (
              <Button
                variant="text"
                startIcon={<InsertDriveFileIcon />}
                onClick={onClick}
              >
                {t('MASK.ADD_DOCUMENT')}
              </Button>
            )}
          </FileButton>
        )}
      </div>
    </Collapse>
  );
};

export function areFilesValid(files: File[], accept?: string): boolean {
  if (!accept) return true;

  // Convert the `accept` string into an array of accepted file types
  const acceptedTypes = accept.split(',').map((type) => type.trim());

  // Helper function to check if a file matches the accepted types
  const isFileValid = (file: File): boolean => {
    return acceptedTypes.some((type) => {
      // Handle wildcard MIME types like "image/*"
      if (type.endsWith('/*')) {
        const baseType = type.split('/')[0];
        return file.type.startsWith(baseType + '/');
      }
      // Handle specific MIME types or file extensions
      return file.type === type || file.name.endsWith(type);
    });
  };

  return files.every(isFileValid);
}
