import UndoIcon from '@mui/icons-material/Undo';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu } from '@work4all/components';
import { ColumnVisibilityContext } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { ColumnInstance } from '@work4all/components/lib/dataDisplay/basic-table/types';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { columnsToMenuSections } from '../../utils/columns-to-menu-sections';
import { ColumnsMenuItem } from '../ColumnsMenuItem';
import { VISIBILITY_COLUMNS_EXCEPTIONS } from '../popover/Popover';

export interface IGroupingsProps {
  visibleColumns: ColumnInstance[];
  popoverState: ReturnType<typeof usePopoverState>;
  onResetColumns?: () => void;
  title?: string;
}

export const ColumnsVisibility: React.FC<IGroupingsProps> = React.memo(
  function ColumnsVisibility(props) {
    const { visibleColumns = [], popoverState, onResetColumns, title } = props;

    const columns = useMemo(() => {
      return visibleColumns.filter(
        (column) =>
          !VISIBILITY_COLUMNS_EXCEPTIONS.includes(column.id) &&
          !column.disableColumnVisibility
      );
    }, [visibleColumns]);

    const { t } = useTranslation();
    const sections = columnsToMenuSections(columns);
    const columnVisibilityContext = useContext(ColumnVisibilityContext);

    return (
      <Menu<ColumnInstance>
        title={title ?? t('MASK.COLUMNS')}
        sections={sections}
        renderItem={(item) => {
          return (
            <ColumnsMenuItem
              label={item.label}
              isChecked={item.isVisible}
              hasSections={Boolean(item.sections)}
            />
          );
        }}
        open={popoverState.open}
        anchorEl={popoverState.anchorEl}
        onClose={popoverState.onClose}
        onItemClick={(item) => {
          columnVisibilityContext.toggleColumnVisibility(item.id);
        }}
        actions={[
          {
            name: t('LISTS_PAGES.RESET_COLUMNS'),
            icon: <UndoIcon color="disabled" />,
            onClick: onResetColumns,
          },
        ]}
      />
    );
  }
);
