import styles from './ProjectGroupAccessRightOverlayController.module.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { uniq } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { UserRow } from '@work4all/components/lib/components/entity-picker/user-picker/UserPicker';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { ConfirmDialog } from '@work4all/components/lib/dialogs/ConfirmDialog';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useDeleteEntity, useFormPlus } from '@work4all/data';

import { InputProjectAccessGroupRelation } from '@work4all/models/lib/Classes/InputProjectAccessGroupRelation.entity';
import { ProjectAccessGroup } from '@work4all/models/lib/Classes/ProjectAccessGroup.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { ControllerPlus } from '../../../../containers/mask-overlays/form-plus/controller-plus';
import { LabeledInput } from '../../../../containers/mask-overlays/locked-inputs';
import { MaskActions } from '../../../../containers/mask-overlays/mask-overlay/components/MaskActions';
import { UserPickerField } from '../../../entity-picker/UserPickerField';
import { NavigationOverlayHeader } from '../../../navigation-overlay-header/NavigationOverlayHeader';
import { useProjectAccessGroupMutate } from '../hooks/use-project-acces-group-mutate';

type FormValues = PathsOf<
  {
    id: number;
    name: string | null;
    user: User | null;
    users: User[];
  },
  2
>;

interface ProjectGroupAccessRightOverlayControllerProps {
  groupId: number;
  groups: ProjectAccessGroup[];
  refetchGroupRights: () => void;
}

export const ProjectGroupAccessRightOverlayController = (
  props: ProjectGroupAccessRightOverlayControllerProps
) => {
  const { groupId, groups, refetchGroupRights } = props;
  const { t } = useTranslation();

  const alreadyInGroupIds = groups.flatMap((x) => x.users).map((x) => x.id);
  const group = groups.find((x) => x.id === groupId);

  const disabled = !groupId;

  const [moveUser, setMoveUser] = useState<User | null>(null);
  const [isInDeleteProcess, setDelete] = useState(false);
  const [deleteEntity] = useDeleteEntity(false);

  const { close } = useHistoryStack();

  const {
    control,
    register,
    watch,
    setValue,
    reset,
    formState: { dirtyFields },
  } = useFormPlus<FormValues>({
    defaultValues: {
      id: groupId,
      name: group?.name || null,
      user: null,
      users: group?.users ?? [],
    },
  });

  const prevValues = useRef<ProjectAccessGroup>(null);

  useEffect(() => {
    if (group) {
      prevValues.current = group;
      reset(group);
    }
  }, [reset, group]);

  const findMoveUserGroup = () =>
    groups.find((x) => x.users.some((y) => y.id === moveUser?.id));

  const users = watch('users') || [];
  const userList = users.map((user) => (
    <Box key={user.id} display="flex" justifyContent="space-between">
      <UserRow user={user} />
      <IconButton
        tabIndex={-1}
        size="small"
        onClick={() => {
          setValue(
            'users',
            users.filter((x) => x.id !== user.id),
            { shouldDirty: true }
          );
          save();
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  ));

  const isSubmittingRef = useRef(false);
  const onCompleteMutate = (data) => {
    reset(data);
    refetchGroupRights();
    isSubmittingRef.current = false;
  };
  const onErrorMutate = () => {
    isSubmittingRef.current = false;
  };
  const mutate = useProjectAccessGroupMutate(onCompleteMutate, onErrorMutate);
  const moveUserMutate = useProjectAccessGroupMutate();

  const save = useCallback(() => {
    isSubmittingRef.current = true;
    try {
      const values = watch();
      if (
        !(values.name && typeof values.users === 'object') ||
        !Object.entries(dirtyFields).length
      ) {
        isSubmittingRef.current = false;
        return;
      }
      const input = {
        name: values.name,
        id: values.id,
      };

      const groupUsers = prevValues.current?.users || [];
      const add = values.users
        .filter((x) => !groupUsers.some((y) => y.id === x.id))
        .map((x) => x.id);
      const remove = groupUsers
        .filter((x) => !values.users.some((y) => y.id === x.id))
        .map((x) => x.id);
      const relations: InputProjectAccessGroupRelation = {
        users: {
          add: uniq(add),
          remove: uniq(remove),
        },
      };
      const extraArgs = add.length || remove.length ? { relations } : undefined;
      mutate(input, extraArgs);

      prevValues.current = values;
    } catch {
      isSubmittingRef.current = false;
    }
  }, [watch, mutate, dirtyFields]);

  return (
    <Box className={styles.wrapper}>
      <NavigationOverlayHeader
        title={t('COMMON.RIGHTS_GROUP.TITLE')}
        breadcrumbsChildren={
          <MaskActions>
            <IconButton
              type="submit"
              size="large"
              disabled={disabled}
              onClick={() => setDelete(true)}
            >
              <DeleteIcon color={disabled ? 'secondary' : 'primary'} />
            </IconButton>
          </MaskActions>
        }
      />
      <Box padding="1rem" gap="0.5rem" display="flex" flexDirection="column">
        <Divider
          title={t('COMMON.GROUP')}
          style={{ width: '100%', paddingTop: 0 }}
        />

        <LabeledInput
          autoFocus
          label={t('COMMON.NAME')}
          {...register('name')}
          onBlur={() => save()}
        />

        <Divider
          title={t('COMMON.USER')}
          style={{ width: '100%', paddingTop: 0 }}
        />

        <Card>
          {userList.length ? (
            <Box
              paddingBottom="1rem"
              gap="1rem"
              display="flex"
              flexDirection="column"
            >
              {userList}
            </Box>
          ) : null}

          <ControllerPlus
            control={control}
            name="user"
            render={({ field }) => {
              return (
                <UserPickerField
                  label={t('FIELDS.findUser')}
                  disabledItemsIds={users.map((x) => x.id)}
                  {...field}
                  onChange={(user) => {
                    if (!user) return;
                    if (alreadyInGroupIds.includes(user.id)) {
                      setMoveUser(user);
                      return;
                    }

                    setValue('users', [...users, user], { shouldDirty: true });
                    save();
                  }}
                />
              );
            }}
          />
        </Card>
      </Box>
      <ConfirmDialog
        open={moveUser !== null}
        title={t('COMMON.RIGHTS_GROUP.TITLE')}
        description={t('COMMON.RIGHTS_GROUP.ALREADY_IN', {
          ...moveUser,
          groupName: findMoveUserGroup()?.name,
        })}
        onConfirm={() => {
          setValue('users', [...users, moveUser], { shouldDirty: true });
          const groupToUpdate = findMoveUserGroup();
          if (groupToUpdate) {
            const relations: InputProjectAccessGroupRelation = {
              users: {
                add: [],
                remove: [moveUser.id],
              },
            };
            moveUserMutate(
              { id: groupToUpdate.id, name: groupToUpdate.name },
              { relations }
            );
          }
          setMoveUser(null);
          save();
        }}
        onCancel={() => setMoveUser(null)}
      />

      <ConfirmDialog
        open={isInDeleteProcess}
        title={t('MASK.REMOVE')}
        description={t('COMMON.RIGHTS_GROUP.DELETE')}
        onConfirm={() => {
          close();
          deleteEntity(
            { type: Entities.projectAccessGroup, ids: [groupId] },
            { onCompleted: () => refetchGroupRights() }
          );
          setDelete(false);
        }}
        onCancel={() => setDelete(false)}
      />
    </Box>
  );
};
