import styles from './../list-entity-picker/ListEntityPicker.module.scss';

import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton, LinearProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import React, {
  type JSX,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Index } from 'react-virtualized';

import { IResponse } from '@work4all/data';
import { FavoritesContext } from '@work4all/data/lib/hooks/favorites/FavoritesProvider';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import { Chip } from '../../../../dataDisplay/chip/Chip';
import { ChipList } from '../../../../dataDisplay/chip/ChipList';
import { usePickerSettings } from '../../hooks/use-picker-settings';
import { useSelectionModel } from '../../hooks/use-selection-model';
import { EntityLike } from '../../types';
import type { Selection } from '../../utils/selection-model';
import { KeyNavigationHandlerContainer } from '../list-entity-picker/KeyNavigationHandlerContainer';
import { IFilterPickerConfig } from '../list-entity-picker/use-filter-picker-config';
import {
  filterOutById,
  getEntityId,
  toSelectionValue,
} from '../list-entity-picker/utils';

import { EntityPickerDefaultSettings } from './components/EntityPickerDefaultSettings';
import { PickerHeader } from './components/PickerHeader';
import { PickerList } from './components/PickerList';
import { PickerContextProvider, PickerContextValue } from './picker-context';
import { FilterResult } from './utils/hooks/use-entity-picker-filter-result';
import { usePickerKeyNav } from './utils/hooks/use-picker-key-nav';

import { EntityPickerTab, EntityPickerTabBar } from '..';

export type { IFilterPickerConfig };

export enum QUERYVALUE {
  ALLITEMS = 'ALLITEMS',
  QUERY = 'QUERY',
}

type ListEntityPickerWithTabsProps<
  TValue extends EntityLike,
  TMultiple extends boolean
> = PickerCommonProps<TValue, TMultiple> & {
  tabs: EntityPickerListProps<TValue>[];

  /**
   * This function will be called to determine to which tab a given item
   * belongs. This will decide which tab will be active when a picker is opened
   * (only it there is an initial value) and wether or not the initially
   * selected items will be shown at the top of the list in the active tab.
   *
   * @param value - This will be the one of the items passed as initial value
   * to the picker. The function will be called for every item in the list
   * (or the only item when using single-selection mode).
   */
  getTabIndex?: (value: TValue) => number;

  /**
   * This function will be called after the initial mount and whenever a user
   * switches the current tab.
   *
   * @param entity - The entity type that pas passed to the selected tab's config.
   */
  onTabChange?: (idx: number, tabConfig: EntityPickerListProps<TValue>) => void;
};

export type PickerCommonProps<
  TValue extends EntityLike,
  TMultiple extends boolean
> = {
  /**
   * add abritrary elements between list and searchbar
   */
  prependList?: React.ReactNode;

  listItemHeight?: number;

  /**
   * get a reference to prgramatically call inner functions of the list
   */
  pickerInterfaceRef?: React.RefObject<ListEntityPickerInterface<TValue> | null>;

  /**
   * if items are selected put them in front of the list, default:true
   */
  prependSelected?: boolean;

  /**
   * Whether to use the picker in single or multiple selection mode.
   * In single-selection mode only one option can be selected at a time
   * and selecting a new option will deselect the previous one.
   * In multi-selection mode multiple values can be selected at the same time
   * and the list item will have a checkbox to represent selected items.
   *
   * **Important**: Using tabs in with with multi-selection is currently not
   * supported. Passing `multiple={false}` is still required for possible
   * future compatibility.
   */
  multiple?: TMultiple;

  hideCheckboxes?: TMultiple;

  /**
   * Define if single selectable values can be cleared.
   *
   * @default true
   */
  clearable?: boolean;

  /**
   * Currently selected value(s). The initial value of this prop will be used
   * to display the list of initially selected entities on top of the list.
   */
  value: Selection<TValue, TMultiple>;

  /**
   * This function will be called whenever the picker's value changes as a
   * result of user interaction. The value will be the entity object (or `null`
   * if nothing is selected) in single-selection mode and an array of entities
   * in multi-selection mode.
   */
  onChange: (value: Selection<TValue, TMultiple>) => void;

  /**
   * This value will determine the height of the picker component.
   * If the total number of items is less that or equal to this value,
   * the filter text input and the top section with initially or
   * recently selected items will not be displayed.
   *
   * @default 5
   */
  maxItems?: number;

  /**
   * This will modify the chip display value
   *
   */
  renderChipContent?: (item: TValue) => React.ReactNode;

  /**
   * This function will be called after picker renders with different size.
   * This can be used to update a popover position, for example.
   */
  onResize?: () => void;

  /**
   * This function will be called when the user inputs a value into the search field.
   */
  onSearchValueChange?: (value: string) => void;

  /***
   *
   */
  onFavoritesToggled?: (active: boolean) => void;

  /**
   * Suppress filtering
   */
  suppressFilter?: boolean;

  /**
   * Enables or disables certain features of the picker.
   *
   * In "simple" mode the text search input and chips section will be hidden and
   * the list items will be displayed in the same order as returned by the API
   * without tampering.
   *
   * In "advanced" mode will will show a search text input to filter the results
   * and in `multiple` mode will display selected items as chips above the
   * input. Additionally the items order will be changed to show selected items
   * (and last N selected, if enabled) at the top of the list. These "static"
   * items will be remembered on mount and will not change if you
   * select/deselect items during the lifetime of the component.
   *
   * In "auto" mode will choose on of the above depending on the size of the
   * dataset returned by the initial query. It will be set to "simple" while
   * fetching the initial data and might change to "advanced" if the dataset
   * size grows above the threshold, but will never change back from "advanced"
   * to "simple".
   *
   * Since in "auto" mode the actual UI will likely change after the initial
   * query is finished, it is recommended to explicitly set the layout to
   * "simple" or "advanced" whenever possible to avoid changes in the UI.
   *
   * @default "auto"
   */
  layout?: 'simple' | 'advanced' | 'auto';
  resultLayout?: QUERYVALUE;

  inputType?: React.HTMLInputTypeAttribute;

  /**
   * You can configure this picker to be used as a "smart filter" by providing
   * parent entity type, the name of the property of the parent entity that this
   * picker is used for and the value of the current filter.
   *
   * When this config is provided, the picker will only display items that are
   * not filtered out by the existing filter and will also display the number of
   * entities that will be matched if a given list item is selected.
   */
  filterConfig?: IFilterPickerConfig;

  noResultsString?: string;

  fullscreen?: boolean;

  /**
   * List of items that are visible, but not clickable.
   */
  disabledItemsIds?: (number | string)[];
  onClose?: () => void;
  renderCount?: (count: number, value: TValue) => React.ReactNode;
  onListChanged?: (items: TValue[]) => void;

  autoFocus?: boolean;

  headerAppend?: React.ReactNode;
};

export type EntityPickerListProps<TValue extends EntityLike> = {
  /////////////////////////
  regularResult: IResponse<TValue>;
  filterResult: FilterResult;
  ///////////////////////

  /**
   * used for common functionality, eg store the last search items of this entity
   */
  entity: Entities;
  /**
   * Text to display inside tab controls.
   */
  label: string;

  /**pass a fixed set of data instead of querying it automatically eg for lokkuptypes that dont support querying */

  /**pass a additional items to be displayed in the result list */
  additionalItems?: TValue[]; //IResponse<TValue>;

  /**
   * This is prefilter which is applied when search query exist instead of prefilter field.
   * If it's empty prefilter will be applied as default.
   */
  searchPrefilter?: unknown[];

  /** Field name that will be used to create a filter for GraphQL query. */
  filterBy: string | string[];

  /**
   * This function will be called for every item in the list. You can render
   * any content here. The returned element will be wrapper inside
   * MUI's <ListItemButton> (height=50px) alongside some additional elements
   * if needed (checkbox, clear icon, etc...).
   */
  renderItemContent: (value: TValue, allItems?: TValue[]) => React.ReactNode;

  /**
   * Function for override count display.
   */
  renderCount?: (count: number, value: TValue) => React.ReactNode;

  alignClearIcon?: boolean;

  /**
   * Placeholder text to display inside the text input. By default will
   * display the same text for all pickers, but can be customized to
   * provide a user a better hint at how the items will be searched.
   */
  placeholder?: string;

  favorites?: boolean;

  /**
   * @default false
   */
  useSearchHistory?: boolean;
};

export type ListEntityPickerInterface<TValue> = {
  toggle: (val: TValue) => void;
  setQueryString: (val) => void;
  goTo: (dir: number) => void;
  select: (dir: number) => void;
  /**@deprecated remove when old picker is gone replace with goto or select */
  handleKeyboardEvent: (event: KeyboardEvent | React.KeyboardEvent) => void;
};

/**
 * Base component for all pickers not using tabs.
 *
 * See [Pickers on Wiki](https://dev.azure.com/work4all-tfs/work4all%20mobile/_wiki/wikis/App%20wiki/59/Pickers)
 */
export function PickerBase<T>(
  props: Omit<PickerCommonProps<T, boolean> & EntityPickerListProps<T>, 'label'>
): JSX.Element {
  const {
    entity = null,
    filterBy = ['name'],
    useSearchHistory = true,
    ...rest
  } = props;
  const tabs = useMemo(
    (): EntityPickerListProps<T>[] => [
      {
        entity: entity,
        filterBy: filterBy,
        useSearchHistory: useSearchHistory,
        ...rest,
        label: '', //not needed in untabed pickers
      },
    ],
    [entity, filterBy, rest, useSearchHistory]
  );

  return <PickerBaseTabbed tabs={tabs} {...rest} />;
}

/**
 * Base component for all pickers using tabs.
 *
 * See [Pickers on Wiki](https://dev.azure.com/work4all-tfs/work4all%20mobile/_wiki/wikis/App%20wiki/59/Pickers)
 */
export function PickerBaseTabbed<
  TValue extends EntityLike,
  TMultiple extends boolean
>(props: ListEntityPickerWithTabsProps<TValue, TMultiple>) {
  const {
    value,
    onChange,
    onSearchValueChange,
    renderChipContent,
    multiple,
    resultLayout = 'ALLITEMS',
    hideCheckboxes,
    maxItems = 5,
    clearable = true,
    pickerInterfaceRef: ref,
    filterConfig: context = null,
    layout: layoutProp = 'auto',
    inputType,
    noResultsString,
    fullscreen,
    prependSelected = true,
    prependList = null,
    disabledItemsIds = [],
    listItemHeight,
    onFavoritesToggled,
    onListChanged,
    onClose,
    renderCount,
    autoFocus,
  } = props;
  const { t } = useTranslation();
  const { saveSearchItem } = useSearchHistory();

  const EMPTY_LIST = [] as never[];

  const inputRef = useRef<HTMLInputElement>(null);

  const showTabs = 'tabs' in props && props.tabs.length > 1;
  const getTabIndex = showTabs ? props.getTabIndex : undefined;

  const tabs = props.tabs;

  const [_activeTabIndex, setActiveTabIndex] = useState(() => {
    if (!getTabIndex) return 0;

    // Select the first tab that has an item selected.
    // If nothing is selected, select the first tab.

    const selection = toSelectionValue(value);

    if (selection.length === 0) return 0;

    const tabIndexes = selection.map((value) => getTabIndex(value));

    return Math.min(...tabIndexes);
  });

  // In case the current index ever goes out of bound, clamp it.
  const activeTabIndex = Math.min(tabs.length - 1, _activeTabIndex);

  const activeTab = tabs[activeTabIndex];
  const {
    entity,
    regularResult,
    filterResult,
    searchPrefilter,
    filterBy,
    renderItemContent = (item) => <Typography>{item[filterBy[0]]}</Typography>,
    alignClearIcon,
    placeholder,
    additionalItems = [],
    useSearchHistory: useSearchHistoryProp,
    favorites,
  } = activeTab;

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onTabChange = props.onTabChange;

  const initialSelected = useRef(toSelectionValue(value)).current;

  const hasFilterContext = context !== null;

  const selection = useSelectionModel({
    multiple,
    keyFn: getEntityId,
    initialSelected,
  });

  useEffect(() => {
    selection.setSelected(toSelectionValue(value));
  }, [selection, value]);

  const handleSelect = (value: TValue) => {
    selection.select(value);
    onChange(selection.getSelected());
  };

  const handleDeselect = (value: TValue) => {
    selection.deselect(value);
    onChange(selection.getSelected());
  };

  const handleToggle = (value: TValue) => {
    if (selection.isSelected(value)) {
      if (multiple || clearable) {
        handleDeselect(value);
      }
    } else {
      if (
        useSearchHistoryProp &&
        [
          Entities.customer,
          Entities.supplier,
          Entities.project,
          Entities.article,
        ].includes(entity)
      ) {
        if (value?.id) {
          saveSearchItem(entity, {
            id: value?.id.toString(),
            //eslint-disable-next-line
            //@ts-ignore
            number: value?.number,
            //eslint-disable-next-line
            //@ts-ignore
            name: value?.name,
          });
        }
      }
      handleSelect(value);
    }
    onSearchValueChange?.('');
    setQuery('');
    inputRef.current?.focus();
  };

  const [showFavorites, setShowFavorites] = useState(false);

  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim();

  const enableSearchHistory =
    queryTrimmed === '' &&
    !!useSearchHistoryProp &&
    !hasFilterContext &&
    !showFavorites;

  // UPDATE: -> its used in froalas texteditor for the mentioning picker!
  // TODO This ref doesn't seem to be used anywhere. Is it needed?
  //
  // If it is, it should probably be changed to not use the `toggle` function and
  // update the selection value directly to so it doesn't call the `onChange`
  // prop. But you can already do it by just passing a new value to the
  // `selection` prop, so I'm not sure why this is even needed.
  //
  // Same fo the query string. If it needs to work in controlled mode, its
  // better to just add a new prop and pass the value directly instead.
  useImperativeHandle<
    ListEntityPickerInterface<TValue>,
    ListEntityPickerInterface<TValue>
  >(ref, () => {
    return {
      toggle: (val) => handleToggle(val),
      setQueryString: setQuery,
      handleKeyboardEvent: () => {
        //
      },
      goTo,
      select,
    };
  });

  const { settings } = usePickerSettings(entity);

  const favoritesContext = useContext(FavoritesContext);

  const itemsTotal = hasFilterContext
    ? filterResult.totalCount
    : regularResult.total;
  const { pending } = regularResult;

  // Remember the max number of items in the list to decide
  // whether or not to show the filter text input.
  const itemsTotalMaxRef = useRef<number>(itemsTotal);
  itemsTotalMaxRef.current = Math.max(itemsTotalMaxRef.current, itemsTotal);

  function getResolvedLayout() {
    if (layoutProp === 'auto') {
      return showTabs ||
        itemsTotalMaxRef.current > maxItems ||
        searchPrefilter ||
        favorites ||
        enableSearchHistory
        ? 'advanced'
        : 'simple';
    } else {
      return layoutProp;
    }
  }

  const layout = getResolvedLayout();

  const showSimpleView = layout === 'simple' && !fullscreen;
  const showPrependedItems =
    !multiple && !showSimpleView && !query && prependSelected === true;

  const items = useMemo(() => {
    return [
      ...additionalItems,
      ...(hasFilterContext ? filterResult.nodes : regularResult.data),
    ];
  }, [
    additionalItems,
    filterResult?.nodes,
    hasFilterContext,
    regularResult.data,
  ]);

  const initialSelectedInActiveTab = useMemo(() => {
    return getTabIndex
      ? initialSelected.filter((value) => getTabIndex(value) === activeTabIndex)
      : initialSelected;
  }, [activeTabIndex, getTabIndex, initialSelected]);

  const prependedItems = (
    showPrependedItems &&
    initialSelectedInActiveTab.length > 0 &&
    !showFavorites
      ? initialSelectedInActiveTab
      : EMPTY_LIST
  ) as TValue[];

  // Remove the prepended options from the main list, so that we don't show
  // them twice.
  const filteredItems = useMemo(() => {
    return items ? filterOutById(items, prependedItems) : [];
  }, [items, prependedItems]);

  // Add static options to the beginning of the list.
  // These options have already been filtered out from the main list, so they
  // will not be shown twice and the overall number of items should remain
  // the same.
  const allItems = useMemo(
    () => [...prependedItems, ...filteredItems],
    [prependedItems, filteredItems]
  );

  useEffect(() => {
    onListChanged?.(allItems);
  }, [onListChanged, allItems]);

  function isRowLoaded({ index }: Index) {
    return allItems[index] != null;
  }

  const PAGE_SIZE = 100;
  const loadMoreRows = () => {
    if (hasFilterContext) return filterResult.fetchMore?.();
    const { startIndex, stopIndex } = calculateRange(
      regularResult.data.length,
      PAGE_SIZE
    );
    return regularResult.fetchMore?.(startIndex, stopIndex);
  };

  // If the picker updates its size we need to reposition the popover.
  // It can resize because of search input showing/hiding or number of
  // displayed items changing.
  const onResize = useLatest(props.onResize);

  useEffect(() => {
    onResize.current?.();
  }, [onResize, showSimpleView, maxItems]);

  const total = Math.max(0, itemsTotal, allItems.length);

  const { goTo, select, keyScopeRef, infiniteListRef, activeRowIndex } =
    usePickerKeyNav(total, handleToggle, allItems);

  const handleToggleFavorites = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setShowFavorites(!showFavorites);
      onFavoritesToggled?.(!showFavorites);
      inputRef.current?.focus();
    },
    [onFavoritesToggled, showFavorites]
  );

  const handleTabChange = (tab: number) => {
    onTabChange(tab, tabs[tab]);
    setActiveTabIndex(tab);
    scrollToTopAfterNextRender.current = true;
    inputRef.current?.focus();
  };

  const showLoadingPlaceholder =
    total === 0 && (hasFilterContext ? filterResult : regularResult).loading;

  const [activeLoadingIndicator, setActiveLoadingIndicator] = useState(false);

  useEffect(() => {
    if (showLoadingPlaceholder || pending) {
      const to = setTimeout(() => {
        setActiveLoadingIndicator(showLoadingPlaceholder || pending);
      }, 200);
      return () => {
        clearTimeout(to);
        setActiveLoadingIndicator(false);
      };
    }
  }, [showLoadingPlaceholder, pending]);

  const scrollToTopAfterNextRender = useRef(false);

  useEffect(() => {
    if (scrollToTopAfterNextRender.current) {
      scrollToTopAfterNextRender.current = false;
      infiniteListRef.current?.scrollToItem(0);
    }
  });

  const allItemsForEmptyQuery = resultLayout === QUERYVALUE.ALLITEMS;

  const contextMenuRef = useRef(null);

  const pickerContext = useMemo<PickerContextValue>(() => {
    return {
      hasSelection: selection.getLength() > 0,
    };
  }, [selection]);

  return (
    <PickerContextProvider value={pickerContext}>
      <KeyNavigationHandlerContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: fullscreen ? '100%' : 0,
        }}
        ref={keyScopeRef}
        autoFocus={autoFocus}
      >
        {!showSimpleView && (
          <>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {multiple && (selection.getSelected() as any).length > 0 && (
              <div className={styles.chipListWrapper}>
                <ChipList>
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {(selection.getSelected() as any).map((el) => {
                    return (
                      <Chip
                        key={getEntityId(el)}
                        maxWidth={false}
                        handleDelete={() => {
                          handleDeselect(el);
                        }}
                        label={
                          renderChipContent?.(el) ||
                          el?.displayName ||
                          el?.[
                            filterBy instanceof Array ? filterBy[0] : filterBy
                          ]
                        }
                      />
                    );
                  })}
                </ChipList>
              </div>
            )}
            <PickerHeader
              query={query}
              autoFocus
              inputType={inputType}
              placeholder={placeholder}
              focusRef={inputRef}
              smallPadding={!!settings}
              onChange={(e) => {
                onSearchValueChange?.(e);
                setQuery(e);
              }}
              prepend={
                fullscreen ? (
                  <IconButton ref={contextMenuRef} onClick={() => onClose()}>
                    <ArrowBack />
                  </IconButton>
                ) : null
              }
              append={
                <>
                  {favorites && (
                    <Button
                      variant="outlined"
                      color={showFavorites ? 'primary' : 'secondary'}
                      onClick={handleToggleFavorites}
                      className={clsx(styles['favorites-button'], {
                        [styles['favorites-button-selected']]: showFavorites,
                        [styles['favorites-button-no-settings']]: !settings,
                      })}
                    >
                      {t('COMMON.FAVORITES').toUpperCase()}
                    </Button>
                  )}
                  <EntityPickerDefaultSettings
                    entity={entity}
                    inputRef={inputRef}
                  />
                  {props.headerAppend}
                </>
              }
            />
          </>
        )}

        {activeLoadingIndicator && <LinearProgress />}

        {showTabs && (
          <EntityPickerTabBar value={activeTabIndex} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <EntityPickerTab key={index} value={index}>
                {tab.label}
              </EntityPickerTab>
            ))}
          </EntityPickerTabBar>
        )}
        {prependList}
        <PickerList
          total={total}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          maxItems={maxItems}
          noResultsText={noResultsString}
          listRef={infiniteListRef}
          loading={showLoadingPlaceholder || pending}
          listItemHeight={listItemHeight}
          itemRenderData={{
            activeRowIndex,
            multiple,
            hideCheckboxes,
            clearable,
            disabledItemsIds,
            renderCount,
            renderItemContent: (item) =>
              renderItemContent(item as TValue, allItems),
            items: allItemsForEmptyQuery
              ? allItems
              : query.length
              ? allItems
              : [],
            counts: filterResult?.counts,

            isSelected: (item: TValue) => selection.isSelected(item),
            isSomethingSelected: selection.getLength() > 0,
            onToggle: handleToggle,
            isFav: (item) =>
              (favoritesContext?.register[entity] || []).includes(item.id),
            onFavToggle: favorites
              ? (item) => {
                  favoritesContext.toggleFavorite(entity, item.id);
                }
              : undefined,
            alignClearIcon,
          }}
        />
      </KeyNavigationHandlerContainer>
    </PickerContextProvider>
  );
}

const calculateRange = (length: number, pageSize: number) => {
  const startIndex = length;
  const stopIndex = startIndex + pageSize - 1;
  return { startIndex, stopIndex };
};
