import { z } from 'zod';

export const EMailSignatureSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  body: z.string().optional(),
  bodyTextmarksResolved: z.string().optional(),
  bodyTextmarkenAufgeloestInlineImagesResolved: z.string().optional(),
  __typename: z.string().optional(),
});

export type EMailSignatureZod = z.infer<typeof EMailSignatureSchema>;
