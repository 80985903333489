import { CellProps } from 'react-table';

import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';

import {
  canApproveOwnInboundInvoices,
  canForwardInboundInvoices,
} from '@work4all/utils/lib/permissions';

import { PermitStatusIconCell } from '../../PermitStatusIconCell';
import { mapSelectedRowStatus } from '../utils';

export const ReStatusCell = (cellProps: CellProps<ReViewModel>) => (
  <PermitStatusIconCell
    {...cellProps}
    value={mapSelectedRowStatus(
      cellProps.value,
      cellProps.row.values.paymentStatus
    )}
    canApprove={canApproveOwnInboundInvoices}
    canForward={canForwardInboundInvoices}
    view="list"
  />
);
