const env: {
  configuration?: string;
} = (function () {
  try {
    const parsed = JSON.parse(
      document.getElementById('W4A_ENV')?.textContent ?? null
    );

    if (typeof parsed === 'object' && parsed !== null) {
      return parsed;
    }
  } catch {
    return {};
  }
})();

export default env;
