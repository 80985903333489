import styles from './NumberInputBase.module.scss';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ButtonBase, InputAdornment } from '@mui/material';
import { useRef } from 'react';

import { reactRefSetter } from '@work4all/utils';

import { Unit } from '../labeled-currency-input/components/unit/Unit';
import { ILabeledInput, LabeledInput } from '../labeled-input/LabeledInput';

import { useNumberInput, UseNumberInputProps } from './use-number-input';
import { useNumberInputProps } from './use-number-input-props';

interface NumberInputBaseOwnProps {
  hideControls?: boolean;
  unit?: string;
}

export interface NumberInputBaseProps
  extends UseNumberInputProps,
    NumberInputBaseOwnProps,
    Omit<
      ILabeledInput,
      keyof UseNumberInputProps | keyof NumberInputBaseOwnProps
    > {}

export function NumberInputBase(props: NumberInputBaseProps) {
  const { numberInputProps, otherProps } = useNumberInputProps(props);
  const { hideControls, unit, ...labeledInputProps } = otherProps;

  const { getInputProps, canIncrement, canDecrement, increment, decrement } =
    useNumberInput(numberInputProps);

  const { ref, ...inputProps } = getInputProps();

  const inputRef = useRef<HTMLInputElement>(null);
  const mergedRef = reactRefSetter(ref, inputRef);

  return (
    <LabeledInput
      ref={mergedRef}
      {...labeledInputProps}
      {...inputProps}
      inputProps={{
        ...labeledInputProps.inputProps,
        style: { ...labeledInputProps.inputProps?.style, textAlign: 'right' },
      }}
      type="text"
      endAdornment={
        unit ? (
          <Unit unit={unit} />
        ) : (
          !hideControls && (
            <InputAdornment className={styles.controls} position="end">
              <ButtonBase
                className={styles.increment}
                tabIndex={-1}
                disabled={!canIncrement}
                onMouseDown={(event) => event.preventDefault()}
                onPointerDown={(event) => {
                  event.preventDefault();
                  inputRef.current.focus();
                  increment();
                }}
              >
                <ArrowDropUpIcon className={styles.icon} />
              </ButtonBase>

              <ButtonBase
                className={styles.decrement}
                tabIndex={-1}
                disabled={!canDecrement}
                onMouseDown={(event) => event.preventDefault()}
                onPointerDown={(event) => {
                  event.preventDefault();
                  inputRef.current.focus();
                  decrement();
                }}
              >
                <ArrowDropDownIcon className={styles.icon} />
              </ButtonBase>
            </InputAdornment>
          )
        )
      }
    />
  );
}
