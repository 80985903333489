import { useEventCallback } from '@mui/material/utils';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useRef,
  useState,
} from 'react';

import { EmailTemplatesPopover, useDialogs } from '@work4all/components';
import { useEmailTemplates } from '@work4all/components/lib/hooks';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../settings';
import { MaskConfig, MaskTemplateEntity } from '../../mask-overlay/types';
import {
  EmailMaskCustomParams,
  EmailOverlayController,
} from '../../mask-overlay/views/email/EmailOverlayController';

interface EmailParams {
  entityTemplate?: MaskTemplateEntity | null;
  params?: MaskConfig<EmailMaskCustomParams>['params'];
}

export interface EmailTemplateButtonContextProps {
  eMailTemplateKind: EMailTemplateKind;
  noTemplate?: boolean;
  languageId?: number;
  overrideRecipients?: string;
  getEmailParams: (
    template: EMailTemplate,
    context?: unknown
  ) => Promise<EmailParams>;
}

interface EmailTemplateButtonContextState {
  emailTempaltePickerFieldRef: React.RefObject<undefined>;
  onClick: () => void;
}

const EmailTemplateButtonContext =
  createContext<EmailTemplateButtonContextState | null>(null);

export const EmailTemplateButtonProvider = (
  props: PropsWithChildren<EmailTemplateButtonContextProps>
) => {
  const {
    children,
    noTemplate,
    eMailTemplateKind,
    overrideRecipients,
    ...args
  } = props;

  const dialogs = useDialogs();

  const [pickEmailTemplateOpen, setPickEmailTemplateOpen] = useState(false);
  const emailTempaltePickerFieldRef = useRef(null);
  const templates = useEmailTemplates(eMailTemplateKind);

  const { value: standardTemplates, set: setStandardTemplates } = useSetting(
    settings.standardEmailTemplates()
  );

  const openEmailMaskForTemplate = useEventCallback(
    async (emaiTemplate: EMailTemplate | null) => {
      const template = !emaiTemplate?.id ? null : emaiTemplate;
      const emailParams = await args.getEmailParams(template);

      if (!emailParams) return;

      const { entityTemplate, params } = emailParams;

      const initialView: IStackItem = {
        view: (
          <EmailOverlayController
            entity={Entities.eMail}
            id={null}
            template={entityTemplate}
            onAfterSave={closeMaskOverlay}
            params={{
              basedon: 'EmailTemplate',
              emailTemplate: template ? JSON.stringify(template) : undefined,
              forceNoTemplate: (template === null).toString(),
              eMailTemplateKind,
              overrideRecipients,
              ...params,
            }}
          />
        ),
      };

      const dialog = dialogs.open(NavigationOverlay, {
        initialView: initialView,
        close() {
          dialogs.close(dialog.id);
        },
      });

      function closeMaskOverlay() {
        dialogs.close(dialog.id);
      }
    }
  );

  const value = {
    emailTempaltePickerFieldRef,
    onClick: () => {
      const standardTemplateId = standardTemplates[eMailTemplateKind];
      const standardTemplate = templates?.find(
        (t) => t.id === standardTemplateId
      );
      if (standardTemplate) {
        openEmailMaskForTemplate(standardTemplate);
      } else {
        setPickEmailTemplateOpen(true);
      }
    },
  };

  return (
    <EmailTemplateButtonContext.Provider value={value}>
      {pickEmailTemplateOpen && (
        <EmailTemplatesPopover
          eMailTemplateKind={eMailTemplateKind}
          noTemplate={noTemplate}
          anchorEl={emailTempaltePickerFieldRef.current}
          onClose={() => setPickEmailTemplateOpen(false)}
          onTemplateClick={(template: EMailTemplate) => {
            setPickEmailTemplateOpen(false);
            openEmailMaskForTemplate(template);
          }}
          standardTemplates={standardTemplates}
          setStandardTemplates={setStandardTemplates}
        />
      )}

      {children}
    </EmailTemplateButtonContext.Provider>
  );
};

export const useEmailTemplateButton = () => {
  const state = useContext(EmailTemplateButtonContext);
  if (!state) {
    throw new Error(
      'Context is null. Make sure you wrapped your component with <EmailTemplateButtonContext />'
    );
  }
  return state;
};
