import PhoneIcon from '@mui/icons-material/Phone';
import { IconButton, Theme, useMediaQuery } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { includesHtml } from '@work4all/components/lib/components/entity-preview/utils';
import { mapToCheckItem } from '@work4all/components/lib/input/check-list-field/LookupCheckListField';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import { useHistoryStackTitleUpdate } from '@work4all/components/lib/navigation/hooks/use-history-stack-title-update';

import { useDataMutation } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { InputTicketChecklisteMarkRelation } from '@work4all/models/lib/Classes/InputTicketChecklisteMarkRelation.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TicketChecklisteMark } from '@work4all/models/lib/Classes/TicketChecklisteMark.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { isHTMLEmpty } from '@work4all/utils';
import { __TICKET_MUTATION_GUARD } from '@work4all/utils/lib/ticket-mutation-guard';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { settings, useSetting } from '../../../../../settings';
import { EmailTemplateButtonProvider } from '../../../components/email-template-button/EmailTemplateButtonProvider';
import { EmailTemplateIconButton } from '../../../components/email-template-button/EmailTemplateIconButton';
import { EmailTemplateMenuItemButton } from '../../../components/email-template-button/EmailTemplateMenuItemButton';
import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { useMaskConfig } from '../../hooks/mask-context';
import { useOverrideProps } from '../../hooks/use-override-props';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { ContactsPopover } from './components/contacts-popover/ContactsPopover';
import { MaskOverlayTicketTimeTrack } from './components/mask-overlay-time-track/MaskOverlayTicketTimeTrack';
import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import {
  TicketMaskStateContext,
  TicketMaskStateContextValue,
} from './hooks/ticket-mask-state-context';
import { useTicketDefaultData } from './hooks/use-ticket-default-data';
import { useTicketFormUpdate } from './hooks/use-ticket-form-update';
import { useTicketRequestData } from './hooks/use-ticket-request-data';
import { TicketMaskFormValue } from './types';

export enum TicketTabKeys {
  general = 'general',
  attachments = 'attachments',
}

interface TicketOverlayControllerProps extends MaskControllerProps {
  registerOnClose?: (handle: () => void) => void;
  timeTrackingAction?: boolean;
}

export const TicketOverlayController = (
  props: TicketOverlayControllerProps
) => {
  const { onAfterSave, registerOnClose, timeTrackingAction = true } = props;
  const { t } = useTranslation();

  const historyStack = useHistoryStack();
  const { setObjectionListener } = historyStack;

  const { lastProps, overrideProps } = useOverrideProps(props);

  const mask = useMaskConfig(lastProps);

  const { value: isTicketSolutionRequiered } = useSetting(
    settings.isTicketSolutionRequiered()
  );

  const customRules = useCallback(
    (data: TicketMaskFormValue) => {
      const status = data?.status1;
      const solutionText = data?.solutionText ?? '';

      if (
        isTicketSolutionRequiered &&
        status === TicketStatus.ERLEDIGT &&
        isHTMLEmpty(solutionText)
      ) {
        return {
          solutionText: {
            message: t('ERROR.ENTER_SOLUTION_IF_STATUS_IS_DONE'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [isTicketSolutionRequiered, t]
  );

  const customFields = useCustomFieldsConfig({ entity: Entities.ticket });

  const request = useTicketRequestData(mask.id);
  const newEntityData = useTicketDefaultData(mask);

  const getTempFileInitialData = useCallback((entity: TicketMaskFormValue) => {
    return entity?.attachmentList?.map((x) => {
      return {
        ...x,
        fileName: x.displayName,
      };
    });
  }, []);

  const normalizeData = useCallback(
    (
      entity: TicketMaskFormValue,
      newEntity: TicketMaskFormValue,
      isCreateMode: boolean
    ) => {
      const cleanedProblemDescription =
        entity && !includesHtml(entity.problemDescription)
          ? entity.problemDescription.replace(/\r\n/g, '<br>')
          : entity?.problemDescription || '';

      return isCreateMode
        ? newEntity
        : (entity && {
            ...entity,
            problemDescription: cleanedProblemDescription,
          }) ??
            newEntity;
    },
    []
  );

  const overlay = useMaskOverlay<TicketMaskFormValue>({
    ...lastProps,
    request,
    newEntityData,
    mask,
    customFields,
    customRules,
    getSubTitle: (x) =>
      mask.isCreateMode ? x.title : `${x.number} | ${x.title}`,
    getTempFileInitialData,
    normalizeData,
  });

  const { form, data, tempFileManager } = overlay;
  const { formState, reset, getValues } = form;

  const [initialDirty, setInitialDirty] = useState(false);
  useEffect(() => {
    if (newEntityData.title && mask.isCreateMode) {
      setInitialDirty(true);
    }
  }, [form, newEntityData.title, mask.isCreateMode]);

  overlay.submitButtonProps.disabled =
    overlay.submitButtonProps.disabled && !initialDirty;

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const { checklist: _, ...mutationData } = request.data as Ticket;
  const [mutate] = useDataMutation<
    Ticket,
    EMode.upsert,
    {
      attachements: InputTicketAttachementsRelation;
      checklistMarks: InputTicketChecklisteMarkRelation;
    }
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: mutationData,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      onAfterSave(data);
    },
  });

  useTicketFormUpdate(form);

  const [showSolution, setShowSolution] = useState(false);

  useEffect(() => {
    setShowSolution((data.solutionText || '').trim() !== '');
  }, [reset, data]);

  const attachementsRelation =
    useAttachementsRelation<InputTicketAttachementsRelation>(
      tempFileManager,
      Entities.ticketAttachment,
      'id'
    );

  const isDirty =
    Object.keys(formState.dirtyFields).length > 0 ||
    tempFileManager.fileListDirty ||
    attachementsRelation?.isDirty ||
    initialDirty;

  const convertToCheckListMarks = useCallback(
    (checklist?: TicketChecklisteMark[]) => {
      if (!checklist) return {};
      const newSelection = checklist.map(mapToCheckItem).map((x) => x.id);
      const oldSelection = (data.checklist || [])
        .map(mapToCheckItem)
        .map((x) => x.id);
      const check = newSelection.filter((x) => !oldSelection.includes(x));
      const uncheck = oldSelection.filter((x) => !newSelection.includes(x));
      return { check, uncheck };
    },
    [data]
  );

  const handleSubmit = useCallback(
    async (
      ticket: TicketMaskFormValue,
      _event?: unknown,
      skipOnComplete = false
    ) => {
      __TICKET_MUTATION_GUARD.markSubmit();

      const updateRaw = mask.isCreateMode
        ? ticket
        : pickUpdateFields(ticket, formState.dirtyFields);

      const updateMapped = prepareInputWithCustomFields(updateRaw);

      const checklistMarks = convertToCheckListMarks(ticket.checklist);

      return await mutate(
        updateMapped,
        {
          relations: {
            attachements: attachementsRelation?.attachements,
            checklistMarks,
          },
        },
        { skipOnComplete: skipOnComplete === true, ticketMutationGuard: true }
      );
    },
    [
      mask.isCreateMode,
      formState.dirtyFields,
      convertToCheckListMarks,
      mutate,
      attachementsRelation,
    ]
  );

  const defaultTicketSenderAddress = useSetting(
    settings.defaultTicketSenderAddress()
  );

  const [isSaving, setIsSaving] = useState(false);
  const isSubmitting = isSaving || form.formState.isSubmitting;

  const updateMaskState = useHistoryStackTitleUpdate(mask.isCreateMode);
  const getEmailParams = useCallback(async () => {
    __TICKET_MUTATION_GUARD.markButton('Email');

    setIsSaving(true);
    if (!formState.isValid) {
      form.trigger();
      setIsSaving(false);
      return;
    }

    let ticketData = getValues();
    let ticketTitle: string;
    const bp = getValues('businessPartner.data');
    const contact = getValues('contact');
    const entityTemplate = bp
      ? {
          entity: contact ? Entities.contact : Entities.customer,
          id: contact ? `${contact.id}:customer:${bp.id}` : bp.id,
        }
      : undefined;
    if (isDirty) {
      ticketData = (await handleSubmit(ticketData, null, true)) as Ticket;
      reset({}, { keepValues: true });
      setInitialDirty(false);
      setObjectionListener(null);

      overrideProps({ id: ticketData.id });
      overlay.initialFormValue.refetch();
      tempFileManager.resetChanges();

      setTimeout(() => {
        ticketTitle = `${ticketData.number} | ${ticketData.title}`;
        updateMaskState(ticketData.id, ticketTitle);
      }, 0);

      // We need to call onAfterSave callback when ticket was already saved, but masked not open.
      // So we register handler for onClose event
      if (registerOnClose) {
        registerOnClose(() => {
          onAfterSave(ticketData);
        });
      }
    }

    /** timeout to prevent clicking on the email CTA again before the overlay has opened completely */
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);

    return {
      entityTemplate,
      params: {
        ticketTemplateContext: JSON.stringify(ticketData),
        senderAddress: defaultTicketSenderAddress.value,
        processedMailTemplateArgs: JSON.stringify({
          noDirectContact: !contact,
        }),
      },
      subTitle: isDirty ? ticketTitle : undefined,
    };
  }, [
    formState.isValid,
    getValues,
    isDirty,
    defaultTicketSenderAddress.value,
    form,
    handleSubmit,
    reset,
    setObjectionListener,
    overlay.initialFormValue,
    tempFileManager,
    registerOnClose,
    updateMaskState,
    onAfterSave,
    overrideProps,
  ]);

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const state = useMemo<TicketMaskStateContextValue>(() => {
    return { showSolution, setShowSolution };
  }, [showSolution, setShowSolution]);

  const [isContactsPopoverOpend, setIsContactsPopoverOpend] = useState(false);
  const contactsPopoverRef = useRef(null);

  const shouldRenderIndividualTab = customFields && customFields.length > 0;
  const beforeTimeTrackMaskOpen = async () => {
    __TICKET_MUTATION_GUARD.markButton('TimeTrack');

    setIsSaving(true);
    let ticketData = getValues();
    const isValid = formState.isValid;
    if (isDirty && isValid) {
      ticketData = (await handleSubmit(getValues(), null, true)) as Ticket;
      reset({}, { keepValues: true });

      setInitialDirty(false);
      setObjectionListener(null);

      overrideProps({ id: ticketData.id });
      overlay.initialFormValue.refetch();
      tempFileManager.resetChanges();

      setTimeout(() => {
        const ticketTitle = `${ticketData.number} | ${ticketData?.title}`;
        updateMaskState(ticketData.id, ticketTitle);
      }, 0);
    } else {
      form.trigger();
    }

    /** timeout to prevent clicking on the time tracking CTA again before the overlay has opened completely */
    setTimeout(() => {
      setIsSaving(false);
    }, 1000);

    return {
      isValid,
      ticketData,
    };
  };

  const ticketDefaultValue = form.watch();

  overlay.submitButtonProps.disabled =
    overlay.submitButtonProps.disabled || isSubmitting;

  overlay.submitButtonProps.onClick = () => {
    __TICKET_MUTATION_GUARD.markButton('Save');
  };

  return (
    <EmailTemplateButtonProvider
      eMailTemplateKind={EMailTemplateKind.TICKET}
      getEmailParams={getEmailParams}
    >
      <ContactsPopover
        popoverConfig={{
          open: isContactsPopoverOpend,
          anchorEl: contactsPopoverRef.current,
          onClose: () => setIsContactsPopoverOpend(false),
        }}
        businessPartner={data.businessPartner}
        creator={data.creator}
        editor1={data.editor1}
        editor2={data.editor2}
        highlightedExternalContact={data.contact}
        project={data.project}
        projectProcess={data.projectProcess}
      />
      <TicketMaskStateContext.Provider value={state}>
        <OverlayController<TicketMaskFormValue>
          {...overlay}
          onSubmit={async (data) => {
            setIsSaving(true);
            await handleSubmit(data);
          }}
          actions={
            <>
              {mask.isEditMode && (
                <IconButton
                  ref={contactsPopoverRef}
                  color="primary"
                  onClick={() => {
                    setIsContactsPopoverOpend(true);
                  }}
                >
                  <PhoneIcon />
                </IconButton>
              )}
              {isDesktop && (
                <>
                  <EmailTemplateIconButton
                    disabled={isSubmitting || overlay.locked}
                  />
                  {timeTrackingAction && (
                    <MaskOverlayTicketTimeTrack
                      ticket={ticketDefaultValue}
                      beforeMaskOpen={beforeTimeTrackMaskOpen}
                      disabled={isSubmitting || overlay.locked}
                    />
                  )}
                </>
              )}
            </>
          }
          moreActions={
            mask.isEditMode && !isDesktop ? (
              <>
                <EmailTemplateMenuItemButton
                  disabled={isSubmitting || overlay.locked}
                />
                {timeTrackingAction && (
                  <MaskOverlayTicketTimeTrack
                    key={'track'}
                    menuItemMode={true}
                    ticket={ticketDefaultValue}
                    beforeMaskOpen={beforeTimeTrackMaskOpen}
                    disabled={isSubmitting || overlay.locked}
                  />
                )}
              </>
            ) : undefined
          }
          tabs={<TicketTabs renderIndividualTab={shouldRenderIndividualTab} />}
        >
          <Content renderIndividualTab={shouldRenderIndividualTab} />
        </OverlayController>
      </TicketMaskStateContext.Provider>
    </EmailTemplateButtonProvider>
  );
};

const TicketTabs = memo(function AppointmentTabs({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab
        value={TicketTabKeys.general}
        label={t('MASK.GENERAL')}
      ></MaskTab>
      <MaskTab
        value={TicketTabKeys.attachments}
        label={t('MASK.ATTACHMENTS')}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value={TicketTabKeys.general}>
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value={TicketTabKeys.attachments}>
        <AttachmentsTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});
