import styles from './styles.module.scss';

import { ListItem } from '@mui/material';
import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronDownIcon } from '@work4all/assets/icons/chevron-down.svg';

import { StackProvider } from '@work4all/components/lib/navigation/history-stack';

import {
  DND_POPOVER_CLOSE_DELAY,
  DND_POPOVER_OPEN_DELAY,
  DndTypes,
} from '@work4all/utils/lib/variables';

import {
  IFavoriteLinkOrFolder,
  isFavoriteLink,
  isFavoriteLinksFolder,
} from '../../../../containers/more/data/favorite-links';
import { LinksPopover } from '../popover/LinksPopover';

import { LinksOverflowContext } from './links-overflow-context';
import { OverflowRootView } from './OverflowRootView';
import { StackContent } from './StackContent';

export interface IOverflowListPopoverProps {
  items: IFavoriteLinkOrFolder[];
  offset: number;
  activeLink: { folderId: string | null; linkId: string } | null;
}

export function OverflowListPopover({
  items,
  offset,
  activeLink,
}: IOverflowListPopoverProps) {
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [initialView, setInitialView] = useState<{
    title: string;
    view: React.ReactElement<any>;
  }>(null);
  const open = Boolean(anchorEl);

  const openPopover = useCallback(() => {
    setAnchorEl(anchorRef.current);

    const initialView = {
      title: 'initial',
      view: (
        <OverflowRootView
          onClose={() => {
            setAnchorEl(null);
          }}
        />
      ),
    };

    setInitialView(initialView);
  }, []);

  const [trigger, triggerDrop] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    canDrop: () => false,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    if (trigger.isOver) {
      const timeout = setTimeout(() => {
        openPopover();
      }, DND_POPOVER_OPEN_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [trigger.isOver, openPopover]);

  const [popover, popoverDrop] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    canDrop: () => false,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    if (popover.isOver) {
      const timeout = setTimeout(() => {
        setAnchorEl(null);
      }, DND_POPOVER_CLOSE_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [popover.isOver]);

  // If one of the links inside the overflow list matches the active link,
  // mark the "More" button as active.
  const active = useMemo(() => {
    return (
      activeLink &&
      !!items.find((item) => {
        return (
          (isFavoriteLink(item) && activeLink.linkId === item.id) ||
          (isFavoriteLinksFolder(item) && activeLink.folderId === item.id)
        );
      })
    );
  }, [items, activeLink]);

  if (!open && !items.length) {
    return null;
  }

  return (
    <div
      ref={(node) => {
        anchorRef.current = node;
        triggerDrop(node);
      }}
    >
      <ListItem
        button
        className={clsx(styles.trigger, active && styles.active)}
        onClick={openPopover}
      >
        <span>
          {items.length} {t('FAV_LINKS.MORE')}
        </span>
        <ChevronDownIcon className={styles.folderIcon} />
      </ListItem>

      <StackProvider onClose={handleClose} initialView={initialView}>
        <LinksPopover
          popoverRef={popoverDrop}
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
        >
          <LinksOverflowContext.Provider value={{ items, offset, activeLink }}>
            <StackContent />
          </LinksOverflowContext.Provider>
        </LinksPopover>
      </StackProvider>
    </div>
  );
}
