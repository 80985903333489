import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../settings';

export function General() {
  const { t } = useTranslation();

  const adminEnabledShowingBirthdays = useSetting(
    settings.adminEnabledShowingBirthdays()
  );

  const handleCheck = (setVisible: boolean) => {
    if (setVisible) {
      adminEnabledShowingBirthdays.set(true);
    } else {
      adminEnabledShowingBirthdays.set(false);
    }
  };

  const user = useUser();

  return (
    <Collapse title={t('RELEVENT.SETTINGS.SHOW')} defaultOpen>
      <Tooltip
        title={
          adminEnabledShowingBirthdays.value
            ? t('RELEVANT.BIRTHDAYS.ENABLED')
            : t('RELEVANT.BIRTHDAYS.DISABLED')
        }
        placement="bottom-start"
      >
        <CheckboxRadioItem
          checked={adminEnabledShowingBirthdays.value}
          onChange={(e) => {
            handleCheck(e.target.checked);
          }}
          label={t('COMMON.BIRTHDAY_other')}
          disabled={!user.isMaster}
        />
      </Tooltip>
    </Collapse>
  );
}
