import { useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useResourceClasses } from './use-resource-classes';

export const useErpPlanningColumns = (props: { entity: Entities }) => {
  const { entity } = props;
  const { data: ressourceClasses } = useResourceClasses();

  const result = useMemo(
    () =>
      ressourceClasses.map((rc) => ({
        title: [rc.name],
        displayTitle: rc.name,
        cell: {
          type: 'ErpRessourceClass',
          params: {
            entity,
            rc,
          },
        },
        id: rc.id.toString(),
        path: ['COMMON.RESSOURCECLASS_other'],
        width: 200,
        sortable: false,
        groupable: false,
        quickSearchable: false,
        defaultHidden: true,
        filterable: {
          type: 'None',
        },
        disableFooterSum: false,
      })),
    [entity, ressourceClasses]
  );

  return result;
};
