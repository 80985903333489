import { forwardRef, useContext } from 'react';

import { useLock } from '@work4all/components/lib/hooks/object-lock';

import { useInaccessibleFields } from '@work4all/data';

import { MaskContext } from '../hooks/mask-context';
import { EntityRightsContext } from '../hooks/use-entity-rights';
/**
 * wraps field with the ability to react to a lock context e.g. if the entity is edited by someone else
 * or the active user is missing rights
 * @param Component
 * @returns
 */
export const withAutoDisable = <
  Props extends { disabled?: boolean; name?: string }
>(
  Component: React.FC<Props>
): React.FC<Props> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return forwardRef<any, Props>(function WithAutoDisable(props, ref) {
    const { locked } = useLock();
    const { update, create } = useContext(EntityRightsContext);
    const mask = useContext(MaskContext);
    const { isInaccessible } = useInaccessibleFields();
    let disabled = props.disabled;

    if (mask?.isLoading) {
      disabled = true;
    }

    if (
      locked ||
      (!update && mask?.mode === 'edit') ||
      (!create && mask?.mode === 'create')
    ) {
      disabled = true;
    }

    if (
      mask !== null &&
      props.name &&
      isInaccessible(mask.entity, props.name)
    ) {
      //if the field is registered with a name
      //additionally for some users fields are not editable by rights definition, those fields are auto locked as well
      disabled = true;
    }

    return <Component ref={ref} {...(props as Props)} disabled={disabled} />;
  }) as React.FC<Props>;
};
