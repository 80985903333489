import styles from './TimeTrackingMask.module.scss';

import { createContext, useContext, useMemo } from 'react';

import { Loading } from '@work4all/components';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import {
  INavigationOverlayProps,
  NavigationOverlay,
} from '@work4all/components/lib/navigation/navigation-overlay';

import {
  ProjectTimeMask,
  ProjectTimeMaskProps,
} from '../../../../../containers/time-tracker/ProjectTimeMask';
import { useTimeTrackingData } from '../hooks';
import { timeTrackingToProjectTimeMaskInit } from '../utils';

type TimeTrackingMaskProps = {
  id?: number;
  mode: 'create' | 'edit' | 'clone';
} & Pick<INavigationOverlayProps, 'open' | 'close'>;

// The mask flickers if initialView changes, so we wrap it in another component
// and pass the props through context.
const VIEW: IStackItem = { view: <ProjectTimeMaskWrapper /> };

export const TimeTrackingMask = ({
  id,
  mode,
  ...rest
}: TimeTrackingMaskProps) => {
  const { data, isLoading } = useTimeTrackingData(id);
  const props = useMemo(() => {
    const getProps = (): ProjectTimeMaskProps => {
      if (mode !== 'create' && !data) {
        return null;
      }

      if (mode === 'create') {
        return {};
      }

      if (mode === 'edit') {
        return { item: data };
      }

      if (mode === 'clone') {
        return { init: timeTrackingToProjectTimeMaskInit(data) };
      }

      return {};
    };

    return getProps();
  }, [data, mode]);

  // A workaround to prevent mask shaking
  if (isLoading) {
    return null;
  }

  return (
    <ProjectTimeMaskContext.Provider value={props}>
      <NavigationOverlay
        {...rest}
        classes={{
          wrapper: styles.wrapper,
        }}
        initialView={VIEW}
      />
    </ProjectTimeMaskContext.Provider>
  );
};

const ProjectTimeMaskContext = createContext<ProjectTimeMaskProps>(null);

function ProjectTimeMaskWrapper() {
  const props = useContext(ProjectTimeMaskContext);

  if (props == null) {
    return <Loading />;
  }

  return <ProjectTimeMask {...props} />;
}
