import { useCallback } from 'react';

import { useTenant } from './routing/TenantProvider';
import { useUploadTempFile } from './use-upload-temp-file';
import { useUser } from './useUser';

/**
 * Will convert URL (downloadUrl) to temp file.
 * 1. Fetch a blob
 * 2. Upload blob as TempFile
 * 3. Return Tempfile
 * @returns Wrapped Tempfile with fileName and fileSize
 */
export const useConvertToTempFile = () => {
  const uploadTempFile = useUploadTempFile();
  const user = useUser();
  const { activeTenant } = useTenant();
  return useCallback(async (url: string, fileName: string) => {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'x-work4all-mandant': `${activeTenant}`,
        'x-work4all-apiurl': user.baseUrl,
      },
    });
    const blob = await response.blob();

    const file = new File([blob], fileName);

    const tempFile = await uploadTempFile(file);
    return {
      tempFile,
      attachment: {
        id: tempFile.generatedObject,
        date: new Date().toISOString(),
        fileName,
        fileInfos: {
          downloadUrl: tempFile.downloadUrl,
          downloadMimeType: tempFile.downloadMimeType,
          previewUrl: tempFile.downloadUrlForPreview,
          previewMimeType: tempFile.previewMimeType,
          fileEntityFilename: fileName,
          fileSize: blob.size,
        },
      },
    };
  }, []);
};
