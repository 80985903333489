import styles from './DateRangePicker.module.scss';

import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

import { ToggleButton } from '../../../../components/toggle-button/ToggleButton';
import { DateTimeInputPicker } from '../../../../input/date-time-input-picker';
import { MultiStepControls, Step } from '../../../../input/multi-step-controls';
import { Divider } from '../../../divider/Divider';

const TIME_UNIT_TYPES = [
  'day',
  'yesterday',
  'tomorrow',
  'week',
  'lastWeek',
  'nextWeek',
  'month',
  'lastMonth',
  'nextMonth',
  'year',
  'lastYear',
  'nextYear',
] as const;
type TimeUnitType = (typeof TIME_UNIT_TYPES)[number];

export type ShownFilterOptions = {
  future?: boolean;
  yesterday?: boolean;
  today?: boolean;
  tomorrow?: boolean;
  todayAndPastOrTodayAndFuture?: boolean;
  week?: boolean;
  month?: boolean;
  year?: boolean;
};

interface IDateRangePickerProps {
  startDate?: Date | RelativeDateFilter;
  endDate?: Date;
  onChange: (startDate: Date | RelativeDateFilter, endDate: Date) => void;
  shownFilterOptions?: ShownFilterOptions;
}

const VALUES: Required<ShownFilterOptions> = {
  future: false,
  yesterday: true,
  today: true,
  tomorrow: true,
  todayAndPastOrTodayAndFuture: true,
  week: true,
  month: true,
  year: true,
};

export const getTimeSpanUnit = (
  startDate: Date | RelativeDateFilter,
  endDate: Date
): { startDate: TimeUnitType | null; endDate: TimeUnitType | null } => {
  let result: { startDate: TimeUnitType | null; endDate: TimeUnitType | null } =
    { startDate: null, endDate: null };
  if ((startDate as RelativeDateFilter) in RelativeDateFilter) {
    switch (startDate) {
      case RelativeDateFilter.currentMonth:
        result = { startDate: 'month', endDate: 'month' };
        break;
      case RelativeDateFilter.lastMonth:
        result = { startDate: 'lastMonth', endDate: 'lastMonth' };
        break;
      case RelativeDateFilter.nextMonth:
        result = { startDate: 'nextMonth', endDate: 'nextMonth' };
        break;
      case RelativeDateFilter.currentWeek:
        result = { startDate: 'week', endDate: 'week' };
        break;
      case RelativeDateFilter.lastWeek:
        result = { startDate: 'lastWeek', endDate: 'lastWeek' };
        break;
      case RelativeDateFilter.nextWeek:
        result = { startDate: 'nextWeek', endDate: 'nextWeek' };
        break;
      case RelativeDateFilter.currentYear:
        result = { startDate: 'year', endDate: 'year' };
        break;
      case RelativeDateFilter.lastYear:
        result = { startDate: 'lastYear', endDate: 'lastYear' };
        break;
      case RelativeDateFilter.nextYear:
        result = { startDate: 'nextYear', endDate: 'nextYear' };
        break;
      case RelativeDateFilter.today:
        result = { startDate: 'day', endDate: 'day' };
        break;
      case RelativeDateFilter.yesterday:
        result = { startDate: 'yesterday', endDate: 'yesterday' };
        break;
      case RelativeDateFilter.tomorrow:
        result = { startDate: 'tomorrow', endDate: 'tomorrow' };
        break;
      case RelativeDateFilter.todayAndPast:
        result = { startDate: null, endDate: 'day' };
        break;
      case RelativeDateFilter.todayAndFuture:
        result = { startDate: 'day', endDate: null };
        break;
      case RelativeDateFilter.future:
        result = { startDate: 'tomorrow', endDate: null };
        break;
    }
  } else {
    const start = DateTime.fromJSDate(startDate as Date);
    const end = DateTime.fromJSDate(endDate);
    const today = DateTime.fromJSDate(new Date());
    if (
      today.hasSame(start, 'year') &&
      today.hasSame(end, 'year') &&
      start.hasSame(end.startOf('year'), 'day') &&
      end.hasSame(start.endOf('year'), 'day')
    ) {
      result = { startDate: 'year', endDate: 'year' };
    }

    if (
      today.hasSame(start, 'month') &&
      today.hasSame(end, 'month') &&
      start.hasSame(end.startOf('month'), 'day') &&
      end.hasSame(start.endOf('month'), 'day')
    ) {
      result = { startDate: 'month', endDate: 'month' };
    }

    if (
      today.hasSame(start, 'week') &&
      today.hasSame(end, 'week') &&
      start.hasSame(end.startOf('week'), 'day') &&
      end.hasSame(start.endOf('week'), 'day')
    ) {
      result = { startDate: 'week', endDate: 'week' };
    }

    if (today.hasSame(start, 'day') && start.hasSame(end, 'day')) {
      result = { startDate: 'day', endDate: 'day' };
    }

    if (startDate === null && today.hasSame(end, 'day')) {
      result = { startDate: null, endDate: 'day' };
    }
  }

  return result;
};

export const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  startDate,
  endDate,
  onChange,
  shownFilterOptions = VALUES,
}) => {
  const { t } = useTranslation();
  const {
    future,
    yesterday,
    today,
    tomorrow,
    todayAndPastOrTodayAndFuture,
    week,
    month,
    year,
  } = shownFilterOptions;

  const checkActiveState = useCallback(
    (timeUnit: RelativeDateFilter): boolean => {
      return timeUnit === startDate;
    },
    [startDate]
  );

  return (
    <div className={styles.pickerWrap}>
      <MultiStepControls>
        <Step active={true} index={0}>
          <DateTimeInputPicker
            required={false}
            dateLabel={t('INPUTS.FROM')}
            withTime={false}
            value={
              startDate &&
              !((startDate as RelativeDateFilter) in RelativeDateFilter)
                ? startDate
                : ''
            }
            onChange={(e) => {
              onChange?.(
                e.currentTarget.value === '' &&
                  !((startDate as RelativeDateFilter) in RelativeDateFilter)
                  ? null
                  : DateTime.fromISO(e.currentTarget.value)
                      .startOf('day')
                      .toJSDate(),
                endDate
              );
            }}
          />
        </Step>

        <Step active={true} index={1}>
          <DateTimeInputPicker
            required={false}
            dateLabel={t('INPUTS.TO')}
            withTime={false}
            value={
              endDate &&
              !((startDate as RelativeDateFilter) in RelativeDateFilter)
                ? endDate
                : ''
            }
            onChange={(e) => {
              onChange?.(
                startDate,
                e.currentTarget.value === '' &&
                  !((startDate as RelativeDateFilter) in RelativeDateFilter)
                  ? null
                  : DateTime.fromISO(e.currentTarget.value)
                      .endOf('day')
                      .toJSDate()
              );
            }}
          />
        </Step>
      </MultiStepControls>

      <Divider style={{ padding: 0 }} />
      <Grid container spacing="1rem">
        {future && (
          <Grid item xs={4}>
            <ToggleButton
              isActive={checkActiveState}
              onChange={(e) => onChange(e, null)}
              values={[
                {
                  id: RelativeDateFilter.future,
                  name: t('COMMON.FUTURE'),
                },
              ]}
            />
          </Grid>
        )}
        {yesterday && (
          <Grid item xs={4}>
            <ToggleButton
              isActive={checkActiveState}
              onChange={(e) => onChange(e, null)}
              values={[
                {
                  id: RelativeDateFilter.yesterday,
                  name: t('COMMON.YESTERDAY'),
                },
              ]}
            />
          </Grid>
        )}
        {today && (
          <Grid item xs={4}>
            <ToggleButton
              isActive={checkActiveState}
              onChange={(e) => onChange(e, null)}
              values={[
                {
                  id: RelativeDateFilter.today,
                  name: t('COMMON.TODAY'),
                },
              ]}
            />
          </Grid>
        )}
        {tomorrow && (
          <Grid item xs={4}>
            <ToggleButton
              isActive={checkActiveState}
              onChange={(e) => onChange(e, null)}
              values={[
                {
                  id: RelativeDateFilter.tomorrow,
                  name: t('COMMON.TOMORROW'),
                },
              ]}
            />
          </Grid>
        )}
      </Grid>

      {todayAndPastOrTodayAndFuture && (
        <ToggleButton
          isActive={checkActiveState}
          onChange={(e) => onChange(e, null)}
          values={[
            {
              id: RelativeDateFilter.todayAndPast,
              name: t('COMMON.TODAY_AND_PAST'),
            },
            {
              id: RelativeDateFilter.todayAndFuture,
              name: t('COMMON.TODAY_AND_FUTURE'),
            },
          ]}
        />
      )}

      {week && (
        <ToggleButton
          isActive={checkActiveState}
          onChange={(e) => onChange(e, null)}
          values={[
            {
              id: RelativeDateFilter.currentWeek,
              name: t('COMMON.THIS_WEEK'),
            },
            {
              id: RelativeDateFilter.lastWeek,
              name: t('COMMON.LAST_WEEK'),
            },
            {
              id: RelativeDateFilter.nextWeek,
              name: t('COMMON.NEXT_WEEK'),
            },
          ]}
        />
      )}

      {month && (
        <ToggleButton
          isActive={checkActiveState}
          onChange={(e) => onChange(e, null)}
          values={[
            {
              id: RelativeDateFilter.currentMonth,
              name: t('COMMON.THIS_MONTH'),
            },
            {
              id: RelativeDateFilter.lastMonth,
              name: t('COMMON.LAST_MONTH'),
            },
            {
              id: RelativeDateFilter.nextMonth,
              name: t('COMMON.NEXT_MONTH'),
            },
          ]}
        />
      )}
      {year && (
        <ToggleButton
          isActive={checkActiveState}
          onChange={(e) => onChange(e, null)}
          values={[
            {
              id: RelativeDateFilter.currentYear,
              name: t('COMMON.THIS_YEAR'),
            },
            {
              id: RelativeDateFilter.lastYear,
              name: t('COMMON.LAST_YEAR'),
            },
            {
              id: RelativeDateFilter.nextYear,
              name: t('COMMON.NEXT_YEAR'),
            },
          ]}
        />
      )}
    </div>
  );
};
