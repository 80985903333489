import styles from './ActionsBar.module.scss';

import { Divider, Stack } from '@mui/material';
import clsx from 'clsx';
import React, { type JSX, RefObject } from 'react';

import { Add } from '../../input/actions/add/Add';
import { Clone } from '../../input/actions/clone/Clone';
import { Columns } from '../../input/actions/columns/Columns';
import { Convert } from '../../input/actions/convert/Convert';
import { CreateWidget } from '../../input/actions/createWidget/CreateWidget';
import { Download } from '../../input/actions/download/Download';
import { Duplicate } from '../../input/actions/duplicate/Duplicate';
import { Edit } from '../../input/actions/edit/Edit';
import { Export } from '../../input/actions/export/Export';
import { Filter } from '../../input/actions/filter/Filter';
import { Go } from '../../input/actions/go/Go';
import { Group } from '../../input/actions/group/Group';
import { Options } from '../../input/actions/options/Options';
import { Remove } from '../../input/actions/remove/Remove';
import { Search } from '../../input/actions/search/Search';
import { Share } from '../../input/actions/share/Share';
import { BaseActionProps, IActionConfig } from '../../input/actions/types';
import { UnfoldAll } from '../../input/actions/unfold-all/UnfoldAll';
import { Upload } from '../../input/actions/upload/Upload';
import { LayoutHorizontalSelect } from '../../input/horizontal-select/custom/LayoutHorizontalSelect';

import { mapCustomActionsToComponent } from './hooks/mapCustomActionsToComponent';
import {
  ActionProps,
  IActionBarProps,
  IActionsBarActions,
} from './IActionsBarActions';
import { MoreMenu } from './MoreMenu';

const dictionary: Record<keyof IActionsBarActions, React.FC<IActionConfig>> = {
  add: Add,
  upload: Upload,
  edit: Edit,
  remove: Remove,
  duplicate: Duplicate,
  convert: Convert,
  go: Go,
  layout: LayoutHorizontalSelect,
  group: Group,
  filter: Filter,
  search: Search,
  export: Export,
  options: Options,
  unfoldAll: UnfoldAll,
  columns: Columns,
  download: Download,
  share: Share,
  createWidget: CreateWidget,
  clone: Clone,
};

const leftActions: Array<keyof IActionsBarActions> = [
  'add',
  'upload',
  'edit',
  'remove',
  'download',
  'share',
  'duplicate',
  'convert',
  'go',
  'export',
  'clone',
];

const rightActions: Array<keyof IActionsBarActions> = [
  'filter',
  'group',
  'search',
  'layout',
  'options',
  'unfoldAll',
  'columns',
];

const isConfig = (props: ActionProps): props is IActionConfig => {
  return props && typeof props !== 'function';
};

const withOnActionClick = (
  onActionClick?: React.MouseEventHandler<HTMLButtonElement>
) => {
  return (
    actionClick: React.MouseEventHandler<HTMLButtonElement>
  ): React.MouseEventHandler<HTMLButtonElement> => {
    if (!onActionClick) {
      return actionClick;
    }

    return (...args) => {
      actionClick(...args);
      onActionClick(...args);
    };
  };
};

export const actionsMapper = (
  key: keyof IActionsBarActions,
  props: IActionBarProps,
  onActionClick?: React.MouseEventHandler<HTMLButtonElement>,
  withRootRef = true
) => {
  const hideTooltip = props?.hideTooltip;

  const config = props[key];
  if (!config) {
    return null;
  }

  if (key === 'group' && !props.isGrouped) {
    return null;
  }

  const onClickCreator = withOnActionClick(onActionClick);

  const FC: React.FC<BaseActionProps> = dictionary[key];
  if (!FC) {
    return null;
  }

  let actionButton: JSX.Element | null = null;
  let children: React.ReactNode | null = null;
  let rootRef: IActionConfig['rootRef'] | null = null;
  let render: IActionConfig['render'];

  if (isConfig(config)) {
    const {
      children: _children,
      rootRef: _rootRef,
      onClick,
      onChange,
      render: _render,
      ..._restConfig
    } = config;
    const restConfig = {
      disabled: props.disabled,
      onChange: onChange,
      hideTooltip,
      ..._restConfig,
    };
    actionButton = (
      <FC key={key} onClick={onClickCreator(onClick)} {...restConfig} />
    );
    children = _children;
    render = _render;

    if (withRootRef && _rootRef) {
      rootRef = _rootRef;
    }
  } else {
    actionButton = (
      <FC
        key={key}
        onClick={config as React.MouseEventHandler<HTMLElement>}
        onChange={config as React.ChangeEventHandler<HTMLInputElement>}
        hideTooltip={hideTooltip}
      />
    );
  }

  const button = (
    <div
      ref={rootRef as RefObject<HTMLDivElement>}
      key={key}
      className={styles.wrapper}
    >
      {actionButton}
      {children}
    </div>
  );

  return render ? (
    <React.Fragment key={key}>{render(button)}</React.Fragment>
  ) : (
    button
  );
};

export const ActionsBar: React.FC<IActionBarProps & { className?: string }> = (
  props
) => {
  const {
    takeAvailableSpace = true,
    menuButtonRef,
    customActions,
    hideMoreButton,
    className,
  } = props;

  const leftCustomActions = mapCustomActionsToComponent(
    customActions?.left?.filter(
      (action) => !action.sections || action.sections.includes('toolbar')
    )
  );
  const rightCustomActions = mapCustomActionsToComponent(
    customActions?.right?.filter(
      (action) => !action.sections || action.sections.includes('toolbar')
    )
  );

  return (
    <div
      className={clsx(styles.bar, className, {
        [styles.takeAvailable]: takeAvailableSpace,
      })}
    >
      <div className={styles.left}>
        {leftActions.map((key) => {
          return actionsMapper(key, props);
        })}

        {!customActions?.hideDivider && leftCustomActions.length > 0 && (
          <Divider
            orientation="vertical"
            sx={{
              height: '1.375rem',
              borderColor: 'ui5.main',
              margin: '0.75rem; 0;',
            }}
          />
        )}

        {leftCustomActions}
      </div>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        divider={
          customActions?.hideDivider ? undefined : (
            <Divider
              orientation="vertical"
              sx={{ height: '1.375rem', borderColor: 'ui5.main' }}
            />
          )
        }
      >
        {rightCustomActions}
        {rightActions.map((key) => {
          return actionsMapper(key, props);
        })}

        {!hideMoreButton && <MoreMenu {...props} buttonRef={menuButtonRef} />}
      </Stack>
    </div>
  );
};
