import styles from './Table.module.scss';

import { Close } from '@mui/icons-material';
import {
  Fab,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
  type JSX,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  actions as tableActions,
  TableInstance,
  TableState,
} from 'react-table';

import {
  BasicTable,
  IBasicTableProps,
} from '@work4all/components/lib/dataDisplay/basic-table';
import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import {
  ICssClasses,
  TableRow,
} from '@work4all/components/lib/dataDisplay/basic-table/types';
import { useLockObjectColumn } from '@work4all/components/lib/hooks/object-lock-subscription/useLockObjectColumn';
import { PrintComponent } from '@work4all/components/lib/layout/print-component/PrintComponent';

import { usePermissions } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { CardConfig } from '@work4all/models/lib/table-schema/card-config';
import {
  ICustomCellConfigBase,
  ITableSchema,
} from '@work4all/models/lib/table-schema/table-schema';
import { AreaConfig } from '@work4all/models/lib/Tables/Tables';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';

import { ListPageContext } from '../../../containers/file-entities-lists/list-page-context';
import { settings, useSetting } from '../../../settings';
import { ControlViewBottom } from '../control-view-bottom/ControlViewBottom';
import { ControlViewCenter } from '../control-view-center/ControlViewCenter';
import { ControlViewLeft, ControlViewLeftProps } from '../control-view-left';
import { TableNoRowsPlaceholder } from '../TableNoRowsPlaceholder';

import { TableContextMenu } from './components/context-menu/TableContextMenu';
import { Board, BoardProps } from './components/kanban-board/Board';
import { TableRightArea } from './components/table-right-area/TableRightArea';
import { IToolBar, Toolbar } from './components/toolbar/ToolBar';
import { ToolbarDndWrapper } from './components/toolbar-dnd-wrapper';
import { DataTableColumnConfig } from './DataTableColumnConfig';
import { useGroupingToolbarActions } from './grouping';
import { useTableColumns } from './hooks/use-table-columns';

export type BottomAreaSize = 'contained' | 'fill';

export interface TableAreas {
  left?: Pick<AreaConfig, 'content'> &
    Pick<
      ControlViewLeftProps,
      'title' | 'custom' | 'collapsible' | 'resizable' | 'actions'
    >;
  right?: AreaConfig;
  bottom?: AreaConfig & { size?: BottomAreaSize };
  center?: AreaConfig;
  top?: AreaConfig;
}

export type ITableProps = Pick<
  IBasicTableProps,
  | 'data'
  | 'loadMoreItems'
  | 'isItemLoaded'
  | 'onRowExpanded'
  | 'prepareRowDisplayModifiers'
  | 'displayFooter'
  | 'footerData'
  | 'onSelectedRowsChange'
  | 'selectableMultiple'
  | 'initialSortBy'
  | 'pending'
  | 'noRowsRenderer'
  | 'draggable'
  | 'scrollRef'
  | 'rowHeightRem'
  | 'customVisibleColumns'
  | 'onTableChange'
  | 'availableDateSections'
> &
  Pick<IToolBar, 'actions'> & {
    noLockableEntity?: boolean;
    cardConfig?: CardConfig;
    columnConfigs: DataTableColumnConfig[];
    total: IBasicTableProps['allItemsCount'];
    loadGroups?: (state: TableState, instance: TableInstance) => void;
    detailView?: JSX.Element;
    /**
     * @default true
     */
    manualGroupBy?: boolean;
    areas?: TableAreas;
    bottomAreaSize?: 'narrow' | 'wide';
    forceRequestFields?;
    /**
     * @default "table"
     */
    layout?: 'table' | 'cards' | 'board';
    onRowDoubleClick?: (id: string | number, item: unknown) => boolean;
    hideToolbar?: boolean;
    classes?: {
      root?: string;
    };
    onRowClick?: (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      row: TableRow
    ) => void;

    additionalColumns?: DataTableColumnConfig[];
    basicClasses?: ICssClasses;
    fetchMore?: IBasicTableProps['loadMoreItems'];
    hideMoreButton?: boolean;
    hideSearch?: boolean;
    listEntityType?: Entities;
    disabledColumns?: string[];
    hiddenColumns?: string[];
    board?: BoardProps;
    schema?: ITableSchema<Record<string, ICustomCellConfigBase>>;
    /* Whether or not the table is rendered within the "Pick from List" modal.
    When it is, parts of the table UI will be changed to make it easier to
    select a row. */
    isSelectionList?: boolean;
  };

const defaultNoRowsRender = () => <TableNoRowsPlaceholder />;
export const Table = React.forwardRef<TableInstance, ITableProps>(
  function Table(props, ref) {
    const {
      layout = 'table',
      noLockableEntity = false,
      hideToolbar = false,
      cardConfig,
      manualGroupBy = true,
      noRowsRenderer = defaultNoRowsRender,
      disabledColumns,
      hiddenColumns,
      hideMoreButton = false,
      hideSearch = false,
      listEntityType,
      schema,
      availableDateSections,
    } = props;

    const isSmUp = useMediaQuery<Theme>((t) => t.breakpoints.up('sm'));
    const isMdUp = useMediaQuery<Theme>((t) => t.breakpoints.up('md'));
    const isLgUp = useMediaQuery<Theme>((t) => t.breakpoints.up('xl'));

    // Preview should be disabled on mobile when opening the table from the
    // "Pick from List" modal as it makes it impossible to confirm selection
    // with the current UI.
    const shouldEnablePreview = isSmUp || !props.isSelectionList;

    const tableInstanceRef = useRef<TableInstance>(null);

    const listPageContext = useContext(ListPageContext);

    const columnConfigs = props.columnConfigs;
    const columnsCache = useTableColumns({ columnConfigs, listEntityType });

    const leftArea = props.areas?.left;
    const rightArea = props.areas?.right;
    const bottomArea = props.areas?.bottom;
    const centerArea = props.areas?.center;
    const topArea = props.areas?.top;

    const columnsVisibilityManager = useColumnVisibilityManager({
      tableInstanceRef,
      disabledColumns,
      hiddenColumns,
    });

    const tablesLayoutBorders = useSetting(settings.tablesLayoutBorders());

    const entityType = listEntityType ?? listPageContext?.entityType;

    const { data } = useLockObjectColumn(
      props.data,
      columnsVisibilityManager,
      entityType,
      noLockableEntity
    );

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { canEdit } = usePermissions();

    const onLocalRowDoubleClick = useCallback(
      (id) => {
        if (!entityType) return;

        if (!canEdit({ entity: entityType, record: { id } })) {
          const entity = entityType.toUpperCase();
          const entityTranslation = i18n.exists(`MORE.${entity}`)
            ? t(`MORE.${entity}`)
            : t(`MORE.${entity}S`);

          enqueueSnackbar(
            t('ERROR.MISSING_RIGHTS', {
              entity: entityTranslation,
            }),
            { variant: 'error', autoHideDuration: 6000 }
          );
          return;
        }
      },
      [entityType, canEdit, i18n, t, enqueueSnackbar]
    );

    const handleRowDoubleClick = (id: string) => {
      function getRealRows(): any[] {
        return data.flatMap(getSubRows).filter((row) => !row.skeleton);

        function getSubRows(row: any): any {
          if (row.subRows) {
            return row.subRows.flatMap(getSubRows);
          } else {
            return row;
          }
        }
      }

      const entityId = id.substring(id.lastIndexOf('.') + 1);
      if (props.onRowDoubleClick) {
        const rows = getRealRows();
        const item = rows.find(
          (item) => item.id === entityId || item.id === Number(entityId)
        );

        const consumed = props.onRowDoubleClick(entityId, item);
        if (!consumed) onLocalRowDoubleClick(entityId);
      } else if (props.actions?.edit?.handler) {
        props.actions?.edit?.handler(entityId);
      } else {
        onLocalRowDoubleClick(entityId);
      }
    };

    const groupingToolbarActions = useGroupingToolbarActions();
    const toolbarActions: IToolBar['actions'] = useMemo(() => {
      return {
        ...props.actions,
        custom: {
          ...props.actions?.custom,
          left: [
            ...(props.actions?.custom?.left ?? []),
            ...groupingToolbarActions,
          ],
        },
      };
    }, [groupingToolbarActions, props.actions]);

    return (
      <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
        <PrintComponent>
          {props.pending && (
            <LinearProgress
              data-test-id="loading-progress-indicator"
              className={styles.progressBar}
            />
          )}
          <div
            className={clsx(styles.root, props.classes?.root)}
            data-test-id="table"
          >
            <div className={styles.right}>
              {!hideToolbar && (
                <ToolbarDndWrapper>
                  <Toolbar
                    hideLayoutSelect={!cardConfig}
                    hideMoreButton={hideMoreButton}
                    mobile={!isLgUp}
                    tableInstanceRef={tableInstanceRef}
                    actions={toolbarActions}
                    listEntityType={entityType}
                    hideSearch={hideSearch}
                    schema={schema}
                  />
                </ToolbarDndWrapper>
              )}
              <Divider orientation="horizontal" />
              <div className={styles.rightRoot}>
                {leftArea && isMdUp ? (
                  <>
                    <ControlViewLeft
                      {...leftArea}
                      entity={listPageContext?.entityType}
                    >
                      {leftArea.content}
                    </ControlViewLeft>
                    <Divider orientation="vertical" />
                  </>
                ) : null}
                <div
                  className={clsx(styles.rightBody, {
                    [styles['rightBody--split']]: !!bottomArea,
                    [styles['rightBody--horizontal-split']]: !!centerArea,
                  })}
                >
                  {isLgUp &&
                    tableInstanceRef.current?.selectedFlatRows.length > 1 && (
                      <Fab
                        variant="extended"
                        className={styles.resetSelectionFab}
                        size="medium"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          tableInstanceRef?.current?.dispatch({
                            type: tableActions.resetSelectedRows,
                          });
                        }}
                      >
                        <Close sx={{ mr: 1 }} />
                        <Typography variant="body1">
                          {t('COMMON.DESELECT')}
                        </Typography>
                      </Fab>
                    )}

                  <ControlViewCenter
                    entityType={entityType}
                    resizable={!!centerArea?.content}
                  >
                    <TableContextMenu actions={props.actions}>
                      {({ onRowContextMenu }) => {
                        return (
                          <>
                            {layout === 'board' && <Board {...props.board} />}
                            <div
                              className={clsx(styles.tableWrapper, {
                                [styles.height100]: !!centerArea?.content,
                                [styles.board]: layout === 'board',
                              })}
                            >
                              {topArea ? topArea.content : null}

                              <BasicTable
                                pending={props.pending}
                                noRowsRenderer={noRowsRenderer}
                                entity={listPageContext?.entityType}
                                mode="server"
                                cardsView={layout === 'cards'}
                                bottomPadding={isLgUp ? null : '5rem'}
                                cardConfig={cardConfig}
                                ref={reactRefSetter(ref, tableInstanceRef)}
                                reordableColumns={true}
                                resizableColumns={true}
                                selectableRows={true}
                                selectableMultiple={props.selectableMultiple}
                                onSelectedRowsChange={
                                  props.onSelectedRowsChange
                                }
                                columns={columnsCache.columns}
                                defaultHidden={
                                  columnsCache.defaultHiddenColumnids
                                }
                                customVisibleColumns={
                                  props.customVisibleColumns
                                }
                                data={data}
                                allItemsCount={props.total}
                                loadMoreItems={
                                  props.loadMoreItems ?? props.fetchMore
                                }
                                loadGroups={props.loadGroups}
                                manualGroupBy={manualGroupBy}
                                initialSortBy={props.initialSortBy}
                                isItemLoaded={props.isItemLoaded}
                                onRowExpanded={props.onRowExpanded}
                                className={styles.table}
                                onRowDoubleClick={handleRowDoubleClick}
                                onRowClick={props.onRowClick}
                                onRowContextMenu={onRowContextMenu}
                                prepareRowDisplayModifiers={
                                  props.prepareRowDisplayModifiers
                                }
                                displayFooter={props.displayFooter}
                                footerData={props.footerData}
                                draggable={props.draggable}
                                classes={{
                                  ...props.basicClasses,
                                  cell: clsx(props.basicClasses?.cell, {
                                    [styles.borderVertical]:
                                      tablesLayoutBorders.value.vertical,
                                  }),
                                  row: clsx(props.basicClasses?.row, {
                                    [styles.borderHorizontal]:
                                      tablesLayoutBorders.value.horizontal,
                                  }),
                                }}
                                scrollRef={props.scrollRef}
                                rowHeightRem={props.rowHeightRem}
                                onTableChange={props.onTableChange}
                                availableDateSections={availableDateSections}
                              />
                            </div>
                          </>
                        );
                      }}
                    </TableContextMenu>
                  </ControlViewCenter>

                  {centerArea ? centerArea.content : null}
                  {bottomArea &&
                    (!bottomArea.size || bottomArea.size === 'contained') && (
                      <ControlViewBottom
                        entityType={entityType}
                        resizable={bottomArea.resizable}
                      >
                        {bottomArea.content}
                      </ControlViewBottom>
                    )}
                </div>
                {shouldEnablePreview && rightArea && rightArea.content && (
                  <TableRightArea
                    rightArea={rightArea}
                    entityType={entityType}
                  />
                )}
              </div>
              {bottomArea && bottomArea.size === 'fill' && (
                <>
                  <Divider />
                  <ControlViewBottom
                    entityType={entityType}
                    resizable={bottomArea.resizable}
                  >
                    {bottomArea.content}
                  </ControlViewBottom>
                </>
              )}
            </div>
          </div>
        </PrintComponent>
      </ColumnVisibilityContext.Provider>
    );
  }
);
