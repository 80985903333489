import { useApolloClient, useMutation } from '@apollo/client';
import { useEventCallback } from '@mui/material/utils';
import { cloneDeep, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  bufferTime,
  catchError,
  concatMap,
  defer,
  firstValueFrom,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

import { createTransaction } from '@work4all/data';
import { evictEntityCache } from '@work4all/data/lib/apollo-extras/evictEntityCache';
import { translateInput } from '@work4all/data/lib/hooks/data-provider';
import { WaitingQueue } from '@work4all/data/lib/utils/waiting-queue';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { InputPromptResult } from '@work4all/models/lib/Classes/InputPromptResult.entity';
import { ModifyShadowBzObjectResult } from '@work4all/models/lib/Classes/ModifyShadowBzObjectResult.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';
import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';
import { ReportBzObjType } from '@work4all/models/lib/Enums/ReportBzObjType.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { convertEntityToBzObjType } from '@work4all/models/lib/utils/convertEntityToBzObjType';

import { useLatest } from '@work4all/utils/lib/hooks/use-latest';
import { AccesorsOf } from '@work4all/utils/lib/paths-of/paths-of';

import {
  ApiErrors,
  ValidationErrors,
} from '../../../../../../apollo/ValidationErrors';
import { NEW_ENTITY_ID } from '../../../../mask-metadata';
import { OnEditPosition } from '../../components/tab-panels/positions/components/edit-table/types';
import { ErpData } from '../../ErpData';

import { IUseShadowObjectApiOptions } from './IUseShadowObjectApiOptions';
import {
  createShadowBzObjectGraphQl,
  CreateShadowBzObjectResponse,
  CreateShadowBzObjectVars,
  DELETE_SHADOW_BZ_OBJECT,
  DeleteShadowBzObjectResponse,
  DeleteShadowBzObjectVars,
  modifyShadowBzObjectAddPositionGraphQl,
  ModifyShadowBzObjectAddPositionResponse,
  ModifyShadowBzObjectAddPositionVars,
  modifyShadowBzObjectGraphQl,
  modifyShadowBzObjectModifyPositionGraphQl,
  ModifyShadowBzObjectModifyPositionResponse,
  ModifyShadowBzObjectModifyPositionVars,
  ModifyShadowBzObjectMovePositionResponse,
  ModifyShadowBzObjectMovePositionVars,
  modifyShadowBzObjectRemovePositionGraphQl,
  ModifyShadowBzObjectRemovePositionResponse,
  ModifyShadowBzObjectRemovePositionVars,
  ModifyShadowBzObjectResponse,
  ModifyShadowBzObjectVars,
  modifyShadowObjectMovePositionGraphQl,
  persistShadowBzObjectGraphQl,
  PersistShadowBzObjectResponse,
  PersistShadowBzObjectVars,
} from './use-shadow-bz-object-graphql';

export const ERP_NUMBER_CELLS: PositionAccessors[] = [
  'amount',
  'singlePriceNet',
  'discount',
  'purchasePrice',
  'vat',
  'width',
  'partsListAmount',
  'weight',
  'length',
  'amountCarton',
  'amoundPalettes',
  'amountVe',
  'purchasePriceSurcharge',
  'factor',
  'minutePrice',
];

export type PositionAccessors = AccesorsOf<Position>;

export interface ShadowObjectAddPositionArgs {
  positionType?: ErpPositionsKind;
  article?: Article | null;
  bomStyle?: InsertBomStyle;
  defaultText?: string;
  index?: number;
  localId?: string;
  copyIds?: number[];
}

export interface ShadowObjectApiOptions<T> {
  onComplete?: (data?: T) => void;
}

export type ValidationErrors = ApiErrors<ErpData>;

interface PendingChangePosition {
  positionId: number;
}

interface PendingChangeAddModifyPosition extends PendingChangePosition {
  type: 'modifyPosition';
  properties?: string[];
}

interface PendingChangeMovePosition extends PendingChangePosition {
  type: 'movePosition';
}
interface PendingChangeNone {
  type: 'none';
}

interface PendingChangeAddPosition {
  type: 'addPosition';
}

interface PendingChangeModify {
  type: 'modify';
}

type PendingChange =
  | (
      | PendingChangeModify
      | PendingChangeNone
      | PendingChangeAddModifyPosition
      | PendingChangeMovePosition
      | PendingChangeAddPosition
    ) & {
      pending: boolean;
    };

const PENDING_NONE: PendingChange = {
  pending: false,
  type: 'none',
};

interface DefferedWithKey {
  key: string;
  deferred: Observable<ModifyShadowBzObjectResult>;
}
const createDeferredWithKey = (
  key: string,
  fn: () => Promise<unknown>
): DefferedWithKey => {
  return { key, deferred: defer(fn) };
};

export function useShadowBzObjectApi(options: IUseShadowObjectApiOptions) {
  const { entity, id, parentEntity, parentId, skip, contactId } = options;

  const client = useApolloClient();

  const promptResult = useRef<InputPromptResult[]>([]);
  const [errors, setErrors] = useState<ValidationErrors>([]);
  const [shadowBzObject, setShadowBzObject] =
    useState<ModifyShadowBzObjectResult | null>(null);

  const shadowBzObjectRef = useLatest(shadowBzObject);

  const [deferredQueue, setDeferredQueue] = useState<Subject<
    Observable<ModifyShadowBzObjectResult>
  > | null>(() => new Subject());
  const [immediateQueue, setImmediateQueue] =
    useState<Subject<DefferedWithKey> | null>(() => new Subject());
  const [stop, setStop] = useState<Subject<void> | null>(() => new Subject());
  const [stopped, setStopped] = useState<Subject<void> | null>(
    () => new Subject()
  );

  const [entityId, setEntityId] = useState(null);

  const [createLoading, setCreateLoading] = useState(false);
  // Only changes not first load
  const [pending, setPending] = useState<PendingChange>(PENDING_NONE);
  const [isDirtyForm, setIsDirty] = useState(false);
  const [signature, setSignature] = useState<string>();
  const isDirty = isDirtyForm || Boolean(signature);
  const init = useCallback(() => {
    unstable_batchedUpdates(() => {
      setDeferredQueue(new Subject());
      setImmediateQueue(new Subject());
      setStop(new Subject());
      setStopped(new Subject());

      setIsDirty(false);
    });
  }, []);

  const [mutateCreate] = useMutation<
    CreateShadowBzObjectResponse,
    CreateShadowBzObjectVars
  >(
    useMemo(() => createShadowBzObjectGraphQl(entity), [entity]),
    {
      onError() {
        setCreateLoading(false);
      },
      onCompleted(response) {
        setCreateLoading(false);
        setShadowBzObject(response.createShadowBzObject);
        init();
      },
    }
  );

  const [mutateModify] = useMutation<
    ModifyShadowBzObjectResponse,
    ModifyShadowBzObjectVars
  >(useMemo(() => modifyShadowBzObjectGraphQl(entity), [entity]));

  const [mutateAddPosition] = useMutation<
    ModifyShadowBzObjectAddPositionResponse,
    ModifyShadowBzObjectAddPositionVars
  >(useMemo(() => modifyShadowBzObjectAddPositionGraphQl(entity), [entity]));

  const [mutateMovePosition] = useMutation<
    ModifyShadowBzObjectMovePositionResponse,
    ModifyShadowBzObjectMovePositionVars
  >(useMemo(() => modifyShadowObjectMovePositionGraphQl(entity), [entity]));

  const [mutateModifyPosition] = useMutation<
    ModifyShadowBzObjectModifyPositionResponse,
    ModifyShadowBzObjectModifyPositionVars
  >(useMemo(() => modifyShadowBzObjectModifyPositionGraphQl(entity), [entity]));

  const [mutateRemovePosition] = useMutation<
    ModifyShadowBzObjectRemovePositionResponse,
    ModifyShadowBzObjectRemovePositionVars
  >(useMemo(() => modifyShadowBzObjectRemovePositionGraphQl(entity), [entity]));

  const [mutatePersist] = useMutation<
    PersistShadowBzObjectResponse,
    PersistShadowBzObjectVars
  >(
    useMemo(() => persistShadowBzObjectGraphQl(entity), [entity])
    // ToDo: onComlete in second param is not respected when other onComplete is called
    //       https://work4all.atlassian.net/browse/WW-4101
  );

  const [mutateDelete] = useMutation<
    DeleteShadowBzObjectResponse,
    DeleteShadowBzObjectVars
  >(DELETE_SHADOW_BZ_OBJECT);

  useEffect(() => {
    if (!deferredQueue || !immediateQueue || !stop) return;
    const sortByPriority = (
      observables: DefferedWithKey[]
    ): DefferedWithKey[] => {
      return observables.sort((a, b) => {
        // Define a simple sorting rule: Function editPosition should be processed before others
        if (a.key === 'editPosition' && b.key !== 'editPosition') return -1;
        if (b.key === 'editPosition' && a.key !== 'editPosition') return 1;
        return 0; // Maintain original order for others
      });
    };
    const subscription = merge(
      deferredQueue.pipe(
        tap(() => setIsDirty(true)),
        takeUntil(stop),
        switchMap((request) => {
          return timer(250).pipe(map(() => request));
        })
      ),
      immediateQueue.pipe(
        tap(() => {
          setIsDirty(true);
        }),
        bufferTime(500),
        map((observables) => sortByPriority(observables)),
        // Flatten the sorted array back into individual Promises (observables)
        mergeMap((sortedObservables) =>
          of(...sortedObservables.map((item) => item.deferred))
        ),
        takeUntil(stop)
      )
    )
      .pipe(
        concatMap((request) => {
          return request.pipe(
            takeUntil(merge(deferredQueue, immediateQueue)),
            catchError(() => {
              // refresh state
              return of(cloneDeep(shadowBzObjectRef.current));
            })
          );
        }),
        tap((result) => {
          setIsDirty(true);
          setShadowBzObject(result);
        })
      )
      .subscribe({
        complete() {
          stopped.next();
        },
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [deferredQueue, immediateQueue, shadowBzObjectRef, stop, stopped]);

  const createShadowBzObject = useEventCallback(() => {
    const bzObjectType = convertEntityToBzObjType(entity);

    const variables: CreateShadowBzObjectVars =
      id == null || id === NEW_ENTITY_ID
        ? {
            type: bzObjectType,
            sdObjType: convertEntityToSdObjType(parentEntity),
            sdObjMemberCode: Number(parentId),
            apCode: contactId,
          }
        : {
            type: bzObjectType,
            bzObjMemberCode: Number(id),
            apCode: contactId,
          };

    if (!skip) {
      setCreateLoading(true);
      mutateCreate({ variables });
    }
  });

  useEffect(createShadowBzObject, [
    mutateCreate,
    entity,
    id,
    parentEntity,
    parentId,
    skip,
    contactId,
    createShadowBzObject,
  ]);

  useEffect(() => {
    const id = shadowBzObject?.id;

    if (id) {
      return () => {
        mutateDelete({ variables: { id } });
      };
    }
  }, [mutateDelete, shadowBzObject?.id]);

  const modify = useEventCallback((value: object) => {
    if (!shadowBzObject) return;

    const { id } = shadowBzObject;
    const additionalProps =
      'costCenterNumber' in value
        ? { costCenterNumber: value.costCenterNumber }
        : {};

    const finalValue = { ...translateInput(entity, value), ...additionalProps };
    if (!Object.keys(finalValue).length) {
      console.warn('Empty values updates are forbidden.');
      return;
    }
    deferredQueue.next(
      defer(() => {
        const transaction = createTransaction({
          name: 'ERP Mask - Modify position',
        });
        setPending({
          pending: true,
          type: 'modify',
        });

        return mutateModify({
          variables: {
            id,
            [entity]: finalValue,
            promptResult: promptResult.current,
          },
          onCompleted: () => {
            transaction?.finish();

            setErrors([]);
            setPending(PENDING_NONE);
            promptResult.current = [];
          },
          onError: (e) => {
            const parsedErrors = ValidationErrors.parseErrors<ErpData>(
              e.graphQLErrors,
              entity
            );
            setErrors(parsedErrors);
            transaction?.finish();
            setPending(PENDING_NONE);
            promptResult.current = [];
          },
        }).then(({ data }) => data.modifyShadowBzObject);
      })
    );
  });

  const persist = useEventCallback(
    async (attachements?: InputErpAnhangAttachementsRelation) => {
      // Wait until all modification finished and then persist.
      await waitingQueue.current.waitUntilEmpty();
      if (!shadowBzObject) return;
      const relations = signature
        ? {
            setSignatureFromTempFile: signature,
          }
        : undefined;

      const promise = firstValueFrom(
        stopped.pipe(
          switchMap(async () => {
            const transaction = createTransaction({
              name: 'ERP Mask - Persist',
            });
            return mutatePersist({
              variables: { id: shadowBzObject.id, attachements, relations },
              onCompleted: (value) => {
                transaction?.finish();
                setEntityId(value.persistShadowBzObject.data.id);
                setSignature('');
                evictEntityCache(client, entity);
                setShadowBzObject(value.persistShadowBzObject);
              },
              onError: () => {
                transaction?.finish();
              },
            });
          }),
          tap(() => {
            init();
          })
        )
      );

      stop.next();

      return await promise;
    }
  );

  const addPosition = useEventCallback(
    async (
      args: ShadowObjectAddPositionArgs,
      options?: ShadowObjectApiOptions<ModifyShadowBzObjectAddPositionResponse>
    ) => {
      const {
        positionType,
        article,
        bomStyle,
        defaultText,
        index,
        localId,
        copyIds,
      } = args;
      const articleId = article?.id;
      if (!shadowBzObject) return;

      const { id } = shadowBzObject;

      const articleIds = articleId === 0 ? [0] : undefined;
      immediateQueue.next(
        createDeferredWithKey('addPosition', () => {
          const transaction = createTransaction({
            name: 'ERP Mask - Add Position',
          });
          setPending({ pending: true, type: 'addPosition' });
          return mutateAddPosition({
            variables: {
              id,
              positionType,
              articleId,
              articleIds,
              bomStyle,
              defaultText,
              localId,
              copyPositions: copyIds?.length
                ? copyIds.map((positionCode) => ({
                    positionCode,
                  }))
                : undefined,
              index:
                index === shadowBzObject.data?.positionList.length
                  ? undefined
                  : index,
            },
            onCompleted: (data) => {
              transaction?.finish();
              options?.onComplete(data);
              setPending(PENDING_NONE);
            },
          }).then(({ data }) => data.modifyShadowBzObjectAddPosition);
        })
      );
    }
  );

  const movePosition = useEventCallback((positionId: number, index: number) => {
    if (!shadowBzObject) return;

    const { id } = shadowBzObject;

    deferredQueue.next(
      defer(() => {
        const transaction = createTransaction({
          name: 'ERP Mask - Move Position',
        });
        setPending({ pending: true, type: 'movePosition', positionId });
        return mutateMovePosition({
          variables: {
            id,
            positionId,
            index,
          },
          onCompleted: () => {
            transaction?.finish();
            setPending(PENDING_NONE);
          },
          onError: () => {
            transaction?.finish();
            setPending(PENDING_NONE);
          },
        }).then(({ data }) => data.modifyShadowBzObjectMovePosition);
      })
    );
  });

  const removePosition = useEventCallback(async (positionIds: number[]) => {
    if (!shadowBzObject) return;

    const { id } = shadowBzObject;

    immediateQueue.next(
      createDeferredWithKey('removePosition', () =>
        mutateRemovePosition({
          variables: {
            id,
            positionId: positionIds[0],
            positionIds: positionIds.slice(1),
          },
        }).then(({ data }) => data.modifyShadowBzObjectRemovePosition)
      )
    );
  });
  const waitingQueue = useRef(new WaitingQueue());

  const editPosition = useEventCallback(
    async (
      result: OnEditPosition<Position>,
      options?: ShadowObjectApiOptions<ModifyShadowBzObjectModifyPositionResponse>
    ) => {
      // Adding one task in progress so we can wait for it
      await waitingQueue.current.queueSingle();
      if (!shadowBzObject) return;

      if (
        !shadowBzObject.data.positionList.some(
          (x) => x.id === result.position.id
        )
      ) {
        console.warn('Skip removing removed entity');
        return;
      }
      const { id } = shadowBzObject;
      const { position } = result;
      if (!Object.keys(position).length || !position.id) {
        console.warn(
          'Skipping request. Reason: There is no values or id in request.',
          position
        );
        return;
      }

      const request: Partial<Position> = {};

      Object.entries(position).forEach((x) => {
        const [field, value] = x;
        if (
          ERP_NUMBER_CELLS.includes(field as PositionAccessors) &&
          typeof value === 'string'
        )
          request[field] = parseFloat(value);
        else request[field] = value;
      });

      delete position.__typename;
      const positionToMutate = translateInput<Position>(Entities.position, {
        id: position.id,
        ...request,
      });
      if (Object.keys(omit(positionToMutate, 'code')).length) {
        immediateQueue.next(
          createDeferredWithKey('editPosition', () => {
            const transaction = createTransaction({
              name: 'ERP Mask - Modify Position',
            });
            setPending({
              pending: true,
              type: 'modifyPosition',
              positionId: position.id,
              properties: Object.keys(position),
            });
            return mutateModifyPosition({
              variables: {
                id,
                position: positionToMutate,
              },
              onCompleted: (data) => {
                transaction?.finish();
                options?.onComplete(data);
                setPending(PENDING_NONE);
                // Release task
                waitingQueue.current.releaseSingle();
              },
              onError: () => {
                // Release task
                waitingQueue.current.releaseSingle();
              },
            }).then(({ data }) => {
              return data.modifyShadowBzObjectModifyPosition;
            });
          })
        );
      }
    }
  );

  const refetch = createShadowBzObject;

  return [
    shadowBzObject,
    useMemo(
      () => ({
        entityId,
        loading: createLoading,
        isDirty,
        persist,
        modify,
        pending,
        promptResult,
        addPosition,
        movePosition,
        editPosition,
        removePosition,
        setSignature,
        refetch,
      }),
      [
        entityId,
        createLoading,
        isDirty,
        persist,
        modify,
        pending,
        promptResult,
        addPosition,
        movePosition,
        editPosition,
        removePosition,
        setSignature,
        refetch,
      ]
    ),
    errors,
  ] as const;
}

export function convertEntityToReportBzObjType(
  entity: Entities
): ReportBzObjType {
  switch (entity) {
    case Entities.calculation:
      return ReportBzObjType.KALKULATION;
    case Entities.contract:
      return ReportBzObjType.AUFTRAG;
    case Entities.offer:
      return ReportBzObjType.ANGEBOT;
    case Entities.invoice:
      return ReportBzObjType.RECHNUNG;
    case Entities.deliveryNote:
      return ReportBzObjType.LIEFERSCHEIN;
    case Entities.order:
      return ReportBzObjType.BESTELLUNG;
    case Entities.demand:
      return ReportBzObjType.BEDARF;
    case Entities.inboundDeliveryNote:
      return ReportBzObjType.EINGANGSLIEFERSCHEIN;
    default:
      throw new Error(
        `Cannot convert entity "${entity}" tp \`ReportBzObjType\``
      );
  }
}

function convertEntityToSdObjType(entity: Entities): SdObjType {
  switch (entity) {
    case Entities.customer:
      return SdObjType.KUNDE;
    case Entities.supplier:
      return SdObjType.LIEFERANT;
    case Entities.project:
      return SdObjType.PROJEKT;
    default:
      throw new Error(`Cannot convert entity "${entity}" to \`SdObjType\``);
  }
}
