import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { DateTime } from 'luxon';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Filters } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { CountIndicator } from '@work4all/components/lib/dataDisplay/count-indicator/CountIndicator';
import { CheckListField } from '@work4all/components/lib/input/check-list-field/CheckListField';

import { useDataProvider } from '@work4all/data';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TicketFilter } from '@work4all/models/lib/Classes/TicketFilter.entity';
import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { settings, useSetting } from '../../../../../settings';
import { CheckListLabel } from '../../../../filters/PersonalAndGeneralView/components/CheckListLabel';

import { TicketKindMenu } from './TicketKindMenu';

const endDate = DateTime.now().endOf('day');
const statusList = [TicketStatus.OFFEN];
const statusFilter = statusList.map((x) => ({ id: x }));
const ActiveTicketIndicator = (props: { id: number }) => {
  const { id } = props;
  const requestData = useMemo(
    () => ({
      entity: Entities.ticket,
      data: {},
      filter: [
        {
          followUpDate: {
            $lt: endDate.toISO(),
          },
        },
        {
          ticketKindId: {
            $eq: id,
          },
        },
        {
          $and: [
            {
              status1: {
                $in: statusList,
              },
            },
          ],
        },
      ],
    }),
    [id]
  );
  const result = useDataProvider<Ticket>(requestData);

  useEntityEvents((event) => {
    if (event.entity === Entities.ticket) {
      result.refetch();
    }
  });

  return <CountIndicator variant="secondary" value={result.total} />;
};

const data = {
  id: null,
  name: null,
};

export function KindFilters({
  selectedFilter,
  setSelectedFilter,
}: {
  selectedFilter: TicketFilter | TicketKind;
  setSelectedFilter: Dispatch<SetStateAction<TicketFilter | TicketKind>>;
}) {
  const { t } = useTranslation();

  const requestData = useMemo(
    () => ({
      entity: Entities.ticketKind,
      data,
      operationName: 'GetTicketKindList',
      completeDataResponse: true,
    }),
    []
  );

  const categories = useDataProvider<TicketKind>(requestData);

  const tableStateBag = useTableStateBag();

  const initialFilters = useRef<Filters<object>>(null);

  useEffect(() => {
    if (
      !tableStateBag.tableState?.filters ||
      tableStateBag.tableState.filters.length === 0 ||
      initialFilters.current
    ) {
      return;
    }

    initialFilters.current = tableStateBag.tableState.filters;
  }, [tableStateBag.tableState?.filters]);

  const ticketKindColumn = tableStateBag.columnsById['ticketKind.id'];
  const statusColumn = tableStateBag.columnsById['status1'];
  const dateColumn = tableStateBag.columnsById['followUpDate'];
  const columns = tableStateBag.columnsById;
  const setSearchFilterText = tableStateBag.setSearchFilterText;

  const clearCurrentFilters = useCallback(() => {
    Object.entries(columns).forEach((element) => {
      element[1].setFilter(null);
    });
    setSearchFilterText('');
  }, [columns, setSearchFilterText]);

  const handleGroupsChange = useCallback(
    (groups: TicketKind[]) => {
      clearCurrentFilters();
      if (groups.length === 0) {
        ticketKindColumn.setFilter(null);
        statusColumn.setFilter(null);
        setSelectedFilter(undefined);
      } else {
        dateColumn.setFilter({
          value: {
            endDate: endDate.toJSDate(),
          },
          filterType: dateColumn.filterType,
        });
        statusColumn.setFilter({
          value: statusFilter,
          filterType: statusColumn.filterType,
        });

        ticketKindColumn.setFilter({
          value: groups,
          filterType: ticketKindColumn.filterType,
        });

        setSelectedFilter(groups[0]);
      }
    },
    [
      clearCurrentFilters,
      ticketKindColumn,
      statusColumn,
      dateColumn,
      setSelectedFilter,
    ]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const categoryIds = useMemo(
    () => categories.data.map((category) => category.id),
    [categories.data]
  );

  const ticketKind = useSetting(settings.ticketKindVisibility(categoryIds));

  const openTicketKindMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeTicketKindMenu = () => {
    setAnchorEl(null);
  };

  const toggleTicketKindSelection = (categoryId: number) => {
    const currentValue = ticketKind.value;
    const isCurrentlyChecked = currentValue.includes(categoryId);

    const newCheckedItems = isCurrentlyChecked
      ? currentValue?.filter((id) => id !== categoryId)
      : [...currentValue, categoryId];

    ticketKind.set(newCheckedItems);

    if (
      isCurrentlyChecked &&
      selectedFilter &&
      selectedFilter.id === categoryId
    ) {
      setSelectedFilter(undefined);
      tableStateBag.tableInstance.setAllFilters(initialFilters.current);
    }
  };

  const visibleTicketKinds = useMemo(() => {
    return categories.data.filter((category) =>
      ticketKind.value.includes(category.id)
    );
  }, [categories.data, ticketKind.value]);

  return (
    <>
      <CheckListField
        labelElement={
          <CheckListLabel
            value={t('TICKET.KIND')}
            rightElement={
              <IconButton size="small" onClick={openTicketKindMenu}>
                <MoreVertIcon />
              </IconButton>
            }
          />
        }
        onChange={handleGroupsChange}
        all={visibleTicketKinds}
        value={[selectedFilter]}
        renderIndicator={(id) =>
          typeof id === 'number' ? <ActiveTicketIndicator id={id} /> : undefined
        }
        hideCheckboxs
      />

      <TicketKindMenu
        anchorEl={anchorEl}
        open={open}
        onClose={closeTicketKindMenu}
        categories={categories.data}
        selectedIds={ticketKind.value}
        onToggle={toggleTicketKindSelection}
      />
    </>
  );
}
