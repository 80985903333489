import styles from './ChangeEventView.module.scss';

import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import { ChangeEvent } from '@work4all/models/lib/Classes/ChangeEvent.entity';

import { EntityHistoryContext } from '../entity-history-context';

import { EventLayout } from './event-layout';

export interface ChangeEventViewProps {
  event: ChangeEvent;
}

export function ChangeEventView(props: ChangeEventViewProps) {
  const { event } = props;

  const { t, i18n } = useTranslation();

  const { entity } = useContext(EntityHistoryContext);

  return (
    <EventLayout event={event}>
      {event.changes?.map((change, index) => (
        <Box key={`${event}-${index}`}>
          <Typography
            component="span"
            variant="body2"
            color="text.tertiary"
            style={{ float: 'left' }}
          >
            {i18n.exists(`Entity.History_Change.${change.propertyName}`)
              ? t(`Entity.History_Change.${change.propertyName}`)
              : change.propertyName}
            :&nbsp;
          </Typography>
          <Typography
            className={styles.newValue}
            key={index}
            variant="body2"
            color="text.primary"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                i18n.exists(`${entity}.${change.newValue}`)
                  ? t(`${entity}.${change.newValue}`)
                  : isValidISODate(change.newValue)
                  ? formatDate(change.newValue)
                  : change.newValue,
                {
                  allowedAttributes: {
                    a: ['href'],
                    '*': ['style'],
                  },
                }
              ),
            }}
          />
        </Box>
      ))}
    </EventLayout>
  );
}

const isValidISODate = (dateString: string): boolean => {
  const date = DateTime.fromISO(dateString);
  return date.isValid;
};

const formatDate = (dateString: string): string => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_FULL);
};
