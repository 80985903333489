import { Typography } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { UserPicker } from '@work4all/components/lib/components/entity-picker/user-picker/UserPicker';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { User } from '@work4all/models/lib/Classes/User.entity';

import {
  PickerTargetButton,
  PickerTargetButtonProps,
} from './PickerTargetButton';

export function UserPickerCell(
  props: {
    value: User;
    onChange: (value: User | null) => void;
    hideIcon?: boolean;
  } & Omit<PickerTargetButtonProps, 'onChange' | 'value'>
) {
  const {
    disabled: _disabled = false,
    value,
    onChange,
    hideIcon,
    ...buttonProps
  } = props;
  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<UserPicker value={value} onChange={onChange} multiple={false} />}
    >
      <PickerTargetButton {...buttonProps}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!hideIcon && <HookedUserIcon userId={value?.id} />}
          <Typography style={{ marginLeft: '12px' }}>
            {value?.displayName}
          </Typography>
        </div>
      </PickerTargetButton>
    </EntityPickerPopover>
  );
}
