import { CellProps } from 'react-table';

import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';

import { canApproveVacations } from '@work4all/utils/lib/permissions';

import { PermitStatusIconCell } from '../../PermitStatusIconCell';
import { mapSelectedRowStatus } from '../utils';

export const VacationStatusCardIconCell = (
  cellProps: CellProps<VacationRequestEntity>
) => (
  <PermitStatusIconCell
    {...cellProps}
    value={mapSelectedRowStatus(cellProps.value)}
    canApprove={canApproveVacations}
    hideForward={true}
    view="card"
  />
);
