import { z } from 'zod';

export enum HomeWidgetType {
  timeTracker = 'timeTracker',
  entityWidget = 'entityWidget',
  overviewWidget = 'overviewWidget',
  relevantWidget = 'relevantWidget',
  calendarWidget = 'calendarWidget',
  widgetGroup = 'widgetGroup',
  divider = 'divider',
}

export const HomeWidgetTypeSchema = z.nativeEnum(HomeWidgetType);

export const FilterSortStateSchema = z.object({
  filter: z.any().optional(),
  sort: z.any().optional(),
});

export type FilterSortState = z.infer<typeof FilterSortStateSchema>;

export const EntityWidgetDefinitionSchema = z.object({
  entity: z.any(),
  variant: z.string().optional().nullable(),
  filters: z.any().optional(),
  prefilter: z.array(z.unknown()).optional().nullable(),
  tableUrl: z.string().optional(),
  filter: z.any().optional(),
});

export type EntityWidgetDefinition = z.infer<
  typeof EntityWidgetDefinitionSchema
>;

export const HomeWidgetSchema = z.object({
  id: z.string(),
  type: HomeWidgetTypeSchema,
  definition: EntityWidgetDefinitionSchema.optional(),
  title: z.string().optional(),
  subWidgetIds: z.array(z.string()).optional(),
});

export type HomeWidget = z.infer<typeof HomeWidgetSchema>;

export const HomeWidgetLayoutsSchema = z.object({
  widgets: z.array(HomeWidgetSchema),
  layouts: z.any(),
});

export type HomeWidgetLayouts = z.infer<typeof HomeWidgetLayoutsSchema>;
