import styles from './PositionPicker.module.scss';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useObservableState } from 'observable-hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TableStateBagProvider } from '@work4all/components';
import { ResizableArea } from '@work4all/components/lib/components/ResizableArea/ResizableArea';
import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { LockSubscription } from '@work4all/components/lib/hooks/object-lock-subscription/LockSubscription';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';

import { CustomFieldsConfigProvider } from '@work4all/data/lib/custom-fields';
import { PrintBagProvider } from '@work4all/data/lib/hooks/useHandlePrint';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { PositionGroupsTree } from '../position-group-tree/PositionGroupsTree';

import { ArticleSearch } from './components/ArticleSearch';
import { PositionArticlesTable } from './components/PositionArticlesTable';
import { PositionMore } from './components/PositionMore';
import { PositionsFilter } from './components/PositionsFilter';

export type IPositionPickerProps = {
  className?: string;
  onAddStandard: (articleId: Article) => void;
  onGroupDoubleClick?: (title: string) => void;
  disabled: boolean;
  customerId?: number;
};

export const PositionPicker = (props: IPositionPickerProps) => {
  const { className, onAddStandard, onGroupDoubleClick, disabled, customerId } =
    props;

  const { t } = useTranslation();

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>(null);

  const [searchString, setSearchString] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);

  const formattedSearchString = searchString.trim();

  const [tableInstance, setTableInstance] = useState<TableInstance | null>(
    null
  );

  const tableInstanceRef = useRef<TableInstance>(null);
  useEffect(() => {
    tableInstanceRef.current = tableInstance;
  }, [tableInstance]);
  const previewSize = useSetting(settings.leftAreaSize());

  const columnsVisibilityManager = useColumnVisibilityManager({
    tableInstanceRef,
  });

  const resetColumnsRef = useRef<() => void>(null);

  const erpShowArticle = useSetting(settings.erpShowArticle());

  return (
    <CustomFieldsConfigProvider entity={Entities.article}>
      <PrintBagProvider>
        <LockSubscription entity={Entities.article}>
          <TableStateBagProvider tableInstance={tableInstance}>
            <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
              <div
                className={clsx(styles.grid, className, {
                  [styles.pickerDisabled]: disabled,
                })}
              >
                <div
                  className={styles.toolbar}
                  style={{
                    padding: erpShowArticle.value
                      ? '0.25rem 0 0.25rem 0.5rem'
                      : '0 0 0 0.5rem',
                  }}
                >
                  <ExpandButton
                    title={t('COMMON.ARTICLE_LIST')}
                    icon={
                      erpShowArticle.value ? <ExpandMore /> : <ExpandLess />
                    }
                    onClick={() => {
                      erpShowArticle.set(!erpShowArticle.value);
                    }}
                    orientation="horizontal"
                  />
                  {erpShowArticle.value ? (
                    <>
                      <Box flex="1"></Box>
                      <ArticleSearch
                        onChange={setSearchString}
                        disabled={disabled}
                      />

                      <div className={styles.actions}>
                        {selectedArticles.length ? (
                          <IconButtonWithTooltip
                            tooltip={t('COMMON.APPLY_SELECTION')}
                            icon={<CheckIcon />}
                            onClick={() => {
                              // TODO: add bulk save
                              onAddStandard(selectedArticles[0]);
                            }}
                          />
                        ) : null}
                        <PositionsFilter
                          entity={Entities.article}
                          disabled={disabled}
                          tableInstanceRef={tableInstanceRef}
                        />
                        <PositionMore
                          resetColumns={resetColumnsRef.current}
                          disabled={disabled}
                          tableInstanceRef={tableInstanceRef}
                        />
                      </div>
                    </>
                  ) : null}
                </div>

                {erpShowArticle.value ? (
                  <div className={styles.tableWrapper}>
                    <ResizableArea
                      handles="right"
                      className={styles.groupsContainer}
                      size={previewSize.value}
                      onResize={(size) => {
                        previewSize.set(size);
                      }}
                      maxWidth={800}
                      minWidth={100}
                    >
                      <div>
                        <PositionGroupsTree
                          disabled={disabled || !onGroupDoubleClick}
                          onSelect={(ids) => {
                            if (ids.length > 0) {
                              setSelectedGroupIds(ids);
                            } else {
                              setSelectedGroupIds(null);
                            }
                          }}
                          onNodeDoubleClick={(e) => {
                            if (disabled) return;
                            onGroupDoubleClick?.(e.target.innerText);
                          }}
                        />
                      </div>
                    </ResizableArea>

                    <div className={styles.tableContainer}>
                      <PositionArticlesTable
                        ref={setTableInstance}
                        searchString={formattedSearchString}
                        groups={selectedGroupIds}
                        onSelectedChange={setSelectedArticles}
                        onRowDoubleClick={(article) => onAddStandard(article)}
                        disabled={disabled}
                        setResetColumns={(resetColumns) =>
                          (resetColumnsRef.current = resetColumns)
                        }
                        customerId={customerId}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </ColumnVisibilityContext.Provider>
          </TableStateBagProvider>
        </LockSubscription>
      </PrintBagProvider>
    </CustomFieldsConfigProvider>
  );
};
