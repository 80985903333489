import styles from './NavItem.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useDrag } from 'react-dnd';

import { SearchTextResult } from '@work4all/components/lib/utils/search-text-result/SearchTextResult';

import { Link } from '@work4all/data';

import { DndTypes } from '@work4all/utils/lib/variables';

import { MatchedNavigationLink } from '../../../containers/more/types';
import { LinkDragObject } from '../../../dnd/drag-objects';
import { useEmptyDragPreview } from '../../../dnd/use-empty-drag-preview';

import { ReactComponent as StarIcon } from './star.svg';

export interface INavItemProps {
  link: MatchedNavigationLink;
  favorite: boolean;

  onClick?: () => void;
  onFavoriteChange?: () => void;
}

export function NavItem(props: INavItemProps) {
  const {
    link: { disabled = false, name, translationKey, result, href },
    favorite,
    onFavoriteChange,
  } = props;

  const [, drag, preview] = useDrag<LinkDragObject, unknown, unknown>({
    type: DndTypes.LINK,
    item: { text: name, translationKey, href },
  });

  useEmptyDragPreview(preview);

  function renderLink({
    disabled,
    children,
  }: {
    disabled: boolean;
    children: React.ReactNode;
  }) {
    if (disabled) {
      return <span className={clsx(styles.link)}>{children}</span>;
    } else {
      return (
        <Link to={href} className={clsx(styles.link)} onClick={props.onClick}>
          {children}
        </Link>
      );
    }
  }

  return (
    <div
      ref={(node) => {
        drag(node);
      }}
      className={clsx(styles.root, {
        [styles.favorite]: favorite,
        [styles.inactive]: disabled,
      })}
    >
      <button
        disabled={disabled}
        className={styles.favoriteButton}
        onClick={() => onFavoriteChange?.()}
      >
        <StarIcon className={styles.icon} />
      </button>
      {renderLink({
        disabled,
        children: (
          <Typography
            component="span"
            variant="body2"
            className={clsx(styles.linkText, {
              [styles.inactive]: disabled,
            })}
          >
            {result ? <SearchTextResult result={result} /> : name}
          </Typography>
        ),
      })}
    </div>
  );
}
