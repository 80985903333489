import { cloneDeep, isEqual, omit } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { uuid } from 'short-uuid';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import {
  EditTableEntry,
  EditTablePositionKind,
  IdArray,
  OnEditPosition,
} from '../types';

import { arrayMove } from './arrayMove';
import { getIdsIncomingKeys } from './getIdsIncomingKeys';
import { removeDotsInKeys } from './removeDotsInKeys';
import { writeObject } from './writeObject';

interface EditableActions<T extends EditTableEntry, TContext, TId> {
  onAddPosition?: (context?: TContext) => void;
  onRemovePosition?: (positionId: TId[]) => void;
  onClonePosition?: (positionId: TId[]) => void;
  onMovePosition?: (
    positionId: TId,
    index: number,
    localIndex?: number
  ) => void;
  onEditPosition?: (result: OnEditPosition<T>) => void;
}

type InEditableActions<T extends EditTableEntry, TContext> = EditableActions<
  T,
  TContext,
  T['id']
>;

export interface UseEditableStateProps<T extends EditTableEntry, TContext>
  extends InEditableActions<T, TContext> {
  positions: T[];
  mapAddLocal?: (context: TContext) => T;
  mapRemoveLocal?: (positionId: T['id'][]) => T['id'][];
  mutateState?: (inputs: T[]) => EditTableResultEntry<T>[];
  canAddRemote?: (context: TContext) => boolean;
}

export interface UseEditableStateResult<
  T extends EditTableEntry,
  TContext = EditStateContext
> extends EditableActions<T, TContext, string | T['id']> {
  onCollapsePosition: (position: T) => void;
  allPositions: T[];
  positions: T[];
}

interface AddAction<T extends EditTableEntry> {
  type: 'add';
  newEntry: T;
  index?: number;
}

interface RemoveAction<T extends EditTableEntry> {
  type: 'remove';
  positionId: T['id'][] | string[];
}

interface MergeAction<T extends EditTableEntry> {
  type: 'merge';
  positions: T[];
}

interface EditAction<T extends EditTableEntry> {
  type: 'edit';
  result: OnEditPosition<T>;
}

interface MoveAction<T extends EditTableEntry> {
  type: 'move';
  positionId: T['id'] | string;
  index: number;
}

type PositionStateAction<T extends EditTableEntry> =
  | AddAction<T>
  | RemoveAction<T>
  | MergeAction<T>
  | EditAction<T>
  | MoveAction<T>;

const createDataFetchReducer =
  <T extends EditTableEntry>(
    mutateState: UseEditableStateProps<T, unknown>['mutateState']
  ) =>
  (
    state: EditTableResultEntry<T>[],
    action: PositionStateAction<T>
  ): EditTableResultEntry<T>[] => {
    let newState: EditTableResultEntry<T>[] = [];
    switch (action.type) {
      case 'add': {
        // For now we support only single cache row
        const cacheOnlyIndex = state.findIndex((x) => x.cacheOnly);
        const oldState = [...state].filter((x) => !x.cacheOnly);
        newState = [...oldState, action.newEntry];

        let to = action.index;
        if (to !== undefined) {
          const from = newState.length - 1;
          // if we filtered out cache row we need to move index
          if (cacheOnlyIndex > 0 && cacheOnlyIndex < to) to--;
          const p = [...newState];
          arrayMove(p, from, to);
          newState = p;
        }
        break;
      }
      case 'move': {
        const { index: to, positionId } = action;
        const from = state.findIndex(
          (x) => x.id === positionId || x.localId === positionId
        );
        const p = [...state];
        arrayMove(p, from, to);
        newState = p;
        break;
      }
      case 'merge': {
        const finalState: T[] = [];
        const serverState = action.positions || [];
        serverState.forEach((pos, idx) => {
          const clonedPos = cloneDeep(pos);
          const localPos = state.find((x) => x.localId === pos.localId);

          // only if no local state there could be undefined
          if (
            state.length &&
            localPos?.dirtyFields &&
            Object.entries(localPos.dirtyFields).length
          ) {
            Object.entries(localPos.dirtyFields).forEach(
              (x) => (clonedPos[x[0]] = x[1])
            );
          }

          if (!clonedPos.localId) clonedPos.localId = `${clonedPos.id}`;

          clonedPos.index = idx;
          finalState.push(clonedPos);
        });

        newState = finalState;
        break;
      }
      case 'remove':
        newState = state.filter(
          (x) =>
            // @ts-expect-error T['id'] has type of given T entity
            !action.positionId.includes(x.id) &&
            !action.positionId.includes(x.localId)
        );
        break;
      case 'edit': {
        const { position } = action.result;
        newState = state.map((pos) => {
          delete pos.dirtyFields;
          if (pos.id !== position.id) return pos;
          const val = cloneDeep(pos);
          writeObject(position, val);
          return val;
        });
        break;
      }
      default:
        newState = state;
    }
    return mutateState ? mutateState(newState) : newState;
  };

export type EditTableResultEntry<T> = T & {
  relation?: 'parent' | 'child' | 'none';
  collapsed?: boolean;
};

export interface BaseStateContext {
  index?: number;
  localId?: string;
  cacheOnly?: boolean;
}

export type EditStateContext = Record<string, unknown> & BaseStateContext;
/**
 * State management which is used for EditTable component.
 * It organizes local & remote updates and provides utility functions
 * to manage positions in the table dynamically.
 *
 * The hook leverages React's reducer for state management, and includes
 * logic for adding, removing, editing, moving, and merging table rows.
 * It also handles both client-side updates and interactions with a remote server.
 *
 * @template T The type of table entries, extending `EditTableEntry`.
 * @template TId The type of the unique identifier for table entries.
 * @template TContext The type of the context object used for certain operations.
 *
 * @param props Object containing configuration and callbacks for managing editable table state.
 * @param props.positions Initial positions in the editable table.
 * @param props.onAddPosition Callback to handle adding a new position (optional).
 * @param props.onRemovePosition Callback to handle removing positions (optional).
 * @param props.onEditPosition Callback to handle editing an existing position (optional).
 * @param props.onMovePosition Callback to handle moving a position within the table (optional).
 * @param props.mapAddLocal Function to map a local context to a new position (optional).
 * @param props.mapRemoveLocal Function to map IDs to be removed locally (optional).
 * @param props.mutateState Function to shape or mutate the current state (optional).
 * @param props.canAddRemote Function to determine if a remote position can be added (optional).
 *
 * @returns {UseEditableStateResult<T, TId, TContext>} Object containing:
 * - `positions`: Current table positions, including any local modifications.
 * - `allPositions`: All positions, both local and remote.
 * - `onAddPosition`: Callback to add a new position.
 * - `onRemovePosition`: Callback to remove positions.
 * - `onMovePosition`: Callback to move positions.
 * - `onEditPosition`: Callback to edit a position.
 * - `onCollapsePosition`: Callback to collapse/expand a position.
 */
export function useEditableState<
  T extends EditTableEntry,
  TContext extends BaseStateContext = EditStateContext
>(
  props: UseEditableStateProps<T, TContext>
): UseEditableStateResult<T, TContext> {
  const {
    positions,
    onAddPosition: onAddPositionOnServer,
    onRemovePosition: onRemovePositionOnServer,
    onEditPosition: onEditPositionOnServer,
    onMovePosition: onMovePositionOnServer,
    mapAddLocal,
    mapRemoveLocal,
    mutateState,
    canAddRemote,
  } = props;

  const reducer = createDataFetchReducer<T>(mutateState);
  const [state, dispatch] = useReducer(reducer, []);

  /**
   * Removes one or more positions both locally and remotely.
   * Dispatches a remove action to the reducer and calls the server callback.
   */
  const onRemovePosition = useCallback(
    (positionId: IdArray) => {
      if (mapRemoveLocal)
        dispatch({ type: 'remove', positionId: mapRemoveLocal(positionId) });
      else dispatch({ type: 'remove', positionId });

      if (positionId.filter(Boolean).length) {
        const toRemoveIds = getIdsIncomingKeys(positions, positionId);
        onRemovePositionOnServer?.(toRemoveIds);
      }
    },
    [onRemovePositionOnServer, mapRemoveLocal, positions]
  );

  const delayedUpdates = useRef<T[]>([]);
  /**
   * Effect to process delayed updates, syncing local updates to the remote server.
   */
  useEffect(() => {
    const pendingChanges = delayedUpdates.current
      .map((change) => {
        const persisted = positions.find(
          (y) => y.localId === change.localId && y.id
        );
        return {
          persisted,
          change,
        };
      })
      .filter((x) => x.persisted);
    pendingChanges.forEach(({ persisted, change }) => {
      const position = {
        position: { ...omit(change, 'localId'), id: persisted.id } as T,
      };
      onEditPositionOnServer?.(position);
      delayedUpdates.current = delayedUpdates.current.filter(
        (x) => x.localId !== change.localId
      );
    });
  }, [positions, onEditPositionOnServer, state]);

  /**
   * Edits an existing position, syncing updates both locally and remotely.
   * Applies changes if they differ from the current state.
   */
  const onEditPosition = useCallback(
    (resultIn: OnEditPosition<T>) => {
      const result = removeDotsInKeys(resultIn);
      const entry = positions.find(
        (x) =>
          (result.position.localId &&
            x.localId &&
            x.localId === result.position.localId) ||
          (result.position?.id && x.id && x.id === result.position?.id)
      );

      if (!entry?.id) {
        delayedUpdates.current.push(result.position);
        return;
      }
      const finalChange = Object.entries(result.position).filter((x) => {
        const [field, value] = x;
        if (result.forceUpdate) return true;
        if (typeof value === 'object') return true;
        return !isEqual(entry[field], value);
      });

      const position: T = finalChange.reduce((prev, [field, value]) => {
        prev[field] = value;
        return prev;
      }, {} as T);
      position.id = entry.id;

      if (!finalChange.length) {
        return;
      }

      dispatch({ type: 'edit', result: { position } });
      onEditPositionOnServer?.({ position: omit(position, 'localId') as T });
    },
    [onEditPositionOnServer, positions]
  );

  const lastAdded = useRef<string>(null);
  /**
   * Adds a new position to the table locally and optionally to the server.
   */
  const onAddPosition = useCallback(
    function (result: TContext) {
      if (mapAddLocal) {
        result.localId ??= uuid();
        lastAdded.current = result.localId;
        dispatch({
          type: 'add',
          newEntry: mapAddLocal(result),
          index: result.index,
        });
      }

      if (canAddRemote?.(result) ?? true) onAddPositionOnServer?.(result);
    },
    [onAddPositionOnServer, mapAddLocal, canAddRemote]
  );

  const onClonePosition = useCallback(
    (copyIds: IdArray) => {
      const ids = getIdsIncomingKeys(state, copyIds);
      const newPositions = state.filter((x) => ids.includes(x.id));
      newPositions.forEach((x) =>
        onAddPosition({ ...x, localId: uuid() } as unknown as TContext)
      );
    },
    [state, onAddPosition]
  );

  /**
   * Moves a position to a different index within the table.
   */
  const onMovePosition = useCallback(
    function (localId: string, index: number, localIndex?: number) {
      dispatch({
        type: 'move',
        positionId: localId,
        index: localIndex ?? index,
      });

      const remoteId = positions.find((x) => x.localId === localId)?.id;
      if (remoteId) onMovePositionOnServer?.(remoteId, index);
    },
    [onMovePositionOnServer, positions]
  );

  /**
   * Initializes or merges positions into the state when the `positions` prop changes.
   */
  useEffect(() => {
    dispatch({ type: 'merge', positions });
  }, [positions]);

  const collapsedHeads = useRef<T[]>(
    positions.filter((x) => STUECKLISTE_GROUP.includes(x.positionKind))
  );

  const [computedCollapsedHeads, setCollapsedHeads] = useState<T[]>(
    positions.filter((x) => STUECKLISTE_GROUP.includes(x.positionKind))
  );

  /**
   * Collapses or expands a position, updating the state accordingly.
   */
  const onCollapsePosition = useCallback((position: T) => {
    const prev = collapsedHeads.current;

    const exist = prev.find((x) => x.id === position.id);
    if (exist)
      collapsedHeads.current = prev.filter((x) => x.id !== position.id);
    else collapsedHeads.current = [...prev, position];
    setCollapsedHeads(collapsedHeads.current);
  }, []);

  const resultState = useMemo(() => {
    // newly added bom need to be collapsed
    const bomAdded = state
      .filter((x) => STUECKLISTE_GROUP.includes(x.positionKind))
      .find((x) => x.localId === lastAdded.current);

    if (bomAdded && bomAdded.id) {
      lastAdded.current = '';
      collapsedHeads.current.push(bomAdded);
    }
    const headsIds = collapsedHeads.current.map((x) => x.id);
    return state
      .filter((x) => !headsIds.includes(x.posId))
      .map((x) => {
        if (
          headsIds.includes(x.id) ||
          (x.localId === lastAdded.current && bomAdded)
        )
          return { ...x, collapsed: true };
        return x;
      });
    // we need to enforce this by setting collapse heads, but we need to modify also without rerender when new item is added
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, computedCollapsedHeads]);

  return useMemo(
    () => ({
      positions: resultState,
      allPositions: state,
      onAddPosition,
      onRemovePosition,
      onMovePosition,
      onEditPosition,
      onCollapsePosition,
      onClonePosition,
    }),
    [
      onAddPosition,
      onCollapsePosition,
      onEditPosition,
      onMovePosition,
      onClonePosition,
      onRemovePosition,
      resultState,
      state,
    ]
  );
}

export const STUECKLISTE_GROUP: EditTablePositionKind[] = [
  ErpPositionsKind.INTERNE_STUECKLISTE,
  ErpPositionsKind.STUECKLISTE,
];
