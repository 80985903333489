import styles from './PermitStatusIconCell.module.scss';

import {
  CheckCircleRounded,
  Close,
  RadioButtonUnchecked,
  Send,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { ActionStatuses } from '@work4all/components/lib/components/entity-preview/components/approval-banderole/types';

import { useUser } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { UserLike } from '@work4all/utils/lib/permissions';

import { ApprovalStatuses, useApprovalContext } from './approval-actions';

export type PermitStatusIconCellProps<T extends object> = {
  row?: Row<T>;
  value: ActionStatuses;
  canApprove?: (user: UserLike, entity: T) => boolean;
  canForward?: (user: UserLike) => boolean;
  hideForward?: boolean;
  view: 'list' | 'card';
};

export function PermitStatusIconCell<T extends object>(
  props: PermitStatusIconCellProps<T>
) {
  const { row, canApprove, canForward, hideForward, value, view } = props;

  const { t } = useTranslation();
  const user = useUser();

  const { onClick } = useApprovalContext();

  const id = row?.id;

  const canBeApproved = useMemo(
    () => (canApprove ? canApprove(user, row?.values as T) : false),
    [canApprove, row?.values, user]
  );
  const canBeForwarded = useMemo(
    () => (canForward ? canForward(user) : false),
    [canForward, user]
  );

  const { handleClick, ...menuState } = usePopoverState('bottom');
  const { onClose: closeMenu } = menuState;

  const withAutoClose =
    (
      handler: (id: string, status: ApprovalStatuses) => void,
      status: ApprovalStatuses
    ) =>
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      handler(id, status);
      closeMenu();
    };

  const approved = value === ActionStatuses.approved;
  const rejected = value === ActionStatuses.rejected;
  const openButPayed = value === ActionStatuses.openAndPaid;
  const open = value === ActionStatuses.open;

  return (
    <Stack width="100%" height="100%">
      <Box display="flex" gap={1}>
        <IconButton
          color="primary"
          className={clsx(styles.listIconButton, {
            [styles.listIconButtonDisabled]: !open && !openButPayed,
            [styles.listIconButtonGrayed]: openButPayed,
          })}
          component="button"
          tabIndex={!open && !openButPayed ? -1 : 0}
          onClick={handleClick}
        >
          {rejected ? (
            <Close sx={{ color: 'var(--error)' }} />
          ) : approved ? (
            <CheckCircleRounded sx={{ color: 'var(--success)' }} />
          ) : (
            <RadioButtonUnchecked sx={{ color: 'var(--text01)' }} />
          )}
        </IconButton>
        {view === 'list' && (
          <Button
            className={clsx(styles.listButton, {
              [styles.listButtonDisabled]: !open && !openButPayed,
            })}
            component="button"
            tabIndex={!open && !openButPayed ? -1 : 0}
            onClick={handleClick}
          >
            <Typography
              noWrap
              color={
                rejected
                  ? 'error'
                  : approved || openButPayed
                  ? 'var(--text03)'
                  : 'var(--text01)'
              }
            >
              {t(`PERMIT_STATUSES.${value.toUpperCase()}`)}
            </Typography>
          </Button>
        )}
      </Box>

      <Menu {...menuState}>
        <MenuItem
          onClick={withAutoClose(onClick, ApprovalStatuses.approve)}
          sx={{ paddingRight: 4 }}
          disabled={!canBeApproved}
        >
          <ListItemIcon>
            <CheckCircleRounded sx={{ color: 'var(--text03)' }} />
          </ListItemIcon>
          <ListItemText>
            {t('APPROVAL_CENTER.STATUS', {
              context: ApprovalStatuses.approve.toUpperCase(),
            })}
          </ListItemText>
        </MenuItem>
        {!hideForward && (
          <MenuItem
            onClick={withAutoClose(onClick, ApprovalStatuses.forward)}
            sx={{ paddingRight: 4 }}
            disabled={!canBeForwarded}
          >
            <ListItemIcon>
              <Send sx={{ color: 'var(--text03)' }} />
            </ListItemIcon>
            <ListItemText>
              {t('APPROVAL_CENTER.STATUS', {
                context: ApprovalStatuses.forward.toUpperCase(),
              })}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={withAutoClose(onClick, ApprovalStatuses.reject)}
          sx={{ paddingRight: 4 }}
          disabled={!canBeApproved}
        >
          <ListItemIcon>
            <Close sx={{ color: 'var(--text03)' }} />
          </ListItemIcon>
          <ListItemText>
            {t('APPROVAL_CENTER.STATUS', {
              context: ApprovalStatuses.reject.toUpperCase(),
            })}
          </ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  );
}
