import { get } from 'lodash';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { CRMTypes } from '@work4all/models/lib/Classes/CRMTypes.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Demand } from '@work4all/models/lib/Classes/Demand.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { ProductionContract } from '@work4all/models/lib/Classes/ProductionContract.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { RaViewModel } from '@work4all/models/lib/Classes/RaViewModel.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { TravelExpenses } from '@work4all/models/lib/Classes/TravelExpenses.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { AccesorsOf } from './paths-of/paths-of';

// TODO: It will be calrified in next iteration
export const canSeeSellingPrices = (_user) => {
  return true;
};

export const canDeleteCrmEntity = makeCanPerformEntityOperation<CRMTypes>({
  anyPermissionCode: UserRights.TelefonateAufgabenNotizenLoeschen,
  ownlyOwnPermissionCode:
    UserRights.TelefonateAufgabenNotizenAendernLoeschenEigene,
  ownageProperty: ['userId', 'user.id'],
});

export const canDeleteCallMemo =
  canDeleteCrmEntity as CanPerformEntityOperationFunction<CallMemo>;
export const canDeleteNote =
  canDeleteCrmEntity as CanPerformEntityOperationFunction<Note>;
export const canDeleteTask =
  canDeleteCrmEntity as CanPerformEntityOperationFunction<Task>;

export const canAddCrmEntity = makeHasAnyRightOf([
  UserRights.TelefonateAufgabenNotizenAnlegenAendern,
  UserRights.TelefonateAufgabenNotizenAendernLoeschenEigene,
]);

export const canAddCallMemo = canAddCrmEntity;
export const canAddNote = canAddCrmEntity;
export const canAddTask = canAddCrmEntity;

export const canEditCrmEntity = makeCanPerformEntityOperation<CRMTypes>({
  anyPermissionCode: UserRights.TelefonateAufgabenNotizenAnlegenAendern,
  ownlyOwnPermissionCode:
    UserRights.TelefonateAufgabenNotizenAendernLoeschenEigene,
  ownageProperty: ['userId', 'user.id'],
});

export const canAddEmailSignature = makeHasAnyRightOf([
  UserRights.EMailSignaturenBearbeiten,
]);
export const canEditEmailSignature = canAddEmailSignature;
export const canDeleteEmailSignature = canAddEmailSignature;
export const canAddEmailTemplate = canAddEmailSignature;
export const canEditEmailTemplate = canAddEmailTemplate;
export const canDeleteEmailTemplate = canAddEmailTemplate;

export const canAddTextBuildingBlock = makeHasAnyRightOf([
  UserRights.TextbausteineVerwalten,
]);
export const canEditTextBuildingBlock = canAddTextBuildingBlock;
export const canDeleteTextBuildingBlock = canAddTextBuildingBlock;

export const canAddTemplate = makeHasAnyRightOf([
  UserRights.VorlagenAnlegenLoeschen,
]);
export const canAddWordDocumentTemplate = canAddTemplate;
export const canAddWordLetterTemplate = canAddTemplate;

export const canEditCallMemo =
  canEditCrmEntity as CanPerformEntityOperationFunction<CallMemo>;
export const canEditNote =
  canEditCrmEntity as CanPerformEntityOperationFunction<Note>;
export const canEditTask =
  canEditCrmEntity as CanPerformEntityOperationFunction<Task>;

export const canDeleteAppointment = makeCanPerformEntityOperation<Appointment>({
  anyPermissionCode: UserRights.TermineLoeschen,
  ownlyOwnPermissionCode: UserRights.TermineAendernLoeschenEigene,
  ownageProperty: ['userId', 'user.id'],
});

export const canDeleteTemplate =
  makeCanPerformEntityOperation<WordLetterTemplate>({
    anyPermissionCode: UserRights.VorlagenAnlegenLoeschen,
  });
export const canDeleteWordDocumentTemplate = canDeleteTemplate;
export const canDeleteWordLetterTemplate = canDeleteTemplate;

export const canNotSeePrices = makeHasAnyRightOf([
  UserRights.MobilPreiseAusblenden,
]);

export const canEditWordLetterTemplate = canDeleteTemplate;
export const canEditWordLDocumentTemplate = canDeleteTemplate;

export const canManageProjectProcess = makeHasAnyRightOf([
  UserRights.ProjektvorgaengeAnlegenAendern,
]);

export const canAddAppointment = makeHasAnyRightOf([
  UserRights.TermineAnlegenAendern,
  UserRights.TermineAendernLoeschenEigene,
]);

export const canEditAppointment = makeCanPerformEntityOperation<Appointment>({
  anyPermissionCode: UserRights.TermineAnlegenAendern,
  ownlyOwnPermissionCode: UserRights.TermineAendernLoeschenEigene,
  ownageProperty: ['userId', 'user.id'],
});

export const canDeleteArticle = makeCanPerformEntityOperation<Article>({
  anyPermissionCode: UserRights.ArtikelLoeschen,
});
export const canEditArticle = makeCanPerformEntityOperation<Article>({
  anyPermissionCode: UserRights.ArtikelNeuAendern,
});
export const canAddArticle = makeHasAnyRightOf([UserRights.ArtikelNeuAendern]);

export const canEditERPStatus = makeHasAnyRightOf([
  UserRights.ERPstatusAendern,
]);

export const canDeleteCalculation = makeCanPerformEntityOperation<Calculation>({
  anyPermissionCode: UserRights.KalkulationenLoeschen,
});
export const canAddCalculation = makeHasAnyRightOf([
  UserRights.KalkulationenBearbeiten,
]);
export const canEditCalculation = makeCanPerformEntityOperation<Calculation>({
  anyPermissionCode: UserRights.KalkulationenBearbeiten,
});
export const canConvertCalculation = makeHasAnyRightOf([
  UserRights.KalkulationenVerdoppeln,
]);

export const canAddChecklist = () => true;
export const canDeleteChecklist = () => true;
export const canEditChecklist = () => true;

export const canAddTravelExpenses = makeHasAnyRightOf([
  UserRights.Reisekostenabrechnung,
]);
export const canDeleteTravelExpenses =
  makeCanPerformEntityOperation<TravelExpenses>({
    anyPermissionCode: UserRights.Reisekostenabrechnung,
  });
export const canEditTravelExpenses =
  makeCanPerformEntityOperation<TravelExpenses>({
    anyPermissionCode: UserRights.Reisekostenabrechnung,
  });

export const canDeleteTicket = makeCanPerformEntityOperation<Ticket>({
  anyPermissionCode: UserRights.TicketLoeschen,
});
export const canAddTicket = makeHasAnyRightOf([
  UserRights.TicketAnlegenAendern,
]);
export const canEditTicket = makeCanPerformEntityOperation<Ticket>({
  anyPermissionCode: UserRights.TicketAnlegenAendern,
});

export const canDeleteContact = (user: UserLike, entity: Contact) => {
  if (entity.businessPartnerType === SdObjType.KUNDE)
    return canDeleteCustomerContact(user, entity);
  if (entity.businessPartnerType === SdObjType.LIEFERANT)
    return canDeleteSupplierContact(user, entity);

  return false;
};
export const canDeleteCustomerContact = makeCanPerformEntityOperation<Contact>({
  anyPermissionCode: UserRights.KundenAnsprechpartnerLoeschen,
});
export const canDeleteSupplierContact = makeCanPerformEntityOperation<Contact>({
  anyPermissionCode: UserRights.LieferantenAnsprechpartnerLoeschen,
});

export const canEditContact = (user: UserLike, entity: Contact) => {
  if (entity.businessPartnerType === SdObjType.KUNDE)
    return canEditCustomerContact(user, entity);
  if (entity.businessPartnerType === SdObjType.LIEFERANT)
    return canEditSupplierContact(user, entity);

  return false;
};
export const canEditCustomerContact = makeCanPerformEntityOperation<Contact>({
  anyPermissionCode: UserRights.KundenAnsprechpartnerNeuAendern,
});
export const canEditSupplierContact = makeCanPerformEntityOperation<Contact>({
  anyPermissionCode: UserRights.LieferantenAnsprechpartnerNeuAendern,
});

export const canAddCustomerContact = makeHasAnyRightOf([
  UserRights.KundenAnsprechpartnerNeuAendern,
]);

export const canAddSupplierContact = makeHasAnyRightOf([
  UserRights.LieferantenAnsprechpartnerNeuAendern,
]);

export const canViewUserList = makeHasAnyRightOf([UserRights.Mitarbeiterliste]);

export const canAddUser = makeHasAnyRightOf([UserRights.MitarbeiterNeuAendern]);
export const canEditUser = canAddUser;

export const canDeleteUser = makeCanPerformEntityOperation<FullUser>({
  anyPermissionCode: UserRights.MitarbeiterLoeschen,
});

export const canDeleteContract = makeCanPerformEntityOperation<Contract>({
  anyPermissionCode: UserRights.AuftraegeLoeschen,
});
export const canAddContract = makeHasAnyRightOf([
  UserRights.AuftragsbestaetigungErstellenAendern,
]);
export const canEditContract = makeHasAnyRightOf([
  UserRights.AuftragsbestaetigungErstellenAendern,
]);
export const canConvertContract = makeHasAnyRightOf([
  UserRights.AuftraegeVerdoppeln,
]);
export const canDeleteCustomer = makeCanPerformEntityOperation<Customer>({
  anyPermissionCode: UserRights.KundenLoeschen,
});
export const canAddCustomer = makeHasAnyRightOf([UserRights.KundenNeuAendern]);
export const canEditCustomer = makeCanPerformEntityOperation<Customer>({
  anyPermissionCode: UserRights.KundenNeuAendern,
});

export const canDeleteDeliveryNote =
  makeCanPerformEntityOperation<DeliveryNote>({
    anyPermissionCode: UserRights.LieferscheineLoeschen,
  });
export const canAddDeliveryNote = makeHasAnyRightOf([
  UserRights.LieferscheinErstellenAendern,
]);
export const canEditDeliveryNote = makeCanPerformEntityOperation<DeliveryNote>({
  anyPermissionCode: UserRights.LieferscheinErstellenAendern,
});
export const canConvertDeliveryNote = makeHasAnyRightOf([
  UserRights.LieferscheineVerdoppeln,
]);

export const canDeleteProductionContract =
  makeCanPerformEntityOperation<ProductionContract>({
    anyPermissionCode: UserRights.ProduktionsauftraegeLoeschen,
  });
export const canAddProductionContract = makeHasAnyRightOf([
  UserRights.ProduktionsauftraegeErstellenAendern,
]);
export const canEditProductionContract =
  makeCanPerformEntityOperation<ProductionContract>({
    anyPermissionCode: UserRights.ProduktionsauftraegeErstellenAendern,
  });

export const canAddDemand = makeHasAnyRightOf([
  UserRights.BedarfErstellenAendern,
]);
export const canDeleteDemand = makeCanPerformEntityOperation<Demand>({
  anyPermissionCode: UserRights.BedarfsmeldungenLoeschen,
});
export const canEditDemand = makeHasAnyRightOf([
  UserRights.BedarfErstellenAendern,
]);

export const canConvertDemand = makeHasAnyRightOf([
  UserRights.BedarfsanforderungenVerdoppeln,
]);

export const canDeleteDocument = makeCanPerformEntityOperation<
  Document | EMail
>({
  anyPermissionCode: UserRights.KorrespondenzLoeschen,
  ownlyOwnPermissionCode: UserRights.KorrespondenzLoeschenEigene,
  ownageProperty: ['userId', 'user.id'],
});
export const canAddDocument = makeHasAnyRightOf([
  UserRights.KorrespondenzAnlegenAendern,
  UserRights.KorrespondenzLoeschenEigene,
]);
export const canEditDocument = makeCanPerformEntityOperation<Document | EMail>({
  anyPermissionCode: UserRights.KorrespondenzAnlegenAendern,
  ownlyOwnPermissionCode: UserRights.KorrespondenzLoeschenEigene,
  ownageProperty: ['userId', 'user.id'],
});

export const canDeleteEmail = canDeleteDocument;
export const canAddEmail = canAddDocument;
export const canEditEmail = canEditDocument;

export const canAddInboundDeliveryNote = makeHasAnyRightOf([
  UserRights.EingangslieferscheinAnlegenAendern,
]);
export const canDeleteInboundDeliveryNote =
  makeCanPerformEntityOperation<InboundDeliveryNote>({
    anyPermissionCode: UserRights.EingangslieferscheinLoeschen,
  });
export const canEditInboundDeliveryNote =
  makeCanPerformEntityOperation<InboundDeliveryNote>({
    anyPermissionCode: UserRights.EingangslieferscheinAnlegenAendern,
  });

export const canConvertInboundDeliveryNote = makeHasAnyRightOf([
  UserRights.EingangslieferscheineVerdoppeln,
]);

export const canAddSalesOpportunities = makeHasAnyRightOf([
  UserRights.VerkaufschancenNutzen,
  UserRights.VerkaufschancenNurEigeneAendernLoeschen,
]);
export const canEditSalesOpportunities =
  makeCanPerformEntityOperation<SalesOpportunities>({
    anyPermissionCode: UserRights.VerkaufschancenNutzen,
    ownlyOwnPermissionCode: UserRights.VerkaufschancenNurEigeneAendernLoeschen,
    ownageProperty: ['creator.id'],
  });
export const canDeleteSalesOpportunities =
  makeCanPerformEntityOperation<SalesOpportunities>({
    anyPermissionCode: UserRights.VerkaufschancenNutzen,
    ownlyOwnPermissionCode: UserRights.VerkaufschancenNurEigeneAendernLoeschen,
    ownageProperty: ['creator.id'],
  });

export const canDeleteInboundInvoice =
  makeCanPerformEntityOperation<InboundInvoice>({
    anyPermissionCode: UserRights.EingangsrechnungenLoeschen,
  });
export const canEditInboundInvoice = (
  user: UserLike,
  enity: InboundInvoice
) => {
  return !canOnlyViewInboundInvoice(user, enity);
};
const canOnlyViewInboundInvoice = makeCanPerformEntityOperation<InboundInvoice>(
  {
    anyPermissionCode: UserRights.EingangsrechnungNurAnschauen,
  }
);

export const canAddInboundInvoice = (user: UserLike) =>
  !makeHasAnyRightOf([UserRights.EingangsrechnungNurAnschauen])(user);

export const canDeleteInvoice = makeCanPerformEntityOperation<Invoice>({
  anyPermissionCode: UserRights.RechnungenLoeschen,
});
export const canAddInvoice = makeHasAnyRightOf([
  UserRights.RechnungErstellenAendern,
]);
export const canEditInvoice = makeCanPerformEntityOperation<Invoice>({
  anyPermissionCode: UserRights.RechnungErstellenAendern,
});
export const canConvertInvoice = makeHasAnyRightOf([
  UserRights.RechnungenVerdoppeln,
]);

export const canEditRa = makeCanPerformEntityOperation<RaViewModel>({
  anyPermissionCode: UserRights.RechnungsausgangsbuchEinsehenBearbeiten,
});

export const canDeleteRa = canEditRa;

export const canDeleteLetter = makeCanPerformEntityOperation<Letter>({
  anyPermissionCode: UserRights.KorrespondenzLoeschen,
  ownlyOwnPermissionCode: UserRights.KorrespondenzLoeschenEigene,
  ownageProperty: ['userId', 'user.id'],
});
export const canAddLetter = makeHasAnyRightOf([
  UserRights.KorrespondenzAnlegenAendern,
]);
export const canEditLetter = makeCanPerformEntityOperation<Letter>({
  anyPermissionCode: UserRights.KorrespondenzAnlegenAendern,
});

export const canDeleteOffer = makeCanPerformEntityOperation<Offer>({
  anyPermissionCode: UserRights.AngebotLoeschen,
});
export const canAddOffer = makeHasAnyRightOf([
  UserRights.AngebotErstellenAendern,
]);
export const canEditOffer = makeCanPerformEntityOperation<Offer>({
  anyPermissionCode: UserRights.AngebotErstellenAendern,
});
export const canConvertOffer = makeHasAnyRightOf([
  UserRights.AngeboteVerdoppeln,
]);

export const canAddOrder = makeHasAnyRightOf([
  UserRights.BestellungErstellenAendern,
]);

export const canDeleteOrder = makeCanPerformEntityOperation<Order>({
  anyPermissionCode: UserRights.BestellungenLoeschen,
});
export const canEditOrder = makeCanPerformEntityOperation<Order>({
  anyPermissionCode: UserRights.BestellungErstellenAendern,
});

export const canConvertOrder = makeHasAnyRightOf([
  UserRights.BestellungenVerdoppeln,
]);

export const canDeleteProject = makeCanPerformEntityOperation<Project>({
  anyPermissionCode: UserRights.ProjekteLoeschen,
});
export const canAddProject = makeHasAnyRightOf([
  UserRights.ProjekteNeu,
  UserRights.ProjekteAendernLoeschenEigene,
]);
export const canEditProject = makeCanPerformEntityOperation<Project>({
  anyPermissionCode: UserRights.ProjektAendern,
  ownlyOwnPermissionCode: UserRights.ProjekteAendernLoeschenEigene,
  ownageProperty: ['creatorUserId', 'creator.id'],
});

export const canDeleteSupplier = makeCanPerformEntityOperation<Supplier>({
  anyPermissionCode: UserRights.LieferantenLoeschen,
});
export const canAddSupplier = makeHasAnyRightOf([
  UserRights.LieferantenNeuAendern,
]);
export const canEditSupplier = makeCanPerformEntityOperation<Supplier>({
  anyPermissionCode: UserRights.LieferantenNeuAendern,
});

export const canDeleteVisitationReport =
  makeCanPerformEntityOperation<VisitationReport>({
    anyPermissionCode: UserRights.BesuchsberichteLoeschen,
    ownlyOwnPermissionCode: UserRights.BesuchsberichteAendernLoeschenEigene,
    ownageProperty: ['userId', 'user.id'],
  });
export const canAddVisitationReport = makeHasAnyRightOf([
  UserRights.BesuchsberichteAnlegenAendern,
  UserRights.BesuchsberichteAendernLoeschenEigene,
]);
export const canEditVisitationReport =
  makeCanPerformEntityOperation<VisitationReport>({
    anyPermissionCode: UserRights.BesuchsberichteAnlegenAendern,
    ownlyOwnPermissionCode: UserRights.BesuchsberichteAendernLoeschenEigene,
    ownageProperty: ['userId', 'user.id'],
  });

export const canAddTimeTrackingForProjects = makeHasAnyRightOf([
  UserRights.KostenerfassungNutzen, //dont be buffled by the seemingly wrong rights name, this one was falsley mappe in 1.0 already and for backwards compatibility reasons we had to stick with it
]);

export const canEditTimeTrackingForOthers =
  makeCanPerformEntityOperation<TimeTracking>({
    anyPermissionCode: UserRights.ZeiterfassungFuerAndereBenutzer,
    ownlyOwnPermissionCode: UserRights.KostenerfassungNutzen,
    ownageProperty: ['creator.id'],
  });

export const canViewPurchasePriceColumn = makeHasAnyRightOf([
  UserRights.EKPreiseAnzeigen,
]);

export const canAddPayment = makeHasAnyRightOf([UserRights.ZahlungenErfassen]);

export const canViewOtherCalendars = makeHasAnyRightOf([
  UserRights.FremdeKalenderEinsehen,
]);

export const canViewSellingPrices = makeHasAnyRightOf([
  UserRights.VKPreiseInZeiterfassungAnzeigen,
]);

export const canViewERPPrices = (user: UserLike) =>
  !makeHasAnyRightOf([UserRights.Web_PreiseInERPBelegenAusblenden])(user);

export const canViewInboundInvoicesApprovalCenter = makeHasAnyRightOf([
  UserRights.FreigabeEingangsrechnungen,
  UserRights.FreigabeEingangsrechnungenNurEigene,
  UserRights.FreigabeEingangsrechnungenNurWeiterleiten,
]);

export const canApproveOwnInboundInvoices =
  makeCanPerformEntityOperation<ReViewModel>({
    anyPermissionCode: UserRights.FreigabeEingangsrechnungen,
    ownlyOwnPermissionCode: UserRights.FreigabeEingangsrechnungenNurEigene,
    ownageProperty: ['user.id'],
  });

export const canForwardInboundInvoices = makeHasAnyRightOf([
  UserRights.FreigabeEingangsrechnungenNurWeiterleiten,
]);

export const canApproveVacations = makeHasAnyRightOf([
  UserRights.UrlaubsgenehmigungErteilen,
]);

export const canViewSicknessDays = makeHasAnyRightOf([
  UserRights.KalenderKrankheitstageAnzeigen,
]);

export const canEditCustomerNumber = makeHasAnyRightOf([
  UserRights.KundenDebitorennummerAendern,
]);

export const canEditSupplierNumber = makeHasAnyRightOf([
  UserRights.LieferantenKreditorennummerAendern,
]);

export const canManageAddressConnection = makeHasAnyRightOf([
  UserRights.AdressverknuepfungVerwalten,
]);

export interface UserLike {
  benutzerCode?: number;
  benutzerRechte?: string | null;
}

export interface CanPerformEntityOperationFunction<T> {
  (user: UserLike, entity: T): boolean;
}

interface IMakeCanPerformEntityOperationParams<T extends object> {
  /**
   * Permission code that allows to perform an operation on any entity of a given type.
   */
  anyPermissionCode: UserRights;
  /**
   * Permission code that allows to perform an operation on own entity of a given type.
   *
   * This will only be used if the `can perform on any entity` check does not pass.
   *
   * Can be `null` where a user can't own an entity. In this case it will be
   * ignored.
   *
   * @default null
   */
  ownlyOwnPermissionCode?: UserRights | null;
  /**
   * A list of properties to compare with the current user's id.
   *
   * This will only be used if the `can perform on any entity` check does not pass.
   *
   * You can specify multiple paths in dot notation and the first non-undefined
   * value will be compared against the user id. If the list is empty will
   * always return `false`.
   *
   * Ignored if `ownlyOwnPermissionCode` is `null`.
   *
   * @default null
   */
  ownageProperty?: AccesorsOf<T, 2>[] | null;
}

export function makeHasAnyRightOf(rightsToCheck: UserRights[]) {
  const userHasRight = (user: UserLike) => {
    const found = rightsToCheck.find((right) =>
      checkUserRight(right, user.benutzerRechte)
    );
    return found === undefined ? false : true;
  };

  return userHasRight;
}

function makeCanPerformEntityOperation<
  //eslint-disable-next-line
  T extends { id?: string | number; [key: string]: any }
>(
  params: IMakeCanPerformEntityOperationParams<T>
): CanPerformEntityOperationFunction<T> {
  const {
    anyPermissionCode,
    ownlyOwnPermissionCode = null,
    ownageProperty = null,
  } = params;

  const canPerformOperationOnEntity: CanPerformEntityOperationFunction<T> = (
    user,
    entity
  ) => {
    const hasRightForAnyEntityOfType = checkUserRight(
      anyPermissionCode,
      user.benutzerRechte
    );

    if (hasRightForAnyEntityOfType) {
      return true;
    }

    if (ownlyOwnPermissionCode === null || ownageProperty === null) {
      return false;
    }

    const canPerformOnlyOnOwnOfType = checkUserRight(
      ownlyOwnPermissionCode,
      user.benutzerRechte
    );

    if (canPerformOnlyOnOwnOfType) {
      for (const path of ownageProperty) {
        const value = get(entity, path as AccesorsOf<T>);
        if (value !== undefined) {
          return value === user.benutzerCode;
        }
      }
    }

    return false;
  };

  return canPerformOperationOnEntity;
}
