import styles from './ComponentsTabPanel.module.scss';

import { Box, Theme, useMediaQuery } from '@mui/material';
import { useCallback, useRef } from 'react';
import { TableInstance } from 'react-table';
import { uuid } from 'short-uuid';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import {
  ColumnVisibilityContext,
  useColumnVisibilityManager,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { BOMComponent } from '@work4all/models/lib/Classes/BOMComponent.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../settings';
import { withAutoDisable } from '../../../../utils/with-auto-disable';
import { EditTable } from '../../../erp/components/tab-panels/positions/components/edit-table/EditTable';
import { IEditTable } from '../../../erp/components/tab-panels/positions/components/edit-table/types';
import {
  SelectedPositionProvider,
  useSelectedPositionState,
} from '../../../erp/components/tab-panels/positions/components/hooks/use-selected-position';
import { PositionPicker } from '../../../erp/components/tab-panels/positions/components/position-picker/PositionPicker';

import { ComponentsMask } from './components/ComponentsMask';
import { ComponentsTabPanelTableHeader } from './components/ComponentsTabPanelTableHeader';
import { useBomComponentsColumns } from './hooks/use-bom-components-columns';
import { useBomState } from './hooks/use-bom-state';
import { useComponentsMobileViews } from './hooks/use-components-mobile-view';
import { DEFAULT_BOM_COLUMNS } from './types';

interface ComponentsTabPanelProps {
  disabled: boolean;
}

function ComponentsTabPanelInner(props: ComponentsTabPanelProps) {
  const { disabled } = props;

  const tableInstanceRef = useRef<TableInstance>(null);

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const columnSettings = useSetting(
    settings.erpPositionsConfig(Entities.bOMComponent, DEFAULT_BOM_COLUMNS)
  );
  const handleVisibilityChange = useCallback(
    (columns: string[]) => {
      if (!columns.filter((x) => SELECTION_COLUMN_ID !== x).length) return;
      columnSettings.set({
        ...columnSettings.value,
        order: columns,
        visibility: columns,
      });
    },
    [columnSettings]
  );
  const columnsVisibilityManager = useColumnVisibilityManager({
    tableInstanceRef,
    onVisibilityChange: handleVisibilityChange,
  });

  const previewSize = useSetting(settings.erpArticleSize());
  const erpShowArticle = useSetting(settings.erpShowArticle());

  const editTableRef = useRef<IEditTable>(null);
  const editState = useBomState({ editTableRef });
  const columns = useBomComponentsColumns(editState);

  const erpSize = useSetting(settings.erpRowSize());

  const { setSelectedPositionIds } = useSelectedPositionState();

  const onDropItem = useCallback(
    (article: Article) => editState.onAddPosition({ article }),
    [editState.onAddPosition]
  );

  const { renderMobilePosition, classes } = useComponentsMobileViews();

  return (
    <ColumnVisibilityContext.Provider value={columnsVisibilityManager}>
      <Box className={styles.container}>
        <div className={styles.tableWrapper}>
          {!isMobile && (
            <ComponentsTabPanelTableHeader
              tableInstanceRef={tableInstanceRef}
              disabled={disabled}
              positions={editState.positions}
              onRemovePosition={editState.onRemovePosition}
              onClonePosition={editState.onClonePosition}
              addCacheRow={() => {
                const localId = uuid();
                editState.onAddPosition({
                  cacheOnly: true,
                  localId,
                });
                setTimeout(() => {
                  editTableRef.current?.setEditMode(localId, 'name', ['name']);
                }, 100);
              }}
            />
          )}
          <Box display="flex" flex="1" flexDirection="column" overflow="hidden">
            <EditTable<BOMComponent>
              {...editState}
              onDropItem={onDropItem}
              // refs
              ref={editTableRef}
              tableInstanceRef={tableInstanceRef}
              items={editState.positions}
              columns={columns}
              columnSettings={columnSettings}
              onSelectedItemIdsChange={setSelectedPositionIds}
              itemSize="auto"
              bordersKey={'BOM'}
              rowSizeMode={erpSize.value}
              renderMobileItem={renderMobilePosition}
              classes={classes}
              mask={isMobile ? ComponentsMask : undefined}
            />
          </Box>
        </div>

        {!isMobile && (
          <ResizableArea
            handles="top"
            className={styles.resizable}
            direction="vertical"
            size={
              erpShowArticle.value
                ? previewSize.value
                : { height: remToPx(3.25) }
            }
            onResize={(size) => {
              previewSize.set(size);
              erpShowArticle.set(true);
            }}
            maxHeight={1000}
            minHeight={erpShowArticle.value ? remToPx(8.5) : remToPx(3.25)}
          >
            <PositionPicker
              disabled={disabled}
              className={styles.picker}
              onAddStandard={(article) => {
                editState.onAddPosition({ article });
              }}
            />
          </ResizableArea>
        )}
      </Box>
    </ColumnVisibilityContext.Provider>
  );
}

export const ComponentsTabPanel = withAutoDisable(({ disabled }) => (
  <SelectedPositionProvider>
    <ComponentsTabPanelInner disabled={disabled} />
  </SelectedPositionProvider>
));
